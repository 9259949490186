// Large button
// Included in: _jack.scss
@mixin c-button-large() {
  --button-padding-top: #{rem-baseline(2)};
  --button-padding-bottom: #{rem-baseline(2)};
  --button-border-radius: #{rem-baseline(4)};
  --button-height: #{$button-height-large};
  --button-icon-trans: translateY(-#{rem-calc(2)});
  @include font-button($size: 'large');
}

// Card link
//
// Huge link as a card with icon and description text.
//
// `@require` [card](section-containers.html#kssref-containers-cards-card)
//
// .c-cardlink--highlight - Link has transparent background, dotted border and icon inside of circle.
//
// Markup: cardlink.hbs
//
// Type: component
//
// Style guide: Controls.links.cardlink
// @TODO: use CSS vars and remove mixin

$cardlink-heading-color: var(--color-dark);
$cardlink-description-color: var(--color-slate-petrol);
$cardlink-icon-color: var(--color-orange);
$cardlink-desc-maxwidth: rem-calc(300);
$cardlink-icon-size: rem-calc(40);
$cardlink-add-background: rgba(255, 255, 255, .5);

/** **************
 * Basic styles
 */
.c-cardlink {
  @include c-card;
  display: block;
  text-align: center;
  width: 100%;

  &:hover {
    box-shadow: var(--shadow-medium);
  }

  .c-icon {
    @include u-baseline(1, margin-top);
    color: $cardlink-icon-color;
  }

  svg {
    width: 2.5em;
    height: 2.5em;
  }

  p {
    margin: 0;

    &:empty {
      margin: 0;
    }

    &:first-of-type {
      @include u-baseline(1, margin-top);
    }
  }
}





/** **************
 * Modifier: Link has transparent background and dotted border.
 */
.c-cardlink--highlight {
  @include flex;
  align-items: center;
  justify-content: center;
  background-color: $cardlink-add-background;
  border: 1px dotted var(--color-orange);

  .c-icon {
    background-color: var(--color-orange);
    border-radius: 50%;
    color: var(--color-light);
    margin-top: 0;
    padding: rem-calc(8);
    transform: translate3d(0, 0, 0);
  }
}





/** **************
 * Heading
 */
p.c-cardlink__heading {
  @include font-meta;
  @include font-bold-important;
  color: $cardlink-heading-color;
}





/** **************
 * Description
 */
.c-cardlink__description {
  @include u-inner-max($width: $cardlink-desc-maxwidth);
  color: $cardlink-description-color;
}

/// Offcanvas site menu
///
/// NOT A MODULE – belongs to global scaffold

@import '_config';
@import '_mixins';

.m-offcanvas {
  background-color: $offcanvas-background;

  @include breakpoint($global-navigation-breakpoint) {
    background-color: var(--color-dark);
    height: auto !important; // JS override
  }

  > #{&}__meta,
  > ul > .m-navbar__item:not(.m-navbar__item--meta) {
    background-color: var(--color-dark);
  }
}




/** **************
 * Modifier: Element is open
 */
.m-offcanvas--is-open {
  transform: translateX(rem-calc($global-navigation-mobile-width));
}




/** **************
 * Meta
 */
.m-offcanvas__meta {
  @include offcanvas-meta;
  font-size: 0;

  @include breakpoint($global-navigation-breakpoint) {
    display: none;
  }

  a:not([class*='u-link']) {
    color: var(--color-light);
  }

  svg {
    display: block;
    width: rem-calc($global-logo-width-small);
    height: rem-calc($global-logo-height-small);
  }
}




/** **************
 * Item
 */
.m-offcanvas__item {
  @include offcanvas-meta;
  color: var(--color-light);
  height: auto;
  line-height: 1.5;
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(20);
  position: relative;

  @include breakpoint($global-navigation-breakpoint) {
    border-bottom: none;
    color: var(--color-dark);
    display: inline-block;
    padding: 0;
  }

  &:hover {
    color: var(--color-light);
  }

  p {
    margin-top: 0;
  }

  &.m-multimenu__category {
    margin-bottom: 1px;
  }
}




/** **************
 * Title
 */
.m-offcanvas__title {
  @include breakpoint($global-navigation-breakpoint) {
    display: none;
  }

  label {
    @include offcanvas-meta;
    border-color: var(--color-slush-ice);
    display: block;

    @include breakpoint($global-navigation-breakpoint) {
      display: none;
    }
  }

  svg {
    @include u-baseline(1, margin-right);
    transform: rotate(180deg);
    width: .5em;
    height: .5em;
  }
}




/** **************
 * Category
 */
.m-offcanvas__category {
  @include offcanvas-meta;
  border-bottom: $offcanvas-border;
  color: $offcanvas-category-color;
  display: block;
  font-size: rem-calc(16);
  line-height: 1.5;
  margin: 0;
  position: relative;

  @include breakpoint($global-navigation-breakpoint) {
    display: none;
  }
}




/** **************
 * Sub menu
 */
.m-offcanvas__sub {
  background-color: $offcanvas-sub-background;
  border-right: $offcanvas-sub-border;
  color: $offcanvas-sub-color;
  display: none;
  position: absolute;
  padding: 0;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translate(-($global-navigation-mobile-width), 0);
  width: $global-navigation-mobile-width;
  transition: var(--transition-default);
  z-index: 1;

  @include breakpoint($global-navigation-breakpoint) {
    border: none;
    background-color: transparent;
    display: inline-block;
    transform: translate(0, 0);
    width: auto;
    bottom: auto;
    position: relative;
    transition: none;
    top: auto;
    right: 0;
  }

  * {
    border-color: var(--color-slush-ice);
  }

  .m-offcanvas__category {
    height: auto;
    padding-top: rem-calc(22);
    padding-bottom: rem-calc(22);
  }

  .m-offcanvas__item {
    background-color: var(--color-slush-ice);
    margin-bottom: rem-calc(1) !important;

    @include breakpoint($global-navigation-breakpoint) {
      &:last-of-type {
        margin-bottom: 0 !important;
      }

      &:not(.m-multimenu__category) {
        background-color: transparent;
      }
    }
  }

  // Toggle is checked
  &[data-state='open'] {
    display: inline-block;
    transform: translate(0, 0);
    z-index: 2;
  }
}




/** **************
 * Permalink
 */
.m-offcanvas__permalink {
  @include breakpoint($global-navigation-breakpoint) {
    display: none;
  }
}




/** **************
 * Handler
 */
.m-offcanvas__handler {
  @include u-baseline(8, height);
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  @include breakpoint($global-navigation-breakpoint) {
    display: none;
  }
}





/**
 * Wrapper to provide W3C compatibility
 * and avoiding nested label
 */
.m-offcanvas__item-wrap {
  display: block;
  position: relative;
}





/** **************
 * Toggle
 */
.m-offcanvas__toggle {
  position: absolute;
  top: -9999px;
  left: -9999px;

  @include breakpoint($global-navigation-breakpoint) {
    display: none;
  }
}

// Socializer
//
// Log in with a social account.
//
// Markup: socializer.hbs
//
// Type: module
//
// Style guide: Containers.socializer
.m-socializer {
  @include u-baseline(1, padding-top);
  position: relative;
}









/** ***********
 * Items
 */
.m-socializer__items {
  @include flex;
  @include flex-align(center, top);
  @include u-baseline(1, padding-top);
  flex-wrap: wrap;
}










/** ***********
 * Item
 */
.m-socializer__item {
  @include u-baseline(3, margin-bottom);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  flex: 1 1 auto;

  @include breakpoint(medium) {
    flex: 0 1 50%;
  }

  @include breakpoint(large) {
    flex: 1 1 auto;
  }

  @include breakpoint(xxlarge) {
    flex: 0 1 50%;
  }
}

// Wishlists table objects
//

/** **************
 * New list link
 */
.o-wishlists__new {
  @include u-link($global-link-options-dark);
  text-align: right;

  .c-icon {
    @include u-baseline(1, margin-right);
  }

  svg {
    width: rem-calc(24);
    height: rem-calc(28);
  }
}




/** **************
 * Search form
 */
.o-wishlists__search {
  position: relative;

  .c-icon {
    @include u-baseline(1.5, top);
    @include u-baseline(2, right);
    color: var(--color-slush-slate);
    position: absolute;
  }

  input[type='text'],
  input[type='search'] {
    @include u-baseline(3, padding-right);
    margin-bottom: 0;
  }
}

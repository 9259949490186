// Facts & Figures
//
// Display facts more figurative.
//
// Markup: factsfigures.hbs
//
// Type: module
//
// Javascript: true
//
// Style guide: Containers.factsfigures

$factsfigures-breakpoint: xlarge;

.m-factsfigures__grid {
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(auto-fit, minmax(min-content, #{rem-calc(160)}));
  grid-template-rows: min-content;
  gap: rem-baseline(10) 0;
  justify-content: center;
  align-content: flex-start;
  justify-items: center;
  align-items: flex-start;

  @include breakpoint(large) {
    grid-template-columns: repeat(auto-fit, minmax(min-content, 25%));
  }
}


.m-factsfigures {
  text-align: center;

  [data-module='countup'] {
    display: inline-block;
    transition: var(--transition-smooth);
    opacity: 0;
  }
}



/** ***********
 * Icon
 */
.m-factsfigures__icon {
  @include u-baseline(8, width);
  @include u-baseline(8, height);
  color: var(--color-slush-ice);
  display: block;
  margin: 0 auto rem-baseline(3) auto;

  @include breakpoint($factsfigures-breakpoint) {
    @include u-baseline(11, width);
    @include u-baseline(11, height);
  }
}





/** ***********
 * Figure + Suffix
 */
.m-factsfigures__figure {
  font: var(--font-32-display);
  color: var(--color-petrol);
  grid-area: values;

  @include breakpoint(large) {
    font: var(--font-40-display);
  }

  @include breakpoint(xlarge) {
    font: var(--font-64-display);
  }
}

.m-factsfigures__suffix {
  color: var(--color-slush);
}




/** ***********
 * Description
 */
.m-factsfigures__description {
  @include font-meta;
  color: var(--color-iron);
  grid-area: description;

  a {
    @include u-link($global-link-options-underline);
  }
}

// Post
//
// Post item for the Lichtjournal and search results.
//
// Overrides [.c-button](section-controls.html#kssref-controls-button) styles.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x30      |
// | large         | 400x400    |
//
// .m-post--small - Post with smaller image (search results)
//
// Markup: post.hbs
//
// Type: module
//
// Style guide: Containers.post

$post-heading-color: var(--color-dark);
$post-xlarge-gutters: rem-calc($global-xlarge-gutter);

/** **************
 * Basic styles
 */
.m-post {
  @include u-module-margin;
  @include u-baseline(3, padding-bottom);
  border-bottom: $global-border-dotted;

  @include breakpoint(large) {
    @include u-baseline(5, padding-bottom);
  }

  .c-button {
    display: none;

    @include breakpoint(medium) {
      display: inline-block;
    }
  }
}






/** ***********
 * Modifier: Small post
 */
.m-post--small {
  .m-post__img {
    @include xy-cell(3);

    @include breakpoint(xlarge) {
      @include xy-cell(3, $gutters: $post-xlarge-gutters);
    }

    + .m-post__entry {
      @include xy-cell(9);

      @include breakpoint(xlarge) {
        @include xy-cell(9, $gutters: $post-xlarge-gutters);
      }
    }
  }
}







/** **************
 * Inner container
 */
.m-post__inner {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
  @include u-row-padding($negative: true);
}




/** **************
 * Image
 */
.m-post__img {
  @include xy-cell(4);

  @include breakpoint(medium) {
    @include xy-cell(5);
  }

  @include breakpoint(large) {
    @include xy-cell(5);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(5, $gutters: $post-xlarge-gutters);
  }

  + .m-post__entry {
    @include xy-cell(8);

    @include breakpoint(medium) {
      @include xy-cell(7);
    }

    @include breakpoint(xlarge) {
      @include xy-cell(7, $gutters: $post-xlarge-gutters);
    }
  }
}






/** ***********
 * Entry
 */
.m-post__entry {
  @include u-single-cell;
  text-wrap: balance;
}








/** ***********
 * Heading
 */
.m-post__heading {
  @include font-h2;
  @include font-bold-important;
  @include u-baseline(1, margin-bottom);
  margin-top: 0;

  a {
    @include u-link($global-link-options-dark);
  }
}






/** ***********
 * Subheading
 */
.m-post__subheading {
  color: var(--color-slate-petrol);
  display: block;
  margin: 0;
}









/** ***********
 * Excerpt
 */
.m-post__excerpt {
  @include font-meta;
  display: none;

  @include breakpoint(medium) {
    display: block;
  }
}

// Image upload
//
// Form image upload with preview.
//
// Markup: forms-image.hbs
//
// Type: component
//
// Javascript: true
//
// Style guide: Forms.forms-image

.c-forms-image {
  position: relative;

  input[type='file'] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}





/** ***********
 * Wrapper
 */
.c-forms-image__wrapper {
  background: var(--color-light);
  border: 1px dashed var(--color-petrol);
  border-radius: rem-calc($global-border-radius);
  color: var(--color-petrol);
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;

  &:focus-within {
    background-color: var(--color-geyser);
  }
}







/** ***********
 * Label
 */
.c-forms-image__label {
  @include font-small;
  @include font-bold-important;
  @include u-baseline(1, padding-left);
  @include u-baseline(1, padding-right);
  color: var(--color-petrol);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 24ch;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  small {
    @include font-tiny;
  }

  .c-icon {
    @include u-baseline(1, margin-bottom);
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 2em;
    height: 2em;
  }
}





/** ***********
 * Delete link
 */
.c-forms-image__delete {
  @include u-baseline(-1, top);
  @include u-baseline(-1, right);
  @include u-baseline(3, width);
  @include u-baseline(3, height);
  appearance: none;
  background-color: var(--color-light);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  font-size: 0;
  padding: 0;
  position: absolute;
  z-index: 3;

  .c-icon {
    color: var(--color-warning);
    display: block;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
  }
}





/** ***********
 * Image preview
 */
.c-forms-image__preview {
  background-color: var(--color-light);
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;

  img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    z-index: 1;
  }
}

// Note
//
// Message notes in templates.
//
// .m-note--is-error - Note is a blocker.
// .m-note--is-success - Note is a success.
//
// Markup: note.hbs
//
// Type: module
//
// Style guide: Containers.note

$note-icon-color-default: var(--color-orange);
$note-background-default: var(--color-orange-light);
$note-icon-color-error: var(--color-warning);
$note-background-error: #f8e4e4;
$note-border: 1px dashed;
$note-border-color-default: var(--color-orange);
$note-border-color-error: $note-icon-color-error;

.m-note {
  @include font-small; // override
  @include u-baseline(3, padding-top);
  @include u-baseline(3, padding-bottom);
  @include u-baseline(7.5, padding-left);
  @include u-baseline(4, padding-right);
  background-color: var(--note-background, #{$note-background-default});
  border-left: rem-calc(4) solid var(--note-border-color, var(--color-slate-petrol));
  border-color: var(--note-border-color, #{$note-border-color-default});
  list-style: none;
  margin: 0;
  position: relative;
  max-width: var(--note-max-width);
  width: 100%;

  a:not(.c-button) {
    @include u-link($global-link-options-underline);
  }

  + .m-note {
    margin-top: -(rem-calc(1));
  }

  .c-checkbox {
    @include u-baseline(-5, margin-left);
  }
}



/** ***********
 * Modifier: Note is an error/blocker
 */
.m-note--is-error {
  --note-background: #{$note-background-error};
  --note-border-color: #{$note-border-color-error};
  --note-icon-color: #{$note-border-color-error};
}

.m-note--is-success {
  --note-background: var(--color-success-light);
  --note-border-color: var(--color-success);
  --note-icon-color: var(--color-success);
}





/** ***********
 * Modifier: Element has bottom margin
 */
.m-note--has-margin {
  @include u-baseline(3, margin-bottom);
}







/** ***********
 * Item
 */
.m-note__item {
  position: relative;

  + .m-note__item {
    @include u-baseline(3, margin-top);
  }

  ul,
  ol {
    @include u-baseline(2, margin-bottom);

    li {
      &:not(:last-child) {
        @include u-baseline(1, margin-bottom);
      }
    }
  }

  ul {
    columns: var(--note-list-columns);
    list-style-type: disc;
  }

  p {
    @include u-baseline(2, margin-bottom);
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }

    // Form fieldset override
    &:first-of-type:not(:last-child) {
      @include u-baseline(2, margin-bottom);

      + p {
        margin-top: 0;
      }
    }
  }
}







/** ***********
 * Info icon
 */
.m-note__icon {
  @include u-baseline(-4.5, left);
  @include u-baseline(.25, top);
  @include u-icon-relative-size;
  color: var(--note-icon-color, var(--color-orange));
  display: inline-block;
  position: absolute;
}









/** ***********
 * Text body
 */
.m-note__body {
  + [data-content-type='buttons'] {
    .c-button {
      @include u-baseline(2, margin-top);
    }
  }
}

// Template for custom product form
// in wishlist.

/** ***********
 * Image
 */
.t-custom-product__img {
  --grid-col-start-small: start;
  --grid-col-end-small: end;
  --grid-col-start-large: start;
  --grid-col-end-large: 5;
  --grid-row-start-xxlarge: 1;

  @include breakpoint(large) {
    @include u-baseline(2, padding-right);
  }
}




/** ***********
 * Form area
 */
.t-custom-product__form {
  --grid-col-start-small: start;
  --grid-col-end-small: end;
  --grid-col-start-large: 5;
  --grid-col-end-large: end;
  --grid-row-start-small: 2;
  --grid-row-start-large: 1;
  --grid-row-start-xxlarge: 1;
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
}




/** ***********
 * Form item
 */
.t-custom-product__form-item {
  @include xy-cell;

  @include breakpoint(large) {
    @include u-single-cell(6, $xlarge: true);
  }
}

// Tooltip
//
// Small icon showing text on mouseover.
//
// Javascript: true
//
// Markup: tooltip.hbs
//
// Type: component
//
// Style guide: Controls.tooltip

$tooltip-arrow-size: rem-baseline(1);

.c-tooltip {
  @include u-baseline(.75, margin-left);
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  transform: translateY(-.1em);
  width: 1em;
  height: 1em;

  .c-icon {
    color: var(--color-slate-petrol);
    vertical-align: initial;
    transform: translateY(-.1em);
    width: 1em;
    height: 1em;
  }
}



/** ***********
 * Modifier: Set arrow positions
 */
.c-tooltip--left {
  &:after {
    border-left-color: var(--color-light) !important;
    left: 100%;
  }
}

.c-tooltip--right {
  &:after {
    border-right-color: var(--color-light) !important;
    right: 100%;
  }
}





/** ***********
 * Flyout
 */
.c-toooltip__flyout {
  @include u-baseline(2, padding);
  background-color: var(--color-light);
  box-shadow: var(--shadow-popup);
  font-style: normal;
  position: absolute;
  width: rem-calc(220);
  z-index: 100;

  &:after {
    top: calc(50% - #{$tooltip-arrow-size});
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-width: $tooltip-arrow-size;
  }
}

// Combo
//
// Headline / text combination.
//
// Markup: combo.hbs
//
// .c-combo--bold-heading - Bold heading
//
// Type: component
//
// Style guide: Content.combo
.c-combo {
  @include u-inner-max($width: 720);
  @include u-baseline(3, margin-bottom);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-wrap: balance;

  @include breakpoint(xlarge) {
    @include u-baseline(5, margin-bottom);
  }
}

/** ***********
 * Text color on dark backgrounds
 */
@include u-text-on-dark('.c-combo');








/** ***********
 * Heading
 */
.c-combo__heading {
  @include font-h2;
  @include font-bold-important;
  @include u-baseline(2, margin-bottom);
  margin-top: 0;

  @include breakpoint(medium) {
    @supports not (text-wrap: balance) {
      margin-left: auto;
      margin-right: auto;
      max-width: 60%;
    }
  }
}





/** ***********
 * Body text
 */
.c-combo__body {
  @include font-meta;

  a {
    @include u-link($global-link-options-underline);
  }

  p {
    margin: 0;

    &:not(:last-child) {
      @include u-baseline(2, margin-bottom);
    }
  }
}

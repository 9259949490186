// Comment
//
// Module to display comments with optional rating
//
// Markup: comment.hbs
//
// Type: component
//
// Style guide: Content.comment

$comment-breakpoint: large;
$comment-avatar-size: rem-calc(100);

/** **************
 * Wrapper
 */
.c-comment {
  @include u-baseline(4, margin-bottom);

  @include breakpoint($comment-breakpoint) {
    display: flex;
  }

  &:last-of-type {
    @include u-baseline(2, margin-bottom);
  }

  // Custom rating styles
  .c-rating {
    display: block;

    @include breakpoint($comment-breakpoint) {
      display: inline-block;
      margin-bottom: -(rem-calc(3));
    }
  }
}




/** **************
 * Meta wrapper
 */
.c-comment__meta {
  @include u-baseline(1, margin-bottom);

  @include breakpoint($comment-breakpoint) {
    flex: 1 0 rem-calc(210);
    margin-bottom: 0;
    max-width: rem-calc(210);
  }

  .c-rating {
    @include u-baseline(1, margin-bottom);
  }
}




/** **************
 * Date
 */
.c-comment__date {
  display: block;
  color: var(--color-slate-petrol);
}




/** **************
 * Name
 */
.c-comment__name {
  display: block;
  overflow-wrap: break-word;
  word-wrap: break-word;
}




/** **************
 * Avatar
 */
.c-comment__avatar {
  display: block;

  figure {
    @include u-baseline(1, margin-bottom);
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    width: $comment-avatar-size;
    height: $comment-avatar-size;
  }
}




/** **************
 * Comment entry
 */
.c-comment__entry {
  p {
    margin-top: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

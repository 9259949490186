// BFF – Black Friday Feature
//
// Stage with countdown for Black Friday.
//
// Markup: bff.hbs
//
// Type: organism
//
// Style guide: Promotion.bff

$bff-breakpoint: xlarge;

.o-bff {
  @include u-baseline(2, padding-top);
  background-color: var(--color-cod);
  color: var(--color-light);
  position: relative;

  @include breakpoint($bff-breakpoint) {
    padding-top: 0;
  }

  @include breakpoint(xxlarge) {
    .c-button {
      @include c-button-large;
    }
  }
}






/** ***********
 * Image
 */
.o-bff__img {
  .c-image {
    background-color: var(--color-cod);
  }
}





/** ***********
 * Content wrapper
 */
.o-bff__content {
  @include u-baseline(1, padding-top);
  @include u-baseline(3, padding-bottom);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  margin-left: auto;
  margin-right: auto;
  max-width: rem-calc(520);
  text-align: center;

  @include breakpoint($bff-breakpoint) {
    @include u-baseline(3, padding-right);
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    max-width: none;
    padding-left: 0;
    position: absolute;
    text-align: left;
    top: 0;
    right: 0;
    width: 50%;
    z-index: 2;
  }
}







/** ***********
 * Content entry
 */
.o-bff__entry {
  width: 100%;
}









/** ***********
 * Modifier: Small display for category header
 */
.o-bff--is-small {
  .o-bff__content {
    @include breakpoint($bff-breakpoint) {
      width: 48%;
    }
  }
}

// Page title
//
// Heading module with intro and dotted line
//
// .m-pagetitle--small - Page title with small heading
// .m-pagetitle--has-no-border - Page title without border
//
// Markup: pagetitle.hbs
//
// Type: module
//
// Style guide: Content.pagetitle

$pagetitle-breakpoint: medium;
$pagetitle-intro-color: var(--color-slate-petrol);
$pagetitle-border: $global-border-dotted;

/** **************
 * Basic styles
 */
.m-pagetitle {
  @include u-baseline(3, padding-right);
  @include u-baseline(2, margin-bottom);
  border-bottom: $pagetitle-border;
  margin-top: 0;
  padding-bottom: rem-calc(12);
  position: relative;
  word-break: break-word;

  @include breakpoint($pagetitle-breakpoint) {
    @include u-baseline(3, margin-bottom);
  }

  .c-boxednote {
    @include breakpoint(large) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}






/** **************
 * Modifier: Small heading
 */
.m-pagetitle--small {
  &.m-pagetitle,
  &.m-pagetitle--has-no-border {
    padding-bottom: 0;
  }

  .m-pagetitle__heading {
    @include font-h2;
  }
}




/** **************
 * Modifier: element has no border
 */
.m-pagetitle--has-no-border {
  border: none;
  @include u-baseline(1, margin-bottom);
}




/** **************
 * Intro content
 */
.m-pagetitle__intro {
  @include font-subheading($type: 'copy');
  color: $pagetitle-intro-color;
  display: block;
  font-weight: normal;

  @include breakpoint($pagetitle-breakpoint) {
    @include u-baseline(1, margin-bottom);
  }
}




/** **************
 * Heading
 */
.m-pagetitle__heading {
  @include font-h1;
  @include font-bold-important;
  @include u-baseline(1, margin-bottom);
  display: block;
  text-wrap: balance;
}






/** ***********
 * Text color on dark backgrounds
 */
@include u-text-on-dark('.m-pagetitle');
@include u-text-on-dark('.m-pagetitle__intro');

// Category list
//
// (catlist) – Hybrid of [List group](section-content.html#kssref-content-lists-listgroup) (mobile) and [Bordered list](section-content.html#kssref-content-lists-borderlist) (desktop).
// Used on category listing pages (generic and brands).
//
// .c-catlist--topborder - List has only top border
// .c-catlist--noborder - List has no border
// .c-catlist--is-backlink - List is back link
//
// Markup: catlist.hbs
//
// Type: component
//
// Style guide: Lists.catlist
// @TODO: use CSS vars

$catlist-breakpoint: xlarge;

/** **************
 * Basic styles
 */
.c-catlist {
  @include u-listgroup;
  @include u-baseline(2, margin-bottom);
  margin-top: 0;

  // Desktop styles
  @include breakpoint($catlist-breakpoint) {
    @include u-borderlist;
    border-bottom: $global-border-dotted;

    li {
      background-color: transparent;
      border: none;
      border-top: $global-border-dotted;
      border-radius: 0 !important;
      padding-left: 0;
      padding-right: 0;

      &:first-of-type {
        @include u-baseline(2, padding-top);
      }
    }

    // Override arrowlink styles to left position
    .c-arrowlink--after {
      @include c-arrowlink($arrowlink-options);
      width: 100%;

      span {
        margin-right: 0;
        width: 100%;
      }

      &:after {
        display: none;
      }
    }
  }
}




/** **************
 * Modifier: List has only top border
 */
.c-catlist--topborder {
  @include breakpoint($catlist-breakpoint) {
    border-bottom: none;
  }
}




/** **************
 * Modifier: List has no border
 */
.c-catlist--noborder {
  @include breakpoint($catlist-breakpoint) {
    border: none;

    li {
      border: none;
    }
  }
}





/** **************
 * Modifier: List is backlink
 */
.c-catlist--is-backlink {
  @include breakpoint($catlist-breakpoint) {
    .c-arrowlink--after {
      @include c-arrowlink($arrowlink-options-left);
    }
  }
}









/** ***********
 * Modifier: Hidden items (more)
 */
.c-catlist--more {
  display: none;

  @include breakpoint($catlist-breakpoint) {
    display: block;
  }
}









/** ***********
 * Toggle item
 */
.c-catlist__toggle {
  background-color: var(--color-pearl) !important;
  border-bottom-left-radius: rem-calc(5);
  border-bottom-right-radius: rem-calc(5);
  cursor: pointer;

  @include breakpoint($catlist-breakpoint) {
    display: none;
  }
}








/** ***********
 * Toggle handler
 */
.c-catlist__handler {
  @include font-meta;
  display: block;
  position: relative;

  .c-icon {
    @include u-baseline(2, width);
    position: absolute;
    right: 0;
    top: rem-calc(2);
  }
}

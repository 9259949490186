// Tiny product
//
// Small product image with info.
//
// Markup: tinyproduct.hbs
//
// Type: module
//
// Style guide: Products.tinyproduct

/** **************
 * Basic styles
 */
.m-tinyproduct {
  @include u-baseline(4, margin-bottom);
  @include clearfix;
  position: relative;

  @include breakpoint(large) {
    @include u-baseline(5, margin-bottom);
  }

  &:hover,
  &:focus {
    img {
      @include u-product-img-hover;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    display: block;
  }

  .c-colors {
    @include u-baseline(1, padding-top);
  }

  .c-product-info__summary {
    @include u-baseline(7, padding-right);
  }
}








/** ***********
 * image
 */
.m-tinyproduct__img {
  @include u-floating-label($selector: '.c-energy');
  float: left;
  position: relative;
  width: rem-baseline(15);

  img {
    @include u-img-responsive;
    @include u-img-border;
  }

  + .m-tinyproduct__info {
    padding-left: rem-baseline(18);
  }

  .c-energy {
    @include u-baseline(1, right);
  }
}





/** ***********
 * Badges
 */
.m-tinyproduct__badges {
  @include u-baseline(2, bottom);
  position: absolute;
}

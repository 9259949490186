// Flyer
//
// Landscape image with content and optional button.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x9999    |
// | small         | 520x9999   |
// | large         | 940x9999   |
// 9999 = Image height is flexible.
//
// Markup: flyer.hbs
//
// Type: module
//
// Style guide: Promotion.Flyer

$flyer-breakpoint: large;

/** **************
 * Wrapper
 */
.m-flyer {
  @include xy-grid-container;
  @include xy-grid;
}




/** **************
 * Image
 */
.m-flyer__img {
  @include xy-cell;
  @include u-baseline(2, margin-bottom);

  @include breakpoint($flyer-breakpoint) {
    @include xy-cell(8);
    margin-bottom: 0;
  }
}






/** **************
 * Entry wrapper
 */
.m-flyer__inner {
  @include breakpoint($flyer-breakpoint) {
    @include u-baseline(2, padding-left);
  }
}





/** **************
 * Content entry
 */
.m-flyer__entry {
  @include xy-cell;
  @include font-small; // override

  @include breakpoint($flyer-breakpoint) {
    @include xy-cell(4);
  }

  [class*='heading--']:first-child {
    @include u-baseline(2, margin-bottom);
    font-weight: bold;
    margin-top: 0;
  }

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;
  }

  .c-button {
    @include u-baseline(1, margin-top);
  }
}

// Popup
//
// Extended tooltip popup with custom HTML content and limited position options.
//
// Markup: popup.hbs
//
// Type: module
//
// Javascript: true
//
// Style guide: Containers.popup

$popup-background: var(--color-light);
$popup-width: rem-calc(380);
$popup-arrow-size: rem-calc(6);
$popup-overlay-background: rgba(0, 0, 0, .5);
$popup-heading-background: var(--color-petrol);
$popup-heading-color: var(--color-light);

.m-popup {
  // Transparent overlay
  &:before {
    @include u-toggle-visibility($zindex: -1);
    background-color: $popup-overlay-background;
    content: '';
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: var(--transition-default);
    z-index: -1;
  }

  // Active state
  &[data-popup-active] {
    &:before {
      @include u-toggle-visibility('show', $zindex: index($elements, popup_bg));
    }

    .m-popup__inner {
      @include u-toggle-visibility('show', $zindex: index($elements, popup));
      transform: translate3d(0, 0, 0);
    }
  }
}

// Fix Reveal z-index war
.is-reveal-open {
  .m-popup {
    &[data-popup-active] {
      &:before {
        z-index: 1006;
      }

      .m-popup__inner {
        z-index: 1007;
      }
    }
  }
}






/** ***********
 * Inner container
 */
.m-popup__inner {
  @include u-toggle-visibility($zindex: -1);
  background-color: $popup-background;
  backface-visibility: hidden;
  box-shadow: var(--shadow-popup);
  max-width: $popup-width;
  position: absolute;
  transform: translate3d(0, rem-calc(6), 0);
  transition: opacity .2s ease, transform .3s ease-in-out, visibility .3s ease, z-index .2s ease;
  top: -500px; // initially hidden
  width: 100%;

  // @TODO: Replace with the upcoming
  // u-arrow mixin
  &:after {
    border: $popup-arrow-size solid transparent;
    border-color: transparent transparent $popup-background $popup-background;
    content: '';
    box-shadow: -3px 3px 4px 0 $global-flyout-shadow-color-1;
    display: block;
    position: absolute;
    top: 100%;
    left: calc(50% - #{$popup-arrow-size * 1.4});
    width: 0;
    height: 0;
    transform: rotate(-45deg);
    transform-origin: top left;
  }
}








/** ***********
 * Heading
 */
.m-popup__heading {
  @include u-baseline(2, padding);
  background-color: $popup-heading-background;
  color: $popup-heading-color;
  text-align: center;
}






/** ***********
 * List
 */
.m-popup__list {
  @include u-baseline(2, margin);
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
}

.m-popup__list-item {
  @include u-baseline(3, margin-bottom);
  flex: 1 0 50%;
}

// Pinboard
//
// "Lichtszenen" pinboard with pins.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x9999    |
// | small         | 520x9999   |
// | large         | 1425x9999  |
// 9999 = Image height is flexible.
//
// Markup: pinboard.hbs
//
// Type: template
//
// Style guide: Containers.pinboard

.o-pinboard {
  --pinboard-border-default: 1px solid var(--color-slush);
  border: var(--pinboard-border, var(--pinboard-border-default));
  position: relative;
}





/** ***********
 * Pins
 */
.o-pinboard__pins {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
}







/** ***********
 * Image
 */
.o-pinboard__img {
  position: relative;
  z-index: 0;
}

// Text box
//
// Container for heading, text and footer link.
//
// Markup: textbox.hbs
//
// Type: module
//
// Style guide: Content.textbox

.m-textbox {
  @include font-meta;
  display: flex;
  width: 100%;
}






/** ***********
 * Modifier: Centered textbox with aligned headings
 */
.m-textbox--center {
  .m-textbox__wrapper {
    align-content: flex-start;
    display: grid;
    justify-items: center;
    text-align: center;
  }
}



/** ***********
 * Wrapper
 */
.m-textbox__wrapper {
  width: 100%;
}





/** ***********
 * Icon
 */
.m-textbox__icon {
  @include u-baseline(2, margin-right);
  color: var(--textbox-icon-color, var(--color-orange));

  .c-icon {
    margin-top: -.3125em;
    width: 2.5em;
    height: 2.5em;
  }
}







/** ***********
 * Header
 */
.m-textbox__header {
  text-wrap: balance;

  h1,
  h2,
  h3,
  h4 {
    @include font-h3;
    @include font-bold-important;
    @include u-baseline(2, margin-bottom);
    margin-top: 0;

    a {
      @include u-link($global-link-options-orange);
    }
  }
}









/** ***********
 * Text body
 */
.m-textbox__body {
  @include u-content-formatting;
  text-wrap: var(--textbox-text-wrap);

  p {
    max-width: 65ch;
  }

  > p:last-child {
    margin-bottom: 0;
  }
}






/** ***********
 * Link
 */
.m-textbox__link {
  @include u-baseline(2, margin-top);
}

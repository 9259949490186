// Details
//
// Accordion with product details and shipment info.
//
// Markup: details.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Containers.details
@use 'sass:math';
$details-toggle-icon-color: var(--color-light);
$details-toggle-icon-background: var(--color-slush-slate);
$details-toggle-icon-active-background: var(--color-petrol);
$details-svg-size: 50%;

/** **************
 * Main styles
 */
.m-details {
  @include u-baseline(3, margin-bottom);
  position: relative;
}




/** **************
 * Status (delivery)
 */
.m-details__status {
  @include xy-cell(6, $output: (base size));
  position: absolute;
  top: 0;
  left: 0;
}




/** **************
 * Toggle
 */
.m-details__toggle {
  @include u-link($global-link-options-petrol);
  @include u-baseline(1, padding-bottom);
  text-align: right;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  i {
    @include u-icon-relative-size;
    background-color: $details-toggle-icon-background;
    border-radius: 50%;
    color: $details-toggle-icon-color;
    display: inline-block;
    margin-left: rem-calc(6);
    position: relative;
    transform: translateY(-(rem-calc(2)));
    vertical-align: middle;
  }

  svg {
    position: absolute;
    top: math.div($details-svg-size, 2);
    left: math.div($details-svg-size, 2);
    width: $details-svg-size;
    height: $details-svg-size;

    &:last-child {
      display: none;
    }
  }

  [aria-expanded='true'] {
    i {
      background-color: $details-toggle-icon-active-background;
    }

    svg {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: block;
      }
    }
  }
}




/** **************
 * Label
 */
.m-details__label {
  cursor: pointer;

  .c-icon {
    transform: translateY(0);
  }
}





/** **************
 * Content entry
 */
.m-details__entry {
  @include u-baseline(1, padding-top);
  border-bottom: $global-border-dotted;

  [class*='heading-'] {
    @include u-baseline(1, margin-bottom);
  }

  .c-desclist a:not(.u-link--underline) {
    @include u-link($global-link-options-orange);
  }
}

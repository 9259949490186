// Overload
//
// New generation loading spinner that overlays everything.
//
// Markup: overload.hbs
//
// Type: component
//
// Style guide: Containers.overload
$overload-spinner-size: rem-calc(56);

.c-overload {
  @include u-baseline(2, padding);
  background: rgba(255, 255, 255, .95);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(auto, #{rem-calc(500)}); // prevents vertical centering on large containers
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: var(--overload-top, 0);
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
}




/** ***********
 * Inner container
 */
.c-overload__inner {
  &:after {
    animation: spinner .6s linear infinite;
    border-radius: 50%;
    border: 4px solid var(--color-pearl);
    border-top-color: var(--color-orange);
    content: '';
    display: block;
    width: $overload-spinner-size;
    height: $overload-spinner-size;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 7;
  }
}





/** ***********
 * Animation
 */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

// Address card
//
// Displays address for registered users in checkout.
//
// Markup: addresscard.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Containers.addresscard
//

.m-addresscard {
  @include c-card;
  @include u-module-margin(small);
}





/** **************
 * Entry content
 */
.m-addresscard__entry {
  @include breakpoint(medium) {
    border-right: $global-border-dotted;
    max-width: rem-calc(260);
  }
}

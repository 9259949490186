// Number List
//
// List with fancy numbers.
//
// Markup: numberlist.hbs
//
// .o-numberlist--vertical - Vertical list.
// .o-numberlist--formatted - Formatted text for the case if no custom content is included.
//
// Type: organism
//
// Style guide: Containers.numberlist

$numberlist-size: rem-calc($global-baseline * 5);

.o-numberlist {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
  counter-reset: item;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
  position: relative;
}








/** ***********
 * Modifier: Vertical list with max-width
 */
.o-numberlist--vertical {
  max-width: rem-calc(650);

  .o-numberlist__item {
    @include u-single-cell;
    @include u-baseline(2, margin-bottom);

    @include breakpoint(large) {
      margin-bottom: 0;
    }
  }
}







/** ***********
 * Modifier: Formatted text
 */
.o-numberlist--formatted {
  .o-numberlist__item {
    @include font-meta;
    @include u-baseline(1, margin-bottom);

    @include breakpoint(large) {
      @include u-baseline(2, margin-bottom);
    }
  }
}





/** ***********
 * List item
 */
.o-numberlist__item {
  $line-height: ($numberlist-size + .1rem);

  @include xy-cell;
  @include u-baseline(4, margin-bottom);
  position: relative;

  @include breakpoint(xlarge) {
    @include xy-cell(4);
  }

  @include breakpoint(xxlarge) {
    @include xy-cell(4, $gutters: $global-xlarge-gutter);
  }

  &:before {
    @include u-number-circle($content: counter(item));
  }

  p {
    @include u-baseline(2, margin-bottom);
    margin-top: 0;
  }
}






/** ***********
 * Inner container
 */
.o-numberlist__inner {
  @include u-baseline(7, padding-left);
}

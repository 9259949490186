// Product item
//
// Product listing item. Uses [`.o-products`](section-organisms.html#kssref-organisms-products) as list wrapper.
//
// Markup: product.hbs
//
// Type: module
//
// Style guide: Products.product

$product-price-color-new: var(--color-warning);
$product-color: var(--color-dark);
$product-vendor-color: var(--color-petrol);
$product-label-background: $product-price-color-new;
$product-label-color: var(--color-light);
$product-colors-minheight: rem-baseline(7);

/** **************
 * Basic styles
 */
.m-product {
  color: $product-color;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:hover,
  &:focus {
    .m-product__img {
      @include u-product-img-hover;
    }
  }

  > div {
    flex-shrink: 0;
  }

  .c-product-info__name {
    flex: 1 0 auto;
  }

  .c-product-info__info {
    display: block;
    flex-shrink: 0;
  }

  .c-product-info__summary {
    @include u-baseline(7, padding-right);
  }

  .c-product-info__colors {
    min-height: $product-colors-minheight;
  }
}




/** **************
 * Image
 */
.m-product__img {
  @include u-baseline(2, margin-bottom);
  @include u-img-border;
  @include u-floating-label($selector: '.c-energy');
  position: relative;

  .c-predwords {
    --predwords-position: absolute;
    @include u-baseline(2, top);
  }
}








/** ***********
 * Link
 *
 * This makes the entire product clickable
 * by a pseudo overlay
 */
.m-product__link {
  @include u-link($global-link-options-petrol);
  text-decoration: none;

  &:before {
    content: '';
    display: block;
    height: calc(100% - #{$product-colors-minheight});
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
}







/** ***********
 * Badges
 */
.m-product__badges {
  @include u-baseline(2, bottom);
  position: absolute;
}

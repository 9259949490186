// PredWords
//
// icon on product image if item is sposnored via PredWords
//
// Markup: predwords.hbs
//
// Type: component
//
// Style guide: Products.Predwords
@use 'sass:math';
$predwords-size: 2em;

.c-predwords {
  @include u-baseline(3, margin-right);
  background-color: var(--color-orange);
  color: var(--color-light);
  display: block;
  float: left; // footnote
  margin-top: -(rem-calc(3)); // footnote vertical align
  position: var(--predwords-position, relative);
  text-align: center;
  width: 2em;
  height: 2em;
  line-height: $predwords-size;

  // Skewed border
  &:before {
    bottom: 0;
    left: 100%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: var(--color-orange);
    border-width: $predwords-size;
    border-right-width: math.div($predwords-size, 2);
    margin-left: -($predwords-size);
  }

  .c-icon {
    width: 1.2em;
    height: 1.2em;
  }
}

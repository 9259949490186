@keyframes wishlisticleNewItemList {
  0% {
    background-color: var(--color-orange-light);
  }

  100% {
    background-color: $wishlisticle-background;
  }
}


@keyframes wishlisticleNewItemGroup {
  0% {
    background-color: var(--color-orange-light);
  }

  100% {
    background-color: $wishlisticle-group-background;
  }
}

// Listing title
//
// Title with optional brand logo on product listing pages
//
// Markup: listingtitle.hbs
//
// Type: module
//
// Style guide: Content.listingtitle

$listing-breakpoint: large;
$listing-logo-small-width: rem-calc(75);
$listing-logo-small-height: rem-calc(40);
$listing-logo-large-width: rem-calc(90);
$listing-logo-large-height: rem-calc(48);

/** **************
 * Base styles
 */
.m-listingtitle {
  @include flex;
  @include flex-align(left, middle);
  @include u-baseline(2, margin-top);
  @include u-baseline(3, margin-bottom);
}








/** ***********
 * Modifier: Font weight is mixed,
 * emphasis through <strong/> tag
 */
.m-listingtitle--mixed {
  .m-listingtitle__heading {
    font-weight: normal !important;
  }
}






/** **************
 * Heading
 */
.m-listingtitle__heading {
  @include u-baseline(2, padding-right);
  @include font-h2;
  @include font-bold-important;
  flex-grow: 1;
  margin: 0;
}





/** **************
 * Logo (Brands only)
 */
.m-listingtitle__logo {
  flex: 0 0 $listing-logo-small-width;
  overflow: hidden;
  width: $listing-logo-small-width;
  text-align: right;

  @include breakpoint($listing-breakpoint) {
    flex: 0 0 $listing-logo-large-width;
    width: $listing-logo-large-width;
  }

  img {
    display: block;
    float: right;
    max-height: $listing-logo-small-height;
    max-width: 100%;

    @include breakpoint($listing-breakpoint) {
      max-height: $listing-logo-large-height;
    }
  }
}

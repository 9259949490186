// Description list
//
// Description list that looks like a table, but is actually a `<dl>`
//
// Markup: desclist.hbs
//
// .c-desclist--formatted - Formatted titles for project details or sth.
// .c-desclist--has-border - List has border at bottom
//
// Type: component
//
// Style guide: Lists.desclist

.c-desclist {
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: var(--desclist-cols, 1fr);
  gap: var(--desclist-gap, #{rem-baseline(1)});
  justify-content: stretch;
  justify-items: stretch;
  align-items: flex-start;
  margin-top: 0;
  text-align: left;

  @include breakpoint(medium) {
    --desclist-gap: #{rem-baseline(2)};
    --desclist-cols: minmax(100px, 180px) 1fr;
  }

  dt {
    @include u-baseline(2, padding-right);
    color: var(--desclist-dt-color, var(--color-iron));
  }

  dd {
    @include u-baseline(1, padding-bottom);
    color: var(--desclist-dd-color, var(--color-dark));
    margin-left: 0;

    @include breakpoint(medium) {
      padding-bottom: 0;
    }
  }
}





/** ***********
 * Modifier: Formatted titles for project details or sth.
 */
.c-desclist--formatted {
  dt {
    --desclist-cols: minmax(100px, 33%) 1fr;
    @include u-baseline(.5, padding-top);
    @include font-tiny;
    letter-spacing: .05em;
    text-transform: uppercase;
  }
}




/** **************
 * Modifier: List has a border
 */
.c-desclist--has-border {
  @include u-baseline(1, padding-bottom);
  border-bottom: $global-border-dotted;
}




/** **************
 * Modifier: List has prices
 */
.c-desclist--prices {
  dt {
    flex: 1 0 calc(100% - #{rem-calc(70)});
    max-width: 100%;
  }

  dd {
    flex: 0 0 rem-calc(70);
    max-width: 100%;
    text-align: right;
  }
}

@mixin m-article-img($breakpoint: medium) {
  @include breakpoint($breakpoint) {
    @include u-baseline(4, margin-right);
    flex: 0 0 $article-image-width-large;
    width: $article-image-width-large;
  }
}


@mixin m-article-price($breakpoint: medium) {
  @include breakpoint($breakpoint) {
    float: right;
    margin: 0;
    text-align: right;
    max-width: rem-calc(360);
  }
}

// Branding
//
// Brand logo on listing pages
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x9999    |
// | large         | 120x9999   |
// 9999 = Image height is flexible, **but** the displayed max-width is 120px.
//
// Markup: branding.hbs
//
// Type: component
//
// Style guide: Media.branding
//

.c-branding {
  display: block;
  margin: 0 auto;
  max-width: rem-calc(120);

  img {
    @include u-img-responsive;
  }
}

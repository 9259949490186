// Edit
//
// Wrapper for item edit. Includes icon and flyout with optional content.
//
// The contents of `.m-edit__body` will be parsed via [Mustache](section-vendor.html#kssref-vendor-mustache) templates.
//
// Type: module
//
// JavaScript: true
//
// Markup: edit.hbs
//
// Style guide: Controls.edit

$edit-breakpoint: large;
$edit-flyout-background: rgba(0, 0, 0, .5);

.m-edit {
  position: relative;

  .c-select {
    width: 100%;
  }
}






/** **************
 * Trigger
 */
.m-edit__trigger {
  @include u-icon-relative-size;
  color: var(--color-slate-petrol);
  cursor: pointer;
  display: block;

  .c-icon {
    width: 100%;
    height: 100%;
  }
}






/** **************
 * Body
 */
.m-edit__body {
  @include breakpoint($edit-breakpoint) {
    position: absolute;
    top: calc(100% + #{rem-baseline(1)});
    right: -(rem-baseline(2));
  }
}





/** **************
 * List
 */
.m-edit__list {
  @include u-list-reset;

  > li {
    @include u-baseline(1, margin-bottom);
  }

  .c-button--ghost {
    @include u-link;
  }
}






/** **************
 * Flyout
 */
.m-edit__flyout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;

  @include breakpoint($edit-breakpoint) {
    position: relative;
  }

  &:before {
    background-color: $edit-flyout-background;
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;

    @include breakpoint($edit-breakpoint) {
      display: none;
    }
  }
}






/** **************
 * Inner container
 */
.m-edit__inner {
  @include u-baseline(2, padding);
  background-color: var(--color-light);
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;

  @include breakpoint($edit-breakpoint) {
    @include u-baseline(1, padding);
    background-color: var(--color-concrete);
    border: 1px solid var(--color-slush-slate);
    border-radius: rem-calc(5);
    box-shadow: var(--shadow-heavy);
    position: relative;
    width: rem-calc(280);
  }
}

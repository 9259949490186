// ShowOff teaser
//
// Large centered teaser with optional button.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x9999    |
// | small         | 520x9999   |
// | large         | 950x9999   |
// 9999 = Image height is flexible.
//
// Markup: showoff.hbs
//
// Type: module
//
// Style guide: Promotion.showoff

/** **************
 * Basic styles
 */
.m-showoff {
  text-wrap: balance;

  figure {
    @include u-baseline(2, margin-bottom);
  }

  img {
    @include u-img-responsive;
  }

  .c-image a {
    display: block;
  }

  &:not(.m-showoff--no-margin) {
    @include u-module-margin;
  }
}




/** **************
 * Entry content
 */
.m-showoff__entry {
  @include font-meta;
  @include u-inner-max($width: rem-calc(665));
  text-align: center;

  p {
    @include u-baseline(3, margin-bottom);
    margin: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}






/** ***********
 * Text body
 */
.m-showoff__body {
  @include u-baseline(4, margin-bottom);

  + .c-button {
    margin-top: 0;
  }
}




/** **************
 * Heading
 */
.m-showoff__heading {
  @include font-h2;
  @include font-bold-important;
  @include u-baseline(2, margin-bottom);
}

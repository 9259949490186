// Wishlisticle
//
// Wishlist modal item
//
// Markup: wishlisticle.hbs
//
// Type: component
//
// Style guide: Wishlist.wishlisticle

$wishlisticle-icon-wrapper-width: rem-calc(48);
$wishlisticle-icon-width: rem-calc(16);
$wishlisticle-background: var(--color-pearl);
$wishlisticle-group-background: var(--color-ice);
$wishlisticle-animation: 2s forwards;
$wishlisticle-animation-delay: 1.5s;

@import '_mixins';

/** **************
 * Wrapper
 */
.c-wishlisticle {
  @include flex;
  @include flex-align(left, middle);
  @include u-baseline(1, padding);
  background-color: $wishlisticle-background;
  border-bottom: 1px solid var(--color-slush-slate);
}






/** **************
 * Modifier: Item is a group
 */
.c-wishlisticle--is-group {
  background-color: $wishlisticle-group-background;
}






/** **************
 * Modifier: Item is fresh added
 */
.c-wishlisticle--is-new {
  background-color: var(--color-orange-light);
  transition: var(--transition-default);
  animation: wishlisticleNewItemList $wishlisticle-animation;
  animation-delay: $wishlisticle-animation-delay;

  &.c-wishlisticle--is-group {
    animation: wishlisticleNewItemGroup $wishlisticle-animation;
    animation-delay: $wishlisticle-animation-delay;
  }
}










/** **************
 * Icon
 */
.c-wishlisticle__icon {
  @include u-baseline(1, padding-left);
  flex: 0 0 $wishlisticle-icon-wrapper-width;
  width: $wishlisticle-icon-wrapper-width;

  .c-icon {
    @include u-baseline(6, height);
    @include u-baseline(3, width);
    transform: translate3d(0, 0, 0);
  }
}







/** **************
 * Content
 */
.c-wishlisticle__content {
  @include xy-cell(auto, $output: (base));
  min-width: 0;

  p {
    margin: 0;
  }
}







/** **************
 * Name
 */
.c-wishlisticle__name {
  @include text-truncate(ellipsis);
  display: block;
}

// Up top
//
// Link to top that appears when user scrolls page up and
// vis versa.
//
// Requires `site/Uptop.js`
//
// Markup: uptop.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: SiteElements.Uptop

$uptop-background: var(--color-ice);
$uptop-background-hover: var(--color-slush-slate);
$uptop-color: var(--color-dark);
$uptop-size: rem-calc($global-controls-height);

.m-uptop {
  background-color: $uptop-background;
  border-radius: 50%;
  box-shadow: var(--shadow-medium);
  color: $uptop-color;
  display: inline-block;
  font-size: 0;
  line-height: $uptop-size;
  width: $uptop-size;
  height: $uptop-size;
  opacity: .8;
  position: fixed;
  bottom: var(--bottom, -(rem-calc($uptop-size * 2)));
  right: rem-calc(10);
  text-align: center;
  transition: var(--transition-smooth);
  z-index: index($elements, uptop);

  @include breakpoint(large) {
    right: rem-calc(28);
  }

  &:hover,
  &:focus {
    background-color: $uptop-background;
    color: $uptop-color;
    opacity: 1;
  }

  svg {
    transform: rotate(-90deg);
    width: rem-calc(10);
    height: 100%;
  }
}






/** ***********
 * Add transitions to Userlike elements
 */
[id^='button--userlike'] [class^='umm-'] {
  transition: var(--transition-smooth) !important;
}

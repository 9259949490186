// Mood Hero
//
// Full width hero teaser with large background image.
//
// .m-hero-mood--scheme-light.m-hero-mood--tinted - Hero content has tinting overlay and light font color.
//
// Markup: hero-mood.hbs
//
// Full: true
//
// Type: module
//
// Style guide: Promotion.Hero.hero-mood
$hero-mood-breakpoint: large;
$hero-mood-max-width: rem-calc(1900);

/** **************
 * Basic styles
 */
.m-hero-mood {
  position: relative;
  text-wrap: balance;
}





/** ***********
 * Modifier: color schemes
 */
.m-hero-mood--scheme-light {
  --hero-body-color: var(--color-light);

  &.m-hero-mood--tinted {
    --hero-body-bg: var(--tint-dark-medium);
  }
}

.m-hero-mood--scheme-dark {
  --hero-body-color: var(--color-dark);

  &.m-hero-mood--tinted {
    --hero-body-bg: var(--tint-light-medium);
    --hero-body-gradient: 255;
  }
}



/** ***********
 * Wrapper
 */
.m-hero-mood__wrapper {
  max-width: $hero-mood-max-width;
  margin-top: -(rem-baseline(2));
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;

  @include breakpoint($hero-mood-breakpoint) {
    margin-top: 0;
  }
}




/**
 * Inner container
 */
.m-hero-mood__inner {
  position: relative;
  z-index: 1;
  max-width: $global-max-width;
  margin: 0 auto;
  min-height: 50vh;
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: 1fr .5fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0 0;
  grid-template-areas: 'left center right' 'left center right';
  align-items: stretch;

  @include breakpoint($hero-mood-breakpoint) {
    @include u-baseline(3, padding);
    max-height: rem-calc(800);
    min-height: 70vh;
  }
}





/** ***********
 * Content
 */
.m-hero-mood__content {
  --gradient: var(--hero-body-gradient, 0);
  @include u-baseline(8, padding-top);
  @include u-baseline(4, padding-bottom);
  @include u-baseline(3, padding-left);
  @include u-baseline(3, padding-right);
  align-self: flex-end;
  border-radius: rem-calc($global-border-radius);
  color: var(--hero-body-color, var(--color-dark));
  grid-area: 1 / 1 / 3 / 4;
  max-width: $global-max-width;
  background: linear-gradient(0deg, rgba(var(--gradient), var(--gradient), var(--gradient), .9) 0%, rgba(var(--gradient), var(--gradient), var(--gradient), 0) 100%);

  @include breakpoint($hero-mood-breakpoint) {
    @include u-baseline(4, padding-top);
    align-self: var(--hero-align, center);
    background: var(--hero-body-bg, transparent);
    grid-area: var(--hero-area, left);
  }
}




/**
 * Heading
 */
.m-hero-mood__heading {
  @include font-h1;
  @include u-baseline(1, margin-bottom);
  color: var(--hero-body-color, var(--color-dark));
  margin-top: 0;
}




/** ***********
 * Body text
 */
.m-hero-mood__body {
  @include font-meta;
  @include u-baseline(4, margin-bottom);
}





/** ***********
 * Backgound Image
 */
.m-hero-mood__img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 0;

  .c-image {
    @include u-img-cover;
  }
}

// Variants label
//
// Label for product variants. `data-variants-label` will be filled by JavaScript.
//
// Markup: variantslabel.hbs
//
// Type: component
//
// Style guide: Products.variants-label
// @deprecated

.c-variantslabel {
  @include u-baseline(1, margin-bottom);
  display: inline-block;

  .u-bold {
    @include u-baseline(1, margin-right);
    display: inline-block;
  }
}

// Item
//
// Wishlist item wrapper to display item options. Will be used as a partial block.
//
// Markup: wishitem.hbs
//
// Type: organism
//
// JavaScript: true
//
// Style guide: Wishlist.wishitem

@import '_config';

/** **************
 * Container and article overrides
 */
.o-wishitem {
  .m-article {
    border: $wishitem-border;
    border-top: none;
    margin-top: 0 !important;

    @include breakpoint($wishitem-breakpoint) {
      border: $wishitem-border;
      border-left: none;
    }
  }
}















/** **************
 * Inner container
 */
.o-wishitem__inner {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
}






/** **************
 * Controls
 */
.o-wishitem__controls {
  @include xy-cell($output: (base size));
  @include flex;
  @include flex-align(right, middle);
  border: $wishitem-border;
  background-color: var(--color-pearl);
  text-align: right;
  width: 100%;

  @include breakpoint($wishitem-breakpoint) {
    @include xy-cell(shrink, $output: (base size));
    @include flex-align(center, bottom);
    text-align: center;
    width: $wishitem-controls-width;
  }

  ul {
    @include flex;
    @include flex-align(center, middle);
    @include u-list-reset;
    @include u-baseline(8.5, height);
    padding: rem-calc(14) 0;
    width: auto;

    @include breakpoint($wishitem-breakpoint) {
      display: block;
      flex: none;
      height: auto;
      margin: 0 rem-calc(12);
      padding-bottom: 0;
      width: calc(100% - #{rem-calc(24)});
    }

    > li {
      @include flex;
      @include flex-align(center, middle);
      border-left: $wishitem-controls-border;
      color: $wishlist-controls-color;
      height: 100%;
      width: rem-calc(60);

      @include breakpoint($wishitem-breakpoint) {
        @include u-baseline(2, padding-top);
        @include u-baseline(2, padding-bottom);
        border-top: $wishitem-controls-border;
        border-left: none;
        display: block;
        flex: none;
        height: auto;
        width: auto;
      }

      &:hover {
        color: $wishlist-controls-color-hover;
      }
    }
  }
}







/** ***********
 * Drag item
 */
.o-wishitem__controls--drag {
  touch-action: none;
  cursor: pointer;
}





/** **************
 * Body
 */
.o-wishitem__body {
  @include xy-cell($output: (base size));
  -webkit-box-flex: 1;
  width: 100%;

  @include breakpoint($wishitem-breakpoint) {
    @include xy-cell(auto, $output: (base size));
  }
}






/** **************
 * Comment
 */
.o-wishitem__comment {
  @include u-baseline(2, padding);
  background-color: var(--color-light);
  border: $wishitem-border;
  border-top: none;
  color: var(--color-petrol);

  @include breakpoint($wishitem-breakpoint) {
    width: calc(100% - #{$wishitem-controls-width - rem-calc(1)});
    margin-left: calc(#{$wishitem-controls-width} - #{rem-calc(1)});
    padding-left: $wishitem-comment-padding;
  }

  p {
    margin-top: 0;
    position: relative;

    &:first-of-type:before {
      @include u-baseline(1, margin-bottom);
      content: 'Kommentar:'; // I know … but it works.
      color: var(--color-dark);
      display: block;
      font-style: italic;

      @include breakpoint($wishitem-breakpoint) {
        position: absolute;
        left: calc(-#{$wishitem-comment-padding} + #{rem-baseline(3)});
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}


.wishlist-footer-comment-shared {
  @include breakpoint($wishitem-breakpoint) {
    width: 100%;
    margin-left: 0;
  }
}



.wishlist-shared-index {
  .m-pagetitle {
    max-width: 75rem;
    margin-right: auto;
    margin-left: auto;
    padding: 0 1.55rem;
  }
}

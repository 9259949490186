// Dedicator
//
// Container with image and content, dedicated to a person.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x30      |
// | large         | 300x300    |
//
// Markup: dedicator.hbs
//
// Type: module
//
// Style guide: Containers.dedicator

$dedicator-breakpoint: xxlarge;

.m-dedicator {
  @include u-baseline(3, margin-bottom);

  @include breakpoint($dedicator-breakpoint) {
    @include xy-grid-container;
    @include xy-grid;
  }
}







/** ***********
 * Image
 */
.m-dedicator__img {
  @include u-baseline(38, max-width);
  @include u-baseline(2, margin-bottom);
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @include breakpoint($dedicator-breakpoint) {
    @include xy-cell(4);
  }
}








/** ***********
 * Content container
 */
.m-dedicator__content {
  @include breakpoint($dedicator-breakpoint) {
    @include xy-cell(8);
    @include flex;
    @include flex-align(left, middle);
  }
}






/** ***********
 * Text body
 */
.m-dedicator__body {
  @include font-entry;
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);

  @include breakpoint($dedicator-breakpoint) {
    @include u-baseline(2, padding-left);
  }
}







/** ***********
 * Heading
 */
.m-dedicator__heading {
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(1, margin-bottom);
  margin-top: 0;
  text-align: center;

  @include breakpoint($dedicator-breakpoint) {
    text-align: left;
  }
}

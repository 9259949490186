/// Web fonts

$font-path: '../fonts';

@font-face {
  font-family: Dax;
  font-weight: normal;
  src:
    url('#{$font-path}/DaxWeb.woff2') format('woff2'),
    url('#{$font-path}/DaxWeb.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: Dax;
  font-weight: bold;
  src:
    url('#{$font-path}/DaxWeb-Bold.woff2') format('woff2'),
    url('#{$font-path}/DaxWeb-Bold.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src:
    url('#{$font-path}/lato-v16-latin-regular.woff2') format('woff2'),
    url('#{$font-path}/lato-v16-latin-regular.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  src:
    url('#{$font-path}/lato-v16-latin-italic.woff2') format('woff2'),
    url('#{$font-path}/lato-v16-latin-italic.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 700;
  src:
    url('#{$font-path}/lato-v16-latin-700italic.woff2') format('woff2'),
    url('#{$font-path}/lato-v16-latin-700italic.woff') format('woff');
  font-display: fallback;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src:
    url('#{$font-path}/lato-v16-latin-700.woff2') format('woff2'),
    url('#{$font-path}/lato-v16-latin-700.woff') format('woff');
  font-display: fallback;
}



/// Font settings
$font-stack: ('Helvetica', 'Arial', sans-serif);

$fonts: (
  base: (
    'stack':          $font-stack,
    'body':           ('Lato', #{$font-stack}),
    'copy':           ('Lato', #{$font-stack}),
    'display':        ('Dax', #{$font-stack}),
    'display-bold':   ('Dax', #{$font-stack}),
    'size':           100%,
    'line-height':    1.5
  ),
  size: (
    '13':             13,
    '16':             16,
    '18':             18,
    '20':             20,
    '22':             22,
    '24':             24,
    '28':             28,
    '32':             32,
    '40':             40,
    '80':             80,
  )
);




/// Function to parse font map
@function font($font, $type: 'base') {
  @return map-get(map-get($fonts, $font), $type);
}




// Global font options
//
// Please use these instead individual variables or values.

$font-13: (
  size:          '13',
  line-height:   16
);

$font-16: (
  size:          '16',
  line-height:   24
);

$font-18: (
  size:          '18',
  line-height:   32
);

$font-18-narrow: (
  size:          '18',
  line-height:   24
);

$font-20: (
  size:          '20',
  line-height:   32
);

$font-20-narrow: (
  size:          '20',
  line-height:   24
);

$font-22: (
  size:          '22',
  line-height:   32
);

$font-24: (
  size:          '24',
  line-height:   32
);

$font-28: (
  size:          '28',
  line-height:   40
);

$font-32: (
  size:          '32',
  line-height:   40
);

$font-40: (
  size:          '40',
  line-height:   48
);

$font-80: (
  size:          '80',
  line-height:   112
);








// H0 (Super size)
@mixin font-h0() {
  font: var(--font-40-display);

  @include breakpoint(large) {
    font: var(--font-80-display);
  }
}

// H1
@mixin font-h1() {
  font: var(--font-32-display);

  @include breakpoint(large) {
    font: var(--font-40-display);
  }
}

// H2
@mixin font-h2() {
  font: var(--font-24-display);

  @include breakpoint(large) {
    font: var(--font-28-display);
  }
}

// H3
@mixin font-h3() {
  font: var(--font-20-display);

  @include breakpoint(large) {
    font: var(--font-22-display);
  }
}

// H4 and smaller
@mixin font-h4() {
  font: var(--font-18-display);
}

// Entry
// Used for copy text in entry-in-grid context
// @see Lichtjournal detail page
@mixin font-entry() {
  font: var(--font-20-copy);
}

// Lead text
@mixin font-lead() {
  font: var(--font-20-display);

  @include breakpoint(xxlarge) {
    font: var(--font-22-display);
  }
}

// Meta
// For Links, list, single CTA elements
@mixin font-meta($type: 'copy', $narrow: false) {
  font: var(--font-16-#{$type});

  @include breakpoint(xlarge) {
    @if $narrow {
      font: var(--font-18-narrow-#{$type});
    } @else {
      font: var(--font-18-#{$type});
    }
  }
}

// Small
@mixin font-small($type: 'copy') {
  font: var(--font-16-#{$type});
}

// Tiny
@mixin font-tiny() {
  font: var(--font-13);
}



// Uppercase subheading
@mixin font-subheading($type: 'copy') {
  font: var(--font-16-#{$type});
  letter-spacing: .025em;
  text-transform: uppercase;
}



// Button sizes
@mixin font-button($size: null) {
  @if $size == 'large' {
    font: var(--font-20-narrow-display);
  } @else if $size == 'xlarge-small' {
    font: var(--font-20-display);
  } @else if $size == 'xlarge' {
    font: var(--font-24-display);
  } @else {
    font: var(--font-16-copy);
  }
}





// Force bold font weight due breakpoint
// override of the font shorthand in the
// CSS custom vars in :root.
@mixin font-bold-important() {
  font-weight: bold !important;
}

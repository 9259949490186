// Tip Box
//
// Box with headline, text, [numberlist](section-containers.html#kssref-containers-numberlist) and optional image.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x9999    |
// | large         | 465x9999   |
// 9999 = Image height is flexible.
//
// .m-tipbox--highlight - Light orange background for more attention.
//
// Markup: tipbox.hbs
//
// Type: module
//
// Style guide: Containers.tipbox

$tipbox-background: var(--color-ice);

.m-tipbox {
  width: 100%;

  @include breakpoint(large) {
    @include flex;
  }
}








/** ***********
 * Image
 */
.m-tipbox__img {
  position: relative;

  @include breakpoint(large) {
    @include flex;
    flex: 0 0 rem-calc(300);
  }

  @include breakpoint(xlarge) {
    flex: 0 0 rem-calc(465);
  }

  .c-image {
    @include u-img-cover;
  }

  + .m-tipbox__content {
    @include u-baseline(3, padding-top);

    @include breakpoint(large) {
      @include u-baseline(5, padding-left);
    }
  }
}








/** ***********
 * Content
 */
.m-tipbox__content {
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  background-color: var(--tipbox-background, #{$tipbox-background});

  @include breakpoint(medium) {
    @include u-baseline(3, padding-top);
    @include u-baseline(3, padding-left);
    @include u-baseline(3, padding-right);
    flex: 1;
  }

  .m-widget__heading {
    @include u-baseline(2, margin-bottom);
  }

  .o-numberlist {
    @include u-baseline(3, margin-top);
  }
}









/** ***********
 * Text body
 */
.m-tipbox__body {
  @include font-meta;

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;
  }
}








/** ***********
 * Modifier: Tipbox has light orange background color
 */
.m-tipbox--highlight {
  --tipbox-background: #{var(--color-orange-light)};
}







/** ***********
 * Modifier: Tipbox has bottom margins
 */
.m-tipbox--margin-small {
  @include u-baseline(2, margin-bottom);
}

.m-tipbox--margin-large {
  @include u-baseline(5, margin-bottom);
}







// Tip Box w/o image
//
// Tipbox without image. The image component must not be rendered if no image is available.
//
// Markup: tipbox--no-image.hbs
//
// Type: module
//
// Style guide: Containers.tipbox.no_image

// Mobile menu (MoMe)
//
// Menu for mobile filter and categories inside of mobile panel.
//
// Used as filter (arrows) and categories menu (checkmark)
//
// Markup: mome.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Controls.mome

$mome-item-color: var(--color-dark);
$mome-value-color: var(--color-slate);
$mome-border: 1px solid var(--color-slush-ice);

/** **************
 * Wrapper
 */
.m-mome {
  height: 100%;
  margin: 0;
  overflow-y: scroll;
}




/** **************
 * List styles
 */
.m-mome__list {
  @include u-list-reset;
  margin: 0;
}

.m-mome__list--level-1 {
  > li {
    @include u-baseline(3, padding-left);
  }
}




/** **************
 * Item
 */
.m-mome__item {
  border-bottom: $mome-border;

  > a,
  label {
    @include u-baseline(2, padding-left);
    @include u-baseline(2, padding-right);
    @include u-baseline(2, padding-top);
    @include u-baseline(2, padding-bottom);
    color: $mome-item-color;
  }

  > a:not(.c-arrowlink--after) {
    display: block;
  }

  svg {
    position: absolute;
    right: rem-baseline(2);
  }
}




/** **************
 * Range slider
 */
.m-mome__range {
  @include u-baseline(5, padding-left);
  @include u-baseline(5, padding-right);
  padding-top: rem-calc(13);
}





/** **************
 * Label
 */
.m-mome__label {
  display: inline-block;
  font-weight: var(--weight, normal);
  margin-right: rem-calc(4);
}




/** **************
 * Value
 */
.m-mome__value {
  color: $mome-value-color;
}

// Display active star
@mixin c-rating-active() {
  svg:last-child {
    display: block;
  }

  svg:first-child {
    display: none;
  }
}

//
// Display stars by selection without function
//

// Loop through all stars
@for $i from 1 through 5 {
  .c-rating--stars-#{$i} {

    // Highlight all stars based on selected count
    @for $j from 1 through $i {
      .c-rating--item-#{$j} {
        @include c-rating-active;
      }
    }
  }
}

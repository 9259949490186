// Summary
//
// Cart summary with voucher form and prices.
//
// Markup: summary.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Containers.summary

/** **************
 * Basic styles
 */
.m-summary {
  @include c-card;
  @include u-baseline(3, padding-top);

  table {
    margin-bottom: 0;

    a {
      @include u-link($global-link-options-underline);
    }

    tbody td {
      @include u-baseline(2, padding-bottom);
      padding-left: 0;
      padding-right: 0;
    }
  }

  tfoot {
    @include font-meta($type: 'display');
    @include font-bold-important;

    td {
      padding-bottom: 0;
    }
  }

  .c-button {
    @include u-baseline(3, margin-top);
    @include u-baseline(1, margin-bottom);
  }
}





/** **************
 * Inner container
 */
.m-summary__inner {
  @include u-inner-max($width: 460);
  width: 100%;

  @include breakpoint(large) {
    padding: 0;
  }
}




/** **************
 * Products list
 */
table.m-summary__products {
  @include u-baseline(2, margin-bottom);

  tbody tr {
    border-bottom: $global-border-dotted !important;

    // Remove padding from first rows TD
    &:first-child td {
      padding-top: 0;
    }
  }

  tbody td {
    @include u-baseline(2, padding-top);
    @include u-baseline(2, padding-bottom);
    vertical-align: bottom;

    &:nth-child(2) {
      @include u-baseline(2, padding-left);
      @include u-baseline(1, padding-right);
      color: var(--color-slate-petrol);
      text-align: right;
      width: rem-calc(60);
    }
  }
}




/** **************
 * Price value
 */
.m-summary__value {
  text-align: right;
  width: rem-calc(80);
  white-space: nowrap;
}






.m-summary__vat {
  @include font-small($type: 'copy');

  td {
    font-weight: normal;
  }
}







/** **************
 * Voucher form (hidden by default)
 */
tr.m-summary__voucher {
  @include element-invisible;

  .c-input {
    margin-top: 0;
    margin-bottom: rem-calc(4);
  }

  p:last-child {
    @include u-baseline(1, margin-bottom);
    margin-top: 0;
  }

  a {
    @include u-link($global-link-options-orange-underline);
  }

  &.is-active {
    @include element-invisible-off;
  }
}

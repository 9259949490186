// Step
//
// Step on how to get a light consultancy.
//
// Markup: step.hbs
//
//
// .m-step--is-small - Smaller fonts.
//
// Type: module
//
// Style guide: Promotion.steps.step

$step-breakpoint: large;
$step-background: var(--color-light);
$step-border-color: var(--color-orange-light);

.m-step {
  @include u-baseline(3, padding);
  @include flex-align(center, top);
  background-color: $step-background;
  border-radius: rem-calc(6);
  box-shadow: var(--shadow-popup);
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  text-wrap: balance;
  width: 100%;

  @include breakpoint($step-breakpoint) {
    flex-direction: column;
  }

  @include breakpoint(xlarge) {
    @include u-baseline(4, padding);
  }

  &:before {
    @include u-baseline(1, height);
    background-color: var(--step-border-color, #{$step-border-color});
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &:not(:has(.m-step__icon)) {
    @include breakpoint(xlarge) {
      @include u-baseline(5, padding-top);
    }
  }
}







/** ***********
 * Icon
 */
.m-step__icon {
  flex: 0 1 rem-calc(72);
  width: rem-calc(72);
  text-align: left;

  @include breakpoint($step-breakpoint) {
    @include u-baseline(2, margin-bottom);
    text-align: center;
    flex: none;
    width: 100%;
  }

  .c-icon {
    @include u-baseline(6, width);
    @include u-baseline(6, height);

    @include breakpoint($step-breakpoint) {
      @include u-baseline(11, width);
      @include u-baseline(11, height);
    }
  }
}







/** ***********
 * Content wrapper
 */
.m-step__content {
  flex: 1;
  text-align: left;

  @include breakpoint($step-breakpoint) {
    flex: none;
    text-align: center;
    width: 100%;
  }
}





/** ***********
 * Heading
 */
.m-step__heading {
  @include font-h2;
  @include font-bold-important;
  @include u-baseline(1, margin-bottom);
  margin-top: 0;
}







/** ***********
 * Body
 */
.m-step__body {
  @include font-meta;
}

// Orders
//
// Table with borders, overriding table styles.
//
// Markup: orders.hbs
//
// Type: module
//
// Style guide: Containers.orders

$orders-breakpoint: large;

.m-orders {
  tbody {
    td:last-of-type {
      @include breakpoint($orders-breakpoint) {
        width: rem-calc(120);
      }
    }
  }

  // Text alignment
  thead th:nth-child(4),
  tbody td:nth-child(4) {
    @include breakpoint($orders-breakpoint) {
      @include u-baseline(4, padding-right);
      text-align: right;
    }
  }
}






/** **************
 * Inner container
 */
.m-orders__inner {
  @include c-card;
  width: 100%;
}





/** **************
 * Heading
 */
.m-orders__heading {
  @include font-h3;
  @include font-bold-important;
  margin-top: 0;
}






// Single order
//
// Single order table with some minor modifications.
//
// Markup: orders-single.hbs
//
// Style guide: Containers.orders.single

/** **************
 * Modifier: Single order
 */
.m-orders--single {
  @include u-baseline(3, margin-bottom);

  tbody td {
    vertical-align: top;
  }

  thead th:nth-child(4),
  tbody td:nth-child(4) {
    text-align: left;
  }

  thead th:nth-child(5),
  tbody td:nth-child(5) {
    @include breakpoint($orders-breakpoint) {
      text-align: right;
    }
  }

  .stack tbody tr:last-child {
    border-bottom: 1px solid var(--color-iron);
  }


  .m-summary,
  .m-summary__inner {
    padding: 0;
  }
}

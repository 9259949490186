// Rating
//
// Rating stars without function, but yet values to display.
//
// Voting automatically disabled when `.c-rating--stars-` class is added
//
// .c-rating--stars-1 - 1 star
// .c-rating--stars-2 - 2 stars
// .c-rating--stars-3 - 3 stars
// .c-rating--stars-4 - 4 stars
// .c-rating--stars-5 - 5 stars
//
// Markup: rating.hbs
//
// Type: component
//
// Style guide: Controls.ratings.rating
@import '_mixins';

$rating-star-color: var(--color-rating);
$rating-star-width: rem-calc(15);
$rating-star-height: rem-baseline(2);
$rating-star-width-vote: rem-calc(21);
$rating-star-height-vote: rem-calc(22);

.c-rating {
  display: inline-block;
  direction: rtl;
  font-size: 0;
  transform: translateY(1px);
  vertical-align: text-top;
  width: rem-calc(115);

  input {
    @include element-invisible;
  }

  label {
    @include u-baseline(1, margin-right);
    color: $rating-star-color;
    display: inline-block;
    width: $rating-star-width;
    height: $rating-star-height;
    margin-left: 0;

    svg:last-child {
      display: none;
    }

    &[for] {
      cursor: default;
    }
  }

  svg {
    width: $rating-star-width;
    height: $rating-star-height;
  }

  &:not([class*='c-rating--stars-']) {
    width: rem-calc(145);

    label {
      cursor: pointer;
      width: $rating-star-width-vote;
      height: $rating-star-height-vote;
    }

    svg {
      width: $rating-star-width-vote;
      height: $rating-star-height-vote;
    }

    > input:checked ~ label, // show gold star when clicked
    &:not(:checked) > label:hover, // hover current star
    &:not(:checked) > label:hover ~ label {
      @include c-rating-active;
    }

    > input:checked + label:hover, // hover current star when changing rating
    > input:checked ~ label:hover,
    > label:hover ~ input:checked ~ label, // lighten current selection
    > input:checked ~ label:hover ~ label {
      @include c-rating-active;
    }
  }

  .c-icon {
    vertical-align: baseline;
  }
}






/** **************
 * Bordered (CTA on detail page)
 */
.c-rating--border {
  border: 1px solid $rating-star-color;
  border-radius: rem-calc(24);
  height: rem-calc($global-controls-height);
  padding: rem-baseline(1.5) rem-baseline(2);
  width: rem-calc(171) !important;

  .c-rating--item-5 {
    margin-right: 0;
  }
}

// Foundation tabs overrides
//
// @see http://foundation.zurb.com/sites/docs/tabs.html

.tabs-content {
  @include tabs-content;
}

.tabs-panel {
  @include tabs-panel;
}

// Icon link
//
// Generic link with icon
//
// Markup: iconlink.hbs
//
// .c-iconlink--underline - Modifier description
//
// Type: component
//
// Style guide: Controls.links.iconlink
.c-iconlink {
  display: inline-block;
  font-size: 0;

  .c-icon {
    @include u-icon-relative-size;
    @include font-small; // override
    margin-right: rem-calc(6);
    transform: translateY(-.3em);
  }
}

a.iconlink {
  @include u-link($global-link-options-default);
}





/** ***********
 * Modifier: Underlined link
 */
.c-iconlink--underline {
  @include u-link($global-link-options-underline-inherit);

  &:hover {
    .c-iconlink__text {
      text-decoration: none;
    }
  }

  .c-iconlink__text {
    text-decoration: underline;
  }
}





/** ***********
 * Modifier: Inline link in copytext
 */
.c-iconlink--inline {
  &:not(:first-child) {
    @include u-baseline(.5, margin-left);
  }
}





/** ***********
 * Text
 */
.c-iconlink__text {
  @include font-small; // override
  display: inline-block;
}

@mixin c-card() {
  @include u-baseline(2, padding);
  background-color: var(--card-background, var(--color-light));

  @include breakpoint(xlarge) {
    @include u-baseline(3, padding);
  }

  [class*='heading--']:first-child {
    @include u-baseline(2, margin-bottom);
    margin-top: 0;
  }

  p {
    @include u-baseline(2, margin-bottom);
    margin-top: 0;
  }

  ul:last-child,
  ol:last-child,
  p:last-child {
    margin-bottom: 0;
  }

  p:last-child .c-button {
    @include u-baseline(1, margin-bottom);
  }

  // temporary not visible see PRD2-4363
  &.review-order-comment-wrap {
    display: none;
  }

}

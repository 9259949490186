// Product Hero
//
// Hero with product and branding.
//
// Markup: hero-product.hbs
//
// Full: true
//
// Type: module
//
// Style guide: Promotion.Hero.hero-product

$hero-min-height: rem-calc(300);
$hero-product-background: var(--color-concrete);

/** **************
 * Basic styles
 */
.m-hero-product {
  @include u-module-margin;
  min-height: $hero-min-height;
  background-color: $hero-product-background;
  height: auto;
  max-height: none;
}





/** ***********
 * Modifier: Large image using more ratio
 */
.m-hero-product--large-img {
  .m-hero-product__wrapper {
    max-width: rem-calc($global-max-width);

    @include breakpoint(xlarge) {
      grid-template-columns: 1fr 1.25fr;
    }
  }

  .m-hero-product__content {
    @include breakpoint(large) {
      @include u-baseline(5, padding-right);
    }
  }

  .m-hero-product__img {
    max-width: none;
    width: 100%;
  }
}




/** **************
 * Wrapper
 */
.m-hero-product__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  grid-template-areas: 'image' 'content';
  max-width: rem-calc(1100);
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(large) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'content image';
    justify-content: center;
    align-items: center;
  }
}




/** **************
 * Content
 */
.m-hero-product__content {
  @include u-baseline(5, padding-top);
  @include u-baseline(5, padding-bottom);
  grid-area: content;
  text-align: center;

  @include breakpoint(large) {
    @include u-baseline(10, padding-top);
    @include u-baseline(10, padding-bottom);
    text-align: left;
  }
}




/** **************
 * Heading
 */
.m-hero-product__heading {
  @include font-h1;
  margin-top: 0;
}


/** **************
 * Product image
 */
.m-hero-product__img {
  align-self: var(--hero-align, center);
  justify-self: center;
  grid-area: image;
  max-width: rem-calc(500);
  padding-top: var(--hero-img-padding, 0);

  @include breakpoint(large) {
    padding-top: 0;
  }

  img {
    aspect-ratio: var(--img-ratio);
  }
}




/** **************
 * Vendor box
 */
.m-hero-product__vendor {
  @include u-baseline(7, margin-top);
  @include u-baseline(3, padding-top);
  border-top: $global-border-dotted;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: rem-calc(260);

  @include breakpoint(large) {
    margin-left: 0;
  }

  img {
    @include u-baseline(2, margin-right);
    display: block;
    float: left;
    max-width: 100%;
    width: rem-calc(80);
  }

  figcaption {
    padding-left: rem-calc(104);
    font-family: font(base, 'display');
  }
}

// Slide-out
//
//
$slideout-breakpoint: medium;
$slideout-max-width: rem-calc(540);

.t-slideout {
  --slideout-top: #{rem-baseline(8)};
  --slideout-wrapper-padding: #{rem-baseline(8)};
  --slideout-content-padding: 0 #{rem-baseline(3)} #{rem-baseline(8)};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: var(--slideout-visibility, hidden);
  z-index: #{index($elements, slideout)};

  @include breakpoint($slideout-breakpoint) {
    --slideout-top: 0;
    --slideout-container-bottom: 0;
    --slideout-width: #{$slideout-max-width};
    --slideout-close-top: #{rem-baseline(6)};
    --slideout-close-right: #{rem-baseline(6)};
    --slideout-wrapper-padding: #{rem-baseline(10)};
    --slideout-content-padding: 0 #{rem-baseline(6)} #{rem-baseline(10)};
  }

  &[data-state='open'] {
    --slideout-visibility: visible;
    --slideout-container-pos: 100%;
    --slideout-container-bottom: 0;
    --slideout-opacity: 1;

    @include breakpoint($slideout-breakpoint) {
      --slideout-container-bottom: 0;
      --slideout-container-right: 0;
    }
  }
}




/** ***********
 * Close button
 */
.t-slideout__close {
  position: absolute;
  top: var(--slideout-close-top, #{rem-baseline(3)});
  right: var(--slideout-close-right, #{rem-baseline(3)});
  z-index: 2;

  &:focus {
    outline: var(--color-slate) dotted rem-calc(1);
  }
}





/** ***********
 * Container
 */
.t-slideout__container {
  height: calc(100% - var(--slideout-top));
  position: absolute;
  right: 0;
  bottom: var(--slideout-container-bottom, -100%);
  transition: var(--transition-smooth);
  width: var(--slideout-width, 100%);
  z-index: 1;

  @include breakpoint($slideout-breakpoint) {
    right: var(--slideout-container-right, -#{$slideout-max-width});
    top: var(--slideout-top);
  }
}




/** ***********
 * Wrapper
 */
.t-slideout__wrapper {
  background-color: var(--color-light);
  box-shadow: var(--shadow-heavy);
  padding-top: var(--slideout-wrapper-padding);
  height: 100%;
  width: 100%;
}




/** ***********
 * Content
 */
.t-slideout__content {
  height: 100%;
  overflow-y: auto;
  padding: var(--slideout-content-padding);
  position: relative;
}






/** ***********
 * Translucent overlay
 */
.t-slideout__overlay {
  background-color: rgba(0, 0, 0, .3);
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: var(--slideout-opacity, 0);
  transition: var(--transition-smooth);
  visibility: var(--slideout-visibility, hidden);
  z-index: 0;
}

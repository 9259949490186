// Post meta
//
// Author, date and categories for the Lichtjournal.
//
// Markup: postmeta.hbs
//
// Type: module
//
// Style guide: Content.postmeta

/** **************
 * Basic styles
 */
.m-postmeta {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
  @include u-module-margin;
  @include u-baseline(2, padding-top);
  @include u-baseline(1, padding-bottom);
  @include font-meta;
  clear: both;
  font-style: italic !important;
  position: relative;

  // Border
  &:after {
    @include u-baseline(1.5, left);
    @include u-baseline(1.5, right);
    border-bottom: $global-border-dotted;
    content: '';
    position: absolute;
    bottom: 0;
  }
}




/** **************
 * Item
 */
.m-postmeta__item {
  @include xy-cell;
  text-align: center;

  @include breakpoint(large) {
    @include xy-cell(6);
    text-align: left;
  }

  &:first-of-type {
    @include breakpoint(large) {
      text-align: right;
      order: 2;
    }
  }
}

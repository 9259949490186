// Mapster
//
// Interactive map container using Mustache and JSON.
//
// Markup: mapster.hbs
//
// Type: organism
//
// Javascript: true
//
// Style guide: Maps.mapster

@import '_config';
@import '_mixins';

.o-mapster {
  @include u-baseline(-2, margin-left);
  @include u-baseline(-2, margin-right);
  box-shadow: 0 rem-calc(8) rem-calc(20) $mapster-shadow-color;
  overflow: hidden;
  position: relative;
  height: $mapster-height-small;

  @include breakpoint(medium) {
    border-radius: rem-calc($global-border-radius);
    margin-left: 0;
    margin-right: 0;
  }

  @include breakpoint($mapster-breakpoint) {
    @include flex;
    @include flex-align(left, top);
    height: $mapster-height-large;
  }
}









/** ***********
 * Loading spinner
 */
.o-mapster__spinner {
  @include u-full-overlay;
  @include flex;
  @include flex-align(center, middle);
  background-color: $mapster-spinner-background;
  opacity: var(--opacity, 0);
  visibility: var(--visibility, hidden);
  transition: var(--transition-default);
  z-index: 20;
}









/** ***********
 * Sidebar
 */
.o-mapster__sidebar {
  background-color: $mapster-sidebar-background;

  @include breakpoint($mapster-breakpoint) {
    display: block;
    flex: 0 1 $mapster-sidebar-width-large;
    height: 100%;
  }
}










/** ***********
 * Header
 */
.m-mapster__header {
  @include u-baseline(2, padding);
  background-color: $mapster-header-background;
  position: relative;
  z-index: 12;

  @include breakpoint($mapster-breakpoint) {
    @include u-baseline(3, padding);
    height: $mapster-form-height;
  }
}







/** ***********
 * Form
 */
.m-mapster__form {
  @include flex;
  @include flex-align(left, bottom);
  flex-wrap: var(--flex-wrap, wrap);
  width: 100%;

  @include breakpoint($mapster-breakpoint) {
    flex-wrap: nowrap;
  }
}

.m-mapster__form-item {
  &:nth-child(1) {
    @include u-baseline(1, padding-right);
    flex: 1 0 auto;

    @include breakpoint($mapster-breakpoint) {
      flex: 1 0 auto;
      padding: 0;
    }
  }

  &:nth-child(2) {
    @include u-baseline(1, padding-left);
    flex: 0 0 rem-calc(100);
    width: rem-calc(100);

    @include breakpoint($mapster-breakpoint) {
      flex: 1 0 auto;
      padding: 0;
      width: auto;
    }
  }

  &:last-child {
    @include u-baseline(2, margin-top);
    flex: 0 1 100%;

    @include breakpoint($mapster-breakpoint) {
      flex: 1 0 auto;
      margin-top: 0;
    }
  }


  &:not(:last-child) {
    @include breakpoint($mapster-breakpoint) {
      @include u-baseline(2, margin-right);
    }
  }

  .c-input,
  .c-select {
    margin-bottom: 0 !important;
  }
}










/** ***********
 * Map
 */
.o-mapster__map {
  @include u-baseline(62, height);
  flex: 1;

  @include breakpoint($mapster-breakpoint) {
    height: 100%;
  }

  [data-mapster-map] {
    height: 100%;
  }
}









/** ***********
 * Results list
 */
.o-mapster__results {
  @include u-list-reset;
  background-color: $mapster-results-background;
  box-shadow: 4px -4px 6px $mapster-results-shadow-color;
  height: calc(#{$mapster-height-small} - #{$mapster-form-height-small});
  margin: 0;
  overflow: auto;
  position: absolute;
  left: -100%;
  bottom: 0;
  transition: var(--transition-default);
  max-width: rem-calc(420);
  width: calc(100% - #{rem-calc(60)});
  z-index: 11;

  @include breakpoint($mapster-breakpoint) {
    background: transparent;
    bottom: auto;
    box-shadow: none;
    height: calc(100% - #{$mapster-form-height});
    max-width: none;
    position: relative;
    transition: none;
    width: 100%;
    left: 0;
    display: block;
  }

  &[data-state='active'] {
    left: 0;
  }
}






/** ***********
 * Toggle results icon for mobile
 */
.o-mapster__toggler {
  @include u-baseline(5, width);
  @include u-baseline(5, height);
  @include u-baseline(2, left);
  background-color: $mapster-toggler-background;
  border-radius: 50%;
  box-shadow: var(--shadow-popup);
  color: $mapster-toggler-color;
  position: absolute;
  top: calc(#{$mapster-form-height-small} + #{rem-baseline(2)});
  text-align: center;
  z-index: 10;

  @include breakpoint($mapster-breakpoint) {
    display: none;
  }

  .c-icon {
    @include u-baseline(2.5, width);
    @include u-baseline(2.5, height);
    @include u-baseline(1.5, margin-top);
  }
}









/** ***********
 * Legacy browser node
 */
.o-mapster__legacy {
  @include u-full-overlay($zindex: 10);
  @include u-baseline(4, padding);
  background-color: $mapster-legacy-background;
}






/** ***********
 * Result item
 */
.m-mapster-result {
  @include u-baseline(3, padding-top);
  @include u-baseline(3, padding-bottom);
  @include u-baseline(2, padding-right);
  @include u-baseline(8, padding-left);
  border-left: rem-calc(5) solid transparent;
  position: relative;

  &:before {
    @include u-baseline(1, width);
    @include u-baseline(1, height);
    @include u-baseline(4, left);
    @include u-baseline(4.25, top);
    background-color: $mapster-result-dot-color;
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
  }
}

.m-mapster-result__heading {
  @include font-h4;
  @include u-baseline(1, margin-bottom);
  font-weight: bold;
}

.m-mapster-result__body {
  @include u-baseline(2, margin-bottom);
}

.m-mapster-result__meta {
  @include breakpoint($mapster-breakpoint) {
    display: flex;
  }
}

.m-mapster-result__contact {
  @include u-baseline(2, margin-bottom);
  @include breakpoint($mapster-breakpoint) {
    flex: 1 0 auto;
    margin-bottom: 0;
  }
}

.m-mapster-result__extras {
  @include breakpoint($mapster-breakpoint) {
    flex: 1 0 auto;
    text-align: right;
  }

  .c-iconlink:not(:first-child) {
    @include u-baseline(1.5, margin-left);
  }
}

.m-mapster-result--is-active {
  background-color: $mapster-result-active-background;
}

.m-mapster-result--is-premium {
  border-color: $global-primary-color;

  &:before {
    @include u-baseline(3, width);
    @include u-baseline(3, height);
    @include u-baseline(3, left);
    @include u-baseline(6.5, top);
    background-color: $mapster-result-premium-dot-color;
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
  }

  &:after {
    @include u-baseline(3.75, left);
    @include u-baseline(7.25, top);
    background-image: $global-icon-star-white;
    background-repeat: no-repeat;
    content: '';
    display: block;
    position: absolute;
    width: rem-calc(12);
    height: rem-calc(12);
  }
}

.m-mapster-result--is-showroom {
  border-color: $mapster-result-showroom-border-color;

  &:before {
    display: none;
  }

  &:after {
    @include u-baseline(3, left);
    @include u-baseline(3.25, top);
    @include u-baseline(3, width);
    @include u-baseline(3, height);
    background-image: $global-icon-sun;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: block;
    position: absolute;
  }
}

.m-mapster-result__label {
  @include m-mapster-label;
}




.m-mapster-noresult {
  @include u-baseline(3, padding);
  text-align: center;
}














/** ***********
 * Marker
 */
.m-mapster-marker {
  @include u-baseline(4, width);
  @include u-baseline(4, height);
  background-color: var(--marker-background, #{$mapster-marker-background});
  border: rem-calc(4) solid $mapster-marker-border-color;
  border-radius: 50%;
  box-shadow: 0 rem-calc(3) rem-calc(8) $mapster-marker-shadow-color;
}

.m-mapster-marker--is-active {
  background-color: $mapster-marker-active-background;
}

.m-mapster-marker--is-premium {
  background-color: $mapster-marker-premium-background;

  &:after {
    background-image: $global-icon-star-white;
    background-repeat: no-repeat;
    content: '';
    display: block;
    position: absolute;
    top: rem-calc(6);
    left: rem-calc(6);
    width: rem-calc(12);
    height: rem-calc(12);
  }

  &.m-mapster-marker--is-active {
    background-color: $global-primary-color;
  }
}

.m-mapster-marker--is-showroom {
  @include u-baseline(5, width);
  @include u-baseline(5, height);
  background-color: $mapster-marker-showroom-background;
  color: $mapster-marker-showroom-color;

  .c-icon {
    width: 100%;
    height: 100%;
  }
}










/** ***********
 * Popup
 */
.m-mapster-popup__heading {
  @include font-h4;
  @include u-baseline(1, margin-bottom);
  @include u-baseline(2, padding-right);
  font-weight: bold;
}

.m-mapster-popup__body {
  @include font-small; // override
}

.m-mapster-popup__premium {
  @include m-mapster-label;
}

.m-mapster-popup__meta {
  @include u-baseline(2, margin-top);
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding: 0;

  > li {
    flex: 0 1 50%;

    &:last-child {
      text-align: right;
    }
  }
}








/** ***********
 * Mapbox plugin overrides
 */
.mapboxgl-popup {
  font-family: font(base, 'copy'); // plugin overwrite

  &.is-premium {
    .mapboxgl-popup-content {
      @include u-baseline(1.5, padding-top);
      border-top: rem-calc(4) solid $global-primary-color;
    }
  }

  &.is-showroom {
    .mapboxgl-popup-content {
      @include u-baseline(1.5, padding-top);
      border-top: rem-calc(4) solid $mapster-result-showroom-border-color;
    }
  }
}

.mapboxgl-popup-content {
  @include u-baseline(2, padding);
  box-shadow: var(--shadow-popup);
  min-width: rem-calc(246);
}

.mapboxgl-popup-close-button {
  @include u-baseline(1, top);
  @include u-baseline(1, right);
  color: $mapster-marker-close-color;
  font-size: rem-calc(20);
}

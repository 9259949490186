// Social Icons
//
// Social icons module used in site footer and flexible.
//
// Markup: social.hbs
//
// Type: module
//
// Style guide: Controls.social
.m-social {
  @include font-small;
  text-align: center;

  ul {
    @include u-list-reset;
    display: inline-block;
    margin: 0;
  }


  li {
    @include u-baseline(2, margin-left);
    display: inline-block;
  }

  a {
    color: currentColor;
    display: block;

    &:hover {
      color: var(--color-orange);
    }
  }

  .c-icon {
    @include u-icon-relative-size;
  }
}

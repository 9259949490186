// Backlink wrapper
//
// This is not a pattern library module!
.m-backlink {
  @include u-baseline(1, padding-top);
  @include u-baseline(2, margin-bottom);

  @include breakpoint(large) {
    @include u-baseline(2, padding-top);
  }

  &:not(.m-backlink--nested) {
    @include u-cell-row;
  }
}

// Filter list
//
// Filter list with selected items.
//
// Markup: filterlist.hbs
//
// Type: module
//
// Style guide: Controls.filters.filterlist

$filterlist-color: var(--color-slate-petrol);

.m-filterlist {
  @include u-baseline(1, margin-top);
  @include u-baseline(3, margin-bottom);
  color: $filterlist-color;
  display: block;

  ul {
    @include u-list-reset;
    @include u-baseline(2, margin-bottom);
    display: inline-block;
    margin-top: 0;
  }

  li {
    @include u-baseline(2, margin-right);
    display: inline-block;

    // Change styles on remove link
    &:nth-child(n+3):last-child {
      .c-removelink {
        @include u-link($global-link-options-iron);
      }
    }
  }
}




/** **************
 * Inner container
 */
.m-filterlist__inner {
  border-bottom: $global-border-dotted;
}




/** **************
 * Label
 */
.m-filterlist__label {
  @include u-baseline(2, margin-right);
  display: inline-block;
}

// Select arrow mixin
@mixin form-select-arrow() {
  &:before {
    @include u-baseline(5, max-height);
    @include u-baseline(5, right);
    background-color: $form-border-color;
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    top: rem-calc(3);
    bottom: rem-calc(3);
    width: rem-calc(1);
  }

  &:after {
    @include u-baseline(2.25, top);
    @include u-baseline(2, right);
    background-image: $global-icon-arrow;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    pointer-events: none;
    position: absolute;
    transform: var(--arrow-transform, rotate(90deg));
    height: rem-calc(10);
    width: rem-calc(6);
  }
}


// Select mixin for pretty dropdowns
@mixin form-select() {
  @include form-select-arrow;
  @include u-baseline(3, margin-bottom);
  @include font-small;
  background-color: $form-select-background;
  border-radius: $form-border-radius;
  border: $form-border;
  box-shadow: var(--shadow-light);
  display: block;
  height: $form-input-height;
  position: relative;

  &:hover,
  &:focus {
    background-color: var(--color-ice);
    box-shadow: var(--shadow-light);
  }

  select {
    @include u-baseline(1, padding-top);
    @include u-baseline(1, padding-bottom);
    @include u-baseline(2, padding-left);
    @include u-baseline(5, padding-right);
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: $form-border-radius;
    font-size: inherit;
    height: 100%;
    margin-bottom: 0;

    &:focus {
      outline: auto;
    }

    &:disabled {
      opacity: .3;
    }
  }
}

// Mailbox
//
// Email address of registered user in checkout.
// Toggles input field to change address.
//
// Markup: mailbox.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Containers.mailbox
//

.m-mailbox {
  @include u-baseline(3, margin-bottom);
}

// Tabs
//
// Tabs with scrolling overflow when max-width exceeded.
//
// Markup: tabs.hbs
//
// Type: component
//
// JavaScript: true
//
// Style guide: Controls.tabs
@use 'sass:math';

$tabs-color: var(--color-dark);
$tabs-active-background: var(--color-ice);
$tabs-border: 1px solid var(--color-slush);
$tabs-hover-border: 1px solid var(--color-petrol);
$tabs-width: rem-calc(160);
$tabs-height: rem-calc($global-controls-height);

/** **************
 * Base styles
 */
.c-tabs {
  overflow-x: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: rem-calc($global-max-width);
  width: 100%;

  @include breakpoint(large) {
    @include u-baseline(1, padding-left);
  }

  ul {
    @include u-baseline(6, margin-bottom);
    @include u-list-reset;
    font-size: 0;
    margin-top: 0;
    padding: 0 rem-baseline(2);
    text-align: center;
    width: 100%;
    white-space: nowrap;
  }

  li {
    box-shadow: var(--shadow-light);
    display: inline-block;
    min-width: $tabs-width;

    + li {
      margin-left: -1px;
    }

    &:first-child {
      .c-tabs__item {
        border-top-left-radius: rem-calc($global-border-radius);
        border-bottom-left-radius: rem-calc($global-border-radius);
      }
    }

    &:last-child {
      .c-tabs__item {
        border-top-right-radius: rem-calc($global-border-radius);
        border-bottom-right-radius: rem-calc($global-border-radius);
      }
    }
  }
}





/** ***********
 * Modifier: Left aligned tabs
 */
.c-tabs--left {
  ul {
    text-align: left;
  }
}




/** **************
 * Tab item
 */
.c-tabs__item {
  @include font-meta($type: 'display');
  @include u-baseline(1, padding);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  background-color: var(--tabs-background, var(--color-light));
  border: 1px solid var(--color-slush);
  color: $tabs-color;
  cursor: pointer;
  display: block;
  height: $tabs-height;
  line-height: math.div($tabs-height, 1.65);
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 0;

  &:hover {
    --tabs-background: var(--color-ice);
    box-shadow: var(--shadow-medium);
    outline: none;
    z-index: 1;
  }

  &.is-active,
  &:focus {
    --tabs-background: var(--color-ice);
    outline: none;
  }
}




/** **************
 * Active state
 */
.is-active > .c-tabs__item {
  --tabs-background: var(--color-ice);
}

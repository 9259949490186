// Flyout
//
// Including:
// – Megamenu
// – Menu items
// – Teaser

$flyout-background-color: var(--color-pearl);

.o-flyout {
  @include breakpoint($global-navigation-breakpoint) {
    @include xy-grid-container;
    @include xy-grid;
    @include u-toggle-visibility();
    background-color: $flyout-background-color;
    float: none !important;
    padding: 0 !important;
    position: absolute;
    top: 100%;
    left: 0;
    width: auto;
  }

  img {
    @include u-img-responsive;
  }

  ul {
    @include u-list-reset;
  }

  // Override sneaky PageBuilder div
  > div {
    width: 100%;
  }
}


[data-state='open'] .o-flyout {
  @include breakpoint($global-navigation-breakpoint) {
    @include u-toggle-visibility('show');
  }
}




/** **************
 * Inner container
 */
.o-flyout__inner {
  @include breakpoint($global-navigation-breakpoint) {
    background-color: $flyout-background-color;
    box-shadow: var(--shadow-heavy);
    position: relative;
    width: 100%;
  }
}




/** **************
 * Modifier: Inner container has full width
 */
.o-flyout__inner--full {
  .m-menu__title {
    display: none;
  }

  @include breakpoint($global-navigation-breakpoint) {
    padding: rem-baseline(2);

    .m-menu__title {
      @include u-baseline(1.5, padding-left);
      @include u-baseline(1, padding-top);
      display: block;
      margin-bottom: 0;
    }
  }
}




/** **************
 * Modifier: Element is open
 */
.o-flyout--is-open {
  @include breakpoint($global-navigation-breakpoint) {
    @include u-toggle-visibility('show');
  }
}

// Create list
//
// Wishlist creator module.
//
// Markup: createlist.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Wishlist.createlist

.m-createlist {
  @include u-borderbox;

  .c-icon {
    @include u-baseline(1, margin-right);
    @include u-baseline(4, width);
    @include u-baseline(5, height);
    transform: translate3d(0, 0, 0);
  }

  .m-wishlister__form {
    @include u-baseline(1, padding-top);
    @include u-baseline(4, padding-left);
    background-color: transparent;
    border: none;
    padding-bottom: 0;
    padding-right: 0;
  }
}







/** ***********
 * Link
 */
.m-createlist__link {
  color: var(--color-dark);
  display: block;
}

// Expander
//
// Expandable content with more options and transitions.
//
// | data-attribute             | Type         | Description           |
// |----------------------------|--------------|-----------------------|
// | `data-expander-height`     | Number       | Max. px height of container (default: 90) |
// | `data-expander-desktop`    | Number       | If set to 1 the plugin will work on large screens (default: 0) |
// | `data-expander-readmore`   | String       | Link text on collapsed (default: 'Mehr lesen') |
// | `data-expander-readless`   | String       | Link text on expanded (default: 'Weniger lesen') |
//
// Type: organism
//
// JavaScript: true
//
// Markup: expander.hbs
//
// Style guide: Content.readmore

$expander-gradient-background: var(--color-light);
$expander-gradient-start: rgba(255, 255, 255, .85);
$expander-gradient-stop: rgba(255, 255, 255, 0);

.o-expander {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &[data-expander-active='true'] {
    @include u-baseline(6, margin-bottom);
    -webkit-line-clamp: var(--expander-lines, 4);

    @include breakpoint(xlarge) {
      -webkit-line-clamp: var(--expander-lines-desktop, none);
    }
  }
}






/** ***********
 * Expander toggle button
 */
.o-expander__toggle {
  @include u-baseline(-4, margin-top);
  position: relative;
  text-align: center;
  z-index: 2;

  &:before {
    background: linear-gradient(0deg, var(--expander-gradient-start, $expander-gradient-start) 0%, $expander-gradient-stop 100%);
    content: '';
    display: block;
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    height: 80px;
  }
}

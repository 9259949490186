// Search
//
// Search form
//

$search-breakpoint: $global-navigation-breakpoint;
$search-cancel-background: var(--color-slush);
$search-background: var(--color-light);

/** **************
 * Basic styles
 */
.m-search {
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);
  @include u-baseline(2, margin-bottom);
  background-color: $search-background;
  border-bottom: 1px solid var(--color-slush-ice);
  position: relative;

  @include breakpoint($search-breakpoint) {
    @include u-baseline(-11, margin-top);
    border: none;
    margin-bottom: 0;
    transition: var(--transition-default);
    transform: translate3d(0, 0, 0);
    z-index: 15;
  }

  &.is-active {
    margin-top: 0;
  }

  input[type='search'],
  input[type='text'] {
    @include font-h3;
    @include u-baseline(6, padding-left);
    @include u-baseline(6, height);
    color: var(--color-dark);
    background-color: transparent;
    margin-bottom: 0;
    width: 100%;

    @include breakpoint($search-breakpoint) {
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;
      height: rem-calc($global-controls-height-large);
      padding-right: rem-baseline(5);
      padding-left: 0;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    // Native x icon
    &::-webkit-search-cancel-button {
      @include u-baseline(1, right);
      @include u-baseline(1.5, top);
      @include u-baseline(2, width);
      @include u-baseline(2, height);
      -webkit-appearance: none;
      background-color: $search-cancel-background;
      background-image: $global-icon-close;
      background-size: rem-calc(12) rem-calc(12);
      background-position: 50% 50%;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;

      @include breakpoint($search-breakpoint) {
        @include u-baseline(8, right);
        @include u-baseline(1.75, top);
      }
    }
  }
}




/** **************
 * Wrapper
 */
.m-search__wrapper {
  @include xy-grid-container;
  @include xy-grid;
}




/** **************
 * Inner container
 */
.m-search__inner {
  @include u-single-cell;
  position: relative;
}




/** **************
 * Submit
 */
.m-search__submit {
  @include u-baseline(1.25, top);
  @include u-baseline(1.5, left);
  @include u-baseline(3, width);
  @include u-baseline(3, height);
  color: var(--color-slate);
  position: absolute;

  @include breakpoint($search-breakpoint) {
    @include font-h3;
    @include u-icon-relative-size;
    @include u-baseline(2, right);
    left: auto;
    top: rem-calc(5);
  }

  .c-icon {
    width: 100%;
    height: 100%;

    @include breakpoint($search-breakpoint) {
      @include u-icon-relative-size;
    }
  }
}




/** ***********
 * FactFinder overrides
 */
ff-suggest-item.selectable-item small,
ff-suggest-item[type='category'] small {
  color: var(--color-slate);
}

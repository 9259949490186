// Logos
//
// Logo images for homepage.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | large         | 182x9999   |
// 9999 = Image height is flexible, **but** the displayed max-width is 180px.
//
// Markup: logos.hbs
//
// Type: module
//
// Style guide: Media.logos

.m-logos {
  @include u-baseline(2, gap);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}




/** ***********
 * Item
 */
.m-logos__item {
  flex: 0 0 calc(25% - #{rem-baseline(2)});

  @include breakpoint(medium) {
    flex: 1 1 0;
  }
}

// Bordered list
//
// List with borders and icon links
//
// For borders on top and bottom use additional `.c-borderlist--has-border`
//
// .c-borderlist--has-border - List has border
//
// Markup: borderlist.hbs
//
// Type: component
//
// Style guide: Lists.borderlist
.c-borderlist {
  @include u-list-reset;
  @include u-borderlist;
  margin: 0;
}





/** **************
 * List has a border on top and bottom
 */
.c-borderlist--has-border {
  @include u-baseline(2, padding-top);
  border-top: $global-border-dotted;
  border-bottom: $global-border-dotted;
}

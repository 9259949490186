// Countdown
//
// Flip clock counting down until deadline
//
// Markup: countdown.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Media.countdown
@use 'sass:math';
$countdown-dot-size: .55em;
$countdown-dot-size-small: .55em;
$countdown-breakpoint: xlarge;

.m-countdown {
  text-align: center;
  margin: 0 auto;
  perspective: 400px;

  @include breakpoint($countdown-breakpoint) {
    text-align: left;
  }
}

/** ***********
 * Plain countdown (text only)
 */
.m-countdown[data-countdown-type='plain'] {
  display: inline-block;

  .m-countdown__item {
    margin-right: rem-calc(4);

    &:before,
    &:after {
      display: none;
    }

    &:not(:first-of-type) {
      margin-right: 0;

      .m-countdown__value:after {
        content: ':';
      }

      .m-countdown__label {
        display: none;
      }
    }

    &:last-child {
      .m-countdown__value:after {
        content: '';
      }
    }
  }

  .m-countdown__value {
    font-weight: bold;
  }
}



/** ***********
 * Item
 */
.m-countdown__item {
  display: inline-block;
  margin-right: 1em;
  position: relative;
  text-align: center;

  @include breakpoint($countdown-breakpoint) {
    margin-right: 2em;
  }

  &:last-child {
    margin-right: 0;
  }

  // Dot spacer
  &:nth-child(2),
  &:nth-child(3) {
    &:before,
    &:after {
      background-color: var(--color-light);
      border-radius: 50%;
      content: '';
      display: block;
      position: absolute;
      right: -.675em;
      width: math.div($countdown-dot-size, 2);
      height: math.div($countdown-dot-size, 2);

      @include breakpoint($countdown-breakpoint) {
        right: -1.3em;
        width: $countdown-dot-size;
        height: $countdown-dot-size;
      }
    }

    &:before {
      top: calc(30% - #{$countdown-dot-size});
    }

    &:after {
      top: calc(47% - #{$countdown-dot-size});
    }
  }
}




/** ***********
 * Slot label
 */
.m-countdown__slot {
  @include font-tiny;
  @include u-baseline(1, padding-top);
  color: var(--color-light);
  display: inline-block;

  @include breakpoint($countdown-breakpoint) {
    @include font-meta;
  }
}








/** ***********
 * Modifier: Small display for category header
 */
.m-countdown--is-small {
  .c-flock {
    @include breakpoint($countdown-breakpoint) {
      font-size: unquote('clamp(30px, 2.5vw, 48px)');
    }
  }

  .m-countdown__slot {
    @include font-tiny;
  }
}

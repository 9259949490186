// Reveal form wrapper
//

.o-reveal-form {
  @include xy-grid-container;
  @include xy-grid;
}


/** **************
 * Inner container
 */
.o-reveal-form__inner {
  @include xy-cell;
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);
}

// Widget teaser block
//
$widget-teaser-xlarge-gutters: rem-calc($global-xlarge-gutter);

.o-widget-teaser {
  @include xy-grid-container;
  @include xy-grid;

  @include breakpoint(large) {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .m-widget {
    @include xy-cell;

    @include breakpoint(large) {
      @include xy-cell(4);
      display: flex;
      flex-direction: column;
    }

    @include breakpoint(xlarge) {
      @include xy-cell(4, $gutters: $widget-teaser-xlarge-gutters);
    }
  }
}

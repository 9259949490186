// Form Group
//
// Flexbox grid for forms, used with Formie plugin in Craft CMS.
//
// Markup: form-group.hbs
//
// Type: organism
//
// Style guide: Forms.form-group

// Display max. 4 items per row
$form-group-item-width: 22%;

.o-form-group {
  @include u-baseline(3, gap);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0 rem-baseline(3);
  width: 100%;
}


.o-form-group__item {
  flex: 1 0 auto;
  align-self: auto;

  @include breakpoint(medium) {
    flex: 1 0 var(--item-width, #{$form-group-item-width});
  }

  &.shrink {
    @include breakpoint(medium) {
      flex: 0 1 var(--item-width, #{$form-group-item-width});
    }
  }
}

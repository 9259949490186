// Cards
//
// Organism to display cards with equal height.
// Works with `.c-card` and `.m-contentcard`.
//
// **Additional [grid classes](https://get.foundation/sites/docs/xy-grid.html) in the DOM are required to make it more flexible!**
//
// Type: organism
//
// Style guide: Containers.cards

.o-cards {
  &:not(.o-cards--nested) {
    @include xy-grid-container;
    @include xy-grid;
  }

  &.o-cards--nested {
    @include xy-grid-container($padding: 0);
    @include xy-grid;
    @include xy-gutters($negative: true);
  }

  .m-widget {
    margin-bottom: 0;
  }
}







/** ***********
 * Modifier: 2 col grid
 */
.o-cards--2-col {
  .o-cards__item {
    @include breakpoint(large) {
      @include xy-cell(6);
    }
  }
}





/** ***********
 * Item
 */
.o-cards__item {
  @include xy-cell;
  @include u-baseline(2, margin-bottom);
  display: flex; // make 'em equal height!

  @include breakpoint(medium) {
    @include xy-cell(6);
    @include u-baseline(3, margin-bottom);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(4);
  }

  .c-card {
    width: 100%;
  }
}

// Standardized horizontal view for all
// variants. Sidebar jack will use `$breakpoint only`
// to switch back to initial mobile first layout.
@mixin m-jack-horizontal($breakpoint: null) {
  @include breakpoint($breakpoint) {
    --flex-dir: row;
    --text-align: left;
    @include u-baseline(4, padding);
    @include flex-align(left, middle);
  }

  .m-jack__icon {
    @include breakpoint($breakpoint) {
      --order: 1;
      flex: 0 1 $jack-icon-width;
      margin-bottom: 0;
    }
  }

  .m-jack__content {
    @include breakpoint($breakpoint) {
      --order: 2;
      @include u-baseline(5, padding-left);
      flex: 1;
    }
  }

  .m-jack__heading {
    @include breakpoint($breakpoint) {
      padding: 0;
    }
  }

  .m-jack__cta {
    @include breakpoint($breakpoint) {
      --position: relative;
      --left: auto;
      bottom: auto;
    }
  }
}

// Full width section wrapper
//

.t-section {
  @include u-baseline(3, padding-top);
  @include clearfix;

  .o-category-items,
  .o-products {
    margin-bottom: 0;
  }
}




/** **************
 * Header
 */
.t-section__header {
  @include xy-grid-container;
  @include xy-grid;

  [class*='heading'] {
    @include xy-cell(8);
    @include u-baseline(2, margin-bottom);
    margin-top: 0;
  }
}




/** **************
 * Inner container
 */
.t-section__inner {
  @include xy-grid-container;
  @include xy-grid;
}




/** **************
 * Entry content
 */
.t-section__entry {
  @include xy-cell;
}









/** ***********
 * Modifier: Inner paddings
 */
.t-section--spacing-small {
  @include u-baseline(3, padding-top);
  @include u-baseline(3, padding-bottom);

  @include breakpoint(large) {
    @include u-baseline(5, padding-top);
    @include u-baseline(5, padding-bottom);
  }
}

.t-section--spacing-large {
  @include u-baseline(5, padding-top);
  @include u-baseline(5, padding-bottom);

  @include breakpoint(large) {
    @include u-baseline(10, padding-top);
    @include u-baseline(10, padding-bottom);
  }
}





/** ***********
 * Modifier: Bottom margins
 */
.t-section--margin-small {
  @include u-baseline(3, margin-bottom);

  @include breakpoint(large) {
    @include u-baseline(5, margin-bottom);
  }
}

.t-section--margin-large {
  @include u-baseline(5, margin-bottom);

  @include breakpoint(large) {
    @include u-baseline(10, margin-bottom);
  }
}








// Basic page template
//
// - Full width header
// - Content on left
// - Sidebar right
$page-xlarge-gutters: rem-calc($global-xlarge-gutter);

.t-page {
  @include xy-grid-container;
  @include xy-grid;
  @include u-row-padding;
  @include u-module-margin;
  position: relative;
}







/** ***********
 * Modifier: Sidebar content is right aligned
 */
.t-page__sidebar--right {
  text-align: right;

  .c-branding {
    display: inline-block;
  }
}





/** ***********
 * Modifier: Template has no breakpoint
 */
.t-page--no-breakpoint {
  .t-page__content {
    @include xy-cell(8);
    margin-bottom: 0;

    @include breakpoint(xlarge) {
      @include xy-cell(7, $gutters: $page-xlarge-gutters);
    }

    .m-entry {
      @include breakpoint(medium down) {
        margin-bottom: 0;
      }
    }
  }

  .t-page__sidebar {
    @include xy-cell(4);

    @include breakpoint(xlarge) {
      @include xy-cell(4, $gutters: $page-xlarge-gutters);
      @include xy-cell-offset(1);
    }
  }
}




/** **************
 * Modifier: Template is 50-50 (2 * 6)
 */
.t-page--is-5050 {
  .t-page__content,
  .t-page__sidebar {
    @include breakpoint(xlarge) {
      @include xy-cell(6, $gutters: $page-xlarge-gutters);
    }
  }
}




/** **************
 * Modifier: Template has no bottom margin
 */
.t-page--no-margin {
  margin-bottom: 0;

  .t-page__content {
    margin-bottom: 0;
  }
}




/** **************
 * Modifier: Template has no gap to sidebar
 */
.t-page--no-gap {
  .t-page__content {
    @include breakpoint(xlarge) {
      @include xy-cell(8);
    }

    @include breakpoint(xxlarge) {
      @include xy-cell(8, $gutters: $page-xlarge-gutters);
    }
  }

  .t-page__sidebar {
    @include breakpoint(xlarge) {
      @include xy-cell(4);
      @include xy-cell-offset(0);
    }

    @include breakpoint(xxlarge) {
      @include xy-cell(4, $gutters: $page-xlarge-gutters);
    }
  }
}






/** ***********
 * Modifier: Sticky sidebar
 */
.t-page--is-sticky {
  align-items: flex-start;

  .t-page__sidebar {
    @include u-baseline(2, top);
    position: sticky;
  }
}





/** **************
 * Header
 */
.t-page__header {
  @include u-single-cell($xlarge: true);

  &:not(.no-margin) {
    @include u-module-margin;
  }
}



/** **************
 * Content
 */
.t-page__content {
  @include xy-cell;
  @include u-baseline(4, margin-bottom);

  @include breakpoint(xlarge) {
    @include xy-cell(8);
    margin-bottom: 0;
  }

  @include breakpoint(xxlarge) {
    @include xy-cell(7, $gutters: $page-xlarge-gutters);
  }

  .m-entry {
    @include u-module-margin;
  }
}




/** **************
 * Sidebar
 */
.t-page__sidebar {
  @include xy-cell;
  overflow-x: hidden; // avoid scrollbar on newsletter / fragebogen

  @include breakpoint(xlarge) {
    @include xy-cell(4);
  }

  @include breakpoint(xxlarge) {
    @include xy-cell(4, $gutters: $page-xlarge-gutters);
    @include xy-cell-offset(1);
  }
}

// Soapbox
//
// Template for large organisms like "Lichtszenen".
//
// Markup: soapbox.hbs
//
// Type: template
//
// Style guide: Containers.soapbox


.t-soapbox {
  @include u-baseline(6, padding-top);
  @include u-baseline(8, padding-bottom);

  &:not(.t-soapbox--plain) {
    background-color: var(--color-light);
  }

  @at-root [class*='u-grid--padding'] & {
    padding-top: 0;

    &:last-child {
      padding-bottom: 0;
    }
  }

  // Textbox override to avoid re-saving every page
  // manually thanks to old Page Builder HTML-to-DB.
  &[data-content-type='lightscene-assistent'] {
    .m-textbox {
      display: block;
    }
  }

  .m-accordion {
    margin-bottom: 0;
  }
}






/** ***********
 * Outer grid container
 */
.t-soapbox__outer {
  @include xy-grid-container;
  @include xy-grid;
}







/** ***********
 * Inner container
 */
.t-soapbox__inner {
  @include u-single-cell;
  position: relative;
}







/** ***********
 * Intro
 */
.t-soapbox__intro {
  @include u-baseline(4, margin-bottom);

  @include breakpoint(large) {
    @include xy-cell(7, $output: (base size));
  }
}







/** ***********
 * Image (Pinboard)
 */
.t-soapbox__image {
  &:not(:last-child) {
    @include u-baseline(4, margin-bottom);
  }
}





/** ***********
 * Actual organism
 */
.t-soapbox__content {
  @include u-baseline(3, scroll-margin-top);

  // Letter
  &:before {
    @include font-h0;
    @include u-baseline(10, width);
    @include u-baseline(10, height);
    @include u-baseline(11, line-height);
    @include u-baseline(3, margin-bottom);
    background-color: var(--color-ice);
    border-radius: 50%;
    color: var(--color-slush);
    content: attr(data-position);
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(large) {
      @include u-baseline(14, width);
      @include u-baseline(14, height);
      @include u-baseline(15, line-height);
      @include u-baseline(2, right);
      position: absolute;
    }
  }

  &.t-soapbox--no-letter {
    &:before {
      display: none;
    }
  }
}






/** ***********
 * Entry wrapper
 */
.t-soapbox__wrapper {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);

  &.t-soapbox--has-sidebar {
    .t-soapbox__entry {
      @include breakpoint(large) {
        @include xy-cell(8, $breakpoint: large);
      }
    }
  }
}





/** ***********
 * Entry
 */
.t-soapbox__entry {
  @include u-single-cell();

  .m-entry > p:last-child {
    margin-bottom: 0;
  }
}





/** ***********
 * Sidebar
 */
.t-soapbox__sidebar {
  @include xy-cell();

  @include breakpoint(large) {
    @include xy-cell(4, $breakpoint: large);
  }

  @include breakpoint(xlarge) {
    @include u-baseline(2, padding-left);
  }
}

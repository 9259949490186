// Column list
//
// List with CSS columns.
//
// Markup: columnlist.hbs
//
// Type: component
//
// Style guide: Lists.columnlist

.c-columnlist {
  @include u-list-reset;
  @include u-baseline(2, column-gap);
  columns: 300px 3;

  > li {
    @include u-baseline(2, margin-bottom);
  }
}

// Template: Product Detail Page (PDP)
//
//

$pdp-breakpoint: xlarge;

.t-pdp {
  @include xy-grid-container;
  @include xy-grid;
}




/** **************
 * Image
 */
.t-pdp__images {
  @include xy-cell;

  @include breakpoint($pdp-breakpoint) {
    @include xy-cell(7);
    @include u-module-margin;
  }
}






/** ***********
 * Inner wrapper
 *
 * Pushes right padding to create space
 */
.t-pdp__images-inner {
  @include breakpoint($pdp-breakpoint) {
    @include u-baseline(4, padding-right);
  }
}







/**
 * Entry content
 */
.t-pdp__entry {
  @include xy-cell;

  @include breakpoint($pdp-breakpoint) {
    @include xy-cell(5);
  }

  @include breakpoint(xxlarge) {
    @include u-baseline(4, padding-left);
  }
}




/** **************
 * Service
 */
.t-pdp-service {
  @include xy-grid-container;
  @include xy-grid;
}




/** **************
 * Reviews
 */
.t-pdp-service__reviews {
  @include xy-cell;

  @include breakpoint($pdp-breakpoint) {
    @include xy-cell(8);
  }
}




/** **************
 * Contact
 */
.t-pdp-service__contact {
  @include xy-cell;

  @include breakpoint($pdp-breakpoint) {
    @include xy-cell(4);
  }
}





/** ***********
 * Badges
 */
.t-pdp__badges {
  @include u-baseline(2, margin-bottom);
}

// Page intro
//
// Large heading and intro text for pages with full with modules (i.e. team, projects, lexikon).
//
// Overrides [.m-entry](section-content.html#kssref-content-entry) styles.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x9999    |
// | small         | 520x9999   |
// | large         | 1400x9999  |
// 9999 = Image height is flexible, **but** shouldn't be higher than 1400x790.
//
// Markup: pageintro.hbs
//
// Type: module
//
// Style guide: Content.pageintro

/** **************
 * Wrapper
 */
.m-pageintro {
  @include xy-grid-container;
  @include xy-grid;
  @include u-baseline(4, margin-bottom);
}




/** **************
 * Modifier: Element has no border
 */
.m-pageintro--has-no-border {
  @include u-baseline(2, margin-bottom);

  .m-pageintro__entry {
    border: none;
  }
}





/** **************
 * Inner container
 */
.m-pageintro__inner {
  @include u-single-cell;
}






/** ***********
 * Image
 */
.m-pageintro__img {
  @include u-baseline(3, margin-bottom);

  @include breakpoint(large) {
    @include u-baseline(4, margin-bottom);
  }
}





/** **************
 * Entry content
 */
.m-pageintro__entry {
  @include breakpoint(large) {
    border-bottom: $global-border-dotted;
  }

  .m-entry {
    @include breakpoint(large) {
      @include xy-cell(8, $output: (base size));
    }
  }

  p {
    max-width: 70ch;
  }
}

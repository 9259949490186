// Colors
$multimenu-color: var(--color-petrol);
$multimenu-background-color: var(--color-pearl);
$multimenu-item-active-color: var(--color-pearl);
$multimenu-item-background: var(--color-slush-ice);




// Fonts
$multimenu-font-small: font(base, 'copy');
$multimenu-font-large: font(base, 'display');

// Features
//
// Displays product features and energy class.
//
// Markup: features.hbs
//
// Type: module
//
// Style guide: Media.features

.m-features {
  @include u-list-reset;
  margin-top: 0;

  li {
    @include u-baseline(1, margin-right);
    @include u-baseline(1, margin-bottom);
    display: inline-block;
    vertical-align: top;
  }

  .c-energy {
    transform: translateY(rem-calc(1));
  }
}

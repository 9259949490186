// Footnote
//
// Small text for footnotes.
//
// Markup: footnote.hbs
//
// Type: component
//
// Style guide: Content.footnote
//

.c-footnote {
  @include font-small; // override
  @include u-baseline(2, padding-top);
  color: var(--footnote-color);

  a {
    @include u-link($global-link-options-underline-inherit);
  }

  p {
    @include u-baseline(1, margin-top);
    @include u-baseline(1, margin-bottom);

    &:first-child {
      margin-top: 0;
    }
  }

  &[class*='color--'] {
    a {
      color: currentColor;
    }
  }
}

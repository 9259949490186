// Widgets
//
// The widget is a special module which can be build out of different components,
// but should always be within the `.m-widget` context.
//
// Markup: widget.hbs
//
// .m-widget--has-border - Closing border at the bottom of the widget.
// .m-widget--is-callout - Callout widget for e.g. Fragebogen
// .m-widget--is-callout.m-widget--is-dark - Dark widget for e.g. Project details
//
// Weight: 2
//
// Style guide: Widgets

$widget-callout-background: var(--color-ice);
$widget-callout-background-light: var(--color-pearl);
$widget-dark-background: var(--color-cod);
$widget-dark-color: var(--color-alabaster);
$widget-link-options: (
  iconColor: var(--color-orange),
  iconColorHover: var(--color-dark),
  linkType: $global-link-options-dark
);

/** **************
 * Wrapper
 */
.m-widget {
  @include u-module-margin('medium');
  background: var(--widget-background);

  .c-callout__wrapper {
    padding: 0;
  }
}




/** **************
 * Modifier: Element is flexbox
 */
.m-widget--flex {
  @include flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0;
}




/** **************
 * Modifier: element has border
 */
.m-widget--has-border {
  border-bottom: $global-border-dotted;
}




/** **************
 * Modifier: Element is a callout with background color
 */
.m-widget--is-callout {
  @include u-baseline(3, padding-top);
  @include u-baseline(3, padding-right);
  @include u-baseline(2, padding-bottom);
  @include u-baseline(3, padding-left);
  --widget-background: #{$widget-callout-background};
  border-radius: rem-calc($global-border-radius);

  .c-borderlist {
    @include u-baseline(2, margin-bottom);
  }
}

.m-widget--callout-light {
  --widget-background: #{$widget-callout-background-light};
}

.m-widget--is-dark {
  --desclist-dt-color: var(--color-slush);
  --desclist-dd-color: var(--color-alabaster);
  --widget-background: #{$widget-dark-background};
  color: $widget-dark-color;
}



/** ***********
 * Text color on dark backgrounds
 */
@include u-text-on-dark('.m-widget:not(.m-widget--is-callout), .m-widget:not(.m-widget--callout-light)');


// Modules
//
// Components to build a widget.
//
// Style guide: Widgets.Modules



// Heading
//
// Heading with border
//
// Markup: widget__heading.hbs
//
// .m-widget__heading--light - Lighter border color
// .m-widget__heading--dotted - Dotted border style
//
// Weight: 1
//
// Type: module
//
// Style guide: Widgets.Modules.Heading
.m-widget__heading {
  --widget-border: 1px var(--widget-border-style, solid) var(--widget-border-color, var(--color-iron));
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(2, padding-bottom);
  @include u-baseline(3, margin-bottom);
  @include clearfix;
  border-bottom: var(--widget-border);
  flex: none;
  margin-top: 0;
  padding-top: 0;
  width: 100%;
}




/** **************
 * Modifier: Heading has a light border
 */
.m-widget__heading--light {
  --widget-border-color: #{var(--color-slush)};
}




/** **************
 * Modifier: Heading has a dotted border
 */
.m-widget__heading--dotted {
  --widget-border-style: dotted;
}





/** ***********
 * Modifier: Plain heading without border
 */
.m-widget__heading--plain {
  --widget-border: none;
  padding-bottom: 0;
}




/** **************
 * Status in heading
 */
.m-widget__status {
  display: block;

  @include breakpoint(medium) {
    display: inline-block;
    float: right;
  }

  span {
    @include u-baseline(1, margin-left);
    display: inline-block;
  }
}





// Sub-heading
//
// Widget sub-heading
//
// Markup: widget__subheading.hbs
//
// Weight: 3
//
// Type: module
//
// Style guide: Widgets.Modules.Sub-heading
.m-widget__subheading {
  @include font-meta($type: 'display');
  @include u-baseline(1, margin-bottom);
  margin-top: 0;
}




// Image
//
// Widget image
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x16      |
// | large         | 725x370    |
//
// Markup: widget__image.hbs
//
// Weight: 2
//
// Type: module
//
// Style guide: Widgets.Modules.Image
.m-widget__image {
  @include u-baseline(2, margin-bottom);
  flex: none;

  img {
    @include u-img-responsive;
  }

  &[data-layout='round-centered'] {
    @include u-baseline(3, margin-bottom);
    max-width: rem-baseline(17);
    margin-left: auto;
    margin-right: auto;
  }

  &[data-layout='round-float'] {
    @include u-baseline(3, margin-right);
    @include u-baseline(3, margin-bottom);
    float: left;
    max-width: rem-baseline(17);
    width: 100%;
  }
}




// Entry
//
// Widget content entry
//
// Markup: widget__entry.hbs
//
// Weight: 4
//
// Type: module
//
// Style guide: Widgets.Modules.Entry
.m-widget__entry {
  @include u-content-links;
  flex: 1 0 auto;

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;

    > .c-button {
      margin-bottom: 0;
    }
  }

  .c-button {
    @include u-baseline(3, margin-bottom);
  }
}

// Large font for e.g. projects
.t-sector--double {
  .m-widget__entry {
    @include breakpoint(xlarge) {
      @include font-meta;
    }
  }
}


// Link
//
// Widget read more link
//
// Markup: widget__link.hbs
//
// Weight: 5
//
// Type: module
//
// Style guide: Widgets.Modules.Link
.m-widget__link {
  @include u-baseline(1, padding-top);
  @include u-baseline(1, padding-bottom);
  @include c-arrowlink($widget-link-options);
  border-top: $global-border-dotted;
  border-bottom: $global-border-dotted;
  flex: none;
  width: 100%;

  @include breakpoint(xlarge) {
    @include u-baseline(1.5, padding-top);
    @include u-baseline(1.5, padding-bottom);
  }
}

// Selectabs
//
// Tabs to select product variant. Tabs has a fixed width.
//
// Markup: selectabs.hbs
//
// Type: module
//
// Style guide: Controls.selectabs

$selectabs-color: var(--color-dark);
$selectabs-background: var(--color-light);
$selectabs-active-background: var(--color-pearl);
$selectabs-active-border-color: var(--color-orange);
$selectabs-active-border: 0 0 0 3px rgba(58, 91, 104, .3);
$selectabs-item-width: rem-calc(220);

/** **************
 * Basic styles
 */
.m-selectabs {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
  @include u-baseline(1, margin-top);
  @include u-baseline(1, margin-bottom);
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  width: 100%;

  > li {
    @include u-baseline(2, margin-bottom);
    @include u-single-cell($size: shrink);
    @include flex;
    flex-direction: column;
    max-width: $selectabs-item-width;
  }
}




/** **************
 * Inner container
 */
.m-selectabs__inner {
  display: flex;
  flex-direction: column;
  width: 100%;
}




/** **************
 * Item
 */
.m-selectabs__item {
  @include u-baseline(4, min-height);
  background-color: $selectabs-background;
  border-radius: rem-calc($global-border-radius);
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid var(--color-slate);
  color: $selectabs-color;
  cursor: pointer;
  display: flex;
  height: 100%;
  line-height: 1.23;
  min-width: rem-calc(46);
  overflow: hidden;
  padding: rem-calc(12) rem-calc(14);
  position: relative;
  width: 100%;

  &:focus {
    outline: auto;
  }

  &:hover,
  &.m-selectabs--active {
    &:not(.m-selectabs--disabled) {
      background-color: $selectabs-active-background;
      box-shadow: var(--shadow-medium);
    }
  }

  &.m-selectabs--active {
    &:not(.m-selectabs--disabled) {
      box-shadow: $selectabs-active-border, var(--shadow-heavy);
      transform: translateY(-2px);
    }
  }
}




/** ***********
 * Modifier: Tab is color
 */
.m-selectabs--color-only {
  @include u-baseline(5, width);
  @include u-baseline(5, height);
  border-radius: 50%;
  line-height: 1;
  min-height: 0;
  min-width: 0;
  padding: 0;
}





/** ***********
 * Modifier: Tab is disabled
 */
.m-selectabs--disabled {
  opacity: .35;
}








/** **************
 * Title
 */
.m-selectabs__title {
  flex: 1 0 auto;

  + div {
    display: block;
  }
}




.m-selectabs__img {
  @include u-baseline(6, width);
  flex: 1 0 auto;


  + .m-selectabs__inner {
    @include u-baseline(2, margin-left);
  }
}






.m-selectabs__extra {
  @include u-baseline(1, margin-top);
}



// To be deprecated
// .m-selectabs__label {
//   @include font-small;
//   @include u-baseline(1, margin-top);
// }




/** **************
 * Prices
 */
.m-selectabs__price {
  display: inline-block;
  margin-right: rem-calc(2);
  white-space: nowrap;
}

.m-selectabs__oldprice {
  text-decoration: line-through;
  white-space: nowrap;
}

// Content card
//
// Card with content and optional edit links
//
// `@require` **01_components/card**
//
// Markup: contentcard.hbs
//
// Type: module
//
// Style guide: Containers.contentcard
//

$contentcard-edit-link-width: rem-calc(75);

.m-contentcard {
  @include c-card;
  width: 100%;

  .hr {
    @include u-baseline(2, margin-bottom);
  }

  a:not([class]) {
    @include u-link($global-link-options-underline);
  }
}





/** **************
 * Header
 */
.m-contentcard__header {
  @include clearfix;
  text-align: right;

  a {
    float: right;
    width: $contentcard-edit-link-width;
  }
}






/** **************
 * Heading
 */
.m-contentcard__heading {
  @include font-h4;
  @include u-baseline(2, margin-bottom);
  float: left;
  font-weight: bold;
  margin-top: 0;
  text-align: left;
  width: calc(100% - #{$contentcard-edit-link-width});
}






/** **************
 * Entry content
 */
.m-contentcard__entry {
  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;

      .c-button {
        @include u-baseline(2, margin-top);
      }
    }
  }
}





/** **************
 * List
 */
.m-contentcard__list {
  @include u-baseline(2, padding-left);

  li {
    @include u-baseline(1, margin-bottom);
  }
}

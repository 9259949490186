// Pagination
//
// Use the oagination with max. 5 items.
//
// Markup: pagination.hbs
//
// Type: module
//
// Style guide: Controls.Pagination

$pagination-background: var(--color-pearl);
$pagination-background-active: var(--color-petrol);
$pagination-color: var(--color-dark);
$pagination-color-active: var(--color-light);
$pagination-height: rem-calc($global-controls-height);
$pagination-width: rem-calc(48);
$pagination-border: 1px solid var(--color-slush);

/** **************
 * Basic styles
 */
.m-pagination {
  @include u-baseline(3, margin-bottom);
  @include clearfix;
  text-align: center;

  @include breakpoint(large) {
    text-align: right;
  }
}




/** **************
 * Links
 */
.m-pagination__links {
  @include u-list-reset;
  display: inline-block;
  font-size: 0;

  li {
    display: inline-block;

    &:first-of-type a {
      border-top-left-radius: rem-calc($global-border-radius);
      border-bottom-left-radius: rem-calc($global-border-radius);
    }

    &:last-of-type a {
      border-top-right-radius: rem-calc($global-border-radius);
      border-bottom-right-radius: rem-calc($global-border-radius);
    }
  }
}




/** **************
 * Item
 */
.m-pagination__item {
  @include font-small; // override
  background-color: $pagination-background;
  border: $pagination-border;
  color: $pagination-color;
  display: inline-block;
  line-height: $pagination-height;
  margin-right: -(rem-calc(1));
  text-align: center;
  height: $pagination-height;
  width: $pagination-width;

  svg {
    width: .75em;
    height: .75em;
  }

  &:hover,
  &.m-pagination--active {
    background: $pagination-background-active;
    color: $pagination-color-active;
  }
}




/** **************
 * Rotate prev arrow
 */
.m-pagination--prev {
  svg {
    transform: rotate(180deg);
  }
}

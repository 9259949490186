// Category intro text and pills

/** **************
 * Intro text
 */
.o-category-intro {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;

  .o-category__column {
    @include grid-column;

    @include breakpoint(large) {
      &:first-child {
        width: 50%;
      }

      &:last-child {
        width: 50%;
      }
    }

    @include breakpoint(xlarge) {
      &:first-child {
        width: 40%;
      }

      &:last-child {
        width: 60%;
      }
    }
  }
}

/** **************
 * Category tile items with 5 items each row
 */
.o-category-items {
  @include u-list-reset;
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;

  .o-category__column {
    @include xy-cell(6);
    @include u-baseline(3, margin-bottom);
    position: relative;
    display: flex;

    @include breakpoint(medium only) {
      @include xy-cell(4);
    }

    @include breakpoint(large only) {
      @include xy-cell(3);
    }
  }

  @include breakpoint(xlarge) {
    @include xy-grid-layout(5, $selector: '.o-category__column');
  }

  .m-teaser {
    margin-bottom: 0;
  }
}

// Foundation Reveal Overrides
//
// @see https://get.foundation/sites/docs/reveal.html
// @source /node_modules/foundation-sites/scss/components/_reveal.scss

.reveal {
  &.tiny {
    @include breakpoint(medium) {
      min-width: rem-calc(320);
      max-width: rem-calc(420);
    }
  }

  &.large {
    @include breakpoint(medium) {
      max-width: rem-calc(1280);
    }
  }
}

@import 'colors';
@import 'fonts';


// _foundation-settings.scss
$global-body-background: var(--color-light);
$global-body-color: var(--color-dark);




// Fonts
$global-font-family: font(base, 'body');
$global-font-heading: font(base, 'display');



// Colors
$global-primary-color: var(--color-orange);
$global-link-color: var(--color-dark);


// Grid
$global-grid-gutter: .75rem;
$global-max-width: 1470px;
$global-gutter: 24px;
$global-xlarge-gutter: 40px;
$global-content-col-medium: 8;
$global-content-col-large: 6;




// Baseline
//
// DO NOT CHANGE THIS UNLESS YOU
// KNOW WHAT YOU'RE DOING
$global-baseline: 8;




// Transitions
// $global-easeInOutExpo: cubic-bezier(1, 0, 0, 1);
// $global-easing: ease-in-out;
// $global-easing-duration: .05s;
// $global-transition: all $global-easing-duration $global-easing;
// $global-transition-smooth: all .25s $global-easing;
// $global-svg-transform-transition: transform $global-easing-duration $global-easing;



// Shadows
// $global-shadow-color: rgba(0, 0, 0, .25);
$global-flyout-shadow-color-1: rgba(0, 0, 0, .1);
$global-flyout-shadow-color-2: rgba(0, 0, 0, .025);
// $global-img-shadow-color: rgba(0, 0, 0, .1);
// $global-shadow: 0 0 4px $global-shadow-color;
// $global-flyout-shadow: 0 2px 9px $global-flyout-shadow-color-1, 0 3px 3px $global-flyout-shadow-color-2;
// $global-img-shadow: 0 0 16px $global-img-shadow-color;
// $global-input-shadow: inset 0 1px 3px rgba(0, 0, 0, .39), 0 -1px 1px var(--color-light), 0 1px 0 var(--color-light);

// Focus styles
// $global-focus-color: var(--color-orange);
$global-focus-color: var(--color-slate-petrol);



// Colors
$global-background-internal: var(--color-ice);




// Border
$global-border-radius: 3;
$global-border-dotted: 1px dotted var(--color-slush-slate);
$global-border-gray: 1px solid var(--color-dark);
$global-border-light: 1px solid var(--color-slush);
$global-header-height-small: 55px;
$global-header-border-color: rgba(255, 255, 255, .25);




// Input and button size
$global-controls-height: ($global-baseline * 6);
$global-controls-height-large: ($global-baseline * 7);
$global-controls-background: var(--color-light);




// Navigation
$global-navigation-breakpoint: xlarge;
$global-navigation-breakpoint-down: large down;
$global-navigation-mobile-width: 17rem;




// Logo
$global-logo-width-small: 109px;
$global-logo-height-small: 26px;
$global-logo-width-large: 174px;
$global-logo-height-large: 46px;




// Links
$global-link-options-default: (
  color: var(--color-petrol),
  hover: var(--color-dark)
);

$global-link-options-dark: (
  color: var(--color-dark),
  hover: var(--color-petrol)
);

$global-link-options-dark-underline: (
  color: var(--color-dark),
  hover: var(--color-dark),
  decoration_hover: underline
);

$global-link-options-iron: (
  color: var(--color-iron),
  hover: var(--color-dark),
  decoration_hover: underline
);

$global-link-options-light: (
  color: var(--color-light),
  hover: var(--color-orange)
);

$global-link-options-orange: (
  color: var(--color-orange),
  hover: var(--color-dark)
);

$global-link-options-orange-underline: (
  color: var(--color-orange),
  hover: var(--color-orange),
  decoration: underline,
  decoration_hover: none
);

$global-link-options-petrol: (
  color: var(--color-petrol),
  hover: var(--color-dark),
  decoration_hover: underline
);

$global-link-options-slate: (
  color: var(--color-slate),
  hover: var(--color-light)
);

$global-link-options-slate-petrol: (
  color: var(--color-slate-petrol),
  hover: var(--color-light)
);

$global-link-options-warning: (
  color: var(--color-warning),
  hover: var(--color-dark)
);

$global-link-options-underline: (
  color: var(--color-dark),
  hover: var(--color-petrol),
  decoration: underline,
  decoration_hover: none
);

$global-link-options-grey-underline: (
  color: var(--color-iron),
  hover: var(--color-dark),
  decoration: underline,
  decoration_hover: none
);

$global-link-options-underline-light: (
  color: var(--color-light),
  hover: var(--color-light),
  decoration: underline,
  decoration_hover: none
);

$global-link-options-underline-inherit: (
  color: currentColor,
  hover: currentColor,
  decoration: underline,
  decoration_hover: none
);







// Payment icons
$global-payment-icon-width-small: 44;
$global-payment-icon-height-small: 32;
$global-payment-icon-width: 66;
$global-payment-icon-height: 40;



// Icon paths

// SVG by file
$global-svg-path: '../svg';
$global-icon-checkmark: url(#{$global-svg-path}/svg_icon-checkmark.svg);
$global-icon-close: url(#{$global-svg-path}/svg_icon-close.svg);
$global-icon-arrow: url(#{$global-svg-path}/svg_icon-arrow.svg);
$global-icon-star: url(#{$global-svg-path}/svg_icon-star-outline.svg);
$global-icon-star-full: url(#{$global-svg-path}/svg_icon-star.svg);
$global-sprite-attributes: url('../gfx/product-attributes.png');
$global-image-backlight: url('../gfx/backlight.png');
$global-icon-sun: url(#{$global-svg-path}/svg_logo-sun.svg);

// Energy label SVG
$global-icon-energy-a: url(#{$global-svg-path}/svg_icon-energy-a.svg);
$global-icon-energy-b: url(#{$global-svg-path}/svg_icon-energy-b.svg);
$global-icon-energy-c: url(#{$global-svg-path}/svg_icon-energy-c.svg);
$global-icon-energy-d: url(#{$global-svg-path}/svg_icon-energy-d.svg);
$global-icon-energy-e: url(#{$global-svg-path}/svg_icon-energy-e.svg);
$global-icon-energy-f: url(#{$global-svg-path}/svg_icon-energy-f.svg);
$global-icon-energy-g: url(#{$global-svg-path}/svg_icon-energy-g.svg);


// SVG by inline

// svg_icon-quote.svg
$global-icon-blockquote: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path fill="' + palette(brand, 'slush-ice') + '" d="M39 8.29a23.91 23.91 0 0 0-7.64 5.5 13.11 13.11 0 0 0-3.19 7.61h2.42a13.17 13.17 0 0 1 4.36.6 6.94 6.94 0 0 1 2.72 1.68A5.94 5.94 0 0 1 39 26a8.19 8.19 0 0 1 .41 2.58 6.38 6.38 0 0 1-2.22 4.81A7.43 7.43 0 0 1 32 35.41a8.83 8.83 0 0 1-7.28-3.14q-2.54-3.14-2.54-8.68 0-5.16 4.37-10.4a28.48 28.48 0 0 1 10.51-7.95zm-21.18 0a23.91 23.91 0 0 0-7.64 5.5 13.11 13.11 0 0 0-3.23 7.61h2.41a13.17 13.17 0 0 1 4.36.6 6.94 6.94 0 0 1 2.72 1.68 5.94 5.94 0 0 1 1.4 2.32 8.19 8.19 0 0 1 .41 2.58A6.38 6.38 0 0 1 16 33.34a7.43 7.43 0 0 1-5.23 2.06 8.83 8.83 0 0 1-7.28-3.14Q1 29.13 1 23.59q0-5.16 4.37-10.4a28.48 28.48 0 0 1 10.51-7.95z"/></svg>');

// svg_icon-checkbox.svg
$global-icon-checkbox: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8"><clipPath id="checkbox-clip"><path class="nofill" d="M2.47 6.53a.75.75 0 0 0 1.06 0l4-4a.75.75 0 1 0-1.06-1.06L3 4.94 1.53 3.47A.75.75 0 0 0 .47 4.53z"/></clipPath><g clip-path="url(#checkbox-clip)"><path fill="' + palette(brand, 'petrol') + '" d="M-4.75-3.75h17.5v15.5h-17.5z"/></g></svg>');

// svg_icon-arrow.svg
$global-icon-arrow-orange: inline-svg('<svg width="5" height="8" viewBox="0 0 5 8" xmlns="http://www.w3.org/2000/svg"><path fill="' + palette(brand, 'orange') + '" d="M4.316 3.821a.23.23 0 0 1-.072.167l-3.38 3.38a.23.23 0 0 1-.334 0l-.363-.362a.23.23 0 0 1 0-.334l2.85-2.85L.168.97a.23.23 0 0 1 0-.333L.53.274a.23.23 0 0 1 .333 0l3.38 3.38a.23.23 0 0 1 .073.167z"/></svg>');
$global-icon-arrow-white: inline-svg('<svg width="5" height="8" viewBox="0 0 5 8" xmlns="http://www.w3.org/2000/svg"><path fill="' + palette(ui, 'light') + '" d="M4.316 3.821a.23.23 0 0 1-.072.167l-3.38 3.38a.23.23 0 0 1-.334 0l-.363-.362a.23.23 0 0 1 0-.334l2.85-2.85L.168.97a.23.23 0 0 1 0-.333L.53.274a.23.23 0 0 1 .333 0l3.38 3.38a.23.23 0 0 1 .073.167z"/></svg>');
$global-icon-arrow-dark: inline-svg('<svg width="5" height="8" viewBox="0 0 5 8" xmlns="http://www.w3.org/2000/svg"><path fill="' + palette(ui, 'dark') + '" d="M4.316 3.821a.23.23 0 0 1-.072.167l-3.38 3.38a.23.23 0 0 1-.334 0l-.363-.362a.23.23 0 0 1 0-.334l2.85-2.85L.168.97a.23.23 0 0 1 0-.333L.53.274a.23.23 0 0 1 .333 0l3.38 3.38a.23.23 0 0 1 .073.167z"/></svg>');

// svg_icon-sort.svg
$global-icon-arrow-sort: inline-svg('<svg height="8" viewBox="0 0 13 8" width="13" xmlns="http://www.w3.org/2000/svg"><path d="M6.552 7.167a.381.381 0 0 1-.277-.12L.67 1.443a.381.381 0 0 1 0-.554l.6-.602a.381.381 0 0 1 .554 0l4.727 4.727L11.28.288a.381.381 0 0 1 .552 0l.602.6a.381.381 0 0 1 0 .552L6.829 7.046a.381.381 0 0 1-.277.121z" fill="' + palette(ui, 'light') + '"/></svg>');

// svg_icon-sort-double.svg
$global-icon-arrow-sort-double: inline-svg('<svg height="16" viewBox="0 0 11 16" width="11" xmlns="http://www.w3.org/2000/svg"><g fill="' + palette(ui, 'light') + '"><path d="M5.195 15.953a.327.327 0 0 1-.238-.102L.153 11.046a.327.327 0 0 1 0-.475l.514-.516a.327.327 0 0 1 .475 0l4.051 4.051 4.055-4.05a.327.327 0 0 1 .473 0l.516.515a.327.327 0 0 1 0 .473L5.432 15.85a.327.327 0 0 1-.237.104zM5.195.622c.09 0 .175.037.237.102l4.805 4.805a.327.327 0 0 1 0 .475l-.515.516a.327.327 0 0 1-.475 0L5.196 2.469l-4.054 4.05a.327.327 0 0 1-.473 0l-.516-.515a.327.327 0 0 1 0-.473L4.957.726a.327.327 0 0 1 .238-.104z"/></g></svg>');

// svg_icon-plus.svg
$global-icon-plus-white: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.4 7.3"><path fill="' + palette(ui, 'light') + '" d="M4.4.2V3h2.7v1.3H4.4v2.9H3V4.3H.3V3H3V.2z"/></svg>');

// svg_icon-star.svg
$global-icon-star-white: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.86 14.17"><path fill="' + palette(ui, 'light') + '" d="M14.36 5.08l-4.5-.65-2-4.06A.51.51 0 0 0 7.42 0a.51.51 0 0 0-.44.37l-2 4.06-4.48.65a.46.46 0 0 0-.5.41.68.68 0 0 0 .22.43l3.25 3.16-.76 4.47a1.43 1.43 0 0 0 0 .18.53.53 0 0 0 .09.32.32.32 0 0 0 .28.13.76.76 0 0 0 .36-.11l4-2.11 4 2.11a.72.72 0 0 0 .36.11.32.32 0 0 0 .27-.13.53.53 0 0 0 .09-.31 1.37 1.37 0 0 0 0-.18l-.77-4.46 3.24-3.16a.65.65 0 0 0 .23-.45.46.46 0 0 0-.5-.41z"/></svg>');

// Colors
//
// Chromeless: true
//
// Weight: 0
//
// Style guide: General.Colors




// Brand
//
// Markup: pattern-library-colors-brand.hbs
//
// Weight: 1
//
// Chromeless: true
//
// Style guide: General.Colors.brand




// UI
//
// Markup: pattern-library-colors-ui.hbs
//
// Weight: 2
//
// Chromeless: true
//
// Style guide: General.Colors.ui






// Classes
//
// You can use the colors in the HTML markup by using following classes:
//
// | Color        | Markup                                                                          | CSS variable                 |
// |--------------|---------------------------------------------------------------------------------|------------------------------------|
// | orange       | `<div class="color--orange"/>` `<div class="background--orange"/>`                | `--color-orange`         |
// | orange-light | `<div class="color--orange-light"/>` `<div class="background--orange-light"/>`    | `--color-orange-light`         |
// | petrol       | `<div class="color--petrol"/>` `<div class="background--petrol"/>`                | `--color-petrol`         |
// | slate-petrol | `<div class="color--slate-petrol"/>` `<div class="background--slate-petrol"/>`    | `--color-slate-petrol`   |
// | slate        | `<div class="color--slate"/>` `<div class="background--slate"/>`                  | `--color-slate`          |
// | slush-slate  | `<div class="color--slush-slate"/>` `<div class="background--slush-slate"/>`      | `--color-slush-slate`    |
// | slush        | `<div class="color--slush"/>` `<div class="background--slush"/>`                  | `--color-slush`          |
// | slush-ice    | `<div class="color--slush-ice"/>` `<div class="background--slush-ice"/>`          | `--color-slush-ice`      |
// | geyser       | `<div class="color--geyser"/>` `<div class="background--geyser"/>`                | `--color-geyser`          |
// | ice          | `<div class="color--ice"/>` `<div class="background--ice"/>`                      | `--color-ice`            |
// ||||
// | dark         | `<div class="color--dark"/>` `<div class="background--dark"/>`                          | `--color-dark`              |
// | cod         | `<div class="color--cod"/>` `<div class="background--cod"/>`                          | `--color-cod`              |
// | heavy         | `<div class="color--heavy"/>` `<div class="background--heavy"/>`                          | `--color-heavy`              |
// | iron         | `<div class="color--iron"/>` `<div class="background--iron"/>`                          | `--color-iron`              |
// | dusty        | `<div class="color--dusty"/>` `<div class="background--dusty"/>`                        | `--color-dusty`             |
// | dark         | `<div class="color--dark"/>` `<div class="background--dark"/>`                          | `--color-dark`              |
// | concrete        | `<div class="color--concrete"/>` `<div class="background--concrete"/>`               | `--color-concrete`             |
// | pearl        | `<div class="color--pearl"/>` `<div class="background--pearl"/>`               | `--color-pearl`             |
// | alabaster        | `<div class="color--alabaster"/>` `<div class="background--alabaster"/>`               | `--color-alabaster`             |
// | light        | `<div class="color--light"/>` `<div class="background--light"/>`                        | `--color-light`             |
// | warning      | `<div class="color--warning"/>` `<div class="background--warning"/>`                    | `--color-warning`           |
// | warning-light      | `<div class="color--warning-light"/>` `<div class="background--warning-light"/>`  | `--color-warning-light`           |
// | success      | `<div class="color--success"/>` `<div class="background--success"/>`                    | `--color-success`           |
// | success-medium| `<div class="color--success-medium"/>` `<div class="background--success-medium"/>`     | `--color-success-medium`     |
// | success-light| `<div class="color--success-light"/>` `<div class="background--success-light"/>`        | `--color-success-light`     |
// | info         | `<div class="color--info"/>` `<div class="background--info"/>`                          | `--color-info`              |
// | rating         | `<div class="color--rating"/>` `<div class="background--rating"/>`                    | `--color-rating`              |
//
// Weight: 4
//
// Chromeless: true
//
// Style guide: General.Colors.classes

$color-orange:          #e6935c;
$color-orange-light:    #faebe0;
$color-petrol:          #3b5c68;
$color-slate-petrol:    #6e7881;
$color-slate:           #9ca2a5;
$color-slush-slate:     #b2b8b6;
$color-slush:           #c8d0d0;
$color-slush-ice:       #dadddb;
$color-geyser:          #d7e2e5;
$color-ice:             #edf1f2;
$color-dark:            #000;
$color-cod:             #171717;
$color-heavy:           #2f322f;
$color-iron:            #5c5c5c;
$color-dusty:           #969696;
$color-concrete:        #f2f2f2;
$color-pearl:           #f7f7f7;
$color-alabaster:       #fafafa;
$color-light:           #fff;
$color-warning:         #cf021a;
$color-warning-light:   #f8e3e3;
$color-success:         #1fbd4e;
$color-success-medium:  #bee62d;
$color-success-light:   #dcf4e2;
$color-info:            #f19e0e;
$color-rating:          #f2b62c;
$color-energy:          #2e86de;


/// @example
///   .element {
///     color: palette(ui, 'brand');
///
$palettes: (
  brand: (
    'orange':             $color-orange,
    'orange-light':       $color-orange-light,
    'petrol':             $color-petrol,
    'slate-petrol':       $color-slate-petrol,
    'slate':              $color-slate,
    'slush-slate':        $color-slush-slate,
    'slush':              $color-slush,
    'slush-ice':          $color-slush-ice,
    'ice':                $color-ice,
    'geyser':             $color-geyser
  ),
  ui: (
    'dark':               $color-dark,
    'cod':                $color-cod,
    'heavy':              $color-heavy,
    'iron':               $color-iron,
    'dusty':              $color-dusty,
    'concrete':           $color-concrete,
    'pearl':              $color-pearl,
    'alabaster':          $color-alabaster,
    'light':              $color-light,
    'warning':            $color-warning,
    'warning-light':      $color-warning-light,
    'success':            $color-success,
    'success-medium':     $color-success-medium,
    'success-light':      $color-success-light,
    'info':               $color-info,
    'rating':             $color-rating,
    'energy':             $color-energy
  )
);


/// Function to parse color palettes
@function palette($palette, $tone: 'brand') {
  @return map-get(map-get($palettes, $palette), $tone);
}

/// Generating color and background Classes
/// from color map
$color-theme-list: (
  orange: brand,
  orange-light: brand,
  petrol: brand,
  slate-petrol: brand,
  slate: brand,
  slush-slate: brand,
  slush: brand,
  slush-ice: brand,
  geyser: brand,
  ice: brand,
  dark: ui,
  cod: ui,
  heavy: ui,
  iron: ui,
  dusty: ui,
  pearl: ui,
  alabaster: ui,
  concrete: ui,
  light: ui,
  warning: ui,
  warning-light: ui,
  success: ui,
  success-medium: ui,
  success-light: ui,
  info: ui,
  rating: ui,
  energy: ui
) !default;


/// Classes to use in templates
@mixin color-theme($color, $section) {
  .color--#{'' + $color} {
    color: var(--color-#{$color});
  }

  .background--#{'' + $color} {
    background-color: var(--color-#{$color});
  }
}

@each $key, $value in $color-theme-list {
  @include color-theme($key, $value);
}


/// Create CSS custom properties
/// from color palette
@mixin color-properties($color, $section) {
  --color-#{'' + $color}: #{palette($section, #{$color})};
}

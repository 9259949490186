// Testimonial
//
// Testimonial with image and quote.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x30      |
// | large         | 460x460    |
//
// Full: true
//
// .m-testimonial--nested - Nested containers inside content sections.
//
// Markup: testimonial.hbs
//
// Type: module
//
// Style guide: Containers.Testimonial

$testimonial-breakpoint: large;
$testimonial-background-color: var(--color-ice);
$testimonial-blockquote-color: var(--color-slush-ice);

/** **************
 * Basic styles
 */
.m-testimonial {
  @include u-baseline(6, padding-top);
  @include u-baseline(6, padding-bottom);
  background-color: $testimonial-background-color;

  [class*='c-arrowlink'] {
    width: 100%;

    > span {
      white-space: normal;
    }
  }
}




/** **************
 * Modifier: Element has bottom margin
 */
.m-testimonial--has-margin {
  @include u-module-margin;
}



/** ***********
 * Modifier: Container is nested inside content area
 */
.m-testimonial--nested {
  @include u-baseline(5, margin-bottom);

  .m-testimonial__entry {
    @include breakpoint($testimonial-breakpoint) {
      padding-left: 0;
    }
  }

  .m-testimonial__quote {
    @include font-h3;
  }

  .m-testimonial__link {
    @include u-baseline(7, padding-left);

    @include breakpoint($testimonial-breakpoint) {
      @include u-baseline(9, padding-left);
    }
  }
}





/** **************
 * Inner container
 */
.m-testimonial__inner {
  @include xy-grid-container;
  @include xy-grid;
  @include flex-align(left, middle);
}




/** **************
 * Image
 */
.m-testimonial__img {
  @include xy-cell(10);
  @include grid-column-position(center);
  @include u-module-margin(small);
  max-width: rem-baseline(35);
  text-align: center;

  @include breakpoint($testimonial-breakpoint) {
    @include xy-cell(4);
    margin-bottom: 0;
    max-width: none;
    order: 2;
  }

  img {
    @include u-img-responsive;
    overflow: hidden;
  }

  .c-image {
    outline: rem-baseline(1) solid var(--color-light);
    border-radius: 50%;
    overflow: hidden;
  }
}




/** **************
 * Content entry
 */
.m-testimonial__entry {
  @include xy-cell;
  @include grid-column-position(center);

  @include breakpoint($testimonial-breakpoint) {
    @include xy-cell(8);
    @include u-baseline(6, padding-left);
    order: 1;
  }
}




/** **************
 * Quote
 */
.m-testimonial__quote {
  @include font-h2;
  @include font-bold-important;
  hyphens: auto;
  max-width: 47ch;

  @include breakpoint($testimonial-breakpoint) {
    @include u-baseline(2, padding-right);
    text-align: left;
  }

  p {
    @include u-baseline(5, margin-bottom);
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}




/** **************
 * Link
 */
.m-testimonial__link {
  @include u-baseline(2, margin-top);
  @include u-baseline(9, padding-left);

  @include breakpoint($testimonial-breakpoint) {
    @include u-baseline(11, padding-left);
  }
}

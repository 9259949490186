// Reveal (Modal)
//
// Modal window using the Foundation [reveal](http://foundation.zurb.com/sites/docs/reveal.html) module.
//
// Type: module
//
// JavaScript: true
//
// Style guide: Containers.reveal

$reveal-background: var(--color-light);
$reveal-header-background: var(--color-light);

.m-reveal {
  &:focus {
    outline: none;
  }
}




// Reveal header
//
// Modal header.
//
// Markup: reveal__header.hbs
//
// Weight: 1
//
// Type: module
//
// Style guide: Containers.reveal.header
.m-reveal__header {
  @include font-h3;
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);
  @include u-baseline(3, padding-left);
  @include u-baseline(7, padding-right);
  position: relative;

  // white background unless .background--* is set.
  &:not([class*='background--']) {
    background-color: $reveal-header-background;
  }

  .c-icon {
    @include u-icon-relative-size;
    @include u-baseline(1, margin-right);
    position: absolute;

    // change style of text when SVG exists.
    + span {
      display: block;
      padding-left: 2em;
    }
  }
}






/** ***********
 * Content
 */
.m-reveal__content {
  @include font-meta;
  @include u-content-formatting($size: 'meta');
  padding: rem-baseline(3) !important;

  // Knockout.js getTemplate()
  // override in address modal
  [data-role='checkout-address-form'] {
    .c-card {
      padding: 0;
    }
  }
}




/** **************
 * Close icon
 */
.m-reveal__close {
  @include u-baseline(2, top);
  @include u-baseline(2, right);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  z-index: 1;

  .c-icon {
    width: 2em;
    height: 2em;
  }
}









/** ***********
 * Modifier: Large reveal with larger padding
 */
.m-reveal--is-large {
  &.m-reveal__content {
    padding-top: rem-baseline(4) !important;

    @include breakpoint(large) {
      padding: rem-baseline(4) !important;
    }
  }
}

// Reveal wishlist wrapper
//

.o-reveal-wishlist {
  @include xy-grid-container;
  @include xy-grid;
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);

  .m-tinyproduct__img {
    @include breakpoint(large) {
      @include u-baseline(2, margin-bottom);
      float: none;
      width: 100%;
    }
  }

  .m-tinyproduct__info {
    @include breakpoint(large) {
      padding-left: 0;
    }
  }
}




/** ***********
 * Image
 */
.o-reveal-wishlist__img {
  @include xy-cell;
  @include u-baseline(2, margin-bottom);

  @include breakpoint(large) {
    @include xy-cell(4, $gutters: $global-baseline);
    @include u-baseline(2, padding-right);
    border-right: 1px solid var(--color-slush);
    margin-bottom: 0;
  }
}





/** ***********
 * Content
 */
.o-reveal-wishlist__content {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell(8, $gutters: $global-baseline);
    @include u-baseline(1, padding-left);
    min-height: rem-calc(200);
    max-height: rem-calc(600);
    overflow-y: auto;
  }
}






/** ***********
 * Inner container
 */
.o-reveal-wishlist__inner {
  height: 100%;
}

// Slapper
//
// List item that looks like a ruler that slaps.
//
// Markup: slapper.hbs
//
// Type: module
//
// Style guide: Controls.slapper

.m-slapper {
  @include c-card;
  @include u-baseline(1, margin-bottom);
  @include u-baseline(8, min-height);
  @include u-baseline(6, padding-right);
  box-shadow: var(--shadow-light);
  display: block;
  position: relative;
  text-decoration: none;

  @include breakpoint(xlarge) {
    @include u-baseline(8, padding-right);
  }

  // Arrow icon
  &:after {
    @include u-arrow-simple;
    @include u-baseline(2, right);
    opacity: .35;
    position: absolute;
    top: calc(50% - #{rem-baseline(1)});

    @include breakpoint(xlarge) {
      @include u-baseline(3, right);
    }
  }

  &:hover {
    box-shadow: var(--shadow-medium);

    &:after {
      opacity: 1;
    }

    .m-slapper__title {
      text-decoration: underline;
    }
  }
}





/** ***********
 * Title
 */
.m-slapper__title {
  @include font-meta;
  @include u-baseline(1, margin-right);
  color: var(--color-dark);
  display: inline-block;
}






/** ***********
 * Body
 */
.m-slapper__body {
  @include font-meta;
  color: var(--color-dusty);
}

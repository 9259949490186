// Delivery options
//
// Radio buttons with prices to display in cart.
//
// Markup: delivery.hbs
//
// .m-delivery--is-nested - Nested item without horizontal padding.
//
// Type: module
//
// Style guide: Containers.delivery

$delivery-price-width: rem-calc(70);

.m-delivery {
  @include c-card;
  @include u-baseline(1, margin-top);

  @include breakpoint(large) {
    margin-top: 0;
  }

  [class*='heading'] {
    margin-top: 0;
  }

  label {
    &:not(:last-of-type) {
      @include u-baseline(2, margin-bottom);
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .c-radio__value {
    padding-top: rem-calc(3);
    display: -webkit-box;
    align-items: stretch;
  }
}






/** ***********
 * Modifier: Container is nested
 */
.m-delivery--is-nested {
  padding-left: 0;
  padding-right: 0;
}







/** **************
 * Text
 */
.m-delivery__text {
  display: block;
  flex: 1 0 auto;
  -webkit-box-flex: 1; // FIXME: Why is autoprefixer not working???
}



/** **************
 * Price
 */
.m-delivery__price {
  display: block;
  flex: 0 0 $delivery-price-width;
  text-align: right;
  width: $delivery-price-width;
  -webkit-box-flex: 0; // FIXME: Why is autoprefixer not working???
}

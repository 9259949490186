// Ratings
//
// Ratings summray with optional anchor link
//
// Markup: ratings.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Controls.ratings

.m-ratings {
  @include u-baseline(3, margin-bottom);
  @include u-baseline(1, margin-left);
  display: inline-block;
  vertical-align: middle;

  a {
    @include u-baseline(1, margin-left);
    @include u-link($global-link-options-underline);
    display: inline-block;
  }
}

// Content Block
//
// Container to wrap simple content in large columns and force a max-width
// to avoid large text lines.
//
// Type: organism
//
// Markup: contentblock.hbs
//
// Style guide: Containers.contentblock

.o-contentblock {
  @include u-module-margin;
  @include u-content-formatting;
  max-width: rem-calc(640);

  h2,
  h3,
  h4 {
    &:first-child {
      padding-top: 0;
    }
  }

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;
  }
}








/** ***********
 * Modifier: Large bottom margin
 */
.o-contentblock--wide {
  @include u-baseline(10, margin-bottom);
}

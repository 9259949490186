// Add link
//
// Link with plus icon
//
// Markup: addlink.hbs
//
// Type: component
//
// Style guide: Controls.links.addlink

$addlink-icon-top: calc(50% - .7em);
$addlink-icon-size: 1.5em;

.c-addlink {
  @include font-small; // override
  @include u-link($global-link-options-dark);
  display: inline-block;
  padding-left: 2.5em;
  position: relative;
  line-height: rem-baseline(2);

  &:before {
    background-color: var(--color-orange);
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: $addlink-icon-top;
    width: $addlink-icon-size;
    height: $addlink-icon-size;
  }

  &:after {
    background-image: $global-icon-plus-white;
    background-position: 50% 50%;
    background-size: .75em .75em;
    background-repeat: no-repeat;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: $addlink-icon-top;
    width: $addlink-icon-size;
    height: $addlink-icon-size;
  }
}

// Link box
//
// Static link box with category and brands links.
//
// Markup: linkbox.hbs
//
// Type: module
//
// Style guide: Content.linkbox
$linkbox-breakpoint: large;

.m-linkbox {
  @include breakpoint($linkbox-breakpoint) {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
}







/** ***********
 * Section
 */
.m-linkbox__section {
  &:not(:last-child) {
    @include u-baseline(7, margin-bottom);

    @include breakpoint($linkbox-breakpoint) {
      margin-bottom: 0;
    }
  }
}







/** ***********
 * Section heading
 */
.m-linkbox__heading {
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(4, margin-bottom);
  margin-top: 0;
}






/** ***********
 * List
 */
.m-linkbox__list {
  @include u-list-reset;
  columns: 2 rem-calc(150);
}









/** ***********
 * List item
 */
.m-linkbox__item {
  @include font-small;
  @include u-baseline(3, margin-bottom);
}









/** ***********
 * Modifier: Single section (e.g. brands)
 */
.m-linkbox--single {
  @include breakpoint($linkbox-breakpoint) {
    flex: 0 1 33.3333%;
  }

  .m-linkbox__list {
    @include breakpoint($linkbox-breakpoint) {
      columns: 1;
    }
  }
}






/** ***********
 * Modifier: Single section (e.g. brands)
 */
.m-linkbox--double {
  @include breakpoint($linkbox-breakpoint) {
    flex: 1 1 auto;
  }
}






/** ***********
 * Modifier: Triple columns
 */
.m-linkbox--triple {
  @include breakpoint($linkbox-breakpoint) {
    flex: 1 1 auto;
  }

  .m-linkbox__list {
    @include breakpoint($linkbox-breakpoint) {
      columns: 3;
    }
  }
}

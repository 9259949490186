// Filter
//
// Filter item with flyout
//
// Markup: filter.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Controls.filters.filter

$filter-background-active: var(--color-light);
$filter-height: $form-input-height;
$filter-maxwidth: rem-calc(280);
$filter-active-width: rem-calc(280);

.m-filter {
  @include font-small;
  height: $form-input-height;
  line-height: 1.23;
  max-width: $filter-maxwidth;
  position: relative;
  width: 100%;

  &:hover,
  &:focus {
    .m-filter__content {
      box-shadow: var(--shadow-light);
    }
  }

  // foundation override
  [type='checkbox'] + label[for] {
    display: block;
    font-size: inherit;
    margin-right: 0;
    margin-left: 0;
  }
}




/** **************
 * Handler
 */
.m-filter__handler {
  @include u-ellipsis($filter-maxwidth);
  @include u-baseline(2, padding-left);
  @include u-baseline(6, padding-right);
  @include u-baseline(1.25, padding-top);
  @include u-baseline(1, padding-bottom);
  height: $filter-height;
  margin: 0 !important; // Magento override
  position: absolute;
  transition: none;
  width: var(--width, 100%);
  z-index: var(--zindex, 1);

  &:hover {
    + .m-filter__content {
      background-color: var(--color-concrete);
    }
  }
}




/** **************
 * Toggle
 */
.m-filter__toggle {
  @include element-invisible;

  &:focus:not(:checked) {
    ~ .m-filter__handler {
      @include u-focus-styles;
    }
  }

  &:checked {
    ~ .m-filter__handler {
      --zindex: 6;
      border-bottom: $form-border;
      width: $filter-active-width;
    }

    ~ .m-filter__content {
      --height: auto;
      --zindex: 5;
      background-color: $filter-background-active;
      box-shadow: var(--shadow-medium);
      width: $filter-active-width;

      &:after {
        --arrow-transform: rotate(-90deg);
      }

      .m-filter__entry {
        @include u-toggle-visibility('show');
      }
    }
  }
}




/** **************
 * Modifier: filter is positioned on right edge
 *           and opens to the left
 */
.m-filter--right {
  .m-filter__toggle:checked {
    ~ .m-filter__content,
    ~ .m-filter__handler {
      left: auto;
      right: 0;
    }
  }
}




/** **************
 * Content
 */
.m-filter__content {
  @include form-select;
  box-shadow: var(--shadow-light);
  height: var(--height, $filter-height);
  padding: 0 0 rem-baseline(3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--zindex, 0);
  transition: none;
}




/** **************
 * Content entry
 */
.m-filter__entry {
  @include u-toggle-visibility;
  padding-top: $form-input-height;
}




/** **************
 * Inner content wrapper
 */
.m-filter__inner {
  @include u-baseline(2, margin-bottom);
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  @include u-baseline(28, max-height);
  overflow: auto;
}




/** **************
 * Submit button
 */
.m-filter__submit {
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
}




/** **************
 * Container with more filters
 */
.m-filter__more {
  @include u-ellipsis($filter-maxwidth);
  @include form-select;
  @include u-baseline(1, padding-top);
  @include u-baseline(2, padding-left);
  @include u-baseline(6, padding-right);
  box-shadow: var(--shadow-light);
  margin: 0 !important; // Magento override

  @include breakpoint(xlarge) {
    @include u-baseline(1.2, padding-top);
  }
}

.m-filter__more-check {
  @include element-invisible;

  &:checked {
    ~ .o-filters__item--hidden {
      display: block;
    }

    ~ .o-filters__item--temp {
      display: none;
    }
  }
}

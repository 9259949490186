// Lead text
//
// Larger text for post and page intros.
//
// Markup: leadtext.hbs
//
// Type: component
//
// Style guide: Content.leadtext
.c-leadtext {
  @include font-lead;
  @include u-baseline(5, margin-bottom);

  p {
    margin: 0;

    &:not(:last-child) {
      @include u-baseline(3, margin-bottom);
    }
  }
}

// Line Spacer
//
// Headline spacer with line on the sides.
//
// Markup: linespacer.hbs
//
// Type: module
//
// Style guide: Content.linespacer

.c-linespacer {
  @include u-baseline(2, margin-bottom);
  color: var(--color-slate);
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
}








/** ***********
 * Text value
 */
.c-linespacer__text {
  @include u-baseline(3, padding-left);
  @include u-baseline(3, padding-right);
  display: inline-block;
  position: relative;

  &:before,
  &:after {
    background-color: var(--color-slush-ice);
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: calc(50% - 1px);
    width: rem-calc(800);
  }

  &:before {
    right: 100%;
  }

  &:after {
    left: 100%;
  }
}

$pin-size-small: rem-calc($global-baseline * 4);
$pin-size-large: rem-calc($global-baseline * 6);
$pin-size-secondary: rem-calc($global-baseline * 3);
$pin-border-size-small: rem-calc(3);
$pin-border-size-large: rem-calc(6);
$pin-border-color: var(--color-light);

$pin-flyout-width-small: rem-calc(240);
$pin-flyout-height-small: rem-baseline(8);
$pin-flyout-width-large: rem-calc(320);
$pin-flyout-height-large: rem-baseline(8);
$pin-flyout-background: var(--color-light);

$pin-color-light: var(--color-light);
$pin-color-active: var(--color-light);
$pin-background-default: rgba(229, 147, 91, .5);
$pin-background-pulse: rgba(229, 147, 91, .75);
$pin-background-active: var(--color-orange);
$pin-background-secondary: var(--color-slate);

$pin-shadow-color: rgba(0, 0, 0, .2);
$pin-burst-shadow-color-0: rgba(229, 147, 91, .7);
$pin-burst-shadow-color-20: rgba(229, 147, 91, 0);
$pin-burst-shadow-color-100: $pin-burst-shadow-color-20;

$pin-arrow-color: var(--color-light);
$pin-arrow-size: rem-calc(6);

// Pin animation: background and scale
@keyframes pin-pulse {
  0% {
    // background-color: $pin-background-default;
    transform: scale(1);
  }

  20% {
    // background-color: $pin-background-pulse;
    transform: scale(.85);
  }

  30%,
  100% {
    // background-color: $pin-background-default;
    transform: scale(1);
  }
}

// Pin animation: pulsating border and scale
@keyframes pin-burst {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 $pin-burst-shadow-color-0;
  }

  20% {
    transform: scale(.95);
    box-shadow: 0 0 0 16px $pin-burst-shadow-color-20;
  }

  30%,
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 $pin-burst-shadow-color-100;
  }
}

// Colors
$form-border-color: var(--color-slush);
// $form-select-background: var(--color-pearl);
// $form-select-background-light: var(--color-light);



// Borders
$form-border: 1px solid $form-border-color;
$form-border-radius: rem-calc(3);





// Sizes
$form-input-height: rem-calc($global-controls-height);




// Checkbox
$forms-checkbox-color-active: var(--color-petrol);
$forms-checkbox-size-small: rem-baseline(3);
$forms-checkbox-size: rem-baseline(4);





// Tabs
$formtab-background-active: var(--color-geyser);
$formtab-border-active: var(--color-petrol);
$formtab-border-color: var(--color-slush);
$formtab-checkmark-size: 1.2em;


$form-error-color: var(--color-warning);
$form-helptext-color: var(--color-slate-petrol);

// Icon title
//
// Heading with icon and border, and optional value.
//
// Markup: icontitle.hbs
//
// Type: module
//
// Style guide: Content.icontitle

.m-icontitle {
  @include u-baseline(1, padding-bottom);
  @include u-baseline(3, margin-bottom);
  border-bottom: $global-border-dotted;
  margin-top: 0;

  strong {
    display: block;
  }

  > span {
    @include u-baseline(1, margin-top);
    display: block;
  }

  .c-icon {
    @include u-baseline(2, margin-right);
    width: 1.25em;
    height: 1.25em;
  }
}

// Rating box
//
// Ratings and reviews, using misc. modules
//
// Overrides [.m-ratings](section-controls.html#kssref-controls-ratings) styles.
//
// Markup: ratingbox.hbs
//
// Type: organism
//
// JavaScript: true
//
// Style guide: Controls.ratings.ratingbox

// Breakpoints
$ratingbox-breakpoint: large;
$ratingbox-background: var(--color-pearl);
$ratingbox-reviews-tab-background: var(--color-slush-ice);
$ratingbox-more-label-offset: rem-calc(210);

.o-ratingbox {
  @include u-module-margin;
  background-color: $ratingbox-background;
}




/** **************
 * Modifier: Box has no ratings
 */
.o-ratingbox--no-ratings {
  .o-ratingbox__comments {
    @include u-baseline(2, margin-bottom);
    font-style: italic;
  }
}




/** **************
 * Inner container
 */
.o-ratingbox__inner {
  @include u-baseline(2, padding);

  @include breakpoint($ratingbox-breakpoint) {
    @include u-baseline(3, padding);
  }
}




/** **************
 * Header
 */
.o-ratingbox__header {
  @include u-baseline(2, margin-bottom);
  @include u-baseline(2, padding-bottom);
  border-bottom: $global-border-dotted;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .m-ratings {
    margin-bottom: 0;

    @include breakpoint($ratingbox-breakpoint) {
      text-align: right;
    }
  }
}




/** **************
 * Heading
 */
.o-ratingbox__heading {
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(2, padding-right);
  margin-top: 0;
  margin-bottom: 0;
}






/** ***********
 * Comments wrapper
 */
.o-ratingbox__comments {
  @include u-baseline(2, margin-bottom);
  border-bottom: $global-border-dotted;
}




/** **************
 * More: Checkbox
 */
.o-ratingbox-more__checkbox {
  @include element-invisible;

  &:checked {
    ~ .o-ratingbox-more__label {
      display: none;
    }

    ~ .o-ratingbox-more__entry {
      @include element-invisible-off;
    }
  }
}




/** **************
 * More: Label
 */
.o-ratingbox-more__label {
  @include u-link($global-link-options-orange);
  @include u-baseline(2, margin-bottom);
  @include u-baseline(2, padding-bottom);
  margin-left: 0 !important;

  @include breakpoint($ratingbox-breakpoint) {
    padding-left: $ratingbox-more-label-offset;
  }
}




/** **************
 * More: Content entry
 */
.o-ratingbox-more__entry {
  @include element-invisible;
  @include u-baseline(1, padding-top);
}




/** **************
 * Form
 */
.o-ratingbox-form {
  max-width: rem-calc(520);

  .c-rating {
    vertical-align: middle;
  }
}










/** ***********
 * Form: Body
 */
.o-ratingbox-form__body {
  @include u-baseline(2, margin-right);
  @include u-baseline(1, margin-bottom);
  display: inline-block;
}









/** **************
 * Form: Form
 */
.o-ratingbox-form__form {
  @include u-accordion-panel;
}




/** **************
 * Form: Content entry
 */
.o-ratingbox-form__entry {
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);
}




/** **************
 * Reviews from lichtberater
 */
.o-ratingbox-reviews__entry {
  @include u-accordion-panel;
}




/**
 * Reviews: Tab
 */
.o-ratingbox-reviews__tab {
  @include u-accordion-tab;
  @include u-baseline(3, padding-left);
  @include u-baseline(3, padding-right);
  background-color: $ratingbox-reviews-tab-background;

  &:focus {
    outline: none;
  }
}




// Rating box form
//
// Example content
//
// Markup: ratingbox_form.hbs
//
// Style guide: Controls.ratings.ratingboxform

// Megaheader
//
// Large intro header with optional modules like image, text and pills.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x12      |
// | small         | 518x197    |
// | large         | 1425x540   |
//
// .m-megaheader--align-left - Left aligned with optional sidebar.
//
// Markup: megaheader.hbs
//
// Type: module
//
// Style guide: Containers.megaheader

$megaheader-breakpoint: large;

.m-megaheader {
  text-align: center;

  .m-person {
    --person-justify: center;

    @include breakpoint(xxlarge) {
      --person-justify: flex-start;
    }
  }
}








/** ***********
 * Icon
 */
.m-megaheader__icon {
  @include font-h1;
  @include u-baseline(1, margin-bottom);
  color: var(--color-orange);

  @include breakpoint($megaheader-breakpoint) {
    @include u-baseline(2, margin-bottom);
  }

  .c-icon {
    width: 2.5em;
    height: 2.5em;
  }
}











/** ***********
 * Image
 */
.m-megaheader__img {
  @include u-baseline(30, height);
  @include u-baseline(-2, margin-left);
  @include u-baseline(-2, margin-right);
  overflow: hidden;
  position: relative;
  z-index: 0;

  @include breakpoint($megaheader-breakpoint) {
    @include u-baseline(3, margin-bottom);
    height: auto;
    margin-left: 0;
    margin-right: 0;
  }

  @include breakpoint($megaheader-breakpoint) {
    @include u-baseline(4, margin-bottom);
  }

  .c-image {
    @include u-img-cover;
  }

  & + .m-megaheader__content {
    --mh-header-top: #{rem-baseline(-8)};
  }
}








/** ***********
 * Content wrapper
 */
.m-megaheader__content {
  position: relative;
  z-index: 1;
}








/** ***********
 * Header
 */
.m-megaheader__header {
  @include u-baseline(2, padding-top);
  @include u-baseline(3, padding-left);
  @include u-baseline(3, padding-right);
  background-color: var(--mh-background, var(--color-light));
  margin-top: var(--mh-header-top);

  @include breakpoint($megaheader-breakpoint) {
    --mh-background: transparent;
    margin: 0;
    padding-top: 0;
    padding-left: calc((100% - var(--mh-header-max, #{rem-calc(720)})) / 2);
    padding-right: calc((100% - var(--mh-header-max, #{rem-calc(720)})) / 2);
  }
}

body.background--pearl {
  --mh-background: var(--color-pearl);
}

body.background--ice {
  --mh-background: var(--color-ice);
}









/** ***********
 * Heading
 */
.m-megaheader__heading {
  @include font-h1;
  @include font-bold-important;
  @include u-baseline(3, margin-bottom);
  margin-top: 0;
  text-wrap: balance;
}








/** ***********
 * Subheading
 */
.m-megaheader__subheading {
  @include font-subheading;
  @include u-baseline(1, margin-bottom);
  color: var(--color-slate-petrol);
  text-transform: uppercase;
}









/** ***********
 * Text body
 */
.m-megaheader__body {
  @include font-entry;
  @include u-content-links;
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  margin: 0 auto;
  text-align: left;
  text-wrap: balance;

  @include breakpoint(medium) {
    text-align: center;
  }

  @include breakpoint(large) {
    @include u-inner-max(rem-calc(760));
  }

  p {
    @include u-baseline(4, margin-bottom);
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}









/** ***********
 * Link pills
 */
.m-megaheader__links {
  @include u-baseline(4, margin-top);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: rem-calc(800);
  text-align: center;

  .m-person {
    @include u-baseline(3, margin-top);
    @include u-baseline(4, margin-bottom);

    @include breakpoint(xxlarge) {
      @include u-baseline(3, margin-left);
    }
  }
}







/** ***********
 * Modifier: Left aligned with optional sidebar
 */
.m-megaheader--align-left {
  .m-megaheader__content {
    @include breakpoint(xlarge down) {
      @include u-baseline(4, padding-bottom);
      border-bottom: $global-border-light;
    }
  }

  @include breakpoint(xxlarge) {
    display: grid;
    grid-template-columns: 1.3fr 0.7fr;
    grid-template-areas: 'image image' 'content links';
    align-items: stretch;
    text-align: left;

    .m-megaheader__img {
      grid-area: image;
    }

    .m-megaheader__content {
      @include u-baseline(16, padding-right);
      grid-area: content;
    }

    .m-megaheader__header {
      padding: 0;
    }

    .m-megaheader__body {
      padding: 0;
      text-align: left;
    }

    .m-megaheader__links {
      grid-area: links;
      margin-top: 0;
      padding: 0;
      text-align: left;
      width: 100%;

      .m-pills {
        text-align: left;
      }
    }
  }
}

$colors-values: (
  'black': #000,
  'brown': #ac6c4a,
  'beige': #eaeac6,
  'grey': #808080,
  'white': #fff,
  'blue': #0080ff,
  'tourquoise': #5de0d3,
  'green': #00b700,
  'yellow': #fd3,
  'orange': #fb863b,
  'red': #ff0002,
  'pink': #ffc0cb,
  'violet': #9349aa
);

$colors-value-transparent: (
  'name': transparent,
  'start': #c3fffe,
  'to': #fff,
  'stop': #c3fffe
) !default;

$colors-value-rose: (
  'name': rose,
  'start': #dba28c,
  'to': #facdb0,
  'stop': #dba28c
) !default;

$colors-value-gold: (
  'name': gold,
  'start': #ffd700,
  'to': #fff4bb,
  'stop': #ffd700
) !default;

$colors-value-copper: (
  'name': copper,
  'start': #a76a49,
  'to': #f1af8b,
  'stop': #a76a49
) !default;

$colors-value-bronze: (
  'name': bronze,
  'start': #aa9a7b,
  'to': #f7d491,
  'stop': #aa9a7b
) !default;

$colors-value-brass: (
  'name': brass,
  'start': #cd961b,
  'to': #f5d58f,
  'stop': #cd961b
) !default;

$colors-value-silver: (
  'name': silver,
  'start': #c0c0c0,
  'to': #ececec,
  'stop': #c0c0c0
) !default;

$colors-value-chrome: (
  'name': chrome,
  'start': #d9dce5,
  'to': #f8f7f7,
  'stop': #d9dce5
) !default;

$colors-value-multi: (
  'name': multi,
  'start': #db1414,
  'to': #ffdc00,
  'stop': #008dff
) !default;


// Creates color classes from colors map.
//
// $color - The color name
// $value - The color hex
@mixin c-colors-simple($color, $value) {
  .c-colors--#{'' + $color} {
    background: #{$value};
  }
}

@each $key, $value in $colors-values {
  @include c-colors-simple($key, $value);
}

// Creates color classes for gradient colors
//
// $color - The color object
@mixin c-colors-gradient($color) {
  .c-colors--#{'' + map-get($color, name)} {
    background: linear-gradient(-225deg, #{'' + map-get($color, start)} 0%, #{'' + map-get($color, to)} 50%, #{'' + map-get($color, stop)} 100%);
  }
}


// Hover and active state of color item
// if item is a link/has href.
@mixin c-colors-hover-state() {
  $colors-shadow-hover: rgba(0, 0, 0, .5);

  border-radius: 50%;
  box-shadow: 0 0 0 2px var(--color-dark), 0 5px 8px $colors-shadow-hover;

  &:after {
    @include u-baseline(2.5, width);
    @include u-baseline(2.5, height);
    cursor: pointer;
    display: block;
    position: absolute;
    content: '';
    border-radius: 50%;
    border: rem-calc(2) solid var(--color-light);
    top: 0;
    left: 0;
  }
}

// Cartalogue
//
// Catalogue order options in cart.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x9999    |
// | large         | 145x9999   |
// 9999 = Image height is flexible, **but** the displayed max-width is 145px.
//
// Markup: cartalogue.hbs
//
// Type: module
//
// Style guide: Promotion.cartalogue

$cartalogue-breakpoint: medium;
$cartalogue-background: var(--color-ice);
$cartalogue-border: 1px dotted var(--color-slush);
$cartalogue-image-width-large: rem-calc(145);
$cartalogue-img-margin-large: rem-baseline(3);
$cartalogue-offset-large: $cartalogue-image-width-large + $cartalogue-img-margin-large;
$cartalogue-image-width-small: rem-calc(80);
$cartalogue-img-margin-small: rem-baseline(2);

.m-cartalogue {
  @include u-baseline(1, margin-top);
  @include u-baseline(2, padding);
  background-color: $cartalogue-background;
  border: $cartalogue-border;

  @include breakpoint($cartalogue-breakpoint) {
    @include flex-align(left, middle);
    display: -webkit-box;
  }

  .c-select {
    @include u-baseline(3, margin-top);

    @include breakpoint($cartalogue-breakpoint) {
      margin-top: 0;
      max-width: rem-calc(300);
    }
  }
}




/** **************
 * Image
 */
.m-cartalogue__img {
  @include u-baseline(2, margin-right);
  @include u-baseline(1, padding);
  float: left;
  width: $cartalogue-image-width-small;

  @include breakpoint($cartalogue-breakpoint) {
    @include u-baseline(3, margin-right);
    float: none;
    flex: 0 0 $cartalogue-image-width-large;
    width: $cartalogue-image-width-large;
  }
}




/** **************
 * Content
 */
.m-cartalogue__content {
  @include flex;
  flex: 1 0 auto;
  flex-direction: column;
  position: relative;
  width: auto;

  [class*='heading-']:first-of-type {
    @include u-baseline(1, padding-top);

    @include breakpoint($cartalogue-breakpoint) {
      margin-top: 0;
    }
  }

  .c-formtab {
    display: inline-block;
  }
}

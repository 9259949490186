// Newsletter
//
// Teaser form for the newsletter. By default this module is a full width module.
// Use the modifier class to include this module inside of content areas.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | large         | 407x394    |
//
// Markup: newsletter.hbs
//
// .m-newsletter--is-module - Newsletter is implemented inside content areas
//
// Full: true
//
// Type: module
//
// Style guide: Promotion.newsletter

$newsletter-background: var(--color-geyser);
$newsletter-color: var(--color-dark);
$newsletter-button-hover-background: var(--color-slate-petrol);

/** **************
 * Basic styles
 */
.m-newsletter {
  @include font-meta;
  background-color: $newsletter-background;
  color: $newsletter-color;

  p {
    margin-top: 0;
    max-width: 60ch;

    &:last-of-type {
      @include u-baseline(3, margin-bottom);
    }
  }

  a:not(.c-button) {
    @include u-link($global-link-options-underline);
  }

  .form__group .c-button {
    @include u-baseline(3, margin-bottom);

    @include breakpoint(xlarge) {
      @include u-baseline(5, margin-bottom);
    }
  }
}




/** **************
 * Modifier: Newsletter is implemented in content areas
 */
.m-newsletter--is-module {
  @include u-baseline(3, margin-bottom);

  .m-newsletter__inner {
    @include u-single-cell;
    @include u-baseline(3, padding);
  }

  // To be deprecated when new success page
  // is deployed.
  .m-newsletter__img {
    display: none;
  }
}








/** ***********
 * Content container
 */
.m-newsletter__content {
  --grid-row-start-xxlarge: 1;
  --grid-col-start-large: 4;
  --grid-col-end-large: 12;
  --grid-col-start-xlarge: 7;
  --grid-col-end-xlarge: 13;
  --grid-align-self: center;
  @include u-baseline(8, padding-top);
  @include u-baseline(4, padding-bottom);
  text-align: center;

  @include breakpoint(xlarge) {
    @include u-baseline(9, padding-bottom);
    text-align: left;
  }
}








/** **************
 * Heading
 */
.m-newsletter__heading {
  @include font-h2;
  @include font-bold-important;
  @include u-baseline(1, margin-bottom);
  margin-top: 0;
}








/** ***********
 * Image
 */
.m-newsletter__img {
  --grid-row-start-small: 2;
  --grid-row-start-xlarge: 1;
  --grid-col-start-large: 4;
  --grid-col-end-large: 12;
  --grid-col-start-xlarge: 2;
  --grid-col-end-xlarge: 7;
  --grid-align-self: flex-end;
  display: flex;
  justify-content: center;

  @include breakpoint(xlarge) {
    @include u-baseline(5, padding-top);
  }

  img {
    display: block;
    height: auto;
    max-width: rem-calc(400);
    transform: translateX(-30px);
    width: 100%;
  }
}








/** ***********
 * Footer text
 */
.m-newsletter__footer {
  @include font-small;
  color: var(--color-slate-petrol);

  a {
    color: inherit;
  }
}

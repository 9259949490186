// Forms
//
// Form styles and scaffold. We're using the [foundation forms](http://foundation.zurb.com/sites/docs/forms.html) with custom styles.
//
// Weight: 2
//
// Style guide: Forms

@import '_config';
@import '_mixins';

@import 'forms-checkbox';
@import 'forms-image';
@import 'forms-input';
@import 'forms-legend';
@import 'forms-radio';
@import 'forms-radiocheck';
@import 'forms-select';
@import 'forms-tab';
@import 'forms-textarea';
@import 'forms-password-strength';

/** **************
 * Global styles / Foundation overrides
 */
label {
  @include font-small; // override

  .c-select,
  .c-checkbox,
  .c-radio,
  .c-input,
  .c-textarea {
    @include u-baseline(1, margin-top);
  }

  .form__helptext {
    display: block;
    margin: 0 !important;
  }
}

legend {
  @include font-meta($type: 'display');
  @include font-bold-important;
  @include u-baseline(2, margin-bottom);
  @include u-baseline(3, padding-top);
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  + .c-formtab {
    margin-top: 0;
  }
}

fieldset {
  @include font-small; // override

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;

    + p {
      margin-top: -(rem-baseline(1));
    }
  }
}










/** **************
 * Optional heading in nested labels (Fragebogen)
 */
.form__heading {
  display: block;
}







/** **************
 * Error styles
 */
.form--error {
  [type='text'],
  [type='email'],
  [type='password'],
  [type='date'],
  textarea,
  .c-formtab__value {
    border-top: 1px solid;
    border-color: $form-error-color;
  }
}




/** **************
 * Wrapper to force max-width in large containers.
 * No need in 50/50 layouts
 */
.form__wrapper {
  @include breakpoint(large) {
    @include xy-cell(10, $output: (base size));
  }
}




.form__item {
  .c-select {
    @include u-baseline(1, margin-top);
  }
}



/** **************
 * Form group –
 * Input field and button in same row
 */
.form__group {
  @include breakpoint(large) {
    display: table;
    width: 100%;
  }

  .c-input {
    @include breakpoint(large) {
      display: table-cell;
      margin-top: 0;
    }
  }
}

.form__group--button {
  @include breakpoint(large) {
    @include u-baseline(2, padding-left);
    display: table-cell;
    vertical-align: top;
    width: 1%;
  }
}





/** ***********
 * Input wrapper for postfix
 */
.form__input {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.form--has-postfix {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;

  .form__error,
  div.mage-error {
    grid-row: 2;
  }
}




/** **************
 * Wrapper for error values
 */
.form__error,
div.mage-error {
  @include u-baseline(2, margin-bottom);
  color: $form-error-color;
  display: block;
  margin-top: -(rem-baseline(1));

  + .c-input__postfix {
    --postfix-background: #{$form-error-color};
  }
}

/** ***********
 * This propably is temporary and should be
 * reviewed after PRD2-3300 has launched.
 */
.mage-error {
  + .c-formtab__value {
    border-color: var(--color-warning);
    color: var(--color-warning);
  }
}




/** **************
 * Help text below label
 */
.form__helptext {
  color: $form-helptext-color;
  display: block;
  margin: 0;
}



.form__link {
  @include u-baseline(1, padding-bottom);
  display: block;

  @include breakpoint(medium) {
    @include u-baseline(1, padding-left);
    display: inline-block;
    float: right;
  }
}

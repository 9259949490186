// Energy Calculator
//
// Energy calculator to calculate efficiency of shop products.
//
// Markup: enercalc.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Promotion.enercalc
$enercalc-breakpoint: xlarge;
$enercalc-background-nosavings: palette(ui, 'iron');
$enercalc-border: inline-svg('<svg viewBox="0 0 381 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"><path d="M.582.5v22.8c30.383 1.293 78.98-3.7 145.792-14.979 66.812-11.278 144.88-6.285 234.208 14.98V.5h-380Z" fill="' + palette(ui, 'energy') + '" fill-rule="evenodd"/></svg>');
$enercalc-border-nosavings: inline-svg('<svg viewBox="0 0 381 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"><path d="M.582.5v22.8c30.383 1.293 78.98-3.7 145.792-14.979 66.812-11.278 144.88-6.285 234.208 14.98V.5h-380Z" fill="' + $enercalc-background-nosavings + '" fill-rule="evenodd"/></svg>');
$enercalc-border-width: 24;
$enercalc-title-background: rgba(255, 255, 255, .13);

.m-enercalc {
  @include u-baseline(5, margin-bottom);
  background-color: var(--color-ice);
  border-radius: rem-calc(8);
  box-shadow: var(--shadow-light);
  display: none;
  max-width: rem-calc(600);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;

  &[data-show] {
    display: block;

    @include breakpoint(large) {
      display: grid;
    }
  }
}






/** ***********
 * App icon
 */
.m-enercalc__icon {
  @include u-baseline(3, top);
  @include u-baseline(3, left);
  @include u-baseline(4.5, width);
  @include u-baseline(4.5, height);
  color: var(--color-energy);
  position: absolute;
}







/** ***********
 * Header container
 */
.m-enercalc-header {
  position: relative;
}

.m-enercalc-header__heading {
  @include font-h4;
  @include font-bold-important;
  @include u-baseline(3, padding-top);
  @include u-baseline(3, padding-right);
  @include u-baseline(1, padding-bottom);
  @include u-baseline(10, padding-left);
}

.m-enercalc-header__body {
  @include u-baseline(3, padding);
  padding-top: 0;
  max-width: 60ch;

  @include breakpoint(medium) {
    @include u-baseline(10, padding-left);
  }
}











/** ***********
 * Result content
 */
.m-enercalc-result {
  background: var(--color-energy);
  color: var(--color-light);
  position: relative;
  text-align: center;
  width: 100%;

  &:after {
    background-image: $enercalc-border;
    background-size: 100% 100%;
    content: '';
    display: block;
    position: absolute;
    top: auto;
    bottom: calc(#{rem-calc(-$enercalc-border-width)} - -1px);
    left: -#{rem-calc(1)};  // 1px glitch fix
    height: rem-calc($enercalc-border-width);
    width: calc(100% + #{rem-calc(2)});  // 1px glitch fix
  }

  sup {
    @include font-h1;
    @include font-bold-important;
    @include u-baseline(1, margin-right);
    @include u-baseline(-2, margin-left);
    display: none;
    transform: translateY(#{rem-baseline(-.5)});
    vertical-align: top;

    @include breakpoint(large) {
      transform: translateY(#{rem-baseline(1)});
    }
  }
}

.m-enercalc-result--nosavings {
  background: $enercalc-background-nosavings;

  &:after {
    background-image: $enercalc-border-nosavings;
  }
}

.m-enercalc-result__title {
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(3, padding);
  background-color: $enercalc-title-background;
}

.m-enercalc-result__profit {
  @include u-baseline(4, padding-top);
  @include u-baseline(3, padding-bottom);
  display: inline-block;
  font: var(--font-40-display);

  @include breakpoint($enercalc-breakpoint) {
    margin: 0;
  }

  sub {
    @include font-h4;
    @include font-bold-important;
    display: inline-block;
    transform: translateY(-2px);
    vertical-align: bottom;
  }
}

.m-enercalc-result__meta {
  @include font-tiny;
  @include u-baseline(4, padding-bottom);
  color: var(--color-ice);
}

.m-enercalc-result__msg {
  @include u-baseline(4, padding-top);
  color: var(--color-ice);
  font-weight: bold;
}





/** ***********
 * Form container
 */
.m-enercalc-form {
  @include u-baseline(3, padding);
  @include u-baseline(5, padding-top);
  @include u-baseline(5, padding-bottom);
  background-color: var(--color-geyser);

  .form__input {
    @include u-baseline(4, margin-bottom);
    display: flex;
    gap: 0 rem-baseline(2);

    @include breakpoint(medium) {
      @include u-baseline(2, margin-bottom);
    }
  }

  .form__item {
    flex: 1 0 auto;

    @include breakpoint(medium) {
      flex: 0 1 calc(50% - #{rem-baseline(1)});
    }
  }

  .c-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.m-enercalc-form__inner {
  max-width: rem-calc(420);
  margin-left: auto;
  margin-right: auto;
}

.m-enercalc-form__heading {
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(3, margin-bottom);
  text-align: center;
}




/** ***********
 * Footnote
 */
.m-enercalc__footnote {
  @include font-tiny;
  @include u-baseline(3, padding-top);
  color: var(--color-slate-petrol);
}











/** ***********
 * User form
 */
.m-enercalc__user {
  background-color: var(--color-ice);
  box-shadow: var(--shadow-light);
  // max-height: calc(100% - #{rem-baseline(4)});
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: var(--transition-smooth);
  transform: translateY(-110%);
  z-index: 2;

  @include breakpoint($enercalc-breakpoint) {
    align-items: stretch;
    display: grid;
    grid-template-columns: 45% 55%;
  }

  &[data-enercalc-active] {
    transform: translateY(0);
  }

  .m-enercalc__col {
    &:first-child {
      @include u-baseline(3, padding);
      background-color: var(--color-petrol);
      color: var(--color-light);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:last-child {
      @include u-baseline(3, padding);
    }
  }
}

.m-enercalc__desc {
  @include breakpoint($enercalc-breakpoint) {
    @include u-baseline(3, padding-right);
  }
}

.m-enercalc__user-input {
  p:first-child {
    margin-top: 0;
  }
}






/** ***********
 * Compare table
 */
.m-enercalc__compare {
  @include u-baseline(3, padding);

  @include breakpoint($enercalc-breakpoint) {
    @include u-baseline(4, padding);
    @include u-baseline(6, padding-right);
  }
}

table.m-enercalc__table {
  @include font-tiny;
  margin-bottom: 0;

  thead th:first-child {
    font-weight: normal;
  }

  thead th:last-child {
    background: var(--color-geyser);
    outline: rem-calc(4) solid var(--color-geyser);

    &:before {
      display: none;
    }
  }

  thead th,
  tbody td {
    text-align: center;
  }

  tbody td {
    color: var(--color-dusty);

    &:last-child {
      background: var(--color-ice);
    }
  }

  td {
    width: 50%;
  }

  tfoot td {
    color: var(--color-iron);
    font-weight: normal;
  }
}

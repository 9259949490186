// Arrow link
//
// Link with arrow icon
//
// Markup: arrowlink.hbs
//
// .c-arrowlink--center - Centered link
//
// Type: component
//
// Style guide: Controls.arrowlink

@import '_config';
@import '_mixins';

.c-arrowlink {
  @include c-arrowlink($arrowlink-options);
}





/** **************
 * Active state
 */
.is-active > .c-arrowlink,
.c-arrowlink--is-active {
  color: $arrowlink-active-color;
  fill: $arrowlink-active-color;
  font-weight: bold;

  &:before {
    background-color: var(--color-petrol) !important;
  }
}




/** **************
 * Link text colors
 */
//.c-arrowlink--dark {
//  @include c-arrowlink($arrowlink-options-dark);
//}

.c-arrowlink--petrol {
  @include c-arrowlink($arrowlink-options-petrol);
}

.c-arrowlink--orange {
  @include c-arrowlink($arrowlink-options-orange);
}




/** **************
 * Icon comes after text
 */
.c-arrowlink--after {
  @include c-arrowlink($arrowlink-options-after);
}




/** **************
 * Icon shows arrow to the left
 */
.c-arrowlink--left {
  @include c-arrowlink($arrowlink-options-left);
}




/** ***********
 * Modifier: Centered link
 */
.c-arrowlink--center {
  justify-content: center;

  > span {
    flex-shrink: 1;
    width: auto;
  }
}






// Petrol text
//
// Markup: arrowlink--petrol.hbs
//
// Style guide: Controls.arrowlink.petrol



// Orange icon
//
// Markup: arrowlink--orange.hbs
//
// Style guide: Controls.arrowlink.orange



// Left arrow
//
// Markup: arrowlink--left.hbs
//
// Style guide: Controls.arrowlink.left



// Icon after the link
//
// Displays the icon after the link.
//
// Markup: arrowlink--after.hbs
//
// Style guide: Controls.arrowlink.after

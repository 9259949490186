// Tables
//
// We are using the [Foundation tables](http://foundation.zurb.com/sites/docs/table.html) with modified
// settings in `_foundation-settings.scss`
//
// Markup: table.hbs
//
// .hover - Table has hover effects on rows
// .simplified - Table has thin borders and no background colors
// .stack - Stacked table for mobile view without table headers
// .stack.stack--with-headings - Stacked table for mobile view. `data-mobile-th` is required if table header should be displayed.
//
// Weight: 2
//
// Style guide: Content.tables

@import '_mixins';

table {
  @include font-small; // override
  @include u-baseline(3, margin-bottom);
  border-collapse: collapse;

  thead {
    border: none;
    position: sticky;
    top: 0;
    z-index: 2;

    // Override Foundation's hover background color
    tr {
      &:hover {
        background-color: var(--color-cod) !important;
      }
    }

    th {
      @include u-baseline(2.5, padding);
      @include u-baseline(2, padding-left);
      @include font-tiny;
      font-weight: normal;
      letter-spacing: .1em;
      text-transform: uppercase;
      position: relative;

      // Faux border because of border bug
      // on sticky table headers.
      &:before {
        background-color: var(--color-iron);
        content: '';
        position: absolute;
        right: -1px;
        width: 1px;
        height: 100%;
        top: 0;
      }
    }

    // Sorting arrows
    [data-th-order] {
      cursor: pointer;

      &:after {
        @include u-baseline(2, right);
        background-image: $global-icon-arrow-sort-double;
        background-size: 100% 100%;
        content: '';
        display: block;
        opacity: .7;
        position: absolute;
        top: calc(50% - #{rem-calc(7)});
        width: rem-calc(9);
        height: rem-calc(14);
      }
    }

    [data-th-active][data-th-order]:after {
      background-image: $global-icon-arrow-sort;
      opacity: 1;
      top: calc(50% - #{rem-calc(4)});
      width: rem-calc(12);
      height: rem-calc(7);
    }

    [data-th-active][data-th-order='desc']:after {
      transform: rotate(180deg);
    }

    [data-th-active] {
      background-color: var(--color-heavy);
    }
  }

  tbody {
    td {
      @include u-baseline(2, padding);
      word-break: break-word;
    }
  }

  tfoot {
    background-color: transparent;
    border: none;
    border-top: 1px solid var(--color-slate);

    td {
      @include u-baseline(2, padding-top);
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.simplified {
    thead,
    tbody {
      border: none;
    }

    thead {
      border-bottom: 1px solid var(--color-slush);

      // Override Foundation's hover background color
      tr {
        &:hover {
          background-color: var(--color-light) !important;
        }
      }

      th {
        border: none;
        font-weight: bold;
        text-align: left;

        &:before {
          display: none;
        }
      }
    }

    thead,
    tbody,
    tbody tr {
      background: var(--table-bg, var(--color-light));
      color: $global-body-color;
    }

    tbody tr {
      border-bottom: $global-border-dotted;
    }

    tbody td {
      //border-bottom: $global-border-dotted;
      border: none;
      border-left: 1px solid var(--color-light);
      padding-left: 0;
    }

    thead th,
    tbody td {
      border-right: none;
      padding-left: 0;
    }

    &.stack--with-headings tbody td:before {
      left: 0;
    }
  }

  &.unstriped {
    border-bottom: none;

    tbody,
    tbody tr,
    tbody td {
      border: none;
      padding-top: 0;
    }
  }

  &.stack--with-headings {
    @include breakpoint(medium down) {
      thead {
        display: none;
      }

      tbody td {
        padding-left: 50%;
        position: relative;

        &:before {
          @include u-baseline(2, left);
          content: attr(data-mobile-th);
          font-weight: bold;
          position: absolute;
          width: 45%;
        }
      }
    }
  }

  .c-arrowlink {
    font-size: 1em;
  }
}



// Modifier: Wishlists table
.table--wishlists {
  tbody {
    td {
      &:last-child {
        @include breakpoint(large) {
          width: rem-calc(240);
        }
      }
    }
  }
}

.modals-overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.u-show {
  display: block;
}

#checkout-loader {
  margin: auto;
  width: 64px;
}

div.cms-content-important /* Magento 2 Page Builder */ {
  @include font-small;
  margin-top: 1em;
  margin-bottom: 1em;
}

.pagebuilder-mobile-hidden {
  display: none;
}

/* Fact-Finder module "fixes" */
ff-suggest-item:hover {
    text-decoration: none !important;
}

/* Google Places Autocomplete */
.pac-container.pac-logo {
    z-index: 99999;
}

/* Hide ReCaptcha Badges */
.grecaptcha-badge {
    visibility: collapse !important;
}

/* Amazon Pay Style defs, see https://amzn.github.io/amazon-payments-magento-2-plugin/faq.html#i-am-using-a-custom-theme-what-do-i-have-to-do */
#addressBookWidgetDiv {
  height: 230px;
}

#walletWidgetDiv {
  height: 230px;
}

.amazon-widget-container {
  margin-top: 16px;
}

// Energy label
//
// Shows image on hover.
//
// .c-energy--a - A (text value is dummy text)
// .c-energy--b - B (text value is dummy text)
// .c-energy--c - C (text value is dummy text)
// .c-energy--d - D (text value is dummy text)
// .c-energy--e - E (text value is dummy text)
// .c-energy--f - F (text value is dummy text)
// .c-energy--g - G (text value is dummy text)
//
// Markup: energy.hbs
//
// Type: component
//
// Style guide: Media.energy

/** **************
 * Default / Wrapper
 */
.c-energy {
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  display: inline-block;
  width: rem-calc(60);
  height: rem-calc(27);

  &:hover {
    color: transparent;
  }
}




/** **************
 * Energy types
 */
.c-energy--a {
  background-image: $global-icon-energy-a;
}

.c-energy--b {
  background-image: $global-icon-energy-b;
}

.c-energy--c {
  background-image: $global-icon-energy-c;
}

.c-energy--d {
  background-image: $global-icon-energy-d;
}

.c-energy--e {
  background-image: $global-icon-energy-e;
}

.c-energy--f {
  background-image: $global-icon-energy-f;
}

.c-energy--g {
  background-image: $global-icon-energy-g;
}

// Reveal related products wrapper
//

.o-reveal-related {
  @include xy-grid-container;
  @include xy-grid;
}




/** **************
 * Inner container
 */
.o-reveal-related__inner {
  @include xy-cell;
  width: 100%;

  @include breakpoint(medium) {
    @include xy-cell($gutters: $global-baseline * 2);
  }

  @include breakpoint(large) {
    @include xy-cell($gutters: $global-baseline);
  }

  .m-carousel {
    @include u-baseline(2, margin-bottom);

    @include breakpoint(medium down) {
      margin-left: -(rem-calc(10));
      margin-right: -(rem-calc(10));
    }
  }
}

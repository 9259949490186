// Jump Marks
//
// Ordered list to jump directly to DOM elements
//
// Markup: jumpmarks.hbs
//
// Type: module
//
// Style guide: Controls.Jumpmarks

$jumpmarks-size: rem-baseline(4);

.m-jumpmarks {
  position: relative;
}







/** ***********
 * Heading
 */
.m-jumpmarks__heading {
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(2, margin-bottom);
  display: block;
}








/** ***********
 * List
 */
.m-jumpmarks__list {
  @include u-baseline(1, margin-bottom);
  counter-reset: item;
  list-style-type: none;
  margin-top: 0;
}








/** ***********
 * List item
 */
.m-jumpmarks__item {
  @include font-meta;
  padding-left: .5em;
  position: relative;

  &:not(:last-child) {
    @include u-baseline(3, margin-bottom);
  }

  // Letter
  &:before {
    @include font-meta($type: 'display');
    @include font-bold-important;
    @include u-baseline(-.35, top);
    background: var(--color-ice);
    border: rem-calc(2) solid var(--color-slush);
    border-radius: 50%;
    color: var(--color-slush);
    content: counter(item, upper-alpha);
    counter-increment: item;
    left: -2.25em;
    line-height: 1.7;
    position: absolute;
    text-align: center;
    width: 2em;
    height: 2em;
    z-index: 1;

    @include breakpoint(xlarge) {
      @include u-baseline(-.25, top);
    }
  }
}

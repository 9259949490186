// Slider
//
// Slider for full-width images with caption, using [Owl Carousel](https://owlcarousel2.github.io/OwlCarousel2/) jQuery plugin and the [display module](section-containers.html#kssref-containers-display).
//
// Overrides owl carousel styles.
//
// Markup: slider.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Promotion.slider
@use 'sass:math';
$slider-breakpoint: medium;
$slider-arrow-color: var(--color-dark);
$slider-arrow-size: rem-calc(50);
$slider-arrow-margin: rem-baseline(2);
$slider-arrow-background: rgba(255, 255, 255, .7);
$slider-arrow-hover-background: rgba(255, 255, 255, 1);
$slider-arrow-hover-color: var(--color-dark);

/** **************
 * Basic styles
 */
.m-slider {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
  @include u-module-margin;
  overflow: hidden;

  .owl-theme {
    .owl-nav {
      [class*='owl-'] {
        background-color: $slider-arrow-background;
        color: $slider-arrow-color;
        position: absolute;
        top: calc(50% - math.div($slider-arrow-size, 2));
        line-height: $slider-arrow-size;
        width: $slider-arrow-size;
        height: $slider-arrow-size;
      }

      .owl-prev {
        left: 0;
      }

      .owl-next {
        right: 0;
      }
    }

    &:hover .owl-nav {
      [class*='owl-'] {
        @include breakpoint($global-navigation-breakpoint) {
          background-color: $slider-arrow-hover-background;
          color: $slider-arrow-hover-color;
        }
      }
    }
  }
}




/** **************
 * Inner container
 */
.m-slider__inner {
  @include u-single-cell;

  @include breakpoint(small down) {
    padding-left: 0;
    padding-right: 0;
  }
}

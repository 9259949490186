// Spinner
//
// Loading spinner, css only
//
// @see: http://tobiasahlin.com/spinkit/
//
// Markup: spinner.hbs
//
// Type: component
//
// JavaScript: true
//
// Style guide: Media.spinner

@import '_mixins';

/** **************
 * Animated dots
 */
.c-spinner {
  @include u-baseline(-2, margin-top);
  animation-delay: .8s;
  color: var(--color-petrol);
  margin-left: .75em;
  position: relative;
  transform: translateZ(0);
}

.c-spinner,
.c-spinner:before,
.c-spinner:after {
  animation-fill-mode: both;
  animation: spinner-bouncedelay 1.8s infinite ease-in-out;
  border-radius: 50%;
  width: 1em;
  height: 1em;
}

.c-spinner:before,
.c-spinner:after {
  content: '';
  position: absolute;
  top: 0;
}

.c-spinner:before {
  animation-delay: -.16s;
  left: -1.25em;
}

.c-spinner:after {
  animation-delay: .2s;
  left: 1.25em;
}




/** **************
 * Modifier: Full height
 */
.c-spinner--full-height {
  height: 100%;
  min-height: rem-calc(200);
}







/** **************
 * Wrapper
 */
.c-spinner__container {
  @include flex;
  @include flex-align(center, middle);
  text-align: center;
}








/** **************
 * Text
 */
.c-spinner__text {
  @include u-baseline(1, margin-bottom);
  display: block;
}

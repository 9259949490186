// Misc. helper classes
// to use in HTML markup



// Enable nested rows
.u-row--nested {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
}



// Row and cell combined
.u-row-cell {
  @include u-cell-row;
  flex-direction: column;
  width: 100%;
}





// Adds bottom margin to element
.u-row--margin {
  @include u-module-margin;
}

.u-row--margin-small {
  @include u-module-margin(small);
}

.u-row--no-margin {
  margin-bottom: 0 !important;
}




// Adding margins to element based on baseline
//
// Extend as you like it!
.u-margin {
  &--b0 {
    margin-bottom: 0;
  }

  &--b1 {
    @include u-baseline(1, margin-bottom);
  }

  &--b2 {
    @include u-baseline(2, margin-bottom);
  }

  &--b3 {
    @include u-baseline(3, margin-bottom);
  }

  &--b4 {
    @include u-baseline(4, margin-bottom);
  }

  &--b5 {
    @include u-baseline(5, margin-bottom);
  }

  &--l2 {
    @include u-baseline(2, margin-left);
  }

  &--t0 {
    margin-top: 0;
  }

  &--t1 {
    @include u-baseline(1, margin-top);
  }

  &--t2 {
    @include u-baseline(2, margin-top);
  }

  &--t4 {
    @include u-baseline(4, margin-top);
  }

  &--t6 {
    @include u-baseline(6, margin-top);
  }
}





// Adding paddings to elements
.u-padding {
  &--t4 {
    @include u-baseline(4, padding-top);
  }
}






// Make an element full width
// when inside flexbox wrapper
.u-fullwidth {
  width: 100%;
}






// Flexbox
.u-flex {
  display: flex;
}






// Make elements equal height
.u-full-height {
  height: 100%;
}





// Adds top padding to module
//
// Mostly used for first row on page
// to force space between breadcrumbs and content
.u-row--push {
  @include u-baseline(3, padding-top);
}

.u-row--push-large {
  @include u-baseline(3, padding-top);

  @include breakpoint(large) {
    @include u-baseline(6, padding-top);
  }
}





// Adds bottom padding to avoid 'small' pages
.u-row--page {
  @include u-baseline(8, padding-bottom);
}







// Justify elements by floating last
// item to the right
.u-justified {
  width: 100%;

  @include breakpoint(medium) {
    > * {
      float: left;
    }

    > *:last-child {
      float: right;
      margin-right: 0;
    }
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}





// Responsive image
//
// (max-width and width: 100%)
.u-img-responsive {
  @include u-img-responsive;
}






// Absolute positioned sidebar images
// to cut off on smaller screens
//
// Used on newsletter templates
.u-img-display {
  min-height: rem-calc(355);
  overflow: hidden;
  position: relative;
  transform: translateX(rem-calc(20));

  img {
    display: block;
    position: absolute;
    max-width: none;
  }
}




// Centered images with defined width
// on HTML image tag
.u-img-center {
  img {
    display: block;
    margin: 0 auto;
  }
}



.u-img--fullwidth {
  display: block;
  max-width: 100%;
  width: 100%;
}



// Centered contents
.u-center,
.u-text--center {
  text-align: center !important;
}




// Removing text-transforms from fonts
.u-text--normal {
  text-transform: none;
}

.u-text--right {
  text-align: right !important;
}


// Adding uppercase to fonts
.u-text--uppercase {
  text-transform: uppercase;
}

.u-text--max {
  max-width: var(--text-max-width, 70ch);
}



// Line-through
.u-linethrough {
  text-decoration: line-through;
}




// Resetting display font from font
// for footnotes (*) etc.
.u-footnote {
  font-family: font(base, 'body');
}



// Link styles
.u-link {
  @include u-link($global-link-options-default);
}

.u-link--text {
  @include u-link($global-link-options-default);
}

.u-link--slate {
  @include u-link($global-link-options-slate);
}

.u-link--slate-petrol {
  @include u-link($global-link-options-slate-petrol);
}

.u-link--light {
  @include u-link($global-link-options-light);
}

.u-link--underline {
  @include u-link($global-link-options-underline);
}

.u-link--underline-inherit {
  @include u-link($global-link-options-underline-inherit);
}

.u-link--dark-underline {
  @include u-link($global-link-options-dark-underline);
}

.u-link--warning {
  @include u-link($global-link-options-warning);
}



// Plain list styles
.u-list--plain {
  @include u-baseline(3, margin-top);
  @include u-baseline(3, margin-bottom);
  list-style: none;
  padding-left: 0;

  > li:not(:last-child) {
    @include u-baseline(1, margin-bottom);
  }
}



// Disabling pointer-events from maps
.u-maps {
  width: 100%;

  &.scrolloff {
    pointer-events: none;
  }
}




// Adding global transition to element
.u-transition {
  transition: var(--transition-default);
}



// Element is hidden
.u-hidden {
  display: none;
}



// Placeholder styles for template:
//
// partials/placeholder.hbs
.u-placeholder {
  @include u-placeholder;

  &--small {
    @include u-placeholder(100);
  }
}





/** ***********
 * Non-breakable content
 */
.u-nobr {
  white-space: nowrap;
}








/** ***********
 * Bold font
 */
.u-bold {
  font-weight: bold;
}





/** ***********
 * Heading with resetted font styles
 */
.u-faux-heading {
  @include u-faux-heading;
}




/** ***********
 * Make container clickable by its link
 */
.u-clickable-container {
  @include u-clickable-container;
}


// No reply
//
// Empty wrapper to use pseudo-classes
// like :last-of-type etc.
// .u-noreply {
//
// }




.u-remove-item {
  animation: removeItemFade .5s forwards;
  transform: translate3d(0, 0, 0);

  > * {
    animation: removeItemShrink .5s forwards;
    transform: translate3d(0, 0, 0);
  }

  &:before {
    animation: removeItemFade .5s forwards;
  }
}

@include keyframes(removeItemFade) {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
    margin-bottom: 0;
  }
}

@include keyframes(removeItemShrink) {
  from {
    height: 100%;
    max-height: 10000rem;
  }

  to {
    max-height: 0;
  }
}

// USP
//
// Why should the user buy from us?
//
// Markup: usp.hbs
//
// Type: module
//
// Style guide: Promotion.usp
.m-usp {
  @include u-baseline(3, padding-top);
  @include u-baseline(1, padding-bottom);
  @include u-baseline(3, grid-gap);
  background-color: var(--color-light);
  border: rem-calc(1) solid var(--color-slush-ice);
  border-radius: rem-calc($global-border-radius);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--usp-grid, rem-calc(270)), 1fr));
}








/** ***********
 * Item
 */
.m-usp__item {
  @include u-baseline(9, padding-left);
  @include u-baseline(3, padding-right);
  @include u-baseline(2, margin-bottom);
  @include font-small;
  color: var(--color-iron);
  position: relative;

  // Check mark icon
  &:before,
  &:after {
    @include u-baseline(3, width);
    @include u-baseline(3, height);
    @include u-baseline(3.5, left);
    content: '';
    display: block;
    position: absolute;
    top: 0;
  }

  &:before {
    background-color: var(--color-orange-light);
    border-radius: 50%;
  }

  &:after {
    background-image: $global-icon-checkmark;
    background-size: 60% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .u-bold {
    color: var(--color-dark);
  }
}

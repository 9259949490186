// Consultant
//
// Consultancy steps on how to light consultancy works.
//
// Markup: consultant.hbs
//
// .o-consultant--is-modal - Module is embedded in a modal.
//
// Type: organism
//
// Style guide: Promotion.consultant

$consultant-divider-color: var(--color-slate-petrol);

.o-consultant {
  background-color: var(--section-background, var(--color-ice));
  color: var(--section-color);
  text-align: center;
}








/** ***********
 * Heading
 */
.o-consultant__heading {
  @include font-h1;
  @include font-bold-important;
  @include u-baseline(3, margin-bottom);
  @include u-inner-max(465);
  margin-top: 0;

  @include breakpoint(medium) {
    @include u-baseline(5, margin-bottom);
  }
}







/** ***********
 * Subheading
 */
.o-consultant__subheading {
  @include font-h2;
  @include font-bold-important;
  @include u-baseline(3, margin-bottom);
  margin-top: 0;
}





/** ***********
 * Items
 */
.o-consultant__items {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
  @include flex-align(center, top);
  @include u-baseline(3, margin-bottom);

  @include breakpoint(large) {
    @include u-baseline(6, margin-bottom);
    align-items: initial;
  }

  @include breakpoint(xlarge) {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
}









/** ***********
 * Item
 */
.o-consultant__item {
  @include xy-cell;
  @include u-baseline(3, margin-bottom);
  color: $global-body-color;
  display: flex;

  @include breakpoint(medium) {
    @include xy-cell(8);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(4, $gutters: rem-calc($global-baseline * 5));
    margin-bottom: 0;
  }
}






/** ***********
 * CTA wrapper
 */
.o-consultant__ctas {
  margin: 0 auto;
  max-width: rem-calc(350);

  @include breakpoint(large) {
    max-width: rem-calc(400);
  }

  @include breakpoint(xlarge) {
    @include flex-align(center, middle);
    display: flex;
    max-width: none;
  }
}








/** ***********
 * CTA item
 */
.o-consultant__cta {
  position: relative;

  .c-button {
    text-align: left;
  }
}









/** ***********
 * Divider
 */
.o-consultant__divider {
  @include u-baseline(1, padding-top);
  @include u-baseline(1, padding-bottom);
  color: $consultant-divider-color;
  text-transform: uppercase;

  @include breakpoint(xlarge) {
    padding: 0;
    width: rem-calc(120);
  }
}

// Column Text
//
// Full width textblock with CSS columns. This module contains Foundation grid mixins.
//
// **Important:** Don't use columns for long texts due [readability issues.](https://css-tricks.com/guide-responsive-friendly-css-columns/#article-header-id-12)
//
// Markup: columntext.hbs
//
// .m-columntext--3cols - Using 3 columns
// .m-columntext--has-rule - Adding thin rule between columns.
// .m-columntext--nomargin - No bottom margin
//
// Type: module
//
// Style guide: Content.columntext
//

$columntext-min-height: rem-calc(400); // Min-height required to apply columns
$columntext-max-width: rem-calc(560); // Fallback for screens smaller than min-height

/** ***********
 * Base styles & grid
 */
.m-columntext {
  &:not(.m-columntext--nomargin) {
    @include u-module-margin;
  }
}



/** ***********
 * Columns
 */
.m-columntext__inner {
  @include font-entry;
  @include u-content-formatting;

  // Apply columns only if screen height is
  // larger than min-height
  @media (min-height: $columntext-min-height) {
    @include u-baseline(6, column-gap);
    columns: var(--column-count, 2) rem-calc(300);
    column-rule: var(--column-rule, none);
  }

  // Apply max-width for readability for screens
  // lower than min-height
  @media (max-height: $columntext-min-height) {
    max-width: $columntext-max-width;
  }

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;
  }

  // Headings will break the columns
  h1,
  h2,
  h3,
  h4 {
    @include font-h2;
    @include font-bold-important;
    @include u-baseline(2, margin-bottom);
    column-span: all;
    margin-top: 0;

    @include breakpoint(xlarge) {
      @include u-baseline(4, margin-bottom);
    }

    &:first-child {
      @include u-inner-max(650);
      text-align: center;
    }
  }
}




/**
 * Modifier: Using 3 columns
 */
.m-columntext--3cols {
  .m-columntext__inner {
    @include font-meta;
    --column-count: 3;
  }
}




/**
 * Modifier: Adding rule between columns
 */
.m-columntext--has-rule {
  .m-columntext__inner {
    --column-rule: 1px solid var(--color-ice);
  }
}

// Person
//
// Round image with name and link.
//
// Markup: person.hbs
//
// .m-person--layout-large - [WIP] Large centered with button link.
//
// Type: module
//
// Style guide: Containers.persons.person
.m-person {
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: rem-baseline(13) auto;
  grid-template-rows: 1fr;
  gap: 0 rem-baseline(2);
  align-items: center;
  justify-content: var(--person-justify, start);
  text-align: var(--person-align, left);
}




/** ***********
 * Modifier: Large centered layout
 */
.m-person--layout-large {
  --person-justify: center;
  --person-align: center;
  --person-border: #{rem-baseline(1)};
  gap: rem-baseline(4) 0;
  grid-template-columns: 1fr;
  text-align: center;

  .m-person__name {
    @include font-h3;
    @include font-bold-important;
    text-decoration: none;
  }
}


/** ***********
 * Image
 */
.m-person__img {
  .c-image img {
    outline: var(--person-border, #{rem-baseline(.5)}) solid var(--color-light);
  }
}



/** ***********
 * Prepended text
 */
.m-person__text {
  @include font-small;
  color: var(--person-secondary-color, var(--color-slate-petrol));

  .c-icon {
    margin-right: rem-calc(4);
  }
}




/** ***********
 * Name
 */
.m-person__name {
  color: var(--person-primary-color);

  a {
    color: currentColor;
  }
}

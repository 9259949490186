// Teaser
//
// Image teaser with caption
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x16      |
// | large         | 465x237    |
//
// .m-teaser--plain - Plain teaser without background color.
//
// Markup: teaser.hbs
//
// Type: module
//
// Style guide: Promotion.Teaser

$teaser-background-color: var(--color-light);
$teaser-link-color: var(--color-dark);

/** **************
 * Basic styles
 */
.m-teaser {
  @include u-link($global-link-options-dark);
  background-color: var(--teaser-background, #{$teaser-background-color});
  display: flex;
  flex-direction: column;
  width: 100%;

  &:hover,
  &:focus {
    img {
      @include u-product-img-hover;
    }
  }

  &:not(.m-teaser--no-margin) {
    @include u-baseline(3, margin-bottom);
  }

  img {
    @include u-img-responsive;
    border-bottom: 1px solid var(--color-pearl);
    height: auto;
    backface-visibility: hidden; // prevents flickering on hover
  }

  // Truncate links
  .c-arrowlink__wrapper {
    display: block;
  }

  &.text--center .c-arrowlink__wrapper {
    display: inline-block;
  }

  .c-arrowlink {
    max-width: 100%;

    span {
      @include text-truncate(ellipsis);
    }
  }

  .c-image {
    flex-shrink: 0;
  }
}




/** ***********
 * Inner container
 */
.m-teaser__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-shrink: 0;
}






/** **************
 * Entry (link etc.)
 */
.m-teaser__entry {
  @include u-baseline(2, padding);
  background-color: var(--teaser-background, #{$teaser-background-color});
  flex-shrink: 0;
}









/** **************
 * Modifier: Element is plain without background color
 */
.m-teaser--plain {
  --teaser-background: transparent;

  .m-teaser__entry {
    @include u-baseline(2, padding-top);
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

// Stepper
//
// Show checkout progress step by step.
//
// Markup: stepper.hbs
//
// Type: module
//
// Style guide: Containers.stepper

$stepper-size: 2em;
$stepper-line-size: rem-calc(2);

.m-stepper {
  --step-size: 1.75em;
  @include font-tiny;
  @include u-baseline(4, padding-top);
  @include u-baseline(4, padding-bottom);
  background-color: var(--color-light);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  position: relative;

  @include breakpoint(medium) {
    --step-size: 2em;
    --step-line-top: var(--step-size);
    --step-line-offset: 2;
    --step-icon-top: calc(var(--step-size) / 2);
    --step-padding: 2.5;
    @include font-small($type: 'display');
  }

  // Dashed line
  &:before {
    border-top: $stepper-line-size dashed var(--color-slush-ice);
    content: '';
    height: $stepper-line-size;
    position: absolute;
    top: calc(var(--step-size) * var(--step-line-offset, 2.25));
    width: 100%;
    z-index: 0;
  }
}





/** ***********
 * Item
 */
.m-stepper__item {
  // @include u-baseline(10, padding-top);
  background-image: linear-gradient(to var(--step-overlay), transparent calc((50% - var(--step-size) / 6)), var(--color-light) 1%);
  color: var(--color-slate-petrol);
  counter-increment: list;
  padding-top: calc(var(--step-size) * var(--step-padding, 2));
  position: relative;
  text-align: center;

  // Circle pond
  &:before {
    background-color: var(--step-bg, var(--color-slush-ice));
    border-radius: 50%;
    color: var(--step-color, var(--color-dark));
    content: var(--step-content, counter(list));
    display: inline-block;
    font-family: var(--font-display);
    font-size: calc(var(--step-size) / 1.5);
    line-height: calc(var(--step-size) * 1.55);
    position: absolute;
    left: calc(50% - calc(var(--step-size) / 1.35));
    top: 0;
    width: calc(var(--step-size) * 1.5);
    height: calc(var(--step-size) * 1.5);
    z-index: 1;
  }

  // Item line
  &:after {
    background-color: var(--step-line-color, var(--step-bg, transparent));
    content: '';
    height: $stepper-line-size;
    position: absolute;
    top: var(--step-line-top, calc(calc(var(--step-size) / 1.25) + #{$stepper-line-size}));
    right: var(--step-line-right, 0);
    left: var(--step-line-left, 0);
  }

  &:first-child {
    --step-overlay: left;
    --step-line-left: 50%;
  }

  &:last-child {
    --step-overlay: right;
    --step-line-right: 50%;
  }

  .c-icon {
    color: var(--step-color, var(--color-dark));
    position: absolute;
    left: calc(50% - calc(var(--step-size) / 2));
    top: var(--step-icon-top, calc(var(--step-size) / 2.5));
    width: var(--step-size);
    height: var(--step-size);
    z-index: 2;
  }
}







/** ***********
 * Modifier: Current item
 */
.m-stepper--is-current {
  --step-bg: var(--color-dark);
  --step-color: var(--color-light);
  color: var(--color-dark);
  font-weight: bold;

  &:first-child:after {
    display: none;
  }

  &:not(:first-child) {
    --step-line-right: 50%;
  }
}






/** ***********
 * Modifier: Done item
 */
.m-stepper--is-done {
  --step-bg: var(--color-petrol);
  --step-color: var(--color-light);

  ~ .m-stepper--is-current {
    --step-line-color: var(--color-petrol);
  }
}




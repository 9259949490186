// Anchors
//
// Alphabetic anchors, converting into dropdown on small devices
//
// Markup: anchors.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Controls.anchors

/** **************
 * Wrapper
 */
.m-anchors {
  @include xy-grid-container;
  @include xy-grid;
  @include u-baseline(2, padding-top);
  display: block;
  background-color: var(--anchors-background, var(--color-light));
  position: sticky;
  top: 0;
  z-index: 10;
}

body.background--pearl {
  --anchors-background: var(--color-pearl);
}

body.background--ice {
  --anchors-background: var(--color-ice);
}





/** **************
 * Inner container
 */
.m-anchors__inner {
  @include u-single-cell;
}




/** **************
 * List
 */
.m-anchors__list {
  @include u-list-reset;
  @include flex;
  @include flex-align(spaced, middle);
  color: var(--color-slush);
  margin-top: 0;
}

// Badge
//
// Simple badge to display on products or inline.
//
// Markup: badge.hbs
//
// Type: component
//
// Style guide: Containers.badge
.c-badge {
  @include u-baseline(3, min-height);
  @include u-baseline(1, padding-left);
  @include u-baseline(2, line-height);
  @include u-baseline(.5, padding-top);
  @include u-baseline(.5, padding-bottom);
  background-color: var(--badge-background, var(--color-dark));
  color: var(--badge-color, var(--color-light));
  display: inline-block;
  font-size: var(--badge-font-size, rem-calc(9));
  letter-spacing: .1rem;
  padding-right: rem-calc(7);
  text-transform: uppercase;
}

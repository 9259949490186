// Breadcrumbs
//
// Note: KSS is converting `:first-child` to `.pseudo-class-first-child`.
// Please ignore the first slash and test this with the templates and Magento.
//
// Markup: breadcrumbs.hbs
//
// Type: module
//
// Style guide: Controls.Breadcrumbs

$breadcrumbs-color: var(--color-slate);
$breadcrumbs-spacer-color: var(--color-slate-petrol);

.m-breadcrumbs {
  @include xy-grid-container;
  @include xy-grid;
  @include u-baseline(3, margin-bottom);
  @include u-baseline(4, min-height); // IE10 likes this
  @include font-small;

  color: $breadcrumbs-color;
  flex: none;
  width: 100%;

  @include breakpoint(large) {
    @include u-baseline(3, padding-top);
  }

  a {
    @include u-link($global-link-options-petrol);
    display: inline-block;
  }
}









/** ***********
 * Inner container
 */
.m-breadcrumbs__inner {
  @include u-single-cell;
}








/** ***********
 * Item
 */
.m-breadcrumbs__item {
  &:before {
    @include u-baseline(1, margin-left);
    @include u-baseline(1, margin-right);
    color: $breadcrumbs-spacer-color;
    content: '/';
    display: inline-block;
  }
}

// Fact-Finder override
ff-breadcrumb-trail-item[type='search'] {
  background-color: transparent;
  font-size: var(--font-16-copy) !important;
  padding-left: 0;
}





/** **************
 * Modifier: Truncate selected items on smaller screens
 */
.m-breadcrumbs--truncate {
  @include breakpoint(small only) {
    display: inline-block;
    padding: 0;
    margin-top: -3px;
    vertical-align: middle;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

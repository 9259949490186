// Autosuggest (Search)
//
// FactFinder autosuggest search layer.
//
// Type: organism
//
// JavaScript: true
//
// Markup: autosuggest.hbs
//
// Style guide: SiteElements.autosuggest

$autosuggest-breakpoint: large;
$autosuggest-background: var(--color-light);
$autosuggest-background-hover: var(--color-pearl);
$autosuggest-color-hover: var(--color-orange);
$autosuggest-shadow: 0 rem-calc(6) 12px rgba(0, 0, 0, .25);

.o-autosuggest {
  @include u-baseline(2, padding-top);
  @include u-baseline(1, padding-bottom);
  @include u-baseline(2, padding-right);
  @include u-baseline(2, padding-left);
  background-color: $autosuggest-background;
  box-shadow: $autosuggest-shadow;
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 19;

  @include breakpoint(xlarge) {
    @include u-baseline(2, padding-right);
    @include u-baseline(2, padding-left);
  }

  ul {
    @include u-list-reset;
    margin: 0;
  }

  [class*='widget__heading'] {
    @include u-baseline(2, margin-bottom);
  }

  .current-search,
  .query {
    font-weight: bold;
  }

  li.selectable-item,
  ff-suggest-item[type='searchTerm'],
  ff-suggest-item[type='category'],
  ff-suggest-item[type='brand'] {
    &:hover,
    &:focus {
      background: $autosuggest-background-hover;
      color: $autosuggest-color-hover;
      cursor: pointer;
    }
  }

  .m-widget {
    max-width: none;
  }
}




/** **************
 * Inner container
 */
.o-autosuggest__inner {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
}




/** **************
 * Column
 */
.o-autosuggest__column {
  @include xy-cell;

  &:first-of-type {
    @include breakpoint($autosuggest-breakpoint) {
      @include xy-cell(4);
    }
  }

  &:last-of-type {
    @include breakpoint($autosuggest-breakpoint) {
      @include xy-cell(8);
    }
  }
}




/** **************
 * Products
 */
.o-autosuggest__products {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);

  .m-widget__heading {
    margin-left: rem-calc(11);
    width: calc(100% - #{rem-calc(22)});
  }
}




/** **************
 * Product
 */
.m-autosuggest-product,
ff-suggest-item[type='productName'] {
  @include xy-cell(6);
  @include u-baseline(2, margin-bottom);
  @include u-baseline(1, padding-bottom);

  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    @include xy-cell(4);
  }

  @include breakpoint(large) {
    @include xy-cell(4);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(3);
  }

  > div {
    flex-shrink: 0;
  }

  .m-autosuggest-product__content {
    flex: 1 0 auto;
    cursor: pointer;
  }

  img {
    @include u-img-responsive;
  }

  &:hover,
  &:focus {
    .m-autosuggest-product__img {
      @include u-product-img-hover;
    }

    .m-autosuggest-product__content {
      text-decoration: underline;
    }
  }
}




/** **************
 * Product image
 */
.m-autosuggest-product__img {
  @include u-baseline(1, margin-bottom);
  @include u-img-border;
  @include u-floating-label($selector: '.c-energy');
  position: relative;
}




/** **************
 * Product vendor
 */
.m-autosuggest-product__vendor {
  @include font-meta($type: 'display');
  @include font-bold-important;
  color: $product-info-vendor-color;
  display: block;
}




/** **************
 * Product name
 */
.m-autosuggest-product__name {
  color: var(--color-slate-petrol);
  display: block;
  flex: 1 0 auto;
}

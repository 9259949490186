// Product info
//
// Display Vendor, name, price and additional infos of
// an product item. Used in [product](section-products.html#kssref-products-product) and [tiny product](section-products.html#kssref-products-tinyproduct).
//
// Markup: product-info.hbs
//
// Type: component
//
// Style guide: Products.product-info

// Colors
$product-info-price-color-new: var(--color-warning);
$product-info-color: var(--color-dark);
$product-info-vendor-color: var(--color-dark);

/** ***********
 * Modifier: Small product (orders overview, RMA)
 */
.m-product--small {
  .c-product-info__vendor {
    @include font-small($type: 'display');
  }

  .c-product-info__title {
    @include font-tiny;
  }
}



/** **************
 * Product name
 */

// Wrapper
.c-product-info__name {
  &:not(:last-child) {
    @include u-baseline(1, padding-bottom);
  }

  &:after {
    content: '\00a0'; /* &nbsp; */
    display: block;
    height: 0;
    visibility: hidden;
  }
}

// Vendor
.c-product-info__vendor {
  @include font-meta($type: 'display');
  @include font-bold-important;
  color: $product-info-vendor-color;
  display: block;
}

// Title
.c-product-info__title {
  color: var(--color-slate-petrol);
  display: block;
}




/** **************
 * Info / prices
 */

// Wrapper
.c-product-info__info {
  position: relative;
  flex: none;

  .c-label {
    position: absolute;
    right: 0;
    top: rem-calc(3);
  }
}


.c-product-info__price {
  font-weight: bold;
}

// Current / new price
.c-product-info__price--new {
  @include u-baseline(1, margin-right);
  color: $product-info-price-color-new;
  font-weight: bold;
}

// Old price
.c-product-info__price--old {
  font-weight: bold;
  text-decoration: line-through;
  white-space: nowrap;
}

// Range slider
//
// Range slider using the VanillaJS plugin [noUiSlider](https://refreshless.com/nouislider/) ([License](http://www.wtfpl.net/about/)).
//
// `data-min` - Minimum value
//
// `data-max` - Maximum value
//
// `data-start` - Initial positions of the slider (Array[first, last])
//
// `data-postfix` - Character to display in text field.
//
// Please be aware of the plugin CSS in `vendor/_nouislider.scss`.
//
// Markup: range.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Controls.Range
@use 'sass:math';
$range-value-spacer-color: var(--color-dark);
$range-value-spacer-width: rem-calc(8);
$range-input-width: rem-calc(120);

/** **************
 * Basic styles
 */
.m-range {
  @include u-baseline(3, margin-bottom);
}




/** **************
 * Values
 */
.m-range__values {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
  @include u-baseline(3, margin-bottom);
  justify-content: space-between;
  position: relative;

  &:after {
    background-color: $range-value-spacer-color;
    content: '';
    position: absolute;
    text-align: center;
    left: calc(50% - #{math.div($range-value-spacer-width, 2)});
    right: 0;
    top: calc(50% - #{rem-calc(1)});
    width: $range-value-spacer-width;
    height: rem-calc(1);
    z-index: 0;
  }
}




/** **************
 * Value
 */
.m-range__value {
  @include u-single-cell($size: 5);
  max-width: $range-input-width;

  input {
    margin-bottom: 0;
    text-align: right;
  }
}

// Tile
//
// Category teaser tile with image and text.
//
// Markup: tile.hbs
//
// Type: module
//
// Style guide: Products.Tile

$tile-color: var(--color-dark);
$tile-color-hover: var(--color-slate);
$tile-background-color: var(--color-light);
$tile-border: $global-border-dotted;

/** ***********
 * Base styles
 */
.m-tile {
  @include font-meta($type: 'display', $narrow: true);
  background-color: $tile-background-color;
  color: $tile-color;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: rem-baseline(2);

  &:hover,
  &:active {
    color: $tile-color-hover;
    box-shadow: var(--shadow-medium);
  }

  img {
    @include u-baseline(1, margin-bottom);
    position: absolute;
    top: 0;
  }

  .c-image {
    background-color: transparent;
  }
}






/** ***********
 * Caption
 */
.m-tile__caption {
  @include u-baseline(3, padding-top);
  @include u-baseline(1, padding-bottom);
  border-top: $tile-border;
  display: block;
}

// Mixin for navbar border
@mixin navbar-border() {
  background-color: $global-header-border-color;
  content: '';
  display: block;
  width: rem-calc(1);
  position: absolute;
  top: rem-calc(4);
  bottom: rem-calc(4);
}

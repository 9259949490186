/// Utiliy mixins
///
/// Table of contents:
///
/// 1. Basics
/// 2. Grid & Container
/// 3. Typography
/// 4. Images & Icons
/// 5. Forms
/// 6. Accordion
/// 7. Helper
@use 'sass:math';
$string-rem: 'rem';

/// ########################################
/// 1. Basics
/// ########################################

// Using margins and paddings based on $global-baseline
// to ensure vertical and horizontal grid
@mixin u-baseline($size: 1, $type: margin) {
  // $calc-size: #{math}.div($size, 16);
  $baseline: $global-baseline * math.div($size, 16);

  #{$type}: #{$baseline}#{$string-rem};
}

// To use baseline inside of CSS property
// @example
//    margin: rem-baseline(3)
@function rem-baseline($size: 1) {
  $calc-size: math.div($size, 16);
  $baseline: #{$global-baseline * ($calc-size)}#{$string-rem};
  @return $baseline;
}



// helper to calculate line-height based
// on baseline grid
//
// IMPORTANT: only used in _fonts.scss
// DO NOT USE this mixins in your CSS!
// USE font() instead.
@mixin u-font-baseline($size, $lineheight) {
  font-size: rem-calc($size);
  line-height: math.div($lineheight, $size);
}











/// ########################################
/// 2. Grid & Container
/// ########################################

// universal bottom margin of modules
@mixin u-module-margin($mode: null) {
  @if $mode == small {
    @include u-baseline(3, margin-bottom);
  } @else if $mode == medium {
    @include u-baseline(6, margin-bottom);
  } @else {
    @include u-baseline(6, margin-bottom);

    @include breakpoint(xlarge) {
      @include u-baseline(10, margin-bottom);
    }
  }
}



// Foundation XY single cell with mandatory breakpoints
// to output the correct aligned container padding.
@mixin u-single-cell($size: 12, $xlarge: false) {
  $xlarge-gutters: rem-calc($global-xlarge-gutter);

  @include xy-cell($size);

  @include breakpoint(medium) {
    @include xy-cell($size);
  }

  @include breakpoint(large) {
    @include xy-cell($size);
  }

  @if $xlarge {
    @include breakpoint(xlarge) {
      @include xy-cell($size, $gutters: $xlarge-gutters);
    }
  }
}



// Foundation XY single cell row (former "grid-column-row")
// with mandatory breakpoints to output the correct aligned
// container padding.
@mixin u-cell-row {
  @include xy-grid-container($padding: 32px);
  @include xy-grid;

  @include breakpoint(large) {
    @include xy-grid-container($padding: 47px);
  }

  // Will be enabled later
  // @include breakpoint(xxlarge) {
  //   @include xy-grid-container($padding: 30px);
  // }
}



// Fix outer padding on xlarge grid gutters
@mixin u-row-padding($negative: false) {
  @include breakpoint(xxlarge) {
    @if $negative {
      margin-left: -1.25rem !important;
      margin-right: -1.25rem !important;
      padding-left: 0;
      padding-right: 0;
    } @else {
      padding-left: .25rem;
      padding-right: .25rem;
    }
  }
}



// Using max() for inner-element max-width
// @see https://css-tricks.com/using-max-for-an-inner-element-max-width/
@mixin u-inner-max($width: auto) {
  padding-left: calc((100% - #{rem-calc($width)}) / 2);
  padding-right: calc((100% - #{rem-calc($width)}) / 2);
}



// Box with dotted border
@mixin u-borderbox() {
  @include u-baseline(2, padding);
  @include u-baseline(2, margin-bottom);
  border: 1px dashed var(--color-petrol);
  border-radius: rem-calc($global-border-radius);
}




// Placeholder mixin for modules not yet styled
@mixin u-placeholder($height: 400) {
  @include flex;
  @include flex-align(center, middle);
  height: rem-calc($height);
  background:
    repeating-linear-gradient(
      45deg,
      var(--color-slush),
      var(--color-slush) 40px,
      var(--color-slush-ice) 40px,
      var(--color-slush-ice) 80px
    );

  > * {
    background-color: var(--color-light);
    box-shadow: var(--shadow-medium);
    padding: rem-calc(6) rem-calc(10);
  }
}




// Modify the items in the last row
// @param {Number} $range
// @param {String} $selector
@mixin u-last-row($range: 2, $selector: '.class') {
  &:nth-last-child(-n+#{$range}):nth-child(#{$range}n+1),
  &:nth-last-child(-n+#{$range}):nth-child(#{$range}n+1) ~ #{$selector} {
    @content;
  }
}







/// ########################################
/// 3. Typography
/// ########################################

// Content links
@mixin u-content-links() {
  a:not(.c-button):not([class*='c-']):not([class*='m-']) {
    @include u-link($global-link-options-underline);
  }
}




// Content formatting
@mixin u-content-formatting($size: '') {
  @include u-content-links;

  h2,
  h3,
  h4 {
    @include u-baseline(2, padding-top);
    @include u-baseline(2, margin-bottom);
    @include font-bold-important;
    margin-top: 0;

    &:first-child {
      padding-top: 0;
    }
  }

  h2 {
    @include font-h2;
  }

  h3,
  h4,
  h5,
  h6 {
    @include font-h3;
  }

  > p {
    margin-top: 0;

    @if $size == 'meta' {
      @include u-baseline(3, margin-bottom);
    } @else {
      @include u-baseline(4, margin-bottom);

      @include breakpoint(xxlarge) {
        @include u-baseline(5, margin-bottom);
      }
    }
  }

  // Apply only if not has a styleguide class
  ul:not([class*='-']) {
    @include c-list('ul');
    @include u-baseline(8, padding-left);
  }

  ol:not([class*='-']) {
    @include c-list('ol');
    @include u-baseline(8, padding-left);
  }

  iframe {
    max-width: 100%;
    width: 100%;
  }
}




// Mixin for link colors
@mixin u-link($link-options: $global-link-options-default) {
  color: map-get($link-options, color);
  cursor: pointer;

  @if map-get($link-options, decoration) {
    text-decoration: map-get($link-options, decoration);
  } @else {
    text-decoration: none;
  }

  &:hover {
    color: map-get($link-options, hover);

    @if map-get($link-options, decoration_hover) {
      text-decoration: map-get($link-options, decoration_hover);
    }
  }
}




// adding ellipsis to element
//
// @param {Number} $max-width – required|fallback
@mixin u-ellipsis($max-width: 200) {
  max-width: rem-calc($max-width);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



// Clamp lines with ellipsis
// @see https://css-tricks.com/almanac/properties/l/line-clamp/
//
// @param {Number} $lines
@mixin u-line-clamp($lines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}




// Set light text color on
// dark sector backgrounds
@mixin u-text-on-dark($selector) {
  .t-sector.background--petrol,
  .t-sector.background--slate-petrol {
    #{$selector} {
      color: var(--color-light);

      a:not(.c-button):not([class*='c-arrowlink']) {
        color: currentColor;

        &:hover {
          color: inherit;
        }
      }
    }
  }
}









@mixin u-number-circle($content: null) {
  $numberlist-size: rem-calc($global-baseline * 5);
  $line-height: ($numberlist-size + .1rem);

  @include font-h4;
  @include u-baseline(-.25, top);
  background: var(--color-slush);
  border-radius: 50%;
  color: var(--color-light);
  content: $content;
  counter-increment: item;
  font-weight: bold;
  left: 0;
  line-height: $line-height;
  position: absolute;
  text-align: center;
  width: $numberlist-size;
  height: $numberlist-size;
  z-index: 1;

  @include breakpoint(large) {
    line-height: $line-height;
  }
}











/// ########################################
/// 4. Images & Icons
/// ########################################

// Image border
@mixin u-img-border() {
  border: 1px solid var(--color-slush-ice);
}

// @deprecated
// @mixin u-img-border-hover() {
//   border-color: var(--color-light);
//   box-shadow: $global-img-shadow;
// }




// setting images to responsive width
// and fluid behaviour
@mixin u-img-responsive() {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: 100%;
}




// Cover image
@mixin u-img-cover() {
  height: 100%;
  width: 100%;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}




// Product image hover
@mixin u-product-img-hover() {
  filter: brightness(105%);
}




// Set SVG icon size relative to font size
@mixin u-icon-relative-size($scale: 1.5) {
  width: #{$scale}em;
  height: #{$scale}em;
}









/// X. Lists
/// ########################################

// removing list styles and box
@mixin u-list-reset() {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li:before {
    display: none;
  }
}




// List group styles
// Used global and used in different components
@mixin u-listgroup() {
  $border-radius: rem-calc(5);

  @include u-list-reset;

  [class*='c-arrowlink'] {
    @include flex;
  }

  li {
    @include font-small($type: 'display');
    background-color: var(--color-light);
    border: 1px solid var(--color-slush-ice);
    margin-bottom: -1px;
    padding: rem-calc(12) rem-baseline(2);

    &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}



// Border list
// Used global and used in different components
@mixin u-borderlist() {
  li {
    @include u-baseline(2, padding-top);
    @include u-baseline(2, padding-bottom);
    @include font-small($type: 'copy');
    border-bottom: $global-border-dotted;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  ul {
    @include u-list-reset;
    @include u-baseline(2, margin-top);
    @include u-baseline(2, padding-top);
    border-top: $global-border-dotted;

    li {
      @include u-baseline(3, padding-left);
    }

    li:last-of-type {
      padding-bottom: 0;
    }
  }
}












/// 5. Forms
/// ########################################

// Prediger themed focus styles
// for rounded block elements.
@mixin u-focus-styles($color: $global-focus-color) {
  box-shadow: 0 0 8px $color;
  border-color: var(--color-light);
  outline: none;
}




// Default form input styles
@mixin u-input-styles($active: false) {
  transition: none !important;

  @if $active {
    background-color: var(--color-geyser);
    box-shadow: var(--shadow-inset);
  } @else {
    background-color: var(--form-input-background, var(--color-light));
    box-shadow: var(--shadow-inset);
    border: 1px solid transparent;
    border-top: none;
    border-radius: var(--form-input-border-radius, #{rem-calc(3)});
  }
}












/// 6. Accordion
/// ########################################

// Accordion panel
//
// Default state (hidden) for accordion panel
@mixin u-accordion-panel() {
  max-height: 0;
  overflow: hidden;
  transition: var(--transition-default);
}




// Accordion tab basics
@mixin u-accordion-tab() {
  @include font-h4;
  @include font-bold;
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);
  cursor: pointer;
  display: block;
  position: relative;

  svg {
    @include u-baseline(3, right);
    color: var(--color-slate-petrol);
    position: absolute;
    top: calc(50% - .5em);
    width: 1em;
    height: 1em;
    transition: var(--transition-default);
    transform: rotate(90deg);
  }

  &[aria-expanded='true'] {
    svg {
      transform: rotate(-90deg);
    }
  }
}











/// 7. Helper
/// ########################################


// helper for smooth transitioned
// visibility toggles
@mixin u-toggle-visibility($mode: 'hide', $zindex: 1) {
  @if $mode == 'hide' {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  @if $mode == 'show' {
    opacity: 1;
    visibility: visible;
    z-index: $zindex;
  }
}




// Full width and height absolute
// or fixed position overlay
@mixin u-full-overlay($position: absolute, $zindex: 5) {
  position: $position;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex;
}




// Element floating absolute on element
// Default: top-right (energy label)
@mixin u-floating-label($selector: '.c-energy') {
  #{$selector} {
    @include u-baseline(1, top);
    position: absolute;
    right: rem-calc(14);
  }
}



// CSS arrow
@mixin u-arrow($size: rem-calc(6), $color: var(--color-dark), $position: 'right', $offset: false, $shadow: false) {
  $shadow-right: 3px -3px 3px 0 $global-flyout-shadow-color-2;
  $shadow-left: -3px 3px 3px 0 $global-flyout-shadow-color-2;
  $shadow-bottom: -3px 3px 4px 0 $global-flyout-shadow-color-1;
  $shadow-top: -3px -3px 4px 0 $global-flyout-shadow-color-2;

  &:after {
    border: $size solid transparent;
    box-sizing: border-box;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;

    @if $position == 'right' {
      border-color: $color $color transparent transparent;
      left: 100%;
      right: auto;
      top: calc(50% - #{$size * 1.5});
      transform: rotate(45deg);
      transform-origin: top left;

      @if $shadow {
        box-shadow: $shadow-right;
      }

      @if $offset == 'top' {
        top: calc(#{rem-calc(16)} + #{math.div($size, 5)});
      }
    }


    @if $position == 'left' {
      border-color: transparent transparent $color $color;
      left: auto;
      right: 100%;
      top: calc(50% - #{math.div($size, 1.5)});
      transform: rotate(45deg);
      transform-origin: bottom right;

      @if $shadow {
        box-shadow: $shadow-left;
      }

      @if $offset == 'top' {
        top: calc(#{rem-calc(16)} + #{$size});
      }
    }

    @if $position == 'bottom' {
      border-color: transparent transparent $color $color;
      top: 100%;
      left: calc(50% - #{$size * 1.4});
      transform: rotate(-45deg);
      transform-origin: top left;

      @if $shadow {
        box-shadow: $shadow-bottom;
      }
    }

    @if $position == 'top' {
      border-color: $color transparent transparent $color;
      bottom: 100%;
      left: calc(50% - #{$size * 1.4});
      transform: rotate(45deg);
      transform-origin: bottom left;

      @if $shadow {
        box-shadow: $shadow-top;
      }
    }
  }
}





// Arrow on circle pond as used in arrowlink
//
// @param {String} $background | optional/fallback
// @param {String} $color | optional/fallback
@mixin u-arrow-circle($background: var(--color-slush), $color: var(--color-light)) {
  @include u-icon-relative-size;
  background-color: $background;
  background-image: $global-icon-arrow-white;
  background-repeat: no-repeat;
  background-position: calc(50% + .05em) 50%;
  background-size: 50% 50%;
  border-radius: 50%;
  color: $color;
  content: '';
  display: inline-block;
  flex: none;
  line-height: 1.5;
  text-align: center;
  padding: rem-calc(4) 0 rem-calc(4) rem-calc(2);
}



// Simple dark arrow
@mixin u-arrow-simple() {
  @include u-icon-relative-size(1);
  background-image: $global-icon-arrow-dark;
  background-size: 100% 100%;
  content: '';
  display: inline-block;
  flex: none;
}



// Faux heading with normal font styles
@mixin u-faux-heading() {
  font-size: 1em;
  margin: 0;
}









// Override link styles in .m-entry
@mixin u-entry-link-override() {
  a:not(.c-button):not([class*='c-arrowlink']) {
    @content;
  }
}




// Make entire container clickable
// when link contains this properties
@mixin u-clickable-container() {
  &::after {
    content: '';
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:hover::after {
    background-color: transparent;
  }
}







// Soft box-shadow
// @deprecated
// @mixin u-soft-shadow($intensity: .08) {
//   box-shadow: 0 1px 1px rgba(0, 0, 0, $intensity),
//               0 2px 2px rgba(0, 0, 0, $intensity),
//               0 4px 4px rgba(0, 0, 0, $intensity),
//               0 8px 8px rgba(0, 0, 0, $intensity),
//               0 16px 16px rgba(0, 0, 0, $intensity);
// }




// Vendor prefixed keyframes
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

// Piglets in a row
//

.o-piglets {
  @include breakpoint(large) {
    display: grid;
    grid-auto-columns: auto;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    @include u-baseline(5, gap);
  }

  .m-piglet {
    &:not(:last-child) {
      @include u-baseline(5, margin-bottom);

      @include breakpoint(large) {
        margin-bottom: 0;
      }
    }
  }
}

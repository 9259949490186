// Radiocheck
//
// Radio and checkbox for mobile menus. This component can be both: radio button or checkbox.
//
// Weight: 3
//
// Markup: forms-radiocheck.hbs
//
// Type: component
//
// Style guide: Forms.Radiocheck

$forms-radiocheck-border-radius: rem-calc($global-border-radius);
$forms-radiocheck-border: 1px solid var(--color-slush-slate);
$forms-radiocheck-color-active: var(--color-petrol);
$forms-radiocheck-size: rem-calc(18);
$forms-radiocheck-padding: $forms-radiocheck-size + (rem-calc($global-baseline));

.c-radiocheck {
  font-weight: normal;
  position: relative;

  label {
    display: block;
  }

  [type='checkbox'],
  [type='radio'] {
    @include element-invisible;

    // Styles for checked input
    &:checked {
      ~ .c-radiocheck__value {
        font-weight: bold;
      }

      ~ .c-radiocheck__value:after {
        background-image: $global-icon-checkmark;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 50% 50%;
      }
    }
  }
}




/** **************
 * Value
 */
.c-radiocheck__value {
  display: block;
  padding-right: $forms-radiocheck-padding;
  position: relative;

  &:after {
    @include u-baseline(1, margin-right);
    content: '';
    display: inline-block;
    width: $forms-radiocheck-size;
    height: $forms-radiocheck-size;
    position: absolute;
    right: 0;
    top: rem-calc(3);
    vertical-align: middle;
  }
}

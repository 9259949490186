/** ***********
 * Survey widget on success page
 *
 * Could be a module someday, could be not.
 */
.shm-survey {
  @include breakpoint(large) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.shm-survey__img {
  float: right;
  max-width: rem-calc(140);
  width: 100%;

  @include breakpoint(large) {
    @include u-baseline(2, margin-bottom);
    @include u-baseline(3, margin-left);
    flex: 0 0 rem-calc(250);
    float: none;
    max-width: rem-calc(250);
    order: 2;
  }
}

.shm-survey__content {
  @include breakpoint(large) {
    flex: 1;
    order: 1;
  }
}

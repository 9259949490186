// Form submit
//
// Submit buttons, reset buttons and other form related elements.
//
// Markup: form-submit.hbs
//
// Type: organism
//
// Style guide: Forms.form-submit

$form-submit-breakpoint: large;

.o-form-submit {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include flex-align(center, middle);
  @include u-baseline(4, padding-top);
  @include u-baseline(1, margin-bottom);
  flex-wrap: wrap-reverse;
  width: 100%;

  @include breakpoint($form-submit-breakpoint) {
    @include flex-align(left, middle);
    margin-right: 0;
    max-width: 100%;
  }
}





/** **************
 * Submit item
 */
.o-form-submit__item {
  @include u-baseline(2, margin-bottom);

  @include breakpoint($form-submit-breakpoint) {
    @include xy-cell($output: (base size));
    flex: 0 1 50%;
    margin-bottom: 0;
  }

  &:first-child:last-child {
    text-align: right;

    @include breakpoint($form-submit-breakpoint) {
      flex: 0 1 100%;
    }
  }

  &:last-child {
    @include xy-cell($output: (base size));
    @include u-baseline(6, margin-bottom);
    text-align: center;

    @include breakpoint($form-submit-breakpoint) {
      flex: 0 1 50%;
      margin-bottom: 0;
      text-align: right;
    }

    .c-button {
      &:last-child:not(:first-child) {
        @include u-baseline(2, margin-left);
      }
    }
  }
}




/** **************
 * Additional note
 */
.o-form-submit__note {
  @include u-baseline(1, padding-top);
  color: var(--color-iron);
  text-align: right;
  flex: 0;
  width: 100%;
}

// Wishlists overview
//

.t-wishlists {
  .dataTables_filter {
    display: none;
  }
}






/** ***********
 * Header
 */
.t-wishlists__header {
  @include xy-grid-container;
  @include xy-grid;
  @include flex-align(left, middle);
  @include u-baseline(2, margin-bottom);

  > div {
    @include xy-cell;
    @include u-baseline(2, margin-bottom);
    text-align: right;

    @include breakpoint(large) {
      @include xy-cell(6);
      text-align: left;
    }
  }
}






/** ***********
 * Body
 */
.t-wishlists__body {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;
}









/** ***********
 * Inner container
 */
.t-wishlists__inner {
  @include u-single-cell;
}

// Pill
//
// Rounded link with arrow inside.
//
// Markup: pill.hbs
//
// .c-pill--is-anchor - Anchor link pill with down arrow.
//
// Type: component
//
// Style guide: Controls.pills.pill

/** **************
 * Outer wrapper
 */
.c-pill {
  @include u-baseline(2, margin-right);
  @include u-baseline(2, margin-bottom);
  background-color: var(--color-light);
  border: 1px solid var(--color-slush);
  border-radius: rem-calc(100);
  display: inline-block;
  padding: rem-calc(6) rem-calc(20) rem-calc(6) rem-calc(7);

  &:hover,
  &:focus {
    border-color: var(--color-petrol);
    box-shadow: var(--shadow-light);

    .c-pill__inner {
      color: var(--color-dark);

      &:before {
        background-color: var(--color-petrol);
      }
    }
  }
}




/** **************
 * Inner container
 */
.c-pill__inner {
  @include c-arrowlink($arrowlink-options);
  width: auto;

  @include breakpoint(xlarge) {
    line-height: 1.5;
  }

  &:before {
    @include u-baseline(1, margin-right);
    transform: translateY(0);
  }

  span:last-child {
    width: calc(100% - 1.5rem);
    white-space: nowrap;
  }
}







/** ***********
 * Modifier: Pill has down arrow
 */
.c-pill--is-anchor {
  .c-pill__inner {
    &:before {
      transform: rotate(90deg);
    }
  }
}

// Removable link
//
// Link with remove icon, used for i.e. filters.
//
// Markup: removelink.hbs
//
// Type: component
//
// Style guide: Controls.links.Removelink

.c-removelink {
  @include u-link($global-link-options-petrol);
  display: inline-block;
  position: relative;

  svg {
    margin-left: rem-calc(5);
    vertical-align: middle;
    width: 1em;
    height: 1em;
  }
}

// Pin
//
// Pin bubble for [Pinboard](section-containers.html#kssref-containers-pinboard) module.
//
// Markup: pin.hbs
//
// .m-pin--light - Light colored pin
// .m-pin--is-active - Active styles
// .m-pin--is-selected - Pin is selected via Accordion
// .m-pin--hide-no - Hide number, show white dot instead
//
// Type: module
//
// Style guide: Containers.pinboard.pin
// @TODO: Use CSS vars

@import '_config';

.m-pin {
  position: absolute;
}






/** ***********
 * Modifier: Pin has light font color
 */
.m-pin--light {
  --pin-color: #{$pin-color-light};
}






/** ***********
 * Modifier: Pin is active
 */
.m-pin--is-active {
  --pin-bg: #{$pin-background-active};
  --pin-color: #{$pin-color-active};
  z-index: 2;

  .m-pin__no {
    pointer-events: none;
  }

  .m-pin__flyout {
    display: block;
  }
}


/** ***********
 * Modifier: Pin is selected (by accordion)
 */
.m-pin--is-selected {
  --pin-bg: #{$pin-background-active};
  --pin-color: #{$pin-color-active};
}






/** ***********
 * Modifier: Hide number and display
 * a white dot instead
 */
.m-pin--hide-no {
  .m-pin__no {
    &:after {
      @include u-baseline(2, width);
      @include u-baseline(2, height);
      background-color: var(--color-light);
      border-radius: 50%;
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - #{rem-baseline(1)});
      left: calc(50% - #{rem-baseline(1)});
    }
  }
}







/** ***********
 * Modifier: Set arrow positions
 */
.m-pin--arrow-left {
  @include u-arrow($size: $pin-arrow-size, $position: 'left', $color: $pin-arrow-color);

  &:after {
    right: calc(100% - 1px);
  }
}

.m-pin--arrow-right {
  @include u-arrow($size: $pin-arrow-size, $position: 'right', $color: $pin-arrow-color);

  &:after {
    left: calc(100% - 1px);
  }
}

.m-pin--arrow-bottom {
  @include u-arrow($size: $pin-arrow-size, $position: 'bottom', $color: $pin-arrow-color);

  &:after {
    top: calc(100% - 1px);
  }
}





/** ***********
 * Number
 */
.m-pin__no {
  @include font-h3;
  @include font-bold-important;
  animation: pin-pulse 6s infinite;
  background-color: var(--pin-bg, #{$pin-background-default});
  border: rem-calc(3) solid $pin-border-color;
  border-radius: 50%;
  box-shadow: 0 2px 8px $pin-shadow-color;
  color: var(--pin-color);
  cursor: pointer;
  height: $pin-size-small;
  line-height: ($pin-size-small - ($pin-border-size-small * 1.5));
  text-align: center;
  position: relative;
  transform: scale(1);
  width: $pin-size-small;

  &:hover {
    --pin-bg: #{$pin-background-active};
    --pin-color: #{$pin-color-active};
    transform: scale(1.1);
  }

  // Pulsating burst
  &:before {
    animation: pin-burst 6s infinite;
    border-radius: 50%;
    box-shadow: 0 2px 8px $pin-shadow-color;
    content: '';
    display: block;
    transition: var(--transition-default);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  @include breakpoint(large) {
    border-width: rem-calc(6);
    height: $pin-size-large;
    font-weight: bold;
    line-height: ($pin-size-large - ($pin-border-size-large * 1.5));
    width: $pin-size-large;
  }
}







/** ***********
 * Flyout
 */
.m-pin__flyout {
  @include u-baseline(1, padding-top);
  @include u-baseline(2, padding-right);
  @include u-baseline(1, padding-bottom);
  @include u-baseline(2, padding-left);
  background-color: $pin-flyout-background;
  box-shadow: var(--shadow-popup);
  display: none;
  position: absolute;
  width: $pin-flyout-width-small;

  @include breakpoint(large) {
    @include u-baseline(2, padding);
    @include u-baseline(3, padding-left);
    @include u-baseline(3, padding-right);
    width: $pin-flyout-width-large;
  }

  // @TODO: Use new arrow mixin
  // &:after {
  //   @include u-baseline(-1, margin-top);
  //   top: 50%;
  //   border: solid transparent;
  //   @include u-baseline(1, border-width);
  //   content: ' ';
  //   height: 0;
  //   width: 0;
  //   position: absolute;
  //   pointer-events: none;
  //   border-color: none;
  // }


  p {
    @include u-baseline(1, margin-bottom);
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}








/** ***********
 * Title
 */
.m-pin__title {
  @include font-h4;
  @include font-bold-important;
  @include u-baseline(1, margin-bottom);
  display: block;
}




// Pin (static)
//
// Pin bubble for [Pinboard](section-containers.html#kssref-containers-pinboard) module, but without animation, content and flyout.
//
// Markup: pin--is-static.hbs
//
// Type: module
//
// Style guide: Containers.pinboard.pin_static
.m-pin--is-static {
  --pin-bg: #{$pin-background-active};
  position: relative;

  .m-pin__no {
    animation: none;
    cursor: default;
    opacity: 1;
    position: relative;

    &:before {
      animation: none;
      box-shadow: none;
    }

    &:hover {
      transform: scale(1);
    }
  }
}








// Pin (secondary)
//
// Smaller pin with `slate` color, combined with static pin.
//
// Markup: pin--is-secondary.hbs
//
// Type: module
//
// Style guide: Containers.pinboard.pin_secondary
.m-pin--is-secondary {
  .m-pin__no {
    background-color: $pin-background-secondary;
    border-width: rem-calc(3);
    width: $pin-size-secondary;
    height: $pin-size-secondary;
  }
}

// User menu
//
// Drop down menu in header with user links and actions.
//
// Markup: usermenu.hbs
//
// .modifier - Modifier description
//
// Type: module
//
// Style guide: Containers.usermenu

$usermenu-breakpoint: medium;

.m-usermenu {
  @include u-baseline(6, padding-top);
  background-color: var(--color-light);
  box-shadow: var(--shadow-heavy);
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  opacity: 1;
  top: 0;
  text-align: left;
  transition: var(--transition-smooth);
  visibility: visible;
  z-index: 101;

  @include breakpoint($usermenu-breakpoint) {
    padding-top: 0;
    transition: none;
  }

  @include breakpoint($usermenu-breakpoint) {
    @include u-arrow($position: 'top', $color: var(--color-light));
    @include u-baseline(-2, right);
    position: absolute;
    left: auto;
    top: calc(100% + #{rem-baseline(1.5)});
    height: auto;
    width: rem-calc(280);

    &:after {
      @include u-baseline(3, right);
      left: auto;
    }
  }

  &[data-hidden] {
    opacity: 0;
    top: 100%;
    visibility: hidden;

    @include breakpoint($usermenu-breakpoint) {
      display: none;
    }
  }
}





/** ***********
 * Body
 */
.m-usermenu__body {
  @include u-baseline(2, padding);

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-slush-ice);
  }
}






/** ***********
 * Menu list
 */
.m-usermenu__list {
  @include u-list-reset;

  > li {
    display: block;

    &:not(:last-child) {
      @include u-baseline(2, margin-bottom);
    }

    &:last-child {
      @include u-baseline(1, margin-bottom);
    }
  }
}




/** ***********
 * Close icon
 */
.m-usermenu__close {
  @include u-baseline(2, top);
  @include u-baseline(2, right);
  position: absolute;

  .c-icon {
    @include u-baseline(3, width);
    @include u-baseline(3, height);
  }
}

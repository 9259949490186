/** **************
 * Wrapper
 */
.o-filternav {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
  @include flex-align(right, top);
  @include u-module-margin(small);
}




/** **************
 * Item
 */
.o-filternav__item {
  @include u-single-cell($size: 6);
}

// Sector
//
// New PageBuilder module row wrapper. Includes optional
// grid layouts, but can also used as a simple wrapper.
// A combination of .t-section and .t-row.
// See [layouts](/layouts.html) for examples.
//
// Chromeless: true
//
// Type: template
//
// Style guide: SiteElements.sector
@import '_config';

.t-sector {
  position: relative;
  width: 100%;
}






/** ***********
 * Modifier: Inner paddings
 */
.t-sector--spacing-small {
  @include u-baseline(5, padding-top);
  @include u-baseline(5, padding-bottom);

  @include breakpoint(large) {
    @include u-baseline(7, padding-top);
    @include u-baseline(7, padding-bottom);
  }
}

.t-sector--spacing-large {
  @include u-baseline(7, padding-top);
  @include u-baseline(7, padding-bottom);

  @include breakpoint(large) {
    @include u-baseline(12, padding-top);
    @include u-baseline(12, padding-bottom);
  }
}





/** ***********
 * Modifier: Bottom margins
 */
.t-sector--margin-small {
  @include u-baseline(5, margin-bottom);

  @include breakpoint(large) {
    @include u-baseline(7, margin-bottom);
  }
}

.t-sector--margin-large {
  @include u-baseline(7, margin-bottom);

  @include breakpoint(large) {
    @include u-baseline(12, margin-bottom);
  }
}








/** ***********
 * Grid row
 */
.t-sector__row {
  @include xy-grid-container;
  @include xy-grid;
  @include flex-align(center, top);
  @include u-row-padding;

  @include breakpoint(medium) {
    align-items: initial;
  }

  &.t-sector--align-left {
    @include flex-align(left, top);
    align-items: initial; // equal height bugfix
  }
}







/** ***********
 * Grid column
 */
.t-sector__col {
  @include u-single-cell;

  @include breakpoint(xxlarge) {
    @include xy-cell($gutters: $sector-xlarge-gutters);
  }

  &:last-child {
    margin-bottom: 0;
  }

  .m-teaser,
  .m-widget:last-child {
    margin-bottom: 0;
  }
}






/** ***********
 * Modifier: Single narrow centered
 */
.t-sector--single-narrow {
  @include flex-align(center, top);

  .t-sector__col {
    @include breakpoint(xlarge) {
      @include xy-cell(8);
    }
  }
}





/** ***********
 * Modifier: Single wide centered
 */
.t-sector--single-wide {
  @include flex-align(center, top);

  .t-sector__col {
    @include breakpoint(xlarge) {
      @include xy-cell(10);
    }
  }
}




/** ***********
 * Modifier: Stretched layout
 */
.t-sector--stretch {
  @include xy-grid-container($width: $global-max-width * 1.275);
}





/** ***********
 * Modifier: Base content layout w/ sidebar
 */
.t-sector--content {
  .t-sector__col:first-child {
    @include u-baseline(4, margin-bottom);

    @include breakpoint(xlarge) {
      @include xy-cell(7);
      margin-bottom: 0;
    }

    @include breakpoint(xxlarge) {
      @include xy-cell(7, $gutters: $sector-xlarge-gutters);
    }
  }

  .t-sector__col:last-child {
    @include breakpoint(xlarge) {
      @include xy-cell(4);
      @include xy-cell-offset(1);
    }

    @include breakpoint(xxlarge) {
      @include xy-cell(4, $gutters: $sector-xlarge-gutters);
      @include xy-cell-offset(1);
    }
  }
}





/** ***********
 * Modifier: Listing content with left sidebar
 */
.t-sector--listing {
  .t-sector__col:first-child {
    @include u-baseline(4, margin-bottom);

    @include breakpoint(xlarge) {
      @include xy-cell(3, $gutters: $sector-xlarge-gutters);
      margin-bottom: 0;
    }
  }

  .t-sector__col:last-child {
    @include breakpoint(xlarge) {
      @include xy-cell(9, $gutters: $sector-xlarge-gutters);
    }
  }
}






/** ***********
 * Modifier: 8/4 layout
 */
.t-sector--8-4 {
  .t-sector__col:first-child {
    @include u-baseline(4, margin-bottom);

    @include breakpoint(large) {
      @include xy-cell(8);
      margin-bottom: 0;
    }

    @include breakpoint(xxlarge) {
      @include xy-cell(8, $gutters: $sector-xlarge-gutters);
    }
  }

  .t-sector__col:last-child {
    @include breakpoint(large) {
      @include xy-cell(4);
    }

    @include breakpoint(xxlarge) {
      @include xy-cell(4, $gutters: $sector-xlarge-gutters);
    }
  }
}




/** ***********
 * Modifier: Double col layout
 */
.t-sector--double {
  .t-sector__col {
    @include u-baseline(3, margin-bottom);

    @include breakpoint(large) {
      @include xy-cell(6);

      @include u-last-row(2, $sector-col-selector) {
        margin-bottom: 0;
      }
    }

    @include breakpoint(xxlarge) {
      @include xy-cell(6, $gutters: $sector-xlarge-gutters);
      @include u-baseline(7, margin-bottom);
    }
  }

  // Don't remove bottom margin of
  // items if unwrapped class is applied.
  // Use this class for tabs that hide HTML
  // elements and interfer the nth-child count.
  &:not(.t-sector--unwrapped) .t-sector__col {
    @include breakpoint(xxlarge) {
      @include u-last-row(2, $sector-col-selector) {
        margin-bottom: 0;
      }
    }
  }
}







/** ***********
 * Modifier: 3-col layout
 */
.t-sector--3-col {
  .t-sector__col {
    @include u-baseline(5, margin-bottom);

    @include breakpoint(medium) {
      @include xy-cell(6);
    }

    @include breakpoint(xlarge) {
      @include xy-cell(4);
    }

    @include breakpoint(xxlarge) {
      @include xy-cell(4, $gutters: $sector-xlarge-gutters);
    }

    // Only 3 items
  }

  &:not(.t-sector--align-left) {
    .t-sector__col:first-child:nth-last-child(3),
    .t-sector__col:first-child:nth-last-child(3) ~ .t-sector__col {
      &:last-child {
        margin-bottom: 0;
        margin-top: 0;

        @include breakpoint(medium) {
          @include u-baseline(5, margin-bottom);
        }
      }

      @include breakpoint(medium) {
        @include xy-cell(10);
        @include u-baseline(5, margin-bottom);
      }

      @include breakpoint(large) {
        @include xy-cell(4);
      }

      @include breakpoint(xlarge) {
        @include xy-cell(4, $gutters: $sector-xlarge-gutters);
      }
    }
  }
}




/** ***********
 * Modifier: 4-col layout
 */
.t-sector--4-col {
  .t-sector__col {
    @include u-baseline(3, margin-bottom);

    @include breakpoint(medium) {
      @include xy-cell(6);
    }

    @include breakpoint(large) {
      @include xy-cell(6);
    }

    @include breakpoint(xxlarge) {
      @include xy-cell(3, $gutters: $sector-xlarge-gutters);
      @include u-baseline(7, margin-bottom);
    }
  }

  // Don't remove bottom margin of
  // items if unwrapped class is applied.
  // Use this class for tabs that hide HTML
  // elements and interfer the nth-child count.
  &:not(.t-sector--unwrapped) .t-sector__col {
    @include breakpoint(medium) {
      @include u-last-row(2, $sector-col-selector) {
        margin-bottom: 0;
      }
    }

    @include breakpoint(xxlarge) {
      @include u-last-row(4, $sector-col-selector) {
        margin-bottom: 0;
      }
    }
  }
}

// Button group
//
// A group of inline-block buttons. Use this partial NOT AS AN INCLUDE!
//
// Markup: button-group.hbs
//
// Type: organism
//
// Style guide: Controls.button-group
// TODO: use CSS vars (button)

.o-button-group {
  .c-button {
    @include u-baseline(2, margin-bottom);
    display: block;
    width: 100%;

    &:not(.c-button--block) {
      @include breakpoint(medium) {
        @include u-baseline(2, margin-right);
        display: inline-block;
        width: auto;
      }
    }
  }
}



/** **************
 * Modifier: Right aligned
 */
.o-button-group--right {
  text-align: right;

  .c-button {
    &:not(.c-button--block) {
      @include breakpoint(medium) {
        @include u-baseline(2, margin-left);
        margin-right: 0;
      }
    }
  }
}






/** ***********
 * Modifier: Reverse order on small breakpoint
 */
.o-button-group--reverse {
  display: flex;
  flex-wrap: wrap-reverse;

  @include breakpoint(medium) {
    display: block;
  }


  .c-button:last-child:not(:first-child) {
    @include breakpoint(medium down) {
      margin-left: 0 !important;
    }
  }

  .o-button-group__item {
    @include u-baseline(1, margin-bottom);
    flex: 0 1 auto;
    max-width: 100%;
    text-align: center;
    width: 100%;
  }
}








/** ***********
 * Modifier: Group as a 6 grid
 */
.o-button-group--grid {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);

  .o-button-group__item {
    @include xy-cell;
    margin-right: 0;

    @include breakpoint(medium) {
      @include u-single-cell(6);
    }
  }
}

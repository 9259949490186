// Colors
$navbar-color: var(--color-light);
$navbar-background-small: var(--color-light);
$navbar-border-color: var(--color-slush-ice);
$navbar-count-active-color: var(--color-orange);
$navbar-overlay-label-background: rgba(254, 254, 254, .6);



// Fonts
$navbar-menu-font: font(base, 'display');




// Sizes
$navbar-padding: rem-calc(10) rem-baseline(2) rem-baseline(1.5) rem-calc(40);
$navbar-padding-large: rem-calc(10) rem-calc(40) rem-baseline(1.5) rem-baseline(2);
$navbar-padding-link: rem-calc(10) rem-baseline(3);




// Toggle
$navbar-toggle-color: var(--color-light);
$navbar-toggle-width: 35px;
$navbar-toggle-height: 35px;
$navbar-toggle-stripe-size: 1px;
$navbar-handler-color: var(--color-light);



// Arrows
$navbar-arrow-color: var(--color-dark);
$navbar-arrow-size: rem-calc(15);

// Video
//
// Markup: video.hbs
//
// .c-video--format-portrait - Portrait format video
//
// Type: component
//
// Style guide: Media.video

.c-video {
  aspect-ratio: var(--video-ratio, 16 / 9);
  margin-bottom: 0;
  position: relative;
  width: 100%;
  height: 100%;
}

.c-video__wrapper {
  text-align: center;
}



.c-video--format-portrait {
  @include breakpoint(large) {
    @include u-baseline(4, gap);
    display: grid;
    grid-auto-columns: auto;
    grid-template-columns: var(--video-grid, .5fr .5fr);
    grid-template-rows: 1fr;
    align-items: flex-end;
    justify-content: var(--video-justify-content, center);
    justify-items: var(--video-justify-items);

    .c-video {
      max-width: rem-calc(600);
    }

    .c-video__caption {
      @include u-baseline(4, padding-bottom);
    }
  }
}



/** **************
 * Video caption, based on wp-caption-text
 */
.c-video__caption {
  @include font-small; // override
  @include u-baseline(2, margin-top);
  @include u-baseline(2, margin-bottom);
  color: var(--color-slate-petrol);
  display: inline-block;
  max-width: 100%;
  text-align: left;
}

// Password Strength Indicator – DEMO ONLY
//
// Shows password strength inline via Magento JS module. DEMO ONLY! DO NOT INCLUDE THIS PARTIAL!
//
// Weight: 3
//
// Markup: forms-password-strength.hbs
//
// Type: component
//
// Style guide: Forms.PasswordStrength


.c-pwstrength {
  @include u-baseline(2, margin-bottom);

  > div {
    @include flex;
    @include flex-align(left, middle);
  }
}











/** ***********
 * Meter base styles
 */
.c-pwstrength__meter {
  font-size: 0;
  flex-grow: 1;

  span {
    @include u-baseline(1, margin-right);
    @include u-baseline(1, height);
    @include u-baseline(5, border-radius);
    background-color: var(--pwstrength-color, var(--color-ice));
    display: inline-block;
    width: calc(25% - #{rem-baseline(1)});
  }
}





/** ***********
 * States
 */
.password-weak {
  span:nth-child(1) {
    --pwstrength-color: var(--color-warning);
  }
}

.password-medium {
  span:nth-child(1),
  span:nth-child(2) {
    --pwstrength-color: var(--color-info);
  }
}

.password-strong {
  span:nth-child(1),
  span:nth-child(2),
  span:nth-child(3) {
    --pwstrength-color: var(--color-success-medium);
  }
}

.password-very-strong {
  span {
    --pwstrength-color: var(--color-success);
  }

  .c-pwstrength__icon {
    display: inline-block;
  }
}











/** ***********
 * Label
 */
.c-pwstrength__label {
  display: inline-block;
  flex: 0 0 rem-baseline(12);
  position: relative;
  text-align: right;

  @include breakpoint(medium) {
    flex: 0 0 rem-calc(130);
  }

  > div:first-child {
    display: inline-block;
  }
}






.c-pwstrength__icon {
  @include u-baseline(.5, margin-left);
  background-color: var(--color-success);
  border-radius: 50%;
  color: var(--color-light);
  display: none;
  font-size: 0;
  width: rem-calc(12);
  height: rem-calc(12);
  padding: rem-calc(2);
  text-align: center;
  transform: translateY(-1px);
  vertical-align: middle;

  svg {
    width: .5rem;
    height: .5rem;
  }
}

// Colors
$arrowlink-icon-background: var(--color-slush);
$arrowlink-icon-color: var(--color-light);
$arrowlink-active-color: var(--color-dark);
$arrowlink-icon-margin: rem-calc($global-baseline);


// Options for mixin
$arrowlink-options: (
  iconColor: $arrowlink-icon-background,
  iconColorHover: var(--color-orange),
  linkType: $global-link-options-dark
);

$arrowlink-options-petrol: (
  iconColor: $arrowlink-icon-background,
  iconColorHover: var(--color-orange),
  linkType: $global-link-options-default
);

$arrowlink-options-orange: (
  iconColor: var(--color-orange),
  iconColorHover: var(--color-dark),
  linkType: $global-link-options-dark
);

$arrowlink-options-after: (
  iconColor: $arrowlink-icon-background,
  iconColorHover: var(--color-orange),
  linkType: $global-link-options-dark,
  position: 'after'
);

$arrowlink-options-left: (
  iconColor: $arrowlink-icon-background,
  iconColorHover: var(--color-orange),
  linkType: $global-link-options-dark,
  direction: 'left'
);

$arrowlink-options-next: (
  iconColor: var(--color-light),
  iconColorHover: var(--color-light),
  iconType: 'orange',
  linkType: $global-link-options-dark,
  position: 'after'
);

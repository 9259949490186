// Link list
//
// Inline list of links with spacer.
//
// Markup: linklist.hbs
//
// Type: component
//
// Style guide: Controls.links.linklist

.c-linklist {
  @include u-list-reset;
  @include u-baseline(2, padding-top);
  text-align: right;
  margin-top: 0;

  li {
    display: inline;

    &:after {
      @include u-baseline(1, margin-right);
      content: '|';
      color: var(--color-slate);
    }

    &:last-child:after {
      display: none;
    }
  }

  a {
    @include u-link($global-link-options-petrol);
    @include u-baseline(1, margin-top);
    @include u-baseline(1, margin-right);
    display: inline-block;
  }
}

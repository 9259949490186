// Global base styles
//
//
//

/** **************
 * Basic styles
 */
html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}


body {
  font-family: font(base, 'body');
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  width: 100%;

  /**
   * Add breakpoint values to <body> and parse them
   * to js function (breakpoints.js).
   *
   * Source: https://www.lullabot.com/blog/article/importing-css-breakpoints-javascript
   */
  &:before {
    content: 'small';
    display: none;

    @include breakpoint(medium) {
      content: 'medium';
    }

    @include breakpoint(large) {
      content: 'large';
    }

    @include breakpoint(xlarge) {
      content: 'xlarge';
    }

    @include breakpoint(xxlarge) {
      content: 'xxlarge';
    }

    @include breakpoint(xxxlarge) {
      content: 'xxxlarge';
    }
  }

  &.overflow-hidden {
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &:has(.noscroll) {
    overflow-y: hidden;
  }
}




/** **************
 * Anchor links
 */
a {
  @include u-link($global-link-options-default);
}




/** **************
 * Label
 */
label {
  cursor: pointer;
  font-weight: normal;
}




/** **************
 * Small text
 */
small {
  @include font-small; // override
}




/** **************
 * Images
 */
figure {
  margin: 0;
}

img {
  max-width: 100%;
}

svg {
  @include u-baseline(3, width);
  @include u-baseline(3, height);
}




/** **************
 * iframe
 */
iframe {
  border: none;
}



/** **************
 * Horizontal rule
 */
.hr {
  @include u-baseline(4, margin-bottom);
  @include u-baseline(2, margin-top);
  flex: 1;
  border: none;
  border-top: $global-border-dotted;
}




/** **************
 * Sticky footer
 *
 * @see https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
 */
.global-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  position: relative;
  z-index: 2;

  @include breakpoint($global-navigation-breakpoint) {
    z-index: 0;
  }

  .global-content {
    flex: 1 0 auto;

    &:after {
      content: '\00a0'; /* &nbsp; */
      display: block;
      height: 0;
      visibility: hidden;
    }
  }
}

.m-navbar,
.m-offcanvas,
.o-footer {
  flex: none;
}


// Foundation interchange class
//
// @see http://foundation.zurb.com/sites/docs/interchange.html
.foundation-mq {
  font-family: 'small=0&medium=32.5em&large=48.0625em&xlarge=64em&xxlarge=90em&xxxlarge=116.25em';
}

.hide-until-loaded {
  display: none;
}


/** ***********
 * Stop animations and transitions
 * on window resize
 */
.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}



/** ***********
 * Hide all geoloc elements, except for online-store (default)
 */
[data-geoloc]:not([data-geoloc~='online-store']) {
  display: none;
}

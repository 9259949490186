// Tab
//
// Tab style checkbox or radio. Both types work.
//
// Markup: forms-tab.hbs
//
// Weight: 5
//
// Type: component
//
// Style guide: Forms.Tab
@use 'sass:math';

.c-formtab {
  @include u-baseline(1, margin-top);
  @include u-baseline(2, margin-right);
  @include u-baseline(2, margin-bottom);
  display: inline-block;

  input {
    @include element-invisible;

    &:checked ~ .c-formtab__value {
      box-shadow: var(--shadow-medium);
      background-color: $formtab-background-active;
      border-color: $formtab-border-active;

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }

    &:focus ~ .c-formtab__value {
      box-shadow: var(--shadow-medium);
      border-color: $formtab-border-active;
    }
  }
}




/** **************
 * Tab has an icon
 */
.c-formtab--has-icon {
  .c-formtab__value {
    @include u-baseline(1, padding-top);
    @include u-baseline(1, padding-bottom);
    @include u-baseline(8, height);

    &:before {
      background-image: none;
    }
  }
}




/** **************
 * Tab value
 */
.c-formtab__value {
  @include u-baseline(1.25, padding-top);
  @include u-baseline(5, padding-left);
  @include u-baseline(5, padding-right);
  @include font-meta($type: 'copy');
  background-color: $global-controls-background;
  background-position: -(rem-calc(10)) 50%;
  border: 1px solid $formtab-border-color;
  border-radius: rem-calc($global-border-radius);
  box-shadow: var(--shadow-light);
  display: inline-block;
  height: rem-calc($global-controls-height);
  position: relative;

  @include breakpoint(xlarge) {
    @include u-baseline(.75, padding-top);
  }

  &:before {
    background-image: $global-icon-checkmark;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    content: '';
    display: block;
    position: absolute;
    left: rem-calc(10);
    top: calc(50% - #{rem-calc(math.div(1.25em, 1.75))});
    opacity: 0;
    visibility: hidden;
    width: 1.25em;
    height: 1.25em;
  }

  svg {
    @include u-baseline(2, margin-right);
    margin-left: -(rem-baseline(2));
    width: 2.5em;
    height: 2.5em;
  }
}

// Filter tabs
//
// Combining headings and [tabs](section-controls.html#kssref-controls-tabs).
//
// Markup: filtertabs.hbs
//
// Type: organism
//
// Style guide: Controls.tabs.filtertabs

.o-filtertabs {
  header {
    max-width: rem-calc(560);
    margin: 0 auto;
    text-align: center;
  }

  [class*='heading-'] {
    @include u-baseline(1, margin-bottom);
    margin-top: 0;
  }

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;
  }
}

// Catalogue
//
// Teaser for the catalogue. Usable as standalone
// and as a widget. The catalogue is in full width in
// default usage. Use modifier classes for implementations
// inside wrappers.
//
// .m-catalogue--is-full - For full width usage.
// .m-catalogue--is-content - For usage in content entries.
//
// Markup: catalogue.hbs
//
// Full: true
//
// Type: module
//
// Style guide: Promotion.catalogue

/** **************
 * Wrapper
 */
.m-catalogue {
  @include u-module-margin;
  background-color: $global-background-internal;
  overflow: hidden;
}




/** **************
 * Inner container
 */
.m-catalogue__inner {
  @include xy-grid-container;
  @include xy-grid;
  @include flex-align(center, top);
  @include u-baseline(5, padding-top);
  @include u-baseline(5, padding-bottom);
  @include font-meta;
  position: relative;

  p {
    margin-top: 0;
  }
}




/** **************
 * Cntent entry
 */
.m-catalogue__entry {
  @include u-single-cell;
  max-width: rem-calc(320);
  position: relative;
  z-index: 1;

  .c-button {
    @include u-baseline(19, margin-top);
  }
}




/** **************
 * Heading
 */
.m-catalogue__heading {
  @include font-h2;
  @include font-bold-important;
  @include u-baseline(3, margin-bottom);
  margin-top: 0;

  + p:first-of-type {
    margin-top: -(rem-calc($global-baseline * 2));
  }
}




/** **************
 * Image
 */
.m-catalogue__img {
  bottom: -(rem-calc(10));
  left: calc(50% - #{rem-calc(120)});
  max-width: rem-calc(200);
  position: absolute;
  z-index: 0;

  img {
    @include u-img-responsive;
  }
}




/** **************
 * Modifier: teaser is full width
 */
.m-catalogue--is-full {
  .m-catalogue__inner {
    @include breakpoint(medium) {
      @include flex-align(center, middle);
      display: flex;
    }
  }

  .m-catalogue__entry {
    @include breakpoint(medium) {
      @include u-baseline(2, padding-left);
      flex: 1;
      max-width: rem-calc(600);
      order: 2;
    }

    .c-button {
      @include breakpoint(medium) {
        margin-top: 0;
      }
    }
  }

  .m-catalogue__heading {
    @include breakpoint(large) {
      @include font-h1;
      font-weight: normal !important;
    }
  }

  .m-catalogue__img {
    @include breakpoint(medium) {
      @include u-baseline(1, top);
      @include u-baseline(2, padding-right);
      flex: 0 1 300px;
      order: 1;
      max-width: 250px;
      position: relative;
      text-align: right;
      left: 0;
    }
  }
}




/** **************
 * Modifier: teaser is in content section
 */
.m-catalogue--is-content {
  .m-catalogue__entry {
    @include breakpoint(medium) {
      @include xy-cell(8);
      @include xy-cell-offset(4);
      max-width: none;
    }

    .c-button {
      @include breakpoint(medium) {
        margin-top: 0;
      }
    }
  }

  .m-catalogue__img {
    @include breakpoint(medium) {
      @include u-baseline(3, top);
      bottom: auto;
      left: -2%;
    }

    @include breakpoint(large) {
      left: 5%;
    }

    @include breakpoint(xlarge) {
      left: 2%;
    }
  }
}

// Basic link mixin
@mixin c-arrowlink($options) {
  $position: map-get($options, position);
  $direction: map-get($options, direction);
  $type: map-get($options, iconType);

  @include flex;
  @include flex-align(left, top);
  @include u-link(map-get($options, linkType));
  @include font-small;
  width: 100%;

  &:hover,
  &:active {
    &:before,
    &:after {
      background-color: map-get($options, iconColorHover);
      box-shadow: var(--shadow-popup);
    }
  }

  @if $position == 'after' {
    &:after {
      @include u-arrow-circle(map-get($options, iconColor));
      margin-right: 0;

      @if $type == 'orange' {
        background-image: $global-icon-arrow-orange;
      }
    }

    &:before {
      display: none;
    }
  } @else {
    &:before {
      @include u-arrow-circle(map-get($options, iconColor));
      margin-right: .75em;
    }
  }

  @if $direction == 'left' {
    &:before {
      transform: rotate(180deg);
      transform-origin: 50% 47%;
    }
  }

  > span {
    display: inline-block;
    width: 100%;

    @if $position == 'after' {
      margin-right: $arrowlink-icon-margin * 2;
    }
  }
}

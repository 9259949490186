// Entry
//
// Wrapper for content entries like WordPress post or any other copytext.
//
// Markup: entry.hbs
//
// Type: module
//
// Style guide: Content.Entry

.m-entry {
  @include font-entry;
  @include u-content-formatting;
  position: relative;

  // PageBuilder nesting fix
  [data-content-type='text'] {
    @include u-content-formatting;
  }

  // PageBuilder inline style override
  [data-content-type='image'] {
    margin-bottom: rem-baseline(2) !important;
  }
}

.m-entry--modal {
  @include font-small;
}



/** ***********
 * Modifier: floating images
 */
@include breakpoint(large) {
  .m-entry__img--left,
  .m-entry__img--right {
    max-width: rem-calc(350);
    width: 100%;
  }

  .m-entry__img--left {
    @include u-baseline(4, margin-right);
    float: left;
  }

  .m-entry__img--right {
    @include u-baseline(4, margin-left);
    float: right;
  }
}




/** ***********
 * Text color on dark backgrounds
 */
@include u-text-on-dark('.m-entry');

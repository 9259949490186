// Footer
//
// Organism with misc. modules and components.
//
// Markup: footer.hbs
//
// Type: organism
//
// Full: true
//
// Weight: 10
//
// Style guide: SiteElements.footer

$footer-breakpoint: large;
$footer-background: var(--color-dark);
$footer-color: var(--color-slate);
$footer-border-color: var(--color-slate-petrol);
$footer-heading-color: var(--color-light);
$footer-link-primary-color: var(--color-light);
$footer-link-secondary-color: var(--color-slate);

.o-footer {
  @include u-baseline(4, padding-top);
  @include u-baseline(4, padding-bottom);
  background-color: $footer-background;
  color: $footer-color;

  @include breakpoint($footer-breakpoint) {
    @include u-baseline(8, padding-top);
    @include u-baseline(8, padding-bottom);
  }

  .m-social {
    text-align: center;

    @include breakpoint($footer-breakpoint) {
      text-align: right;
    }
  }
}





/** **************
 * Modifier: Simplified footer
 */
.o-footer--simplified {
  .o-footer-widgets__item {
    @include xy-cell;

    @include breakpoint($footer-breakpoint) {
      @include xy-cell(4);

      &:last-of-type {
        @include xy-cell(6);
        @include xy-cell-offset(2);
      }
    }

    ul {
      columns: 100px 2;
    }
  }
}






/** **************
 * Heading
 */
.o-footer__heading {
  @include font-h4;
  @include u-baseline(2, margin-bottom);
  color: $footer-heading-color;
  font-weight: bold;
  margin-top: 0;
}




/** **************
 * Menu
 */
.o-footer__menu {
  @include u-list-reset;
  @include u-baseline(4, margin-bottom);

  @include breakpoint($footer-breakpoint) {
    margin-bottom: 0;
  }

  li {
    @include u-baseline(2, margin-bottom);
  }

  a {
    @include u-link($global-link-options-slate);
  }
}




/** **************
 * Horizontal rule
 */
.o-footer__hr {
  @include u-single-cell;

  .hr {
    @include u-baseline(4, margin-top);
    @include u-baseline(4, margin-bottom);
    border-color: $footer-border-color;
  }
}




/** **************
 * Media object
 */
.o-footer-object {
  @include u-baseline(3, margin-bottom);
  display: flex;

  figure {
    @include u-baseline(8, width);
    @include u-baseline(3, margin-right);
    flex: 0 0 rem-calc(64);
    text-align: center;

    img {
      display: inline-block;
      max-width: 100%;
    }
  }

  a {
    @include u-link($global-link-options-light);
  }

  p {
    @include u-baseline(1, margin-bottom);
    margin-top: 0;
  }
}





/** **************
 * Widgets
 */
.o-footer-widgets {
  @include xy-grid-container;
  @include xy-grid;

  // Thanks to the Page Builder and its emitted extra div
  // these overrides are mandatory to avoid layout shifts.
  [data-content-type='html'] {
    @include breakpoint(medium) {
      @include xy-cell(6);

      &:nth-child(3) {
        @include xy-cell;
      }
    }

    @include breakpoint($footer-breakpoint) {
      @include xy-cell(3);

      &:nth-child(3) {
        @include xy-cell(6);
      }
    }

    .o-footer-widgets__item {
      flex: none !important;
      margin: 0 !important;
      width: 100% !important;
    }
  }
}




/** **************
 * Widget item
 */
.o-footer-widgets__item {
  @include xy-cell;

  @include breakpoint(medium) {
    @include xy-cell(6);

    &:last-of-type:not(:nth-child(2)) {
      @include xy-cell;
    }
  }

  @include breakpoint($footer-breakpoint) {
    @include xy-cell(3);

    &:last-of-type:not(:nth-child(2)) {
      @include xy-cell(6);
    }
  }
}






/** **************
 * Credits with logo and links
 */
.o-footer-credits {
  @include xy-grid-container;
  @include xy-grid;
}




/** **************
 * Branding
 */
.o-footer-credits__branding {
  @include xy-cell;
  @include u-baseline(3, margin-bottom);
  text-align: center;

  @include breakpoint($footer-breakpoint) {
    @include xy-cell(6);
    margin-bottom: 0;
    text-align: left;
  }

  .c-icon {
    color: var(--color-light);
  }

  a {
    @include u-link($global-link-options-light);
  }

  svg {
    width: rem-calc(164);
    height: rem-calc(39);
  }
}




/** **************
 * Meta wrapper
 */
.o-footer-meta {
  @include xy-cell;

  @include breakpoint($footer-breakpoint) {
    @include xy-cell(6);
  }
}




/** **************
 * Meta menu
 */
.o-footer-meta__menu {
  @include u-list-reset;
  margin-top: 0;
  text-align: center;

  @include breakpoint($footer-breakpoint) {
    text-align: right;
  }

  li {
    @include u-baseline(1, margin-left);
    display: inline-block;

    &:after {
      @include u-baseline(1, margin-left);
      display: inline-block;
      content: ' | ';
    }

    &:last-child:after {
      display: none;
    }
  }

  a {
    @include u-link($global-link-options-light);
  }
}




/** **************
 * Social links
 */
.o-footer-social {
  @include u-list-reset;
  text-align: center;

  @include breakpoint($footer-breakpoint) {
    text-align: right;
  }

  li {
    @include u-baseline(2, margin-left);
    display: inline-block;
  }

  a {
    color: var(--color-light);
    display: block;

    &:hover {
      color: var(--color-orange);
    }
  }

  .c-icon {
    @include u-baseline(3, width);
    @include u-baseline(3, height);
  }
}




/** **************
 * Highlights
 */
.o-footer-row {
  @include xy-grid-container;
  @include xy-grid;

  ul {
    @include u-list-reset;
  }

  li {
    @include u-baseline(1, margin-right);
    display: inline-block;
  }

  a {
    @include u-link($global-link-options-slate-petrol);
  }
}

// Inner container
.o-footer-row__inner {
  @include u-single-cell;
}

// Addressor
//
// Addresses to choose with additional buttons and links.
//
// Markup: addressor.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Containers.addressor
//

/** **************
 * Address list
 */
.m-addressor__list {
  @include u-list-reset;
  @include u-baseline(1, margin-top);
  @include u-baseline(2, margin-bottom);
  list-style: none;
  padding-left: 0 !important; // overriding .m-reveal__content

  li {
    position: relative;

    &:before {
      display: none !important; // overriding .m-reveal__content
    }
  }

  a {
    @include u-baseline(2, top);
    @include u-baseline(2, right);
    position: absolute;
  }

  .c-radio__value {
    @include u-baseline(12, padding-right);
  }
}





/** **************
 * Footer
 */
.m-addressor__footer {
  @include u-baseline(3, padding-top);
  @include u-baseline(3, padding-bottom);
  @include u-baseline(2, padding-left);
  @include u-baseline(4, margin-bottom);
  border-top: $global-border-dotted;
  border-bottom: $global-border-dotted;
}

// Colors
//
// List of color components.
//
// Markup: colors.hbs
//
// Type: component
//
// Style guide: Media.colors

@import '_mixins';

.c-colors {
  display: flex;
  flex-wrap: wrap;
  font-size: 0;
  padding: rem-baseline(1) 0 0;
}









/** ***********
 * Modifier: Colors to display inline
 */
.c-colors--inline {
  display: inline-block;
  margin-right: rem-calc(6);
  padding-top: 0;
  vertical-align: top;

  .c-colors__item {
    display: inline-block;
    margin-bottom: 0;
    margin-top: rem-calc(2);
    vertical-align: middle;
  }
}








/** ***********
 * Modifier: Color is embedded in swatch
 */
.c-colors--swatch {
  padding: 0;

  .c-colors__color {
    @include u-baseline(5, width);
    @include u-baseline(5, height);
    transform: translateY(0);
  }

  .c-colors__value {
    left: -1px;
  }
}




/** ***********
 * Modifier: Wrapper has border
 */
.c-colors--has-border {
  border: 1px solid var(--color-slate-petrol);
}









/** ***********
 * Item
 */
.c-colors__item {
  @include font-small; // override
  @include u-baseline(1, margin-bottom);
  color: var(--color-slate-petrol);
  display: block;
  line-height: 1;
  position: relative;

  &:not(:last-child) {
    margin-right: rem-calc(10);
  }
}






// Single Color
//
// Single color component. Must be called via the `colors` component.
//
// Markup: colors-color.hbs
//
// Type: component
//
// Style guide: Media.colors.color


/** ***********
 * Single color
 */
.c-colors__color {
  @include u-baseline(2.5, width);
  @include u-baseline(2.5, height);
  border-radius: 50%;
  display: block;
  overflow: hidden;
  position: relative;

  &[href]:hover {
    @include c-colors-hover-state;
  }
}

.c-colors--is-selected {
  .c-colors__color[href] {
    @include c-colors-hover-state;
  }
}













/** ***********
 * Value
 */
.c-colors__value {
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  // Prevent edgy borders on overlapping colors
  &:first-child:not(:last-child) {
    clip-path: polygon(100% 0, 0 0, 0 100%);
  }

  + .c-colors__value {
    clip-path: polygon(100% 0, 100% 100%, 50% 100%, 0 100%, 0 100%);
  }
}


// IE Edge
@supports (-ms-ime-align:auto) {
  .c-colors__value + .c-colors__value {
    top: 50%;
  }
}


/** ***********
 * Gradient colors
 */
@include c-colors-gradient($colors-value-transparent);
@include c-colors-gradient($colors-value-rose);
@include c-colors-gradient($colors-value-gold);
@include c-colors-gradient($colors-value-copper);
@include c-colors-gradient($colors-value-bronze);
@include c-colors-gradient($colors-value-brass);
@include c-colors-gradient($colors-value-silver);
@include c-colors-gradient($colors-value-chrome);
@include c-colors-gradient($colors-value-multi);








// List of all colors
//
// Example list with all available colors
//
// Markup: colors-example-list.hbs
//
// Style guide: Media.colors.list
.c-colors__list {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    @include u-baseline(2, margin);
    display: inline-block;
  }
}


// Splitted colors
//
// Example of splitted colors
//
// Markup: colors-example-split.hbs
//
// Style guide: Media.colors.split

// Jumbo Teaser
//
// Large teaser stage with image, text, links and optional carousel
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x9999    |
// | small         | 520x9999   |
// | large         | 940x9999   |
// 9999 = Image height is flexible.
//
// .m-jumboteaser--switched - Content and image container are switched.
// .m-jumboteaser--longtext - Use teaser as content modules with large text.
// .m-jumboteaser--small-margin - Module has small bottom margin.
// .m-jumboteaser--no-margin - Module has no bottom margin.
//
// Markup: jumboteaser.hbs
//
// Full: true
//
// Type: module
//
// Style guide: Promotion.Jumboteaser

// Breakpoints
$jumboteaser-breakpoint: xlarge;
$jumboteaser-background-dark: var(--color-pearl);
$jumboteaser-background-light: var(--color-light);

/** **************
 * Basic styles
 */
.m-jumboteaser {
  @include u-module-margin;
  @include u-baseline(5, padding-top);
  @include u-baseline(5, padding-bottom);

  @include breakpoint($jumboteaser-breakpoint) {
    @include u-baseline(7, padding-top);
    @include u-baseline(7, padding-bottom);
  }

  .heading--xl {
    @include font-h2;
    @include font-bold-important;
    margin-top: 0;
    text-align: left;
    width: 100%;
  }
}






/** **************
 * Modifier: Teaser has small bottom margin
 */
.m-jumboteaser--small-margin {
  @include u-module-margin(small);
}



/** **************
 * Modifier: Teaser has no bottom margin
 */
.m-jumboteaser--no-margin {
  margin-bottom: 0;
}




/** **************
 * Modifier: background colors
 * @deprecated will be removed after spotlight integration
 */
.m-jumboteaser--light {
  background-color: $jumboteaser-background-light;
}




/** ***********
 * Modifier: Order of containers are switched
 */
.m-jumboteaser--switched {
  .m-jumboteaser__entry {
    @include breakpoint($jumboteaser-breakpoint) {
      --order: 2;
    }
  }

  .m-jumboteaser__img {
    @include breakpoint($jumboteaser-breakpoint) {
      --order: 1;
    }
  }
}





/** ***********
 * Modifier: Module has long text as is used as content module
 */
.m-jumboteaser--longtext {
  .m-jumboteaser__entry,
  .m-jumboteaser__img {
    @include breakpoint($jumboteaser-breakpoint) {
      @include xy-cell(6);
    }
  }

  .m-jumboteaser__entry {
    @include breakpoint($jumboteaser-breakpoint) {
      @include u-baseline(4, padding-right);
    }
  }

  &.m-jumboteaser--switched {
    .m-jumboteaser__entry {
      @include breakpoint($jumboteaser-breakpoint) {
        @include u-baseline(4, padding-left);
      }
    }
  }

  .m-jumboteaser__text {
    @include font-entry;
  }
}





/** **************
 * Inner wrapper
 */
.m-jumboteaser__wrapper {
  @include xy-grid-container;
  @include xy-grid;
  @include u-row-padding;
}




/** **************
 * Content entry
 */
.m-jumboteaser__entry {
  @include xy-cell;
  @include u-baseline(3, margin-bottom);
  flex-direction: column;
  order: var(--order, 2);
  text-wrap: balance;

  @include breakpoint($jumboteaser-breakpoint) {
    --order: 1;
    @include xy-cell(4);
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }

  @include breakpoint(xxlarge) {
    @include xy-cell(4, $gutters: $global-xlarge-gutter);
  }

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;

    @include breakpoint(xlarge) {
      @include u-baseline(4, margin-bottom);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .c-arrowlink__wrapper {
    @include u-baseline(3, margin-top);
    display: block;
  }
}









/** ***********
 * Text body
 */
.m-jumboteaser__body {
  width: 100%;
}







/** ***********
 * Text
 */
.m-jumboteaser__text {
  @include font-meta;
}






/** **************
 * Image
 */
.m-jumboteaser__img {
  @include xy-cell;
  @include u-baseline(2, margin-bottom);
  order: var(--order, 1);

  @include breakpoint($jumboteaser-breakpoint) {
    --order: 2;
    @include xy-cell(8);
    margin-bottom: 0;
  }

  @include breakpoint(xxlarge) {
    @include xy-cell(8, $gutters: $global-xlarge-gutter);
  }

  img {
    @include u-img-responsive;
  }
}








/** ***********
 * Modifier: Element has two images
 */
.m-jumboteaser--has-multi {
  .m-jumboteaser__img {
    @include breakpoint(medium) {
      @include flex;
    }
  }

  .m-jumboteaser__img-item {
    @include breakpoint(medium) {
      @include xy-cell(auto, $output: (base size));
    }


    &:last-child:not(:first-child) {
      @include u-baseline(3, margin-top);

      @include breakpoint(medium) {
        @include u-baseline(3, padding-left);
        margin-top: 0;
      }

      @include breakpoint(xxlarge) {
        @include u-baseline(5, padding-left);
        margin-top: 0;
      }
    }
  }
}







// Jumboteaser with 2 images
//
// Jumboteaser with 2 images – like the title says.
//
// .m-jumboteaser--switched - Content and image container are switched.
//
// Markup: jumboteaser--multi.hbs
//
// Type: module
//
// Style guide: Promotion.Jumboteaser.multi

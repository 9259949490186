// Piglet
//
// Formerly known as Widget, now an independent teaser.
//
// Markup: piglet.hbs
//
// .m-piglet--is-large - Large typo when in double grid.
//
// Type: module
//
// Style guide: Promotion.piglet
@include u-text-on-dark('.m-piglet');

.m-piglet {
  margin-bottom: 0;
  text-wrap: balance;

  // Larger fonts if only to piglets are set
  &:first-child:nth-last-child(2),
  &:first-child:nth-last-child(2) ~ .m-piglet,
  &.m-piglet--is-large {
    .m-piglet__heading {
      @include breakpoint(large) {
        @include font-h2;
        max-width: 40ch;
      }
    }

    .m-piglet__body {
      @include breakpoint(large) {
        @include font-entry;
        max-width: 55ch;
      }
    }
  }
}



/** ***********
 * Heading
 */
.m-piglet__heading {
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(1, margin-bottom);
  flex: none;
  margin-top: 0;
  padding-top: 0;
  width: 100%;
}



/** ***********
 * Image
 */
.m-piglet__image {
  @include u-baseline(3, margin-bottom);
  position: relative;

  img {
    @include u-img-responsive;
  }

  // Faux link to overlap nested containers
  // inside image container
  a {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
    }
  }
}



/** ***********
 * Collaborator
 */
.m-piglet__collaborator {
  --person-primary-color: var(--color-light);
  --person-secondary-color: var(--color-slush);
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%);
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;

  .m-person {
    @include u-baseline(3, margin-left);
    @include u-baseline(3, margin-bottom);
    align-self: flex-end;
  }
}



/** ***********
 * Text body
 */
.m-piglet__body {
  @include font-meta;
  @include u-baseline(3, margin-bottom);

  p {
    margin-top: 0;

    &:not(:last-child) {
      @include u-baseline(3, margin-bottom);
    }
  }
}





/** ***********
 * Link
 */
.m-piglet__link {
  width: 100%;
}

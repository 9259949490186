$mapster-breakpoint: xlarge;
$mapster-height-small: rem-baseline(82);
$mapster-height-large: rem-baseline(97);
$mapster-shadow-color: rgba(0, 0, 0, .35);

$mapster-form-height-small: rem-baseline(22);
$mapster-form-height: rem-baseline(17);

$mapster-spinner-background: var(--color-light);
$mapster-header-background: var(--color-ice);
$mapster-legacy-background: var(--color-light);

$mapster-sidebar-width-large: rem-calc(520);
$mapster-sidebar-background: var(--color-light);

$mapster-results-background: var(--color-light);
$mapster-results-shadow-color: rgba(0, 0, 0, .05);
$mapster-result-dot-color: var(--color-slush);
$mapster-result-active-background: var(--color-pearl);
$mapster-result-premium-dot-color: var(--color-orange);
$mapster-result-showroom-border-color: var(--color-dark);
$mapster-result-label-color: var(--color-slate-petrol);

$mapster-toggler-background: var(--color-petrol);
$mapster-toggler-color: var(--color-light);

$mapster-marker-background: rgba(58, 91, 104, .55);
$mapster-marker-border-color: var(--color-light);
$mapster-marker-shadow-color: rgba(0, 0, 0, .16);
$mapster-marker-premium-background: rgba(229, 147, 91, .7);
$mapster-marker-showroom-background: var(--color-dark);
$mapster-marker-showroom-color: var(--color-light);
$mapster-marker-close-color: var(--color-slate);
$mapster-marker-active-background: var(--color-petrol);

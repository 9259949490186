// Listing head
//
// Listing information for product listings.
//
// Markup: listinghead.hbs
//
// Type: module
//
// Style guide: Content.Listinghead

/** **************
 * Basic styles
 */
.m-listinghead {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include u-baseline(2, margin-bottom);
  @include font-meta;

  @include breakpoint(large) {
    @include xy-gutters($negative: true);
  }
}




/** **************
 * Item
 */
.m-listinghead__item {
  @include xy-cell($output: (base size));
  @include flex-align(left, top);

  @include breakpoint(large) {
    @include xy-cell(6);
  }

  + .m-listinghead__item:last-of-type {
    @include breakpoint(large) {
      text-align: right;
    }
  }
}




/** **************
 * Category
 */
.m-listinghead__category {
  @include u-baseline(1, margin-right);
  display: inline-block;
}

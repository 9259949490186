

.owl-item {
  transform: translate3d(0, 0, 0);
}

.owl-theme {
  .owl-nav {
    [class*='owl-'] {
      background: none;
      border-radius: 0;
      margin: 0;

      &:hover {
        background: none;
      }

      svg {
        width: rem-calc(9);
        height: rem-calc(15);
        vertical-align: baseline;
        transform: translateX((rem-calc(3)));
      }
    }

    .owl-prev {
      transform: rotate(180deg) scale(1);
    }
  }

  .owl-dots {
    margin-top: 0 !important; // Plugin override

    .owl-dot span {
      border-radius: 0;
      margin: rem-baseline(1) rem-calc(2) 0;
      width: rem-baseline(3);
      height: rem-calc(4);
    }

    &.disabled {
      display: block !important;
      visibility: hidden;
    }
  }
}

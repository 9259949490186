// Jack
//
// Light consultancy promotion to display across the entire site.
//
// Markup: jack.hbs
//
// .m-jack--is-sidebar - Module is implemented in a sidebar.
// .m-jack--is-slim - Module slim on height and is full width.
// .m-jack--is-large - Module is very large and full width.
//
// Type: module
//
// Style guide: Promotion.jack

$jack-breakpoint: medium;
$jack-breakpoint-slim: xxlarge;
$jack-breakpoint-large: xlarge;
$jack-modifier-sidebar: '.m-jack--is-sidebar';
$jack-modifier-slim: '.m-jack--is-slim';
$jack-modifier-large: '.m-jack--is-large';
$jack-icon-width: rem-calc(188);
$jack-icon-width-slim: rem-calc(154);
$jack-icon-height-slim: rem-calc(137);
$jack-icon-width-large: rem-calc(359);
$jack-icon-height-large: rem-calc(321);

@import '_mixins';

.m-jack {
  @include flex;
  @include u-baseline(3, padding);
  @include u-baseline(4, margin-bottom);
  flex-direction: var(--flex-dir, column);
  position: relative;
  text-align: var(--text-align, center);
  text-wrap: balance;

  // If not is sidebar
  &:not(#{$jack-modifier-sidebar}) {
    @include m-jack-horizontal($jack-breakpoint);
  }

  .c-button {
    height: auto;
    white-space: normal;
  }
}

// Remove margin when used in Sector
.t-sector__col .m-jack {
  &:last-child {
    margin-bottom: 0;
  }
}







/** ***********
 * Icon
 */
.m-jack__icon {
  @include u-baseline(12, margin-bottom);
  order: var(--order, 2);
  margin-left: var(--margin-left, 0);
  margin-right: var(--margin-right, 0);
  width: 100%;

  .c-icon {
    width: var(--width, $jack-icon-width);
    height: var(--height, 100%);
  }
}









/** ***********
 * Content wrapper
 */
.m-jack__content {
  order: var(--order, 1);
}







/** ***********
 * Heading
 */
.m-jack__heading {
  @include font-h2;
  @include font-bold-important;
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  @include u-baseline(1, margin-bottom);
  margin-top: 0;
}









/** ***********
 * Text body
 */
.m-jack__body {
  @include u-baseline(3, margin-bottom);
  @include font-meta($type: 'display');

  p {
    margin: 0;

    &:not(:last-child) {
      @include u-baseline(3, margin-bottom);
    }
  }
}











/** ***********
 * CTA
 */
.m-jack__cta {
  @include u-baseline(4, bottom);
  display: inline-block;
  position: var(--position, absolute);
  left: var(--left, 0);
  right: 0;
}








/** ***********
 * Additional link
 */
.m-jack__link {
  @include u-baseline(1, margin-top);
  display: block;
  text-align: center;

  @include u-entry-link-override {
    @include font-small; // override
    @include u-link($global-link-options-grey-underline);
  }
}








/** ***********
 * Modifier: element is in sidebar and horizontal
 * for medium breakpoint only
 */
#{$jack-modifier-sidebar} {
  @include m-jack-horizontal($jack-breakpoint only);
}

// Ultra slim sidebar on listing pages
.t-listing__sidebar #{$jack-modifier-sidebar} {
  @include breakpoint(xlarge only) {
    @include u-baseline(1, padding-left);
    @include u-baseline(1, padding-right);
  }

  .m-jack__heading {
    @include breakpoint(xlarge only) {
      @include font-h3;
      padding: 0;
    }
  }

  .m-jack__body p {
    @include breakpoint(xlarge only) {
      @include font-small; // override
    }
  }

  .m-jack__icon {
    @include breakpoint(xlarge only) {
      .c-icon {
        --width: #{rem-calc(140)};
        --height: #{rem-calc(125)};
      }
    }
  }

  .m-jack__cta {
    @include breakpoint(xlarge only) {
      @include u-baseline(1, margin-left);
      @include u-baseline(1, margin-right);
    }
  }
}












/** ***********
 * Modifier: Element is slim and full width
 */
#{$jack-modifier-slim}:not(#{$jack-modifier-sidebar}) {
  margin-bottom: 0;

  @include breakpoint($jack-breakpoint-slim) {
    @include u-baseline(3, padding-top);
    @include u-baseline(3, padding-bottom);
    padding-left: 0;
  }

  .m-jack__icon {
    @include breakpoint($jack-breakpoint-slim) {
      @include xy-cell-offset(1);
      flex: 0 1 $jack-icon-width-slim;

      .c-icon {
        --width: #{$jack-icon-width-slim};
        --height: #{$jack-icon-height-slim};
      }
    }
  }

  .m-jack__content {
    @include breakpoint($jack-breakpoint-slim) {
      @include u-baseline(5, padding-left);
    }

    @include breakpoint(xlarge) {
      @include flex;
      @include flex-align(left, middle);
    }

    p:nth-last-child(1) {
      @include breakpoint(xlarge) {
        margin-bottom: 0;
      }
    }
  }

  .m-jack__body {
    @include breakpoint($jack-breakpoint-slim) {
      margin-bottom: 0;
    }
  }

  .m-jack__cta {
    @include breakpoint(xlarge) {
      @include u-baseline(6, margin-left);

      .c-button {
        white-space: nowrap;
      }
    }
  }
}









/** ***********
 * Modifier: Element is large and full width
 */
#{$jack-modifier-large}:not(#{$jack-modifier-sidebar}) {
  @include breakpoint($jack-breakpoint-large) {
    @include u-baseline(9, padding-top);
    @include u-baseline(9, padding-bottom);
    padding-left: 0;
  }

  .m-jack__icon {
    @include breakpoint($jack-breakpoint-large) {
      @include xy-cell-offset(1);
      flex: 0 1 $jack-icon-width-large;

      .c-icon {
        --width: #{$jack-icon-width-large};
        --height: #{$jack-icon-height-large};
      }
    }
  }

  .m-jack__heading {
    @include breakpoint($jack-breakpoint-large) {
      @include font-h1;
      @include u-baseline(2, margin-bottom);
    }
  }

  .m-jack__content {
    @include breakpoint($jack-breakpoint-large) {
      max-width: rem-calc(500);
    }

    @include breakpoint(xlarge) {
      @include u-baseline(10, padding-left);
      max-width: rem-calc(600);
    }

    .c-button {
      @include breakpoint($jack-breakpoint-large) {
        @include c-button-large;
      }
    }
  }

  .m-jack__body {
    @include breakpoint($jack-breakpoint-large) {
      @include font-h3;
    }
  }
}

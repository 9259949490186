// Flock
//
// Flip clock component.
//
// Markup: flock.hbs
//
// Type: component
//
// Style guide: Media.countdown.flock
$flock-color-top: var(--color-iron);
$flock-color-bottom: var(--color-dark);
$flock-background-top: #f8f8f8;
$flock-background-bottom: #e8e8e8;
$flock-border: #b2b2b2;

.c-flock {
  display: block;
  font-size: unquote('clamp(26px, 5vw, 82px)');
  font-weight: bold;
  line-height: .95;
  padding-bottom: .72em;
  position: relative;
  text-align: center;

  @include breakpoint(large) {
    font-size: unquote('clamp(28px, 4vw, 48px)');
  }
}

.c-flock__top,
.c-flock__bottom,
.c-flock__back::before,
.c-flock__back::after {
  backface-visibility: hidden;
  background: $flock-background-top;
  border-radius: .15em .15em 0 0;
  color: $flock-color-top;
  display: block;
  height: .72em;
  padding: .25em;
  transform: translateZ(0);
  transform-style: preserve-3d;
  width: 1.8em;
}

.c-flock__bottom {
  background: $flock-background-bottom;
  border-radius: 0 0 .15em .15em;
  border-top: solid 1px $flock-border;
  color: $flock-color-bottom;
  position: absolute;
  top: 50%;
  left: 0;
  overflow: hidden;
  pointer-events: none;

  &:after {
    display: block;
    margin-top: -.72em;
  }
}

.c-flock__back::before,
.c-flock__bottom::after {
  content: attr(data-value);
}

.c-flock__back {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0%;

  &:before {
    overflow: hidden;
    position: relative;
    z-index: -1;
  }
}

.flip .c-flock__back::before {
  animation: flipTop .3s cubic-bezier(.37, .01, .94, .35);
  animation-fill-mode: both;
  transform-origin: center bottom;
}

.flip .c-flock__back .c-flock__bottom {
  animation-fill-mode: both;
  animation: flipBottom .6s cubic-bezier(.15, .45, .28, 1);
  transform-origin: center top;
}

@include keyframes(flipTop) {
  0% {
    transform: rotateX(0deg);
    z-index: 2;
  }

  0%,
  99% {
    opacity: .99;
  }

  100% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
}

@include keyframes(flipBottom) {
  0%,
  50% {
    z-index: -1;
    transform: rotateX(90deg);
    opacity: 0;
  }

  51% {
    opacity: .99;
  }

  100% {
    opacity: .99;
    transform: rotateX(0deg);
    z-index: 5;
  }
}

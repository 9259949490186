// Checkbox
//
// Checkbox styles
//
// Weight: 1
//
// Markup: forms-checkbox.hbs
//
// Type: component
//
// Style guide: Forms.Checkbox
//
.c-checkbox {
  @include u-baseline(3, margin-bottom);
  position: relative;

  label {
    display: block;
  }

  [type='checkbox'] {
    @include element-invisible;

    // Styles for checked input
    &:checked {
      ~ .c-checkbox__value {
        font-weight: bold;
      }

      ~ .c-checkbox__value:before {
        @include u-input-styles($active: true);
        background-image: $global-icon-checkbox;
        background-repeat: no-repeat;
        background-size: rem-baseline(1) rem-baseline(1);
        background-position: 50% 50%;
      }
    }

    // Focus styles for a11y
    &:focus:not(:checked) {
      ~ .c-checkbox__value:before {
        @include u-input-styles($active: true);
      }
    }
  }
}









/** ***********
 * Modifier: Checkbox has colors component
 */
.c-checkbox--has-color {
  .c-checkbox__value {
    @include u-baseline(9, padding-left);
  }

  .c-colors {
    @include u-baseline(5, left);
    position: absolute;
    top: 0;
  }
}








/** **************
 * Value
 */
.c-checkbox__value {
  @include u-baseline(4, padding-left);
  display: block;
  padding-left: 2.5em;
  position: relative;

  &:before {
    @include u-baseline(1, margin-right);
    @include u-input-styles;
    content: '';
    display: inline-block;
    width: 1.75em;
    height: 1.75em;
    position: absolute;
    left: 0;
    top: -(rem-calc(2));
    vertical-align: middle;
  }

  &:hover {
    text-decoration: underline;
  }
}

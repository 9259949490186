// Filters
//
// Listing wrapper for filter items
//
// Markup: filters.hbs
//
// Type: organism
//
// Style guide: Controls.filters

.o-filters {
  @include u-baseline(3, margin-bottom);
  position: relative;
  display: grid;
  grid-auto-columns: auto;
  grid-template-columns: repeat(4, 1fr);
  gap: rem-baseline(3) rem-baseline(2);

  .m-filterlist {
    grid-column: 1 / 5;
  }
}




/** **************
 * Item
 */
.o-filters__item {
  width: 100%;

  &.rest-filter {
    display: none;
  }
}




/** ***********
 * Modifier: Item is hidden
 */
.o-filters__item--hidden {
  display: none;
}

// Site header with all modules and components

$header-background-color: #080400;
$header-color: var(--color-light);
$header-shadow: rgba(0, 0, 0, .5);
$header-height-small: $global-header-height-small;

.o-header {
  background-color: $header-background-color;
  position: relative;
  z-index: 20;

  @include breakpoint($global-navigation-breakpoint) {
    margin-bottom: 0;
    position: relative;
  }

  // Box-shadow to overlay flyout
  &:after {
    box-shadow: var(--shadow-heavy);
    content: '';
    display: block;
    position: absolute;
    height: 10px;
    width: 100%;
    z-index: -1;
    bottom: rem-calc(1);
    pointer-events: none; // avoid breaking the hover event

    @include breakpoint($global-navigation-breakpoint) {
      bottom: 0;
      z-index: 100;
    }
  }

  hr {
    border: none;
    border-top: 1px solid $header-color;
    margin: 0;
    opacity: .25;
  }
}





/** **************
 * Modifier: Header is simplified (Checkout etc.)
 */
.o-header--simplified {

  // Remove burger menu placeholder
  .o-header__logo {
    @include u-single-cell($size: 4);

    &:before {
      display: none;
    }
  }

  .o-header__inner:before {
    @include u-baseline(12, height);
  }

  .o-header__meta {
    @include u-single-cell($size: 8);
    display: block;
  }
}




/** **************
 * Inner container
 */
.o-header__inner {
  @include xy-grid-container;
  @include xy-grid;
  @include flex-align(left, middle);
  height: rem-calc($header-height-small);
  position: relative;

  @include breakpoint($global-navigation-breakpoint) {
    @include u-baseline(12, height);
  }

  // Backlight image
  &:before {
    @include breakpoint($global-navigation-breakpoint) {
      background-image: $global-image-backlight;
      background-repeat: no-repeat;
      content: '';
      display: block;
      position: absolute;
      left: -(rem-calc(210));
      top: 0;
      width: rem-calc(794);
      height: rem-calc(135);
      z-index: 0;
    }
  }

  a:not([class*='u-link']) {
    color: var(--color-light);
  }
}




/** **************
 * Logo
 */
.o-header__logo {
  font-size: 0;
  margin-left: rem-calc(59);
  position: relative;

  @include breakpoint($global-navigation-breakpoint) {
    @include xy-cell(4);
  }

  &:before {
    background-color: $global-header-border-color;
    content: '';
    display: block;
    position: absolute;
    top: -(rem-calc(4));
    bottom: -(rem-calc(4));
    left: -(rem-baseline(2));
    width: rem-calc(1);

    @include breakpoint($global-navigation-breakpoint) {
      display: none;
    }
  }

  svg {
    width: rem-calc($global-logo-width-small);
    height: rem-calc($global-logo-height-small);

    @include breakpoint($global-navigation-breakpoint) {
      width: rem-calc($global-logo-width-large);
      height: rem-calc($global-logo-height-large);
    }
  }
}




/** **************
 * Meta links
 */
.o-header__meta {
  @include u-baseline(1, margin-right);
  flex: 1 0 auto;
  text-align: right;

  ul:not(.m-usermenu__list) {
    @include u-list-reset;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  a:not([class*='u-link']) {
    &:hover {
      color: var(--color-orange);
    }
  }
}

.o-header__meta-item {
  @include u-baseline(2.5, margin-left);

  @include breakpoint($global-navigation-breakpoint) {
    @include u-baseline(4, margin-left);
  }
}







/** **************
 * Search toggle icon
 */
.o-header__search {
  display: none;

  @include breakpoint($global-navigation-breakpoint) {
    display: block;
    position: relative;
    width: auto;
  }


  .c-button--ghost {
    color: $navbar-color;
    display: flex;
    align-items: center;
    fill: $navbar-color;
    padding: rem-calc(2) 0;
    transition: var(--transition-default);

    &:hover span,
    &.is-active {
      color: var(--color-orange) !important;
      fill: var(--color-orange) !important;
    }
  }

  .c-icon {
    margin-right: rem-calc(10);
    transform: translateY(0);
  }

  span {
    @include font-tiny;
    color: var(--color-dusty);
    letter-spacing: .15rem;
    text-transform: uppercase;
  }
}

// Callout
//
// Static messages for forms or other purposes.
//
// Orange background and info icon on default.
//
//
// Markup: callout.hbs
//
// Type: component
//
// Style guide: Containers.Callout

/** **************
 * Default
 */
.c-callout {
  @include font-meta;
  @include u-baseline(3, margin-bottom);
  background: var(--callout-background, var(--color-info));
  border-radius: var(--callout-border-radius, #{rem-calc($global-border-radius)});
  color: var(--callout-color, var(--color-light));
  padding: rem-calc(12) rem-baseline(3) rem-calc(12) 3em;
  position: relative;
  width: 100%;

  svg {
    @include u-baseline(2, left);
    @include u-baseline(1.75, top);
    @include u-icon-relative-size;
    position: absolute;

    @include breakpoint(xlarge) {
      @include u-baseline(2, top);
    }
  }

  a {
    @include u-link($global-link-options-underline-light);
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}





/** **************
 * Outer wrapper
 */
.c-callout__wrapper {
  float: none !important;
  width: 100%;

  &:not(.c-callout--nested) {
    @include u-cell-row;
  }

  // i.e. checkout messages
  &.c-callout--nested {
    @include u-single-cell;
  }

  // Magento override
  .messages {
    width: 100%;
  }
}







// Success
//
// Green background and checkmark icon.
//
// Markup: callout--success.hbs
//
// Weight: 1
//
// Style guide: Containers.Callout.success
.c-callout--success {
  --callout-background: var(--color-success);
}



// Error
//
// Red background and warning icon.
//
// Markup: callout--error.hbs
//
// Weight: 2
//
// Style guide: Containers.Callout.error
.c-callout--error {
  --callout-background: var(--color-warning);
}





// Neutral
//
// Neutral background and info icon.
//
// Markup: callout--neutral.hbs
//
// Weight: 3
//
// Style guide: Containers.Callout.neutral
.c-callout--neutral {
  --callout-background: var(--color-slush-ice);
  --callout-color: var(--color-dark);
}


// Plain
//
// Callout has no round borders and margins. Used in basket.
//
// Additional `svg` parameter is **required!**
//
// `hide_wrapper` parameter is recommended!
//
// Markup: callout--plain.hbs
//
// Weight: 4
//
// Style guide: Containers.Callout.plain
.c-callout--plain {
  --callout-border-radius: 0;
  margin: 0;
}

// Features & Ratings combined
//
// Features and ratings on product detail page.
//
// Markup: product-features.hbs
//
// Weight: 10
//
// Type: organism
//
// Style guide: Media.features.product-features

.o-product-features {
  @include clearfix;
  @include u-baseline(2, margin-bottom);
  text-align: right;

  @include breakpoint(xlarge) {
    @include u-baseline(5, margin-bottom);
  }

  .m-features {
    float: left;
  }
}

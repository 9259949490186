// File
//
// File template for dropzone. See [dropzone](section-forms.html#kssref-forms-dropzone) organism for example.
//
// Markup: file.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Forms.dropzone.file

$file-icon-wrapper-width: rem-calc(80);
$file-error-color: var(--color-slush-ice);

.m-file {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include u-baseline(2, padding);
  @include u-baseline(1, margin-bottom);
  background-color: var(--color-light);
  padding-left: 0;
  border: $global-border-light;
  border-radius: rem-calc($global-border-radius);

  .c-progress {
    @include u-baseline(1, margin-top);
  }
}





/** **************
 * Modifier: Item has error
 */
.m-file--has-error {
  border-color: $file-error-color;

  svg,
  p:not(.m-file__error) {
    opacity: .5;
  }
}






/** **************
 * Icon
 */
.m-file__icon {
  @include u-baseline(1, padding-top);
  flex: 0 0 $file-icon-wrapper-width;
  text-align: center;

  svg {
    width: 2em;
    height: 2em;
  }
}





/** **************
 * Content
 */
.m-file__content {
  @include u-baseline(7, padding-right);
  flex: 1 0 calc(100% - #{$file-icon-wrapper-width});
  min-width: 0;
  position: relative;

  p {
    margin-bottom: rem-calc(4) !important; // overriding 'fieldset p:first-of-type+p' (foundation)
    margin-top: 0 !important; // overriding 'fieldset p:first-of-type+p' (foundation)

    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
}






/** ***********
 * File name
 */
.m-file__name {
  @include font-meta;
  @include u-ellipsis($max-width: 500);
}






/** **************
 * Size text
 */
.m-file__size {
  color: var(--color-petrol);
}







/** **************
 * Error text
 */
.m-file__error {
  color: var(--color-warning);
  font-style: italic;
}







/** **************
 * Remove icon
 */
.m-file__remove {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;

  svg {
    @include u-icon-relative-size;
  }
}

// Persons
//
// Grid wrapper for person module.
//
// Markup: persons.hbs
//
// Type: organism
//
// Style guide: Containers.persons
.o-persons {
  @include u-baseline(3, gap);
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: var(--persons-cols, repeat(auto-fill, minmax(max(var(--persons-minmax, #{rem-calc(220)})), 1fr)));
  justify-items: var(--persons-justify, stretch);

  @include breakpoint(large) {
    --persons-minmax: #{rem-calc(360)};
  }
}


.o-persons--single {
  @include breakpoint(large) {
    --persons-minmax: 100%;
    --persons-justify: center;
  }
}


.o-persons--double {
  @include breakpoint(large) {
    --persons-minmax: 33%;
    --persons-justify: center;
  }
}

// Textarea
//
// Textarea styles with optional help text.
//
// Markup: forms-textarea.hbs
//
// Weight: 4
//
// Type: component
//
// Style guide: Forms.Textarea

.c-textarea {
  @include font-small; // override
  @include u-input-styles;
  padding: rem-calc(8) rem-calc(12);

  &:active,
  &:focus {
    @include u-input-styles($active: true);
  }
}

// Progress bar
//
// Markup: progress.hbs
//
// Type: component
//
// Style guide: Forms.progress

$progress-height: rem-baseline(1);

.c-progress {
  background-color: var(--color-ice);
  border-radius: rem-calc(41);
  height: $progress-height;
  overflow: hidden;
}



.c-progress__bar {
  background-color: var(--color-petrol);
  height: $progress-height;
}

// Template: Product listing
//
// Defines only the grid and layout overrides.

$listing-breakpoint: xlarge;
$listing-xlarge-gutters: rem-calc($global-xlarge-gutter);

.t-listing {
  @include xy-grid-container;
  @include xy-grid;
  @include u-row-padding;
}




/** **************
 * Modifier: Element has no header
 */
.t-listing--no-header {
  @include u-baseline(3, padding-top);
}





/** **************
 * Header with title and logos
 */
.t-listing__header {
  @include u-single-cell($xlarge: true);
  @include u-baseline(2, margin-top);
  @include u-baseline(3, margin-bottom);
}






/** **************
 * Sidebar
 */
.t-listing__sidebar {
  @include u-single-cell;
  --usp-grid: rem-calc(180);
  @include breakpoint($listing-breakpoint) {
    @include xy-cell(3, $gutters: $listing-xlarge-gutters);
  }
}




/** **************
 * Content
 */
.t-listing__content {
  @include u-single-cell;
  @include u-baseline(6, padding-bottom);

  @include breakpoint($listing-breakpoint) {
    @include xy-cell(9, $gutters: $listing-xlarge-gutters);
  }
}

// Template for newsletter pages
//
// TODO: This layout needs a complete refactoring.

.t-newsletter {
  @include xy-grid-container;
  @include xy-grid;

  &:not(.t-newsletter--catalog) {
    @include flex-align(left, middle);
  }
}


/** ***********
 * Modifier: CHRISTOPH catalog
 */
.t-newsletter--catalog {
  .t-newsletter__sidebar {
    display: flex;

    @include breakpoint(xlarge) {
      @include xy-cell-offset(1);
    }

    img {
      max-width: 100%;
    }
  }

  .t-newsletter__img {
    @include u-baseline(3, margin-bottom);

    @include breakpoint(large) {
      @include u-baseline(3, margin-right);
    }
  }

  .t-newsletter__content {
    @include breakpoint(large) {
      @include xy-cell(7);
    }

    @include breakpoint(xlarge) {
      @include xy-cell(6);
    }

    @include breakpoint(xxlarge) {
      @include xy-cell(5);
    }
  }
}




/** **************
 * Sidebar
 */
.t-newsletter__sidebar {
  @include xy-cell;
  display: none;

  img {
    @include u-img-responsive;
    max-width: rem-calc(330);
    margin: 0 rem-baseline(5) 0 auto;
  }

  @include breakpoint(large) {
    @include u-module-margin;
    @include xy-cell(5);
    display: block;
  }
}






/** **************
 * Content
 */
.t-newsletter__content {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell(7);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(6);
  }
}









/** ***********
 * Mobile image
 */
.t-newsletter__mobile {
  img {
    max-width: rem-calc(320);
    margin: 0 auto;
    transform: translateX(-20px);
  }
}

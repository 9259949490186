// Pay with …
//
// Pay with PayPal Express or AmazonPay.
//
// Markup: paywith.hbs
//
// Type: module
//
// Style guide: Containers.paywith

$paywith-background: var(--color-light);

.m-paywith {
  @include u-baseline(3, padding-right);
  @include u-baseline(3, padding-left);
  background-color: $paywith-background;
  text-align: center;
}










/** ***********
 * Inner container
 */
.m-paywith__inner {
  @include flex;
  @include flex-align(center, middle);
  @include u-baseline(1, padding-left);
  @include u-baseline(1, padding-right);
  flex-direction: column;
  justify-content: space-evenly;

  @include breakpoint(medium) {
    flex-direction: row;
  }

  @include breakpoint(large) {
    flex-direction: column;
  }

  @include breakpoint(xlarge) {
    flex-direction: row;
  }
}








/** ***********
 * Item (Button)
 */
.m-paywith__item {
  @include u-baseline(1, margin-left);
  @include u-baseline(1, margin-right);
  @include u-baseline(3, margin-bottom);
  max-width: rem-calc(200);
  text-align: center;

  img {
    @include u-img-responsive;
  }
}

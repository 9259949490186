// Status list
//
// Description list to display status with dates
//
// Markup: statuslist.hbs
//
// Type: component
//
// Style guide: Lists.statuslist

.c-statuslist {
  @include flex;
  flex-wrap: wrap;
  margin: 0;

  dt {
    @include u-baseline(1, margin-bottom);
    flex: 0 0 rem-calc(106);
    font-weight: bold;
    max-width: 100%;
  }

  dd {
    @include u-baseline(1, margin-bottom);
    flex: 1 0 calc(100% - #{rem-calc(106)});
    margin-left: 0;
    max-width: 100%;
  }
}

// Helper classes for editors
//
// Helper classes to use in Magento PageBuilder. Please use them wisely and only when it's absolutely mandatory.
//
// Chromeless: true
//
// Style guide: General.Editors




// Colors
//
// Classes for background colors. Don't use them for links, but only for containers.
//
// | Color                                                                | Class for background colors  | Class for text colors |
// |----------------------------------------------------------------------|------------------------------|-----------------------|
// | <span class="pl-tile background--dark"></span> dark (black)          | `background--dark`           | `color--dark`         |
// | <span class="pl-tile background--light"></span> light (white)        | `background--light`          | `color--light`        |
// | <span class="pl-tile background--orange"></span> orange              | `background--orange`         | `color--orange`       |
// | <span class="pl-tile background--orange-light"></span> orange-light  | `background--orange-light`   | Do not use            |
// | <span class="pl-tile background--petrol"></span> petrol              | `background--petrol`         | `color--petrol`       |
// | <span class="pl-tile background--slate-petrol"></span> slate-petrol  | `background--slate-petrol`   | `color--slate-petrol` |
// | <span class="pl-tile background--slate"></span> slate                | `background--slate`          | `color--slate`        |
// | <span class="pl-tile background--slush-slate"></span> slush-slate    | `background--slush-slate`    | `color--slush-slate`  |
// | <span class="pl-tile background--slush"></span> slush                | `background--slush`          | `color--slush`        |
// | <span class="pl-tile background--slush-ice"></span> slush-ice        | `background--slush-ice`      | `color--slush-ice`    |
// | <span class="pl-tile background--ice"></span> ice                    | `background--ice`            | `color--ice`          |
// | <span class="pl-tile background--pearl"></span> pearl                | `background--pearl`          | `color--pearl`        |
// | <span class="pl-tile background--iron"></span> iron                  | `background--iron`           | `color--iron`         |
//
// Chromeless: true
//
// Weight: 1
//








// Spacing
//
// Classes for vertical spacing (top/bottom).
//
// **IMPORTANT:** The spacing applies also for mobile display, therefore large spacings will be large on mobile as well!
//
// | Value (px) | Class for top spacing  | Class for bottom spacing |
// |------------|------------------------|--------------------------|
// | 8          | `spacer--top-1`        | `spacer--bottom-1`       |
// | 16         | `spacer--top-2`        | `spacer--bottom-2`       |
// | 24         | `spacer--top-3`        | `spacer--bottom-3`       |
// | 32         | `spacer--top-4`        | `spacer--bottom-4`       |
// | 40         | `spacer--top-5`        | `spacer--bottom-5`       |
//
// Chromeless: true
//
// Weight: 1
//
// Style guide: General.Editors.Spacing
$editors-spacing-items: 5;

@for $i from 1 through $editors-spacing-items {
  .spacer--top-#{$i} {
    margin-top: rem-baseline(1 * $i) !important;
  }

  .spacer--bottom-#{$i} {
    margin-bottom: rem-baseline(1 * $i) !important;
  }
}

// Message
//
// Full width message on page top to announce important info.
//
// .m-message--highlight - Highlighted message for important announcements.
// .m-message--warning - Alert message for high level announcements.
//
// Markup: message.hbs
//
// Type: module
//
// Style guide: Containers.message

$message-default-gradient-color-start: rgba(240, 248, 242, 1);
$message-default-gradient-color-stop: rgba(220, 244, 226, 1);
$message-highlight-gradient-color-start: rgba(234, 159, 107, 1);
$message-highlight-gradient-color-stop: rgba(229, 147, 91, 1);
$message-warning-gradient-color-start: rgba(242, 74, 94, 1);
$message-warning-gradient-color-stop: rgba(208, 2, 27, 1);
$message-bottom-border-background: rgba(255, 255, 255, .15);
$message-close-background: rgba(255, 255, 255, .25);
$message-label-background: rgba(0, 0, 0, .35);
$message-label-color: var(--color-light);
$message-default-background: var(--color-success-light);
$message-default-color: var(--color-dark);
$message-highlight-background: var(--color-orange);
$message-warning-background: var(--color-warning);
$message-warning-color: var(--color-light);

.m-message {
  @include u-baseline(5, min-height);
  @include u-baseline(1, padding-top);
  @include u-baseline(1, padding-bottom);
  background-color: var(--background, #{$message-default-background});
  background: linear-gradient(90deg, var(--message-gradient-start, $message-default-gradient-color-start) 0%, var(--message-gradient-stop, $message-default-gradient-color-stop) 100%);
  box-shadow: var(--shadow-heavy);
  display: none;
  font-style: italic;
  overflow: hidden;
  position: relative;
  text-wrap: balance;
  z-index: 1;

  // Highlight image
  &:before {
    @include breakpoint($global-navigation-breakpoint) {
      background-image: $global-image-backlight;
      background-repeat: no-repeat;
      content: '';
      display: block;
      position: absolute;
      left: -(rem-calc(210));
      left: 50%;
      margin-left: -(rem-calc(794));
      top: -(rem-calc(20));
      width: rem-calc(794);
      height: rem-calc(135);
      transform: scaleY(-1);
      z-index: 0;
    }
  }

  // White bottom border
  &:after {
    background-color: $message-bottom-border-background;
    content: '';
    height: rem-calc(1);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  a {
    display: block;
    color: inherit;
  }

  // Label
  em {
    background-color: $message-label-background;
    border-radius: rem-calc(2);
    color: $message-label-color;
    display: inline-block;
    font-weight: bold;
    margin: 0 rem-calc(3);
    padding-left: rem-calc(6);
    padding-right: rem-calc(6);
  }

  &.is-active {
    display: block;
  }
}







/** ***********
 * Modifier: Highlight message
 */
.m-message--highlight {
  --message-background: #{$message-highlight-background};
  --message-gradient-start: #{$message-highlight-gradient-color-start};
  --message-gradient-stop: #{$message-highlight-gradient-color-stop};
}







/** ***********
 * Modifier: Warning message
 */
.m-message--warning {
  --message-background: #{$message-warning-background};
  --message-gradient-start: #{$message-warning-gradient-color-start};
  --message-gradient-stop: #{$message-warning-gradient-color-stop};
  color: $message-warning-color;

  .m-message__close {
    color: inherit;
  }
}








/** ***********
 * Inner container
 */
.m-message__inner {
  @include u-baseline(2, padding-left);
  @include u-baseline(5, padding-right);
  margin: 0 auto;
  max-width: rem-calc($global-max-width);
  position: relative;
  text-align: left;
  z-index: 1;

  @include breakpoint(large) {
    @include u-baseline(5, padding-left);
    @include u-baseline(5, padding-right);
    text-align: center;
  }
}









/** ***********
 * Close
 */
.m-message__close {
  @include u-baseline(2, right);
  appearance: none;
  background: $message-close-background;
  border: none;
  cursor: pointer;
  display: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: calc(50% - #{rem-calc(12)});

  &.is-active {
    display: inline-block;
  }
}

// Mobile panel (MoPa)
//
// Type: module
//
// Overlay panel for mobile filter and categories.
@use 'sass:math';
$mopa-background-color: var(--color-light);
$mopa-navbar-height: rem-calc(58);
$mopa-primary-background: var(--color-pearl);
$mopa-title-color: var(--color-dark);
$mopa-controls-height: rem-calc(136);
$mopa-border: 1px solid var(--color-slush);
$mopa-title-arrow-size: 1.5em;


/** **************
 * Basic styles
 */
.m-mopa {
  background-color: $mopa-background-color;
  padding-top: $mopa-navbar-height;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  transition: var(--transition-smooth);
  transform: translate3d(var(--mopa-transform, 0), 0, 0);
  z-index: 10;
}




/** **************
 * Modifier: current panel
 */
.m-mopa--current {
  z-index: 1;
}




/** **************
 * Modifier: Panel is hidden
 */
.m-mopa--hidden {
  --mopa-transform: 100%;
}




/** **************
 * Modifier: Panel is sub-opened
 */
.m-mopa--subopened {
  --mopa-transform: -100%;
}




/** **************
 * Navbar
 */
.m-mopa__navbar {
  @include flex;
  @include flex-align(center, middle);
  background-color: $mopa-primary-background;
  border-bottom: $mopa-border;
  height: $mopa-navbar-height;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}




/** **************
 * Title
 */
.m-mopa__title {
  @include flex;
  @include flex-align(center, middle);
  @include font-h3;
  color: $mopa-title-color;
  line-height: 1;
  position: relative;
  height: 100%;
  width: 100%;

  svg {
    position: absolute;
    left: rem-baseline(2);
    transform: rotate(180deg);
    top: 50%;
    margin-top: -#{math.div($mopa-title-arrow-size, 2)};
    width: $mopa-title-arrow-size;
    height: $mopa-title-arrow-size;
  }
}




/** **************
 * Wrapper
 */
.m-mopa__wrap {
  height: 100%;
  overflow: hidden;
}

/**
 * Modifier: Wrapper has controls
 */
.m-mopa__wrap--has-controls {
  padding-bottom: $mopa-controls-height;
}




/** **************
 * Controls
 */
.m-mopa__controls {
  @include u-baseline(3, padding-top);
  @include u-baseline(2, padding-right);
  @include u-baseline(2, padding-bottom);
  @include u-baseline(2, padding-left);
  background-color: $mopa-primary-background;
  border-top: $mopa-border;
  height: $mopa-controls-height;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

// Legend
//
// Legend element wrapped in `<fieldset>`. Do NOT USE AS AN INCLUDE!
//
// Markup: forms-legend.hbs
//
// Weight: 5
//
// Style guide: Forms.Legend

legend {
  @include font-h4;
  @include u-baseline(2, margin-bottom);
}

// Template for row scaffold
//

.t-row {
  &:not(.t-row--is-single),
  &:not(.t-row--nested),
  &:not(.t-row--stretched) {
    @include xy-grid-container;
    @include xy-grid;
  }

  &.t-row--nested {
    @include xy-grid-container($padding: 0);
    @include xy-grid;
    @include xy-gutters($negative: true);
  }

  &.t-row--stretched {
    @include xy-grid-container($width: 1600px);
    @include xy-grid;
  }

  &.t-row--is-cell {
    @include u-cell-row;
  }
}









/** **************
 * Modifier: 3 columns (large) / 2 columns (medium) / 1 column (small)
 */
.t-row--3-col {
  .t-row__item {
    @include xy-cell;
    overflow: hidden;

    &:not(.t-row__item--narrow) {
      @include u-module-margin;
    }

    @include breakpoint(medium) {
      @include xy-cell(6);
    }

    @include breakpoint(large) {
      @include xy-cell(4);
    }

    &.hide {
      flex: 0 0 0%;
    }
  }
}




/** ***********
 * Modifier: 3 columns to display content items
 * Grid: 3 (large) / 1 centered (medium) / 1 full (small)
 */
.t-row--3-col-display {
  @include flex-align(center, top);

  @include breakpoint(xlarge) {
    align-items: initial;
  }

  &.t-row--nested {
    @include breakpoint(xlarge) {
      margin-left: -1.25rem;
      margin-right: -1.25rem;
    }
  }

  .t-row__item {
    @include xy-cell;
    @include u-baseline(3, margin-bottom);

    @include breakpoint(medium) {
      @include xy-cell(10);
    }

    @include breakpoint(large) {
      @include xy-cell(7);
    }

    @include breakpoint(xlarge) {
      @include xy-cell(4, $gutters: rem-calc($global-baseline * 5));
    }
  }
}





/** **************
 * Modifier: 4 columns (large) / 3 columns (medium) / 3 column (small)
 *
 * Used for MARKEN (no bottom margin)
 */
.t-row--4-col {
  .t-row__item {
    @include xy-cell(6);
    overflow: hidden;

    @include breakpoint(medium) {
      @include xy-cell(4);
    }

    @include breakpoint(xlarge) {
      @include xy-cell(3);
    }

    &.hide {
      flex: 0 0 0%;
    }
  }
}









/** **************
 * Modifier: Template has 50-50 (2 * 6)
 */
.t-row--5050 {
  .t-row__item {
    @include xy-cell;
    @include u-module-margin;

    @include breakpoint(large) {
      @include xy-cell(6);
    }
  }
}




/** **************
 * Modifier: Row with to items and 1 column offset
 */
.t-row--double {
  .t-row__item {
    @include breakpoint(large) {
      @include xy-cell(6);
    }

    @include breakpoint(xlarge) {
      @include xy-cell(5);
      @include xy-cell-offset(1);
    }
  }
}




/** **************
 * Modifier: Template has single row, but narrow width
 */
.t-row--single-narrow {
  .t-row__item {
    @include breakpoint(large) {
      @include xy-cell(8);
      @include xy-cell-offset(2);
    }
  }
}




/** **************
 * Modifier: Template has single row and full width (except 1 offset)
 */
.t-row--single-full {
  .t-row__item {
    @include breakpoint(large) {
      @include xy-cell(10);
      @include xy-cell-offset(1);
    }
  }
}




/** **************
 * Modifier: Template has single text entry
 */
.t-row--single-entry {
  .t-row__item {
    @include breakpoint(large) {
      @include xy-cell;
    }
  }

  .t-row__inner {
    @include breakpoint(large) {
      @include u-baseline(3, padding-left);
      @include u-baseline(3, padding-right);
    }
  }
}




/** **************
 * Item / column
 */
.t-row__item {
  @include u-single-cell;
}

// Benefits
//
// List with checkmarks to display Benefits
//
// Markup: benefits.hbs
//
// Type: component
//
// Style guide: Lists.benefits

.c-benefits {
  @include u-list-reset;
  @include u-baseline(4, margin-bottom);
  margin-top: 0;

  > li {
    @include u-baseline(4.5, padding-left);
    position: relative;

    &:before {
      @include u-icon-relative-size;
      background-image: $global-icon-checkmark;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:not(:last-child) {
      @include u-baseline(1, margin-bottom);
    }
  }
}

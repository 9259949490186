// Icons
//
// All icons will be implemented as SVG.
//
// There are 2 types of implementation: SVG sprite and CSS background.
//
// To use a SVG via sprite, use this markup:
//
// ```
// <svg>
//  <use xlink:href="#svg_logo-prediger"></use>
// </svg>
// ```
//
// All SVG files must be prefixed with `svg_*.svg` to avoid multiple IDs in the DOM.
//
// To generate the SVG sprites, put the files in `assets/svg_src` and run follwing Gulp tasks:
//
// ```
// $ gulp svg
// ```
//
// When using SVG in CSS you **must not** implement them as base64 for performance reasons.
//
// **Important!**
//
// The SVG sprite will be stored in `localStorage` via JavaScript.
//
// More information: [https://osvaldas.info/caching-svg-sprite-in-localstorage](https://osvaldas.info/caching-svg-sprite-in-localstorage)
//
// See `utils/Svg.js`
//
// Markup: icons.hbs
//
// Chromeless: true
//
// Weight: 1
//
// Style guide: Icons

.c-icon-defs {
  height: 0;
  width: 0;
  overflow: hidden;

  polygon:not(.nofill),
  path:not(.nofill),
  rect:not(.nofill) {
    fill: currentColor;
    overflow: hidden;
  }
}

.c-icon {
  display: inline-block;
  vertical-align: middle;
  transform: translateY(-.1em);

  &:not(.c-icon--nofill) {
    fill: currentColor;
  }
}


.c-icon--payment {
  width: rem-calc($global-payment-icon-width);
  height: rem-calc($global-payment-icon-height);
}



// User Interface
//
// User interface icons
//
// Markup: icons--ui.hbs
//
// Chromeless: true
//
// Weight: 1
//
// Style guide: Icons.ui



// Product Attributes
//
// Features, labels etc.
//
// Markup: icons--product-attributes.hbs
//
// Chromeless: true
//
// Weight: 2
//
// Style guide: Icons.product-attributes


// Promotion
//
// Promotional icons for e.g. light consulting. These icons doesn't have custom color fills via CSS.
//
// Markup: icons--promotion.hbs
//
// Chromeless: true
//
// Weight: 3
//
// Style guide: Icons.promotion


// Payment
//
// Payment options. These icons doesn't have custom color fills via CSS.
//
// Markup: icons--payment.hbs
//
// Chromeless: true
//
// Weight: 4
//
// Style guide: Icons.payment


// Social
//
// Social media platform icons.
//
// Markup: icons--social.hbs
//
// Chromeless: true
//
// Weight: 5
//
// Style guide: Icons.social

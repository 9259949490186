// Select
//
// Form styles and scaffold
//
// .c-select--light - light background for grey pages
//
// Weight: 2
//
// Markup: forms-select.hbs
//
// Type: component
//
// Style guide: Forms.Select

$form-select-background: var(--color-pearl);

/** **************
 * Global styles
 */
select {
  display: block;
  width: 100%;
}

.c-select {
  @include form-select;
}




/** **************
 * Light background
 */
.c-select--light {
  background-color: var(--color-light);
}



/**
 * Pushing top
 */
.c-select--push {
  @include u-baseline(1, margin-top);
}




/** **************
 * Auto width for multiple selects in a row
 */
.c-select--auto-width {
  @include u-baseline(2, margin-right);
  display: inline-block;

  &:last-of-type {
    margin-right: 0;
  }

  select {
    @include u-baseline(5, padding-right);
  }
}



/** **************
 * Select is a link
 */
.c-select--is-link {
  @include u-baseline(1.25, padding);
  @include u-baseline(2, padding-left);
}




/** **************
 * No styles, but dropdown
 */
.c-select--plain {
  background-color: transparent;
  border: none;
  display: inline-block;
  font-size: inherit;
  height: auto;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  width: auto;

  &:focus {
    background-color: inherit;
    border: inherit;
  }
}

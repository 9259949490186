/** ***********
 * RMA form styles and overrides
 *
 * This is not a pattern library component,
 * but only a few tweaks.
 */

/** ***********
 * Form item
 */
.o-rma-item {
  position: relative;

  .m-article {
    @include u-baseline(6, margin-left);
  }
}





/** ***********
 * Modifier: Item is disabled and cannot be selected
 */
.o-rma-item--disabled {
  @include u-baseline(2, margin-bottom);
  cursor: not-allowed;
  opacity: .4;

  label {
    cursor: not-allowed;
  }
}




/** ***********
 * Modifier: Multiple articles (verify order template)
 */
.o-rma-item--multi {
  .m-article {
    @include u-baseline(5, margin-left);
  }
}




/** ***********
 * More items (verify order template
 */
.o-rma-item__more {
  @include u-baseline(2, margin-top);
  @include u-baseline(5, margin-left);
  color: var(--color-slate-petrol);
}




/** ***********
 * Checkbox
 */
.o-rma-form__cb {
  position: absolute;
  top: 0;
  left: 0;
}




/** ***********
 * Item options
 */
.o-rma-form__options {
  @include u-baseline(3, margin-top);

  @include breakpoint(xlarge) {
    @include u-baseline(6, padding-left);
    display: flex;
  }
}

.o-rma-form__amount {
  @include u-baseline(3, margin-bottom);

  @include breakpoint(xlarge) {
    @include u-baseline(4, padding-right);
    flex: 0 0 rem-calc(216);
    margin-bottom: 0;
  }

  .form__input {
    width: rem-calc(140);
  }
}

.o-rma-form__select {
  flex: 1 0 auto;
  flex-shrink: 1;
}

// Intro
//
// Heading image and heading. Sub-heading and copytext are optional.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 40x18      |
// | small         | 520x237    |
// | large         | 1057x482   |
// **Note:** `small` is optional (if displayed on small devices). The image heights are flexible, as long all formats fit.
//
// Markup: intro.hbs
//
// Type: module
//
// Style guide: Containers.Intro

/** **************
 * Basic styles
 */
.m-intro {
  @include u-module-margin;

  .heading--xl {
    @include u-baseline(1, margin-bottom);
    margin-top: 0;
  }

  [class*='heading'] + .m-intro__entry {
    @include u-baseline(1, padding-top);
    margin-top: 0;
  }
}




/** **************
 * Headings
 */
.m-intro__heading {
  @include font-h1;
  @include font-bold-important;
  @include u-baseline(1, margin-bottom);
  margin-top: 0;

  + .m-intro__entry {
    @include u-baseline(1, padding-top);
    margin-top: 0;
  }

  + .m-intro__img {
    @include u-baseline(1, padding-top);
  }
}

.m-intro__subheading {
  @include typo-heading-sub();
}




/** **************
 * Image
 */
.m-intro__img {
  img {
    @include u-img-responsive;
  }
}




/** **************
 * Content entry
 */
.m-intro__entry {
  @include font-meta;
  @include u-baseline(2, margin-bottom);

  p {
    margin-top: 0;
  }
}

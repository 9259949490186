// Button X
//
// Button for external services and links. The button can be disabled with the `is_disabled` parameter.
//
// Markup: buttonx.hbs
//
// Type: component
//
// Style guide: Controls.buttonx
.c-buttonx {
  @include u-baseline(7, height);
  @include u-baseline(5, line-height);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  box-shadow: var(--shadow-light);
  background-color: var(--color-light);
  border-radius: rem-calc($global-border-radius);
  border: 1px solid var(--color-concrete);
  display: block;
  width: 100%;

  &:hover,
  &:active {
    box-shadow: var(--shadow-medium);
    background-color: var(--color-pearl);
  }
}








/** ***********
 * Modifier: Button is disabled
 */
.c-buttonx--is-disabled {
  cursor: not-allowed;
  opacity: .5;
}








/** ***********
 * Inner container
 */
.c-buttonx__inner {
  @include flex;
  @include flex-align(center, middle);
  height: 100%;
}










/** ***********
 * Icon
 */
.c-buttonx__icon {
  @include u-baseline(2, margin-right);

  img {
    display: block;
    max-width: none;
    height: 1.25em;
  }
}









/** ***********
 * Text value
 */
.c-buttonx__text {
  white-space: nowrap;
}

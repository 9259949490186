// Order
//
// List item for orders overview in customer account.
//
// Markup: order.hbs
//
// Type: module
//
// Style guide: Products.order
@use 'sass:math';

$order-breakpoint: large;
$order-img-flex: 176;

.m-order {
  --image-ratio: #{math.div($order-img-flex, 2)} / 215;
  @include c-card;
  @include u-baseline(2, gap);
  @include u-baseline(2, margin-bottom);
  box-shadow: var(--order-shadow, var(--shadow-light));
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-template-areas: 'title status' 'products products' 'sum meta';
  position: relative;

  @include breakpoint($order-breakpoint) {
    grid-template-columns: rem-calc(170) 1fr rem-calc(180);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 'title products meta' 'status products sum';
    grid-row-gap: 0;
  }

  &:hover {
    --order-shadow: var(--shadow-medium);
  }
}




/** ***********
 * Modifier: Order is selectable
 */
.m-order--is-selectable {
  grid-template-columns: rem-calc(24) 1fr 1fr;
  grid-template-areas: 'selectable title title' 'products products products' 'sum sum meta';

  @include breakpoint($order-breakpoint) {
    grid-template-columns: rem-calc(24) rem-calc(170) 1fr rem-calc(180);
    grid-template-areas: 'selectable title products meta' 'selectable status products sum';
  }

  .m-order__title {
    @include breakpoint($order-breakpoint down) {
      @include u-baseline(.5, padding-top);
    }
  }
}






/** ***********
 * Order selectable form element
 */
.m-order__selectable {
  grid-area: selectable;

  .c-radio {
    @include u-baseline(-2, margin-top);

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }
}




/** ***********
 * Title
 */
.m-order__title {
  @include u-baseline(2, margin-bottom);
  grid-area: title;
}






/** ***********
 * Invisible item link
 */
.m-order__link {
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}






/** ***********
 * Status
 */
.m-order__status {
  grid-area: status;
  justify-self: flex-end;

  @include breakpoint($order-breakpoint) {
    align-self: flex-end;
    justify-self: flex-start;
  }
}





/** ***********
 * Products
 */
.m-order__products {
  @include u-baseline(2, gap);
  align-self: flex-start;
  grid-area: products;
  display: flex;

  .m-product {
    flex: 0 1 rem-calc($order-img-flex);
    width: 100%;
  }

  .c-product-info__title {
    @include u-line-clamp($lines: 3);
  }
}







/** ***********
 * Remaining amount of products
 */
.m-order__remaining {
  @include font-lead;
  @include font-bold-important;
  aspect-ratio: var(--image-ratio);
  background-color: var(--color-concrete);
  color: var(--color-slate);
  display: flex;
  flex: 0 1 rem-calc(math.div($order-img-flex, 2));
  height: max-content;
  justify-content: center;
  align-items: center;
}





/** ***********
 * Meta
 */
.m-order__meta {
  align-self: flex-end;
  grid-area: meta;
  justify-self: flex-end;
  text-align: right;
  position: relative;
  z-index: 2;

  @include breakpoint($order-breakpoint) {
    align-self: flex-start;
  }
}





/** ***********
 * Summary
 */
.m-order__sum {
  grid-area: sum;

  @include breakpoint($order-breakpoint) {
    align-self: flex-end;
    justify-self: flex-end;
    text-align: right;
  }
}

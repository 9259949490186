// Appointment
//
// App wrapper
//
// Markup: appointment.hbs
//
// Type: template
//
// Javascript: true
//
// Style guide: Appointment.appointment
@use 'sass:math';

$appointment-border-radius: rem-calc(4);

.t-appointment {
  @include u-baseline(56, min-height);
  background-color: var(--color-light);
  border-radius: $appointment-border-radius;
  box-shadow: var(--shadow-medium);
  display: grid;
  overflow: hidden;
  position: relative;
}





/** ***********
 * Location select
 */
.t-appointment__location {
  @include u-baseline(2, padding);
  border-bottom: 1px solid var(--color-geyser);
  text-align: center;

  @include breakpoint(large) {
    @include u-baseline(4, padding);
  }

  .c-select {
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(360);
  }
}

.t-appointment_help {
  @include font-tiny;
  color: var(--color-slate-petrol);
  margin-left: auto;
  margin-right: auto;
  max-width: 50ch;

  a {
    @include u-link($global-link-options-underline-inherit);
  }
}









/** ***********
 * Date section
 */
.t-appointment__date {
  @include u-baseline(2, padding);

  @include breakpoint(large) {
    @include u-baseline(4, padding);
  }
}

/** ***********
 * Datepicker container
 */
.t-appointment__datepicker {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-auto-rows: min-content;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  justify-content: center;
  justify-items: center;

  @include breakpoint(xlarge) {
    transition: all .25s ease;
    display: grid;
    grid-template-columns: 1fr 0fr;
    grid-template-rows: auto;
    gap: 0 0;
    justify-content: center;
    justify-items: center;
    align-items: flex-start;

    &[data-display='ready'] {
      @include u-baseline(50, min-height);
    }

    &[data-state='open'] {
      grid-template-columns: auto 1fr;
    }
  }
}

/** ***********
 * Calendar section
 */
.t-appointment__calendar {
  --overload-top: #{rem-baseline(6)};
  @include u-baseline(3, margin-bottom);
  max-width: rem-calc(308);

  @include breakpoint(xlarge) {
    margin-bottom: 0;
  }
}

/** ***********
 * Times container
 */
.t-appointment__times {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  width: 100%;

  @include breakpoint(large) {
    justify-self: center;
    transition: var(--transition-smoother);
  }

  &[data-state='show'] {
    opacity: 1;
    visibility: visible;
  }
}

.t-appointment__times-list {
  @include breakpoint(xlarge) {
    @include u-baseline(37, height);
    overflow-y: auto;
  }

  &.u-list--plain {
    margin-bottom: 0;
  }
}

.t-appointment__times-item {
  @include u-baseline(5, height);
  @include u-baseline(5, line-height);
  @include font-small;
  background-color: var(--color-pearl);
  border: 1px solid var(--color-slush-ice);
  border-radius: rem-calc(4);
  cursor: pointer;
  display: inline-block;
  max-width: rem-calc(200);
  width: 100%;

  &:hover {
    background-color: var(--color-concrete);
  }

  &[data-state='selected'] {
    background-color: var(--color-orange);
    border-color: var(--color-orange);
    color: var(--color-light);
  }
}










/** ***********
 * User form and confirm
 */
.t-appointment__form {
  @include u-baseline(2, padding);
  background-color: var(--color-pearl);
  border-top: 1px solid var(--color-geyser);

  @include breakpoint(large) {
    @include u-baseline(4, padding);
  }
}

.t-appointment__form-wrap {
  @include u-baseline(4, margin-bottom);

  @include breakpoint(xlarge) {
    @include u-baseline(4, gap);
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
  }
}

.t-appointment__form-info {
  @include font-small;
  @include u-baseline(3, padding-top);
}






/** ***********
 * Content container
 */
.t-appointment__content {
  @include u-baseline(3, padding);

  @include breakpoint(xlarge) {
    @include u-baseline(5, padding);
  }
}






/** ***********
 * Footnote
 */
.t-appointment__footnote {
  @include font-tiny;
  color: var(--color-iron);
  margin: 0 auto;
  max-width: 70ch;
  text-align: center;

  a {
    @include u-link($global-link-options-underline-inherit);
  }
}







/** ***********
 * Error container
 */
.t-appointment__error {
  --note-max-width: #{rem-calc(460)};
  --note-list-columns: 170px 2;
  display: grid;
  gap: 0 rm-baseline(20);
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 100%;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

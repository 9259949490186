// Basket
//
// Selected product freshly put in shopping cart.
// Appears in reveal module.
//
// Markup: basket.hbs
//
// Type: module
//
// Style guide: Products.basket

$basket-background: var(--color-light);
$basket-image-width: rem-calc(104);

/** **************
 * Wrapper
 */
.m-basket {
  @include u-baseline(2, padding);
  @include u-baseline(3, padding-bottom);
  background-color: $basket-background;
}





/** **************
 * Product
 */
.m-basket__product {
  @include u-baseline(3, margin-bottom);
  @include u-baseline(2, gap);
  @include u-floating-label($selector: '.c-energy');
  display: flex;

  figure {
    flex: 0 0 $basket-image-width;
    width: $basket-image-width;
    position: relative;
  }

  img {
    @include u-img-responsive;
  }

  article {
    @include font-meta($type: 'display');
    padding-left: rem-baseline(2);
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}




/** **************
 * Options (Buttons)
 */
.m-basket__options {
  @include u-baseline(2, gap);
  display: grid;
  margin: 0 auto;
  max-width: rem-calc(540);

  @include breakpoint(medium) {
    @include u-baseline(3, gap);
    grid-template-columns: 1fr 1fr;
  }

  .c-button {
    display: block;
  }
}

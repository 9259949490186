// Snazzy Box
//
// Content teaser with flexible width and background image.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 9999x30    |
// | large         | 9999x310   |
// 9999 = Image width is flexible.
//
// Markup: snazzybox.hbs
//
// Type: module
//
// Style guide: Promotion.snazzybox

$snazzybox-background: rgb(0, 0, 0);
$snazzybox-gradient-start: rgba(0, 0, 0, .85);
$snazzybox-gradient-stop: rgba(0, 0, 0, 0);

.m-snazzybox {
  @include u-baseline(3, padding);
  @include u-baseline(48, height);
  @include flex-align(left, bottom);
  background-color: var(--snazzybox-bg, var(--color-light));
  display: flex;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &:hover {
    .c-image {
      filter: brightness(120%);
    }
  }

  // Backdrop gradient
  &:not(.m-snazzybox--is-plain):after {
    background-color: $snazzybox-background;
    background: linear-gradient(0deg, $snazzybox-gradient-start 0%, $snazzybox-gradient-stop 100%);
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}










/** ***********
 * Image
 */
.m-snazzybox__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  .c-image {
    flex: 0 0 auto;
    height: 100%;
    width: 100%;
    padding-top: 0 !important;
  }

  img {
    object-fit: cover;
    height: 100%;
  }

  .c-image__original {
    position: relative;
  }
}










/** ***********
 * Content
 */
.m-snazzybox__content {
  z-index: 2;

  // Arrowlink override
  // @TODO: Change component's structure to CSS vars
  .c-arrowlink {
    color: var(--snazzybox-color, var(--color-light));

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
    }

    &:hover {
      color: var(--snazzybox-color, var(--color-light));
      text-decoration: underline;

      &:before {
        background-color: var(--color-orange);
      }

      &:after {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}






/** ***********
 * Heading
 */
.m-snazzybox__heading {
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(2, margin-bottom);
  color: var(--snazzybox-color, var(--color-light));
  max-width: 40ch;
}

// Iconbox
//
// Small contentbox with icon and padding.
//
// How to use in templates:
//
// ```
// {{#> iconbox/iconbox is_template=true icon="svg_sprite_id"}}
//   Content goes here …
// {{/iconbox/iconbox}}
// ```
//
// Markup: iconbox.hbs
//
// Type: component
//
// Style guide: Containers.iconbox

.c-iconbox {
  @include u-baseline(5, padding-left);
  @include u-baseline(3, padding-right);
  @include u-baseline(.5, padding-top);
  display: block;
  position: relative;

  svg {
    @include u-icon-relative-size;

    &:first-of-type {
      position: absolute;
      left: 0;
      top: rem-baseline(.5);
    }
  }

  p {
    &:first-of-type {
      margin-top: 0;
    }
  }
}









/** ***********
 * Item
 */
.c-iconbox__item {
  font-style: italic;
}

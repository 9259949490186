//
// Variants
//
.o-variants {
  .c-select {
    @include breakpoint(medium) {
      min-width: rem-calc(220);
      max-width: rem-calc(300);
    }
  }
}

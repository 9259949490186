// Overflower
//
// Container with scrollable/draggable items (horizontal). This module uses styles and functions from [Keen Slider](https://keen-slider.io) plugin.
//
// Markup: overflower.hbs
//
// Type: organism
//
// Javascript: true
//
// Style guide: Containers.overflower
@use 'sass:math';

$overflower-default-margin: rem-baseline(5);
$overflower-default-width: rem-calc(300);
$overflower-controls-size: rem-calc($global-baseline * 6);

@import '_mixins';

.o-overflower {
  @include u-baseline(4, margin-bottom);
  position: relative;

  .navigation-wrapper {
    position: relative;
  }
}







/** ***********
 * Header + headings
 */
.o-overflower__header {
  @include u-baseline(3, margin-bottom);
  text-align: var(--overflower-align);
}

.o-overflower__heading {
  @include font-h3;
  @include font-bold-important;
  margin: 0;
}

.o-overflower__subheading {
  @include font-meta;
  color: var(--color-iron);
}




/** ***********
 * Inner container
 */
.o-overflower__slides {
  display: flex;
  overflow-y: hidden;
  width: 100%;
  justify-content: var(--overflower-align, unset);

  @include breakpoint(large) {
    --overflower-item-basis: 40vw;
    --overflower-item-width: #{rem-calc(340)};
  }

  img,
  a {
    user-select: none;
  }

  &:not([data-keen-slider-disabled]) {
    align-content: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 100%;

    .o-overflower__slide {
      position: relative;
      overflow: hidden;
      width: 100%;
      min-height: 100%;
    }
  }

  &:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
    flex-direction: row-reverse;
  }

  &:not([data-keen-slider-disabled])[data-keen-slider-v] {
    flex-wrap: wrap;
  }

  [data-keen-slider-moves] * {
    pointer-events: none;
  }
}








/** ***********
 * Item
 */
.o-overflower__slide {
  @include u-baseline(4, padding-right);
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: var(--overflower-item-basis, 80vw);
  max-width: var(--overflower-item-width, #{$overflower-default-width});
  width: 100%;

  // 1 item only
  &:first-child:last-child {
    @include o-overflower-reset;
    margin-left: auto;
    margin-right: auto;
  }

  // 2 items only
  @include breakpoint(medium) {
    &:nth-child(2):last-child,
    &:nth-child(1):nth-last-child(1),
    &:first-child:nth-last-child(2) {
      @include o-overflower-reset;
    }
  }

  @include breakpoint(large) {
    max-width: var(--overflower-item-width, #{$overflower-default-width});
  }

  // 3 items only
  @include breakpoint(xlarge) {
    &:nth-child(3):last-child,
    &:nth-child(2):nth-last-child(2),
    &:first-child:nth-last-child(3) {
      @include o-overflower-reset;
    }
  }
}





/** ***********
 * Modifier: Overflower has products
 */
[data-overflower-display='products'] {
  .o-overflower__item {
    display: flex;
  }

  .o-overflower__arrow {
    top: calc(50% - #{rem-calc(146) - (#{math.div($overflower-controls-size, 2)})});
  }
}



/** ***********
 * Modifier: Overflower is a single slider
 */
[data-overflower-display='single'] {
  .o-overflower__slides {
    display: flex;
    flex-basis: 100% !important;
    max-width: none !important;
    padding: 0 !important;
  }

  .m-display {
    width: 100%;
  }
}






/** ***********
 * Controls (arrows)
 */
.o-overflower__arrow {
  background-color: var(--color-geyser);
  box-shadow: var(--shadow-medium);
  border-radius: 50%;
  color: var(--color-petrol);
  cursor: pointer;
  width: $overflower-controls-size;
  height: $overflower-controls-size;
  position: absolute;
  top: calc(50% - #{math.div($overflower-controls-size, 2)});
  transition: all .15s ease;
  z-index: 2;

  &:hover {
    background-color: var(--color-petrol);
    box-shadow: var(--shadow-heavy);
    color: var(--color-ice);
  }

  &.arrow--left {
    left: -(#{math.div($overflower-controls-size, 2)});

    .c-icon {
      transform: scale(-1);
      top: calc(50% - #{rem-calc(9)});
    }
  }

  &.arrow--right {
    right: -(#{math.div($overflower-controls-size, 2)});
  }

  &.arrow--disabled {
    display: none;
  }

  .c-icon {
    position: absolute;
    top: calc(50% - #{rem-calc(7)});
    left: calc(50% - #{rem-calc(5)});
    width: rem-calc(10);
    height: rem-calc(17);
    vertical-align: top;
  }
}

/// Navbar in site header
///
/// NOT A MODULE – belongs to global scaffold

@import '_config';
@import '_mixins';

.m-navbar {
  @include xy-grid-container;
  @include xy-grid;
  @include flex-align(left, middle);
  background-color: $navbar-background-small;
  border-bottom: 1px solid $navbar-border-color;

  @include breakpoint($global-navigation-breakpoint) {
    background-color: transparent;
    border-bottom: none;
    position: relative;
  }
}




/** **************
 * Burger icon
 */
.m-navbar__handler {
  @include u-baseline(1, left);
  @include u-baseline(1.2, top);
  -webkit-tap-highlight-color: transparent;
  color: $navbar-handler-color;
  cursor: pointer;
  display: inline-block;
  width: rem-calc($navbar-toggle-width);
  height: rem-calc($navbar-toggle-height);
  margin: 0 !important; // override foundation margin
  position: absolute;

  @include breakpoint($global-navigation-breakpoint) {
    display: none !important;
  }

  svg {
    @include u-baseline(1, top);
    @include u-baseline(1, left);
    position: absolute;

    &:last-child {
      @include u-toggle-visibility;
    }
  }
}




/** **************
 * Burger checkbox (invisible)
 */
.m-navbar__toggle {
  position: absolute;
  top: -9999px;
  left: -9999px;

  @include breakpoint($global-navigation-breakpoint) {
    display: none;
  }
}




/** **************
 * Navbar menu
 */
.m-navbar__menu {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translate(-($global-navigation-mobile-width), 0);
  width: $global-navigation-mobile-width;
  transition: var(--transition-default);
  z-index: index($elements, navigation);

  @include breakpoint($global-navigation-breakpoint) {
    @include xy-cell;
    background-color: transparent;
    position: relative;
    left: 0;
    transition: none;
    transform: translate(0, 0);
  }
}






/** ***********
 * Menu list
 */
.m-navbar__list {
  background-color: var(--color-dark);
  margin: 0;

  @include breakpoint($global-navigation-breakpoint) {
    display: flex;
    justify-content: space-between;
  }
}

.m-navbar__list-item {
  @include breakpoint($global-navigation-breakpoint) {
    display: flex;
  }
}


.m-navbar__item {
  display: block;

  @include breakpoint($global-navigation-breakpoint) {
    border-top: rem-calc(3) solid transparent;

    &--is-current,
    &[data-state='open'] {
      border-top: rem-calc(3) solid var(--color-light);
    }
  }
}




/** **************
 * Opens menu when checkbox is checked
 */
.m-offcanvas--is-open ~ .m-navbar__menu {
  transform: translate(0, 0);
}




/** **************
 * Burger into X when checkbox is checked
 */
.m-offcanvas--is-open.m-navbar__handler {
  svg {
    &:first-child {
      @include u-toggle-visibility;
    }

    &:last-child {
      @include u-toggle-visibility('show');
    }
  }
}




/** **************
 * Overlay on closed menu
 */
.m-navbar__overlay-label {
  background-color: $navbar-overlay-label-background;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  pointer-events: none; // Important to prevent unwanted triggering
  transition: var(--transition-default);
  opacity: 0;
  visibility: hidden;
  z-index: -1;

  @include breakpoint($global-navigation-breakpoint) {
    height: auto !important;
  }
}




/** **************
 * Overlay on opened menu
 */
.m-offcanvas--is-open ~ .m-navbar__overlay-label {
  display: block;
  opacity: 1;
  pointer-events: auto;
  z-index: 1;
  visibility: visible;
}




/** **************
 * Modifier: Item has flyout
 */
.m-navbar__item--has-flyout {

  // Arrow
  .m-navbar__link:after {
    @include breakpoint($global-navigation-breakpoint) {
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-top-color: $navbar-arrow-color;
      border-left-width: rem-calc(18);
      border-right-width: rem-calc(18);
      border-top-width: rem-calc(16);
      bottom: -($navbar-arrow-size);
      left: calc(50% - #{rem-calc(18)});
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }
  }
}


.m-navbar__item--has-flyout[data-state='open'] {
  @include breakpoint($global-navigation-breakpoint) {
    .m-navbar__link:after {
      opacity: 1;
      visibility: visible;
      z-index: 2;
    }
  }
}



/** **************
 * Modifier: Item has meta
 */
.m-navbar__item--meta {
  background-color: var(--color-slate-petrol);
  font-family: font(base, 'copy');

  @include breakpoint($global-navigation-breakpoint) {
    display: none;
  }
}




/** **************
 * Link
 */
.m-navbar__link {
  @include font-meta($type: 'display');
  display: block;

  @include breakpoint($global-navigation-breakpoint) {
    color: var(--color-light);
    padding: $navbar-padding-link;
    position: relative;

    &:hover {
      color: var(--color-slush-ice);
    }
  }
}

.m-navbar--right {
  .m-navbar__item:last-child {
    .m-navbar__link {
      padding-right: 0;
    }
  }
}





/** **************
 * Meta
 */
.m-navbar__meta {
  font-size: 0;
  text-align: right;
  z-index: index($elements, header_meta);

  @include breakpoint($global-navigation-breakpoint-down) {
    width: 100%;
  }

  @include breakpoint($global-navigation-breakpoint) {
    @include xy-grid-container;
    @include xy-grid;
    position: absolute;
    right: $global-grid-gutter;
    top: rem-calc(4);
  }

  ul {
    @include u-list-reset;
    margin: 0;
  }

  li {
    @include font-small($type: 'display');
    display: inline-block;
  }

  .c-icon {
    @include u-icon-relative-size;
    transform: translateY(0);

    @include breakpoint($global-navigation-breakpoint) {
      width: 1.25em;
      height: 1.25em;
    }
  }
}






/** **************
 * Merkliste + Warenkorb icon
 */
.m-navbar__essentials {
  position: absolute;
  top: rem-calc(13);
  right: 0;
  z-index: 1;

  @include breakpoint($global-navigation-breakpoint) {
    position: relative;
    top: 0;
  }

  li {
    position: relative;
  }

  a {
    color: $navbar-color;
    display: inline-block;
    padding: 0 rem-calc(10);

    @include breakpoint($global-navigation-breakpoint) {
      padding: rem-calc(11) rem-calc(20) rem-calc(12);
    }

    &:after {
      @include breakpoint($global-navigation-breakpoint) {
        @include navbar-border;
        right: 0;
      }
    }
  }
}




/** **************
 * Counter
 */
.m-navbar__count {
  &:not(:empty) {
    @include font-tiny;
    color: $navbar-count-active-color;
    display: inline-block;
    padding-left: rem-calc(4);
    transform: translateY(rem-calc(6));
    vertical-align: top;
  }
}

// Pricing
//
// Pricing card.
//
// Markup: pricing.hbs
//
// .m-pricing--highlight - Highlighted colors.
//
// Type: module
//
// Style guide: Promotion.pricing

$pricing-background: var(--color-pearl);
$pricing-highlight-background: var(--color-ice);

.m-pricing {
  @include u-baseline(2, padding);
  @include u-baseline(3, padding-bottom);
  @include flex;
  background-color: $pricing-background;
  flex-direction: column;
  height: 100%;

  @include breakpoint(large) {
    @include u-baseline(3, padding);
    @include u-baseline(4, padding-bottom);
  }

  .c-icon {
    @include u-baseline(5, width);
    @include u-baseline(5, height);
    @include u-baseline(2, margin-top);
    @include u-baseline(3, margin-bottom);
    color: $global-primary-color;
  }

  &:not(:has(.c-icon)) {
    @include breakpoint(large) {
      @include u-baseline(5, padding-top);
    }
  }
}






/** ***********
 * Modifier: Highlight colors
 */
.m-pricing--highlight {
  background: $pricing-highlight-background;

  .m-pricing__price {
    color: $global-primary-color;
  }
}







/** ***********
 * Heading
 */
.m-pricing__heading {
  @include font-h2;
  @include font-bold-important;
  @include u-baseline(1, margin-bottom);
  margin-top: 0;
}







/** ***********
  * Body
  */
.m-pricing__body {
  flex: 1 0 auto;

  p {
    @include u-baseline(2, margin-bottom);
    margin-top: 0;
  }
}








/** ***********
 * Price
 */
.m-pricing__price {
  @include font-h2;
  @include font-bold-important;
}

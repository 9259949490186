// Mixin for 'ol' and 'ul' lists
@mixin c-list($style: 'ol') {
  --list-style-color: var(--color-slate-petrol);
  @include u-baseline(4, padding-left);
  @include u-baseline(2, margin-top);
  @include u-baseline(2, margin-bottom);

  &:not(:last-child) {
    @include u-module-margin;
  }

  li {
    @include u-baseline(1, margin-bottom);
    list-style: none;
    position: relative;

    @if $style == 'ol' {
      counter-increment: list;
      list-style-type: none;

      &:before {
        color: var(--list-style-color);
        content: counter(list) '.';
        position: absolute;
        left: -(rem-baseline(6));
        top: 0;
        text-align: right;
        width: 2em;
      }
    } @else if $style == 'ul' {
      &:before {
        background-color: var(--list-style-color);
        border-radius: 50%;
        content: '';
        display: block;
        position: absolute;
        left: -(rem-baseline(3));
        top: rem-baseline(1.75);
        width: $list-style-size;
        height: $list-style-size;
      }
    }
  }

  > li > ul {
    @include u-baseline(1, margin-top);
    @include u-baseline(3, padding-left);
    margin-top: rem-baseline(1);
    margin-bottom: 0;
  }
}

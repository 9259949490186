// Colors
$offcanvas-background: var(--color-slate-petrol);
$offcanvas-sub-background: var(--color-pearl);
$offcanvas-sub-color: var(--color-iron);
$offcanvas-category-color: var(--color-dark);
$offcanvas-title-color: var(--color-dark);




// Sizes
$offcanvas-item-padding: rem-calc(20) rem-calc(20) rem-calc(20) rem-baseline(2);




// Borders
$offcanvas-border: 1px solid var(--color-slate);
$offcanvas-sub-border: 1px solid var(--color-slush-ice);

// wp-caption
//
// WordPress image class with caption text. Use this classes for other systems, too.
//
// Use `.wp-caption.alignleft` and `.wp-caption.alignright` for floating images in text.
//
// Markup: wp-caption.hbs
//
// Style guide: Containers.wp-caption

$wpcaption-breakpoint: medium;
$wordpress-caption-color: var(--color-slate-petrol);

/** **************
 * Basic styles
 */
[class*='wp-image'] {
  display: inline-block;
  max-width: 100%;

  // Medium images
  &.size-medium {
    &.alignleft,
    &.alignright {
      @include u-baseline(2, margin-bottom);
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}





/** ***********
 * Large images
 */
[class*='wp-image'].size-full,
[class*='wp-image'].size-large {
  @include u-img-responsive;

  // Override since old posts
  // have style attributes
  height: auto !important;
  width: 100% !important;
}








/** ***********
 * Responsive behavior
 */
.wp-caption,
[class*='wp-image'].size-medium {
  max-width: 100%;
  text-align: center;

  img {
    display: block;
  }

  &.alignleft,
  &.alignright {
    @include breakpoint($wpcaption-breakpoint) {
      display: table;
      padding-top: rem-calc(4);

      .wp-caption-text {
        display: table-caption;
        caption-side: bottom;
        margin-bottom: 0 !important;
      }
    }
  }

  &.alignleft {
    @include breakpoint($wpcaption-breakpoint) {
      @include u-baseline(4, margin-right);
      float: left;
    }
  }

  &.alignright {
    @include breakpoint($wpcaption-breakpoint) {
      @include u-baseline(4, margin-left);
      float: right;
    }
  }
}




/** **************
 * Caption text
 */
.wp-caption-text {
  @include font-meta;
  @include u-baseline(4, margin-bottom);
  color: $wordpress-caption-color;
  margin-top: (rem-baseline(2)) !important;
  text-align: left;
}

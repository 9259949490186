// Billboard
//
// Big box with icon and heading.
//
// Markup: billboard.hbs
//
// Type: module
//
// Style guide: Containers.billboard
.m-billboard {
  @include u-baseline(3, margin-bottom);
  background-color: var(--color-ice);
  display: flex;
  flex-direction: column;
  text-align: center;
}




/** ***********
 * Icon
 */
.m-billboard__icon {
  @include u-baseline(9, padding-top);
  @include u-baseline(2, margin-bottom);

  .c-icon {
    @include u-baseline(10, width);
    @include u-baseline(10, height);
    color: var(--color-slush);
  }
}





/** ***********
 * Heading
 */
.m-billboard__heading {
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(12, height);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  margin: 0;
}

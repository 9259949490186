// Reset overflower item flex-basis
@mixin o-overflower-reset() {
  // margin-bottom: var(--overflower-margin, #{$overflower-default-margin});
  flex-basis: 0;
  max-width: rem-calc(1057);

  &:last-child {
    padding-right: 0;
  }
}

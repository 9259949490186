// File Upload
//
// Upload field for [dropzone](section-forms.html#kssref-forms-dropzone) organism.
//
// Markup: upload.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Forms.dropzone.upload

/** **************
 * Basic styles
 */
.m-upload {
  @include font-meta;
  @include u-baseline(2, margin-bottom);
  @include u-baseline(5, padding-top);
  @include u-baseline(5, padding-bottom);
  @include u-baseline(3, padding-left);
  @include u-baseline(3, padding-right);
  background: var(--color-light);
  border: 1px dashed var(--color-petrol);
  border-radius: rem-calc($global-border-radius);
  color: var(--color-petrol);
  text-align: center;

  p {
    margin-bottom: rem-calc(4) !important;
    margin-top: 0 !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  [class*='u-link'] {
    color: var(--color-petrol) !important;
  }

  &.is-active {
    background: var(--color-geyser);
  }

  .c-icon {
    @include u-baseline(1, margin-bottom);
    width: 2em;
    height: 2em;
  }
}





/** **************
 * Description
 */
.m-upload__desc {
  @include font-small; // override
}

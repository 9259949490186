// Blockquote
//
// Basic blockquote styles
//
// .c-blockquote--large - Large blockquote for testimonials.
//
// Markup: blockquote.hbs
//
// Style guide: Content.Blockquote

blockquote,
.c-blockquote {
  @include u-baseline(3, margin-top);
  @include u-baseline(4, margin-bottom);
  margin-left: var(--blockquote-margin-left, #{rem-baseline(2)});
  margin-right: 0;
  padding-left: var(--blockquote-padding-left, #{rem-baseline(5)});
  position: relative;

  @include breakpoint(large) {
    --blockquote-margin-left: #{rem-baseline(4)};
  }

  &:before {
    background-image: $global-icon-blockquote;
    background-repeat: no-repeat;
    background-position: top center;
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    top: var(--blockquote-icon-top, -#{rem-calc(4)});
    width: var(--blockquote-icon-width, #{rem-calc(26)});
  }


  p {
    @include font-entry;
    @include font-bold-important;
    color: var(--section-color);
    margin: 0;
  }

  footer {
    @include font-meta;
    color: var(--section-secondary-color, var(--color-iron));
  }
}






/** ***********
 * Modifier: Large blockquote
 */
.c-blockquote--large {
  --blockquote-padding-left: #{rem-baseline(7)};
  --blockquote-padding-right: #{rem-baseline(7)};
  --blockquote-icon-top: -#{rem-calc(20)};
  --blockquote-icon-width: #{rem-calc(46)};

  p {
    @include font-h2;
  }
}

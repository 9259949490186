// Template: Themenwelt intro with link list and header
//

.t-intro {
  @include xy-grid-container;
  @include xy-grid;
}




/** **************
 * Column
 */
.t-intro__col {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell;
  }

  &:first-child {
    @include breakpoint(xlarge) {
      @include xy-cell(9);
      order: 2;
    }
  }

  &:last-child {
    @include breakpoint(xlarge) {
      @include xy-cell(3);
      order: 1;
    }
  }
}








/** ***********
 * Menus
 */
.t-intro__menus {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
}




/** ***********
 * Menu item
 */
.t-intro__menu {
  @include xy-cell;

  @include breakpoint(medium) {
    @include xy-cell(6);
  }

  @include breakpoint(large) {
    @include xy-cell(6);
  }

  @include breakpoint(xlarge) {
    @include xy-cell;
  }
}

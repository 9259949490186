// Article
//
// Product item in shopping cart.
//
// Markup: article.hbs
//
// Type: module
//
// Style guide: Products.article

$article-breakpoint: xlarge;
$article-price-color-new: var(--color-warning);
$article-image-width-large: rem-calc(184);
$article-img-margin-large: rem-baseline(3);
$article-offset-large: $article-image-width-large + $article-img-margin-large;
$article-image-width-small: rem-calc(100);
$article-img-margin-small: rem-baseline(2);
$article-heading-color: var(--color-petrol);
$article-desc-width: rem-calc(220);
$article-price-label-width: rem-calc(80);

@import '_mixins';

/** **************
 * Basic styles
 */
.m-article {
  @include c-card;
  position: relative;

  @include breakpoint($article-breakpoint) {
    @include flex;
    align-items: stretch;
  }

  &:not(:first-of-type) {
    @include u-baseline(1, margin-top);
  }

  label {
    display: inline-block;
  }

  .c-select {
    margin-top: 0;
  }
}













/** **************
 * Modifier: Article is in WISHLIST
 */
.m-article--wishlist {
  .u-row--nested {
    @include flex-align(left, bottom);
  }

  .m-article__entry {
    @include xy-cell;

    @include breakpoint(large) {
      @include xy-cell(7);
    }

    .c-select {
      @include u-baseline(1, margin-bottom);

      @include breakpoint(large) {
        margin-bottom: 0;
      }
    }
  }

  .m-article__info {
    @include xy-cell;

    @include breakpoint(large) {
      @include xy-cell(5);
    }
  }

  .m-article__desc {
    @include u-baseline(3, margin-bottom);
  }

  .m-article__img {
    @include m-article-img(xlarge);
  }

  .m-article__price {
    @include m-article-price(large);
    @include u-baseline(2, margin-bottom);

    @include breakpoint(large) {
      @include u-baseline(3, margin-bottom);
    }
  }

  .c-button {
    display: block;
    width: 100%;

    @include breakpoint(large) {
      display: inline-block;
      width: auto;
    }
  }

  .m-edit {
    @include u-baseline(2, top);
    @include u-baseline(2, right);
    position: absolute;

    @include breakpoint($article-breakpoint) {
      right: 0;
      top: 0;
    }
  }
}





/** **************
 * Article is NOT WISHLIST
 */
.m-article:not(.m-article--wishlist) {
  .m-article__img {
    @include m-article-img($article-breakpoint);
  }

  .m-article__price {
    @include m-article-price($article-breakpoint);
  }
}





/** ***********
 * Article is embedded as a dumb module
 */
.m-article--embedded {
  padding: 0;
}






/** **************
 * Image
 */
.m-article__img {
  @include u-baseline(3, margin-bottom);
  @include u-floating-label($selector: '.c-energy');
  max-width: rem-calc(200);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;

  @include breakpoint($article-breakpoint) {
    margin-bottom: 0;
    max-width: none;
    width: $article-image-width-small;
  }
}





/** **************
 * Content
 */
.m-article__content {
  @include breakpoint($article-breakpoint) {
    @include flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  .c-select {
    @include u-baseline(11, width);
    margin-bottom: 0;
  }
}




/** **************
 * Heading
 */
.m-article__heading {
  @include u-baseline(3, padding-right);
  @include u-baseline(1, padding-bottom);
  @include u-baseline(2, margin-bottom);
  border-bottom: $global-border-dotted;
  color: $article-heading-color;
  flex: none;
  margin-top: 0;

  a {
    @include u-link($global-link-options-petrol);
  }
}





/** **************
 * Vendor
 */
.m-article__vendor {
  @include font-small($type: 'display');
  display: block;
}





/** **************
 * Name
 */
.m-article__name {
  @include font-meta;
  @include font-bold-important;
  color: var(--color-dark);
  display: block;
}






/** **************
 * Body
 */
.m-article-body {
  @include flex;
  flex-direction: column;
}





/** **************
 * Entry content
 */
.m-article__entry {
  flex: 1 0 auto;
}




/** **************
 * Item info wrapper
 */
.m-article__info {
  flex: none;

  .c-select {
    @include u-baseline(2, margin-bottom);
  }
}




/** **************
 * Description
 */
.m-article__desc {
  @include u-baseline(3, margin-bottom);
  @include flex;
  flex-wrap: wrap;
  margin-top: 0;

  dt,
  dd {
    @include u-baseline(2, margin-bottom);
  }

  dt {
    color: var(--color-slate-petrol);
    flex: 0 1 50%;

    @include breakpoint(large) {
      flex: 0 0 $article-desc-width;
    }
  }

  dd {
    flex: 0 1 50%;
    margin-left: 0;

    @include breakpoint(large) {
      flex: 0 0 calc(100% - #{$article-desc-width});
    }
  }
}




/** **************
 * Price
 */
.m-article__price {
  @include flex;
  flex-wrap: wrap;
  line-height: 1;
  margin: 0;

  dt,
  dd {
    @include u-baseline(2, margin-bottom);
  }

  dt {
    flex: 0 1 $article-price-label-width;
    text-align: left;
    width: $article-price-label-width;
  }

  dd {
    @include u-baseline(1, padding-left);
    width: calc(100% - #{$article-price-label-width});
    margin-left: 0;
    text-align: right;
  }
}

.m-article__price--old {
  display: inline-block;
  text-decoration: line-through;
}

.m-article__price--new {
  @include u-baseline(1, margin-left);
  color: $article-price-color-new;
  white-space: nowrap;
}



/** **************
 * Remove link
 */
.m-cart-item__remove {
  @include u-baseline(1, top);
  @include u-baseline(1, right);
  color: var(--color-dark);
  position: absolute;

  @include breakpoint($article-breakpoint) {
    top: 0;
    right: 0;
  }

  .c-icon {
    @include u-icon-relative-size;
  }
}







.m-article__button {
  text-align: right;
}



// Article (Wishlist)
//
// Product item in wishlist. Has a slighty different markup.
//
// Markup: article--wishlist.hbs
//
// Style guide: Products.article.wishlist

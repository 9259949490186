// Carousel
//
// Slider for products, using [Owl Carousel](https://owlcarousel2.github.io/OwlCarousel2/)
//
// Markup: carousel.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Promotion.carousel
@use 'sass:math';
$carousel-breakpoint: large;
$carousel-arrow-color: var(--color-dark);
$carousel-arrow-size: rem-calc(50);
$carousel-arrow-margin: rem-calc($global-baseline * 2);
$carousel-arrow-offset: $carousel-arrow-size + $carousel-arrow-margin;

/** **************
 * Wrapper / owl.carousel overrides
 */
.m-carousel {
  @include grid-row();
  @include u-module-margin;

  @include breakpoint(small down) {
    .owl-stage {
      padding-left: rem-baseline(2) !important;
    }
  }

  .owl-stage {
    @include flex;
    margin: 0 auto;
  }

  .owl-item {
    @include flex;
  }

  .owl-theme {
    .owl-nav {
      [class*='owl-'] {
        color: $carousel-arrow-color;
        fill: $carousel-arrow-color;
        position: absolute;
        top: calc(50% - #{math.div($carousel-arrow-size, 2)});
        line-height: $carousel-arrow-size;
        width: $carousel-arrow-size;
        height: $carousel-arrow-size;
      }

      .owl-prev {
        left: -($carousel-arrow-offset);
      }

      .owl-next {
        right: -($carousel-arrow-offset);
      }
    }
  }

  [data-spinner] {
    position: absolute;
    left: calc(50% - #{rem-calc(20)});
    top: calc(50% - #{rem-calc(25)});
    z-index: 10;
  }

  .c-select {
    margin-left: rem-baseline(2);
    margin-right: rem-baseline(2);
  }
}




/** **************
 * Inner container
 */
.m-carousel__inner {
  @include u-baseline(30, min-height);
  position: relative;

  @include breakpoint($carousel-breakpoint) {
    @include grid-column(10);
    @include grid-column-position(1);
  }
}




/** **************
 * Carousel item
 */
.m-carousel__item {
  @include flex;
  @include u-baseline(2, padding-top);
  @include u-baseline(-2, margin-top);
  padding-right: rem-calc(1);
  padding-left: rem-calc(1);
  width: 100%;
}




/** **************
 * Stage
 */
.m-carousel__stage {
  @include grid-row(nest);
}




/** **************
 * Header with headings and content
 */
.m-carousel__header {
  @include grid-row(nest);
  @include u-baseline(4, padding-left);
  @include u-baseline(4, padding-right);
  @include u-baseline(3, margin-bottom);
}

.m-carousel__heading,
.m-carousel__entry {
  @include grid-column;
  text-align: center;

  @include breakpoint($carousel-breakpoint) {
    @include grid-column(8);
    @include grid-column-position(2);
  }
}

.m-carousel__heading {
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(1, margin-bottom);
}

.m-carousel__entry {
  @include font-meta;

  p {
    margin: 0;
  }
}









/** ***********
 * Promotion wrapper for .c-cardlink
 */
.m-carousel__promo {
  height: 0;
  padding-top: 122.1429%; // Product image format
  position: relative;
  width: 100%;

  .c-cardlink {
    height: 100%;
    position: absolute;
    top: 0;
  }
}

// Pills
//
// List with [pill](section-controls.html#kssref-controls-pills-pill) components.
//
// Markup: pills.hbs
//
// Type: module
//
// Style guide: Controls.pills
.m-pills {
  list-style: none;
  margin: 0;
  padding-left: 0;
  text-align: left;

  @include breakpoint(medium) {
    text-align: center;
  }

  > li {
    @include u-baseline(1, margin-right);
    display: inline-block;

    @include breakpoint(medium) {
      @include u-baseline(1, margin-left);
    }
  }

  .c-pill {
    margin-right: 0;
  }
}

// Boxed note
//
// Note with dotted border.
//
// Markup: boxednote.hbs
//
// Type: component
//
// Style guide: Content.boxednote

.c-boxednote {
  @include font-small; // override
  @include u-baseline(3, margin-bottom);
  @include u-baseline(1, padding-top);
  @include u-baseline(1, padding-bottom);
  padding-left: rem-calc(12);
  padding-right: rem-calc(12);
  border: 1px dashed var(--color-slush);
  border-radius: 0;
  display: inline-block;
}

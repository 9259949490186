// Display
//
// Full-width images with caption
//
// Markup: display.hbs
//
// Type: module
//
// Style guide: Containers.display

.m-display {
  img {
    @include u-img-responsive;
  }
}




/** **************
 * Caption
 */
.m-display__caption {
  @include u-baseline(1, padding-bottom);
  border-bottom: $global-border-dotted;
  margin-left: rem-calc(20);
  margin-right: rem-calc(20);

  @include breakpoint($global-navigation-breakpoint) {
    margin-left: 0;
    margin-right: 0;
  }
}




/** **************
 * Heading
 */
.m-display__heading {
  @include font-h3;
  @include font-bold-important;
  @include u-baseline(2, margin-top);
  @include u-baseline(1, margin-bottom);

  + .m-display__entry {
    margin-top: 0;
  }
}




/** **************
 * Content entry
 */
.m-display__entry {
  @include font-small; // override
  @include u-baseline(1, margin-bottom);
  @include u-baseline(2, margin-top);
}

// Lists
//
// Basic list styles without using classes.
//
// To use the list styles without class (in WYSIWYG editors etc.) use following mixins in your CSS:
//
// ```
// .your-module ol {
//   @include c-list('ol');
// }
//
// .your-module ul {
//   @include c-list('ul');
// }```
//
// Markup: lists.hbs
//
// Type: component
//
// Style guide: Content.lists


$list-style-size: rem-calc(6);

@import '_mixins';

/** **************
 * Basic styles
 */
ol.c-ol {
  @include c-list('ol');
}

ul.c-ul {
  @include c-list('ul');
}




/** **************
 * Unstyles list
 */
.c-list--unstyled {
  @include u-list-reset;
}




/** **************
 * Inline list
 */
.c-list--inline {
  @include u-list-reset;

  > li {
    display: inline-block;
  }
}

// Report
//
// Content module with product reports.
//
// Markup: report.hbs
//
// Type: module
//
// Style guide: Content.reports.report

$report-breakpoint: medium;

.m-report {
  @include breakpoint($report-breakpoint) {
    display: flex;
  }

  &:not(:first-of-type) {
    @include u-baseline(2, padding-top);
    border-top: $global-border-light;
  }

  &:not(:last-of-type) {
    @include u-baseline(2, margin-bottom);
  }
}





/** **************
 * Header with date
 */
.m-report__header {
  @include breakpoint($report-breakpoint) {
    flex: 0 1 rem-calc(90);
  }
}






/** **************
 * Content
 */
.m-report__content {
  @include breakpoint($report-breakpoint) {
    flex: 1;
  }
}





/** **************
 * Entry content
 */
.m-report__entry {
  @include u-baseline(1, padding-bottom);
  border-bottom: $global-border-dotted;
  margin-top: 0;
}

// Feature
//
// Icon with product features, displayed on product detail page. `c-feature__value` is optional.
//
// Markup: feature.hbs
//
// Weight: 0
//
// Type: component
//
// Style guide: Media.features.feature

/** **************
 * Base styles
 */
.c-feature {
  @include flex;
  @include flex-align(center, middle);
  @include u-baseline(5, width);
  @include u-baseline(5, height);
  background-color: var(--color-ice);
  font-family: Arial, sans-serif;
  font-size: rem-calc(10);
  line-height: 1;
  text-align: center;

  svg {
    width: var(--feature-svg-size, #{rem-baseline(3)});
    height: var(--feature-svg-size, #{rem-baseline(3)});
    max-width: 100%;
  }
}




/** **************
 * Value
 */
.c-feature__value {
  display: block;
}





/** ***********
 * Modifier: Socket
 */
.c-feature--socket {
  --feature-svg-size: #{rem-baseline(2)};

  .c-feature__value {
    margin-bottom: rem-calc(2);
  }
}






/** ***********
 * Modifier: Kelvin
 */
.c-feature--kelvin {
  --feature-svg-size: #{rem-baseline(2.5)};
}



// Desc Box
//
// Description list elements with optional columns display with CSS grid.
//
// Markup: descbox.hbs
//
// Type: module
//
// Style guide: Content.lists.descbox
$descbox-breakpoint: large;

.m-descbox {
  margin: 0;
  position: relative;

  // For RMA detail page we need to adjust the
  // margins here a bit.
  &:not(:first-child) {
    @include breakpoint(large) {
      @include u-baseline(4, margin-top);
    }
  }

  &:not(:last-child) {
    @include u-baseline(4, margin-bottom);
  }
}




/** ***********
 * Modifier: Has grid columns
 */
.m-descbox--has-cols {
  @include breakpoint($descbox-breakpoint) {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(auto, 300px);
    grid-template-rows: repeat(2, min-content);
    column-gap: rem-baseline(5);
  }

  .m-descbox__title {
    grid-row-start: 1;

    &:not(:first-child):after {
      @include breakpoint($descbox-breakpoint) {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        border-right: 1px dotted var(--color-slush-slate);
        transform: translateX(#{rem-baseline(-3)});
        top: 0;
      }
    }
  }
}




/** ***********
 * Title
 */
.m-descbox__title {
  @include u-baseline(1, margin-bottom);
  color: var(--color-slate-petrol);
}




/** ***********
 * Text body
 */
.m-descbox__body {
  margin-left: 0;

  @include breakpoint(medium down) {
    @include u-baseline(2, margin-bottom);
  }
}

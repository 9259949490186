// Gallery
//
// Fancy image gallery, converting to slider (Overflower)
// on mobile breakpoint.
//
// Markup: gallery.hbs
//
// Type: module
//
// Javascript: true
//
// Style guide: Media.gallery
.m-gallery {
  position: relative;
}







/** ***********
 * Slides
 */
.m-gallery__slides {
  aspect-ratio: var(--img-ratio);
  background-color: var(--color-concrete);
  position: relative;
}







/** ***********
 * Slide
 */
.m-gallery__slide {
  transition: opacity .1s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translate3d(0, 0, 0);


  &[data-state='active'] {
    opacity: 1;
  }

  .c-image__caption {
    @include font-tiny;
    @include u-baseline(1.5, padding);
    background-color: var(--tint-dark-heavy);
    color: var(--color-alabaster);
    margin: 0;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    @include breakpoint(large) {
      @include font-small;
      @include u-baseline(3, padding);
    }
  }
}







/** ***********
 * Thumbs
 */
.m-gallery__thumbs {
  @include u-baseline(-.5, margin-left);
  @include u-baseline(-.5, margin-right);
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + #{rem-calc($global-baseline)});

  @include breakpoint(large) {
    @include u-baseline(-1, margin-left);
    @include u-baseline(-1, margin-right);
    width: calc(100% + #{rem-calc($global-baseline * 2)});
  }
}





/** ***********
 * Thumb
 */
.m-gallery__thumb {
  @include u-baseline(1, margin-top);
  @include u-baseline(.5, margin-left);
  @include u-baseline(.5, margin-right);
  flex: 0 1 calc(20% - #{rem-calc($global-baseline)});
  transition: opacity .1s ease;
  cursor: pointer;
  max-width: rem-calc(300);
  opacity: .5;

  @include breakpoint(large) {
    @include u-baseline(2, margin-top);
    @include u-baseline(1, margin-left);
    @include u-baseline(1, margin-right);
    flex: 0 1 calc(20% - #{rem-calc($global-baseline * 2)});
  }

  &:hover,
  &[data-state='active'] {
    opacity: 1;

    img {
      filter: grayscale(0%);
    }
  }

  img {
    filter: grayscale(100%);
  }
}

// Image
//
// Images will be implemented with a blurry thumbnail to simulate a faster and progressive loading.
//
// To reserve the space of an unloaded image we use the '[padding hack](http://andyshora.com/css-image-container-padding-hack.html)'. For that we use the `.c-image` class
// with the modifiers `c-image__thumb` for the thumbnail and `c-image__original` for the original image.
//
// The additional inline style padding will be calculated by this formula:
//
// **image.height / image.width * = padding-top in %**
//
// There is a helper for it: `{{picturePadding height width}}` which outputs `padding-top: [result]%`.
//
// For responsive images we use the `source` attribute.
//
// Markup: image.hbs
//
// Type: component
//
// Style guide: Media.image
//

/** **************
 * Basic styles
 */
.c-image {
  aspect-ratio: var(--img-ratio);
  background-color: var(--img-bg, var(--color-concrete));
  display: block;
  position: relative;
  width: 100%;

  img {
    @include u-img-responsive;
  }

  a {
    display: block;
  }

  // Old method fix for PageBuilder (category pages)
  &:has(.c-image__thumb) {
    overflow: hidden;
  }
}





/** ***********
 * Modifier: Rounded image
 */
.c-image--rounded {
  --img-bg: transparent;

  img {
    border-radius: 50%;
    overflow: hidden;
  }
}




/** **************
 * Thumbnail
 */
.c-image__thumb {
  filter: blur(20px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
  z-index: 0;
}





/** **************
 * Original image
 */
.c-image__original {
  position: absolute;
  top: 0;
  left: 0;
}




/** **************
 * Image caption, based on wp-caption-text
 */
.c-image__caption {
  @include font-small; // override
  @include u-baseline(2, margin-top);
  @include u-baseline(2, margin-bottom);
  color: var(--color-slate-petrol);
  display: inline-block;
  max-width: 100%;
  text-align: left;
}



// Simple Image
//
// For the rare case of using different formats (landscape, portrait etc.) for different
// breakpoints. This component does not have any fallback styles to reserve the space
// while loading!
//
// Markup: image-simple.hbs
//
// Type: component
//
// Style guide: Media.image.simple
//

// Jeton
//
// Simple image or text link as a token (jeton).
//
// Markup: jeton.hbs
//
// Type: module
//
// Style guide: Controls.jeton

.m-jeton {
  align-items: center;
  background-color: var(--jeton-background, var(--color-light));
  display: flex;
  justify-content: center;
  padding: 20px;

  &:hover {
    box-shadow: var(--shadow-light);
  }

  .c-image {
    flex: 1;
  }
}

// Follow up
//
// Button or CTA leading to detail page.
//
// Markup: followup.hbs
//
// Type: module
//
// Style guide: Controls.followup
.m-followup {
  @include u-cell-row;
  @include u-baseline(2, margin-top);
  justify-content: flex-end;
  width: 100%;

  @include breakpoint(large) {
    @include u-baseline(5, margin-top);
  }
}

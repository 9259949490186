// flatpickr Custom styles
// * DO NOT REPLACE WITH PLUGIN UPDATES! *

.flatpickr-calendar.inline {
  box-shadow: none;
  width: 100%;
}

.flatpickr-months {
  @include u-baseline(2, margin-bottom);
}

.flatpickr-prev-month {
  transform: scaleX(-1);
}

.flatpickr-current-month {
  @include font-small;
  font-weight: bold;

  input.cur-year {
    font-weight: bold;
  }

  .flatpickr-monthDropdown-months {
    display: inline-block;
  }
}

.flatpickr-weekdays {
  @include u-baseline(1, margin-bottom);
}

span.flatpickr-weekday {
  @include font-tiny;
  color: $global-body-color;
  font-weight: bold;
  text-transform: uppercase;
}

.flatpickr-day {
  @include u-baseline(.5, margin-bottom);
  @include u-baseline(5, max-width);
  @include u-baseline(5, width);
  @include u-baseline(5, height);
  @include font-small;
  @include u-baseline(4.7, line-height);
  background: var(--color-orange-light);
  font-weight: bold;

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover {
    color: var(--color-slush-slate);
    font-weight: normal;
  }

  &.prevMonthDay:not(.flatpickr-disabled),
  &.nextMonthDay:not(.flatpickr-disabled) {
    background: var(--color-pearl);
    color: var(--color-dusty);
  }

  &.today {
    border-color: var(--color-dark);
  }
}

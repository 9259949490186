/// Main menu in flyout
///
/// NOT A MODULE – belongs to global scaffold

@import '_config';

.m-menu__more {
  @include font-meta;
  color: $menu-more-color;
  display: none;

  @include breakpoint($global-navigation-breakpoint) {
    @include u-link($global-link-options-underline);
    display: inline-block;
  }
}




/** **************
 * Menu list
 */
.m-menu__list {
  @include breakpoint($global-navigation-breakpoint) {
    @include xy-grid-container($padding: 0);
    @include xy-grid;
    @include xy-gutters($negative: true);
    @include u-baseline(3, padding-top);
    @include u-baseline(1, padding-bottom);
    width: 100%;
  }

  li {
    @include breakpoint($global-navigation-breakpoint) {
      @include u-baseline(2, margin-bottom);
    }
  }
}




/** **************
 * Item
 */
.m-menu__item {
  @include breakpoint($global-navigation-breakpoint) {
    @include xy-cell(auto);
    flex: var(--menu-item-column, 1);
    position: relative;

    &:last-of-type {
      &:after {
        display: none;
      }
    }
  }
}




/** **************
 * Modifier: Menu has 3 columns
 */
.m-menu--has-3-cols {
  .m-menu__item {
    &:last-of-type {
      @include breakpoint($global-navigation-breakpoint) {
        @include xy-cell(6);
      }
    }
  }
}




/** **************
 * Modifier: Menu has no border
 */
.m-menu__item--no-border {
  &:after {
    display: none;
  }
}




/** **************
 * Inner container
 */
.m-menu__inner {
  @include breakpoint($global-navigation-breakpoint) {
    @include u-baseline(1.5, padding-right);
    @include u-baseline(1.5, padding-left);
  }
}




/** **************
 * Sub menu
 */
.m-menu__sub {
  @include u-list-reset;
  @include font-small;

  @include breakpoint($global-navigation-breakpoint) {
    @include u-baseline(3, margin-bottom);
    width: var(--menu-sub-width);
  }

  .m-offcanvas__item {
    @include u-link($global-link-options-dark-underline);
  }
}

.m-menu--has-columns {
  .m-menu__sub {
    @include breakpoint($global-navigation-breakpoint) {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: repeat(6, auto);
      grid-auto-flow: column;
      width: 100%;
    }
  }
}


/** **************
 * Title
 */
.m-menu__title {
  display: block;
  margin: 0;

  a {
    @include font-meta($type: 'display');
    color: var(--color-iron);

    @include breakpoint($global-navigation-breakpoint) {
      @include u-link($global-link-options-orange);
      @include font-small;
      font-weight: bold;
    }
  }

  @include breakpoint($global-navigation-breakpoint) {
    @include u-baseline(3, margin-bottom);
  }
}




/** **************
 * Teaser
 */
.m-menu-teaser {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
}




/** **************
 * Teaser item
 */
.m-menu-teaser__item {
  @include u-single-cell;
  @include u-baseline(2, margin-bottom);
}




/** **************
 * Teaser more link
 */
.m-menu-teaser__more {
  @include u-single-cell;
}





/** ***********
 * Menu tiles
 */
.m-menu__tiles {
  @include u-baseline(3, gap);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

// Actionbox
//
// Call to action box for product detail page. See more examples [here.](/pdp_actionboxes.html)
//
// Markup: actionbox.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Containers.actionbox

$actionbox-breakpoint: xxlarge;
$actionbox-background: var(--color-pearl);
$actionbox-price-old-color: var(--color-slate);
$actionbox-price-info-color: var(--color-iron);

/** **************
 * Scaffold
 */
.m-actionbox {
  @include u-baseline(4, margin-bottom);
  max-width: rem-calc(600);

  @include breakpoint(xlarge) {
    @include u-baseline(8, margin-bottom);
  }
}



/** ***********
 * Magento error hack, the dirty kind
 */
.qty.m-actionbox-controls__input {
  div.mage-error {
    @include u-baseline(2, margin-top);
    margin-bottom: 0;

    // We need to set a fix width due relative positioning
    // and DOM position we don't have any influence on.
    width: rem-calc(340);
  }
}




/** **************
 * Inner container
 */
.m-actionbox__inner {
  @include u-baseline(2, padding-top);
  @include u-baseline(3, padding-left);
  @include u-baseline(3, padding-right);
  @include u-baseline(3, padding-bottom);
  background-color: $actionbox-background;
}




/** **************
 * Variant
 */
.m-actionbox__name {
  @include u-baseline(4, margin-bottom);
  @include u-baseline(2, padding-bottom);
  border-bottom: $global-border-dotted;
  margin-top: 0;
  overflow: hidden;

  dt {
    @include u-baseline(1, margin-right);
    clear: both;
    font-weight: bold;
    float: left;
    display: block;
    width: auto;
  }

  dd {
    float: left;
    margin: 0;
  }
}





/** **************
 * Price
 */
.m-actionbox-price {
  @include u-baseline(3, margin-bottom);
  @include u-baseline(8, padding-right);
  border-bottom: $global-border-dotted;
  position: relative;
}

.m-actionbox-price__value {
  @include font-h1;
  @include u-baseline(3, margin-right);
  display: inline-block;
}

.m-actionbox-price__old {
  @include font-h2;
  color: $actionbox-price-old-color;
  display: inline-block;
  white-space: nowrap;
}

.m-actionbox-price__info {
  @include u-baseline(1, margin-top);
  @include u-baseline(4, margin-bottom);
  @include u-content-links;
  color: $actionbox-price-info-color;

  a:not(.c-button):not([class*='c-arrowlink']) {
    color: inherit;
  }
}






/** ***********
 * Label with savings
 */
.m-actionbox__savings {
  position: absolute;
  right: 0;
  top: 0;
}







/** **************
 * Input value, cart button and merkliste button
 */
.m-actionbox-controls {
  @include flex;
  @include u-baseline(5, margin-bottom);

  @include breakpoint(xlarge down) {
    flex-wrap: wrap;
  }
}

.m-actionbox-controls__input {
  @include u-baseline(9, width);
  @include u-baseline(2, padding-right);
  flex: 0 0 rem-baseline(9);

  input {
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}




/** **************
 * Buttons
 */
.m-actionbox-controls__submit {
  --button-padding-left: #{rem-baseline(3)};
  --button-padding-right: #{rem-baseline(3)};
  flex: 1 1 auto;
}

.m-actionbox-controls__save {
  @include u-baseline(3, margin-top);
  flex: 1 1 100%;

  @include breakpoint($actionbox-breakpoint) {
    @include u-baseline(6, width);
    @include u-baseline(2, margin-left);
    flex: 0 0 rem-baseline(6);
    margin-top: 0;
  }
}




/** **************
 * Information
 */
.m-actionbox-info {
  @include breakpoint(xxlarge) {
    @include flex;
  }

  a {
    @include u-link($global-link-options-underline);
    color: $actionbox-price-info-color;
  }
}



/** ***********
 * Item
 */
.m-actionbox-info__item {
  @include breakpoint(xxlarge) {
    flex: 1 1 50%;
  }

  &:not(:last-child) {
    @include u-baseline(2, margin-bottom);

    @include breakpoint(xxlarge) {
      margin-bottom: 0;
    }
  }
}

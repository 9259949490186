// Group
//
// Wishlist group organisms, include [wishlist articles](section-products.html#kssref-products-article-wishlist).
//
// Markup: wishgroup.hbs
//
// Type: organism
//
// JavaScript: true
//
// Style guide: Wishlist.group

$wishgroup-header-background: var(--color-geyser);
$wishgroup-body-background: var(--color-ice);
$wishgroup-border: 1px solid var(--color-dusty);
$wishgroup-controls-border: 1px solid var(--color-slush-slate);
$wishgroup-counter-size: rem-calc(38);

/** **************
 * Container
 */
.o-wishgroup {
  @include u-baseline(3, margin-bottom);
  background-color: $wishgroup-body-background;
  border: $wishgroup-border;
  border-radius: rem-calc($global-border-radius);

  .o-wishgroup-list {
    padding-left: 0;
    padding-right: 0;
  }
}







/** ***********
 * Closed wishgroup
 */
[data-wishgroup-closed] {
  .o-wishgroup__body {
    max-height: 0;
    margin-top: -(rem-calc(1));
  }

  .o-wishgroup__controls--toggle svg {
    transform: rotate(0deg);
  }
}







/** **************
 * Header
 */
.o-wishgroup__header {
  @include flex;
  @include flex-align(left, middle);
  background-color: $wishgroup-header-background;
  border-bottom: $wishgroup-border;
}





/** **************
 * Heading (name)
 */
.o-wishgroup__heading {
  @include font-meta;
  @include flex;
  @include flex-align(left, middle);
  @include u-baseline(5, padding-right);
  @include u-baseline(2, padding-left);
  @include u-baseline(7, height);
  flex: 1;
  min-width: 0;
  white-space: nowrap;

  > span {
    @include text-truncate(ellipsis); // Foundation mixin
    display: inline-block;
    vertical-align: middle;
  }

  .c-icon {
    flex: none;
    width: 1.75em;
    height: 1.75em;
    margin-right: .75em;
    transform: translate3d(0, 0, 0);
  }
}





/** **************
 * Controls
 */
.o-wishgroup__controls {
  @include flex;
  @include flex-align(center, middle);
  @include u-list-reset;
  @include u-baseline(1, margin-top);
  @include u-baseline(1, margin-bottom);
  @include u-baseline(5, height);
  color: var(--color-slate-petrol);
  flex: 0 0 rem-calc(178);

  button {
    color: inherit;
  }

  > li {
    @include flex;
    @include flex-align(center, middle);
    border-left: $wishgroup-controls-border;
    height: 100%;
    text-align: center;
    width: rem-calc(60);
  }
}


.o-wishgroup__controls--toggle {
  svg {
    transform: rotate(180deg);
    width: rem-calc(14);
    height: rem-calc(8);
  }
}


.o-wishgroup__controls--drag {
  cursor: pointer;
  touch-action: none;

  svg {
    transform: translateY(rem-calc(1));
  }
}





/** **************
 * Body
 */
.o-wishgroup__body {
  height: auto;
  overflow: hidden;
}




/** **************
 * Inner container
 */
.o-wishgroup__inner {
  @include u-baseline(2, padding);
  overflow: hidden;
}






/** **************
 * Description
 */
.o-wishgroup__desc {
  @include u-baseline(1, padding-left);
  color: var(--color-iron);
  max-width: 60ch;
}







/** **************
 * Draggable list
 */
.o-wishgroup-list {
  @include u-list-reset;
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  @include u-baseline(4, margin-bottom);

  > li {
    @include u-baseline(2, margin-bottom);
  }

  &:not(.o-wishgroup-list--hide-number) > li {
    counter-increment: wishlist;
    position: relative;

    // Display list number as pseud element
    &:before {
      @include u-baseline(2, top);
      @include u-baseline(2, left);
      font-family: font(base, 'display');
      background-color: var(--color-light);
      border: 1px solid var(--color-slush-ice);
      border-radius: 50%;
      content: counter(wishlist) '.';
      display: inline-block;
      line-height: $wishgroup-counter-size;
      text-align: center;
      position: absolute;
      width: $wishgroup-counter-size;
      height: $wishgroup-counter-size;
      z-index: 2;
    }
  }
}







/** **************
 * Draggable groups
 */
.o-wishgroup-groups {
  list-style: none;
  padding: 0;
}

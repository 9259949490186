// Typography
//
// The basic font used by all websites is Lato, as served
// by fonts.google.com ([see specimen](https://fonts.google.com/specimen/Lato)).
//
// All font family implementations are appended with
// `Arial,Helvetica,sans-serif` in case loading from fonts.google.com fails. All basic
// typography uses `var(--color-dark)` or `#000000`.
//
// **NOTE**: Foundation has a [series of typography helpers](http://foundation.zurb.com/sites/docs/typography-helpers.html) you
// can use.
//
// Markup: typography.hbs
//
// Chromeless: true
//
// Style guide: General.typography

@import '_mixins';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}



/** ***********
 * Modifier: Heading is first element
 */
.heading--is-first {
  margin-top: 0;
  padding-top: 0 !important;
}



/** **************
 * Heading XXL
 */
.heading--xxl {
  @include font-h1;
}




/** **************
 * Heading XL
 */
.heading--xl {
  @include font-h2;
  @include u-baseline(2, margin-bottom);
  @include u-baseline(2, margin-top);

  *:not(a) {
    @include font-h2;
  }
}




/** **************
 * Heading L
 */
.heading--l {
  @include font-h3;
  @include u-baseline(1, margin-top);
  @include u-baseline(2, margin-bottom);
}




/** **************
 * Heading M
 */
.heading--m {
  @include font-h4;
  @include font-bold-important;
  @include u-baseline(2, margin-bottom);
  display: block;
}





/** **************
 * Section headings
 */
.heading--section {
  @include font-h3;
  @include u-baseline(2, margin-bottom);
  margin-top: 0;
  padding-top: 0;
}





/** **************
 * Sub heading
 */
.heading--sub {
  @include typo-heading-sub();
  display: block;

  + [class*='heading-'] {
    margin-top: 0;
  }
}






/** ***********
 * Bold heading
 */
.heading--bold {
  @include font-bold-important;
}




/** ***********
 * Modifier: Heading has right aligned link
 */
.heading--has-link {
  @include flex;
  @include flex-align(left, bottom);

  .heading__value {
    flex: 1 0 auto;
  }

  .heading__link {
    @include font-small($type: 'copy');
    @include u-link($global-link-options-underline);
    text-align: right;
    transform: translateY(-(rem-calc(3)));
  }
}



/** **************
 * Text Meta
 */
.text--meta {
  @include font-meta;
}




/** **************
 * Text XS
 */
.text--xs {
  @include font-small; // override
}




/** **************
 * Tiny text
 */
.text--tiny {
  @include font-tiny;
}





/** **************
 * Text lead
 */
.text--lead {
  @include font-lead;
}




/** **************
 * Text center
 */
.text--center {
  text-align: center;
}


// Sizing
//
// Foundation works in [rems](http://snook.ca/archives/html_and_css/font-size-with-rem) and converts the pixel values we
// enter into rem units via `rem-calc()`. For example, 36px = 2.25rems. Some
// information about this function can be [found on the Foundation website](http://foundation.zurb.com/sites/docs/typography-base.html#type-size).
// `rem-calc()` should be used for all sizing throughout the project.
// All font-sizes are using a mixin for vertical baselines (`utils/_mixins.scss`). The settings for this are located in `utils/typgraphy.scss`
//
// | Element | Size in Rems | Size in Pixels | Line Height |
// |---------|--------------|----------------|-------------|
// |    h1   |    2.5rem    |      40px      |     48px / 1.2     |
// |    h2   |    1.375rem  |      22px      |    32px / 1.45455  |
// |    h3   |    1.5rem    |      18px      |     32px / 1.3333  |
// |    h4   |     1rem     |      16px      |     24px / 1.2     |
// |    h5   |    .875rem   |      15px      |     24px / 1.2     |
// |    h6   |    .75rem    |      11px      |     16px / 1.2     |
// |    p    |     1rem     |      16px      |     24px / 1.5     |
//
// **NOTE:** The default for all elements not defined above are whatever is
// defined for `<p>`.
//
// Weight: 1
//
// Style guide: General.typography.sizing







// Formatting
//
// ## Phone numbers
//
// Phone number will be formatted with the [DIN 5008](https://www.din-5008-richtlinien.de/startseite/telefonnummern/) method:
//
// `+49 40 325859-0`
//
// ## Prices
//
// Prices will be formatted with the [DIN 5008](https://www.din-5008-richtlinien.de/startseite/zahlen/) method.
// The two decimal digits will only displayed in the [summary](section-containers.html#kssref-containers-summary) module and wherever prices will be calculated.
//
// ```
// 3.370 €
// 4,95 €
// ```
//
// Weight: 2
//
// Style guide: General.typography.formatting

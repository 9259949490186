// Wishlist note
//
// Displays text and icon when wishlist is fresh and empty.
//
// Markup: wishnote.hbs
//
// Type: module
//
// Style guide: Wishlist.wishnote

.m-wishnote {
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
  @include u-module-margin;
  @include u-baseline(3, padding-top);
  text-align: center;

  article {
    @include u-single-cell;
  }

  svg {
    transform: translate3d(0, 0, 0);
    width: 3em;
    height: 3em;
  }
}

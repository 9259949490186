// Wishlister
//
// Wishlist item to display in modal to
// add product item to wishlist or wishlist group.
//
// Markup: wishlister.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Wishlist.wishlister
// @TODO: Use CSS vars

$wishlister-group-background: var(--color-ice);
$wishlister-group-background-inactive: var(--color-slush-ice);
$wishlister-border: 1px solid var(--color-slush-slate);
$wishlister-border-radius: rem-calc($global-border-radius);
$wishlister-closed-size: rem-calc(5);

/** **************
 * Wrapper
 */
.m-wishlister {
  @include u-baseline(1, margin-bottom);
  overflow: hidden;
  position: relative;
  padding-left: rem-calc(1);
}






/** **************
 * Modifier: Item is closed
 */
[data-closed='true'] {
  .m-wishlister__header .c-wishlisticle {
    border-radius: $wishlister-border-radius;
  }

  .m-wishlister__groups {
    border-bottom-left-radius: $wishlister-border-radius;
    border-bottom-right-radius: $wishlister-border-radius;
    height: 100%;
    max-height: $wishlister-closed-size;
    margin-left: rem-calc(1);
    margin-right: rem-calc(1);
    overflow: hidden;

    .c-wishlisticle {
      background-color: $wishlister-group-background-inactive;
    }
  }

  .m-wishlister__footer,
  .m-wishlister__form {
    background-color: $wishlister-group-background-inactive;
    height: 100%;
    max-height: $wishlister-closed-size;
    margin-left: rem-calc(2);
    margin-right: rem-calc(2);
    padding: 0;
  }

  .m-wishlister__form {
    max-height: 0;
    margin-top: -(rem-calc(1));
  }

  .c-addlink {
    visibility: hidden;
  }
}







/** **************
 * Header
 */
.m-wishlister__header {
  position: relative;
  z-index: 2;

  .c-wishlisticle {
    border: $wishlister-border;
    border-top-left-radius: $wishlister-border-radius;
    border-top-right-radius: $wishlister-border-radius;
  }
}







/** ***********
 * Not for product in wishlist
 */
.m-wishlister__note {
  @include font-small;
  @include u-baseline(1, padding);
  @include u-baseline(2, padding-left);
  background-color: var(--color-success-light);
  border: $wishlister-border;
  border-radius: $wishlister-border-radius;
  border-bottom: none;
  font-style: italic;

  & + .c-wishlisticle {
    border-top: none;
  }
}






/** **************
 * Groups wrapper
 */
.m-wishlister__groups {
  @include u-list-reset;
  border: $wishlister-border;
  border-top: none;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  z-index: 0;

  border-radius: 0;
  height: auto;
  max-height: rem-calc(1000);
  margin: 0;

  &:empty {
    margin-top: -(rem-calc(1));
  }


  .c-wishlisticle:last-child {
    border-bottom: none;
  }
}





/** **************
 * Footer with link
 */
.m-wishlister__footer,
.m-wishlister__form {
  @include u-baseline(1, padding-top);
  @include u-baseline(1, padding-bottom);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  background-color: $wishlister-group-background;
  border: $wishlister-border;
  border-top: none;
  border-bottom-left-radius: $wishlister-border-radius;
  border-bottom-right-radius: $wishlister-border-radius;
  height: auto;
  max-height: rem-calc(250);
  overflow: hidden;

  .m-wishlister__form {
    border: none;
    padding-left: 0;
    padding-right: 0;
  }
}


.m-wishlister__form {
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);
}

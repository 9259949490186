/// Mutli-menu
///
/// Used in flyout (desktop-only) for menu with tabs
/// on left side (shop)
///
/// NOT A MODULE – belongs to global scaffold

@import '_config';

.m-multimenu {
  @include u-list-reset;
  padding: 0 !important;

  > li {
    @include breakpoint($global-navigation-breakpoint) {
      &:not(:last-of-type) {
        margin-bottom: rem-calc(1);
      }
    }
  }
}




/** **************
 * Category
 */
.m-multimenu__category {
  @include font-meta($type: 'display');
  background-color: $multimenu-item-background;
  color: $multimenu-color;
  display: block;
  transition: none;

  @include breakpoint($global-navigation-breakpoint) {
    padding: rem-calc(24) rem-calc(16) rem-calc(20);
  }

  &.is-active {
    @include breakpoint($global-navigation-breakpoint) {
      background-color: $multimenu-item-active-color;
    }

    ~ .m-multimenu__sub {
      @include breakpoint($global-navigation-breakpoint) {
        display: block;
        z-index: 2;
      }
    }
  }
}




/** **************
 * Sub menu
 */
.m-multimenu__sub {
  @include breakpoint($global-navigation-breakpoint) {
    display: none;
    background: $multimenu-background-color;
    position: absolute;
    top: 0;
    left: 25%;
    height: 100%;
    z-index: -1;
  }
}

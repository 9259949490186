// Accordion
//
// Accordion example with static content. Don't use this as a partial!
//
// How to use the accordion in your templates:
//
// ```
// {{#> accordion/accordion is_template=true}}
//   {{> accordion/accordion__tab id=0 text="Produktbeschreibung" }}
//   {{#> accordion/accordion__panel is_template=true id=0}}
//     … your content goes here …
//   {{/accordion/accordion__panel}}
// {{/accordion/accordion}}
// ```
//
// Markup: accordion.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Containers.accordion

$accordion-tab-background: var(--color-alabaster);
$accordion-tab-color: var(--color-dark);
$accordion-panel-background: var(--color-light);
$accordion-panel-border: 1px solid var(--color-ice);
$accordion-border-color: var(--color-dusty);
$accordion-border: 1px solid var(--color-slush-ice);
$accordion-border-radius: rem-calc($global-border-radius);
$accordion-padding: rem-baseline(3) rem-baseline(2) rem-baseline(4) rem-baseline(2);

.m-accordion {
  --accordion-padding: #{$accordion-padding};
  @include u-module-margin;

  table {
    tbody {
      border-left: none;
      border-right: none;
    }

    td {
      vertical-align: top;
    }

    td:first-child {
      @include breakpoint(large) {
        width: 35%;
        hyphens: auto;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.m-accordion__header {
  margin: 0 !important;
  padding: 0 !important;
}




// Accordion Tab
//
// Clickable headline to open the panel.
//
// Markup: accordion__tab.hbs
//
// Weight: 1
//
// Style guide: Containers.accordion.tab
.m-accordion__tab {
  @include u-accordion-tab;
  @include u-baseline(7, padding-right);
  @include u-baseline(2, padding-left);
  background-color: $accordion-tab-background;
  border: 0;
  border-top: $accordion-border;
  color: $accordion-tab-color;
  cursor: pointer;
  display: flex;
  fill: $accordion-tab-color;
  flex: 0 1 auto;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  text-align: left;
  width: 100%;

  &:hover,
  &.is-selected,
  &.is-active {
    background-color: var(--color-ice);
  }

  &[aria-expanded='true'] {
    .m-accordion__excerpt {
      opacity: 0;
      visibility: hidden;
    }
  }

  // Prefix number for pinboard items
  &[data-accordion-number] {
    @include u-baseline(9, padding-left);

    &:before {
      @include u-number-circle($content: attr(data-accordion-number));
      @include u-baseline(1.5, top);
      @include u-baseline(2, left);
    }
  }
}


/** ***********
 * Tab excerpt
 */
.m-accordion--has-excerpt {
  .m-accordion__title {
    max-width: 65%;
  }
}

.m-accordion__excerpt {
  display: none;

  @include breakpoint(large) {
    @include u-ellipsis($max-width: 620);
    @include font-small;
    @include u-baseline(2, padding-left);
    color: var(--color-slate);
    display: inline-block;
    flex: 1;
    font-style: italic;
    line-height: 1.85;
    transition: var(--transition-default);
  }
}




// Accordion Panel
//
// Content panel to display accordion content.
//
// Markup: accordion__panel.hbs
//
// Weight: 2
//
// Style guide: Containers.accordion.panel
.m-accordion__panel {
  background-color: $accordion-panel-background;
  margin-bottom: rem-calc(2);
  // max-height: 0;
  overflow: hidden;
  // transition: var(--transition-default);
}


/** ***********
 * Inner container
 */
.m-accordion__inner {
  border: $accordion-panel-border;
  border-top: none;
}





// Accordion Entry
//
// Example entry for style guide. Don't use as a partial in your templates!
//
// This element is optional and will add a padding wrapper.
//
// Markup: accordion-example__entry.hbs
//
// Weight: 3
//
// Style guide: Containers.accordion.entry
.m-accordion__entry {
  @include font-small; // override:
  padding: var(--accordion-padding);

  // Magento fix due wrong nesting on PDP page.
  & #{&} {
    padding: 0;
  }

  p {
    margin-top: 0;
    max-width: 65ch;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a:not(.c-button):not(.m-product__link) {
    @include u-link($global-link-options-underline);
  }

  hr {
    @include u-baseline(3, margin-top);
    @include u-baseline(3, margin-bottom);
    border: none;
    border-top: 1px solid $accordion-border-color;
  }

  + .m-accordion__products {
    padding-top: 0;
  }
}










/** ***********
 * Products Grid
 */
.m-accordion__products {
  display: grid;
  gap: rem-baseline(3) rem-baseline(3);
  grid-template-rows: minmax(0, 1fr);
  padding: var(--accordion-padding);

  @include breakpoint(xxlarge) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  .m-tinyproduct {
    margin-bottom: 0;
  }
}




// Accordion Table
//
// Example table for style guide. Don't use as a partial in your templates!
//
// Tables will be desiplayed without padding.
//
// Markup: accordion-example__table.hbs
//
// Weight: 4
//
// Style guide: Containers.accordion.table

// Icon list
//
// Icons displayed as tile wall.
//
// Markup: iconlist.hbs
//
// Type: module
//
// Style guide: Lists.iconlist

.m-iconlist {
  @include u-list-reset;
  display: grid;
  gap: rem-baseline(5) rem-baseline(3);
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  font-size: 0;
}



.m-iconlist__item {
  @include font-tiny;
  color: var(--iconlist-color, var(--color-cod));
  display: flex;
  flex-direction: column;
  align-items: center;
  hyphens: auto;
  text-align: center;

  .c-icon {
    @include u-baseline(5, width);
    @include u-baseline(5, height);
    @include u-baseline(1, margin-bottom);
  }
}

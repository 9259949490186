// Expandable content
//
// Simple trigger to display expandable content.
//
// Important: `<label>` and `<input type="checkbox">` is required.
//
// Markup: expandable.hbs
//
// Type: component
//
// Style guide: Controls.expandable

.c-expandable {
  > [type='checkbox'] {
    @include element-invisible;

    &:checked ~ label {
      display: none;
    }

    &:checked ~ .c-expandable__content {
      display: block;
    }
  }

  > label[for] {
    @include u-link($global-link-options-underline);
    display: inline-block;
    margin-left: 0;
  }
}




/** **************
 * Content
 */
.c-expandable__content {
  display: none;
}

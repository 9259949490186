// Products
//
// Listing wrapper for product items
//
// Markup: products.hbs
//
// Weight: 2
//
// Type: organism
//
// Style guide: Products
$products-xlarge-gutters: rem-calc($global-xlarge-gutter);

.o-products {
  @include u-list-reset;
  @include xy-grid-container($padding: 0);
  @include xy-grid;
  @include xy-gutters($negative: true);
  @include u-row-padding($negative: true);
  gap: rem-baseline(5) 0;
  margin-top: 0;

  @include breakpoint(medium) {
    @include xy-gutters($negative: true, $gutters: 16px);
  }

  @include breakpoint(large) {
    @include xy-gutters($negative: true);
  }

  &:not(.o-products--no-margin) {
    @include u-module-margin(small);
  }
}




/** ***********
 * Modifier: No bottom margins
 */
.o-products--no-margin {
  .o-products__item {
    @include breakpoint(large) {
      gap: 0;
    }
  }
}





/** **************
 * Modifier: 4 column products
 */
.o-products--4-col {
  .o-products__item {
    @include xy-cell(6);

    @include breakpoint(large) {
      @include xy-cell(3);
    }

    @include breakpoint(xlarge) {
      @include xy-cell(3, $gutters: $products-xlarge-gutters);
    }
  }
}




/** ***********
 * Modifier: Centered items
 */
.o-products--is-centered {
  @include flex-align(center, top);
}





/** **************
 * Item
 */
.o-products__item {
  @include xy-cell(6);
  @include flex;

  @include breakpoint(large) {
    @include xy-cell(4);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(4, $gutters: $products-xlarge-gutters);
  }

  // WIP @TODO – Trying to find a flexible flexbox solution
  // &.has-colors:nth-child(3n+1) + .o-products__item:not(.has-colors),
  // &.has-colors:nth-child(3n+2) + .o-products__item:not(.has-colors) {
  //   .c-product-info__colors {
  //     flex: 1 0 auto;
  //     min-height: rem-calc(36);
  //     background: red;
  //   }
  // }
}





/** **************
 * Banner
 */
.o-products__banner {
  @include xy-cell;
  @include u-baseline(3, margin-bottom);

  @include breakpoint(xlarge) {
    @include xy-cell($gutters: $products-xlarge-gutters);
  }
}

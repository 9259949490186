// Contact Box
//
// Box with contact credentials and optional links for product detail page.
//
// Markup: contactbox.hbs
//
// Type: module
//
// Style guide: Containers.contactbox
.m-contactbox {
  @include u-baseline(4, padding-top);
  @include u-baseline(4, padding-bottom);
  @include u-baseline(3, grid-gap);
  background-color: var(--color-ice);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

  + .m-social {
    @include u-baseline(3, margin-top);
  }
}






/** ***********
 * Item
 */
.m-contactbox__item {
  @include u-baseline(3, padding-left);
  @include u-baseline(3, padding-right);
  display: flex;
}







/** ***********
 * Icon
 */
.m-contactbox__icon {
  @include u-baseline(2, margin-right);
  color: var(--color-slate);

  .c-icon {
    width: 2em;
    height: 2em;
  }
}







/** ***********
 * Heading
 */
.m-contactbox__heading {
  @include font-h4;
  @include u-baseline(1, margin-bottom);
  color: $global-body-color;
  font-weight: bold;

  a {
    color: currentColor;

    &:hover {
      text-decoration: underline;
    }
  }
}






/** ***********
 * Text body
 */
.m-contactbox__body {
  @include font-small();
  color: var(--color-iron);

  p {
    @include u-baseline(2, margin-bottom);
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    @include u-link($global-link-options-underline);
  }
}


// Contact Box Item
//
// Box item used by style guide and Spotlight.
//
// Markup: contactbox__item.hbs
//
// Style guide: Containers.contactbox.contactbox__item

// Roller
//
// Film roll style carousel with text.
//
// Overrides owl carousel styles.
//
// Overrides [.m-teaser](section-promotion.html#kssref-promotion-teaser) styles.
//
// Full: true
//
// Markup: roller.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Promotion.roller
@use 'sass:math';
$roller-background: var(--color-pearl);
$roller-arrow-color: var(--color-dark);
$roller-arrow-size: rem-calc(50);
$roller-arrow-margin: rem-baseline(2);
$roller-arrow-background: rgba(255, 255, 255, .7);
$roller-arrow-hover-background: rgba(255, 255, 255, 1);
$roller-arrow-hover-color: var(--color-dark);

/** **************
 * Basic styles
 */
.m-roller {
  @include u-baseline(4, padding-top);
  @include u-baseline(4, padding-bottom);

  .owl-theme .owl-nav {
    [class*='owl-'] {
      background-color: $roller-arrow-background;
      color: $roller-arrow-color;
      position: absolute;
      top: calc(50% - #{math.div($roller-arrow-size, 2)});
      line-height: $roller-arrow-size;
      width: $roller-arrow-size;
      height: $roller-arrow-size;

      &.disabled {
        opacity: 0;
      }
    }

    .owl-prev {
      left: 0;
    }

    .owl-next {
      right: 0;
    }

    &:hover [class*='owl-'] {
      @include breakpoint($global-navigation-breakpoint) {
        background-color: $roller-arrow-hover-background;
        color: $roller-arrow-hover-color;
      }
    }
  }
}




/** **************
 * Inner container
 */
.m-roller__inner {
  @include xy-grid-container;
  @include xy-grid;
  @include flex-align(left, middle);
}




/** **************
 * Header
 */
.m-roller__header {
  @include xy-cell;
  @include u-baseline(2, margin-bottom);

  @include breakpoint(large) {
    @include xy-cell(4);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(3);
    margin-bottom: 0;
  }

  [class*='heading-'] {
    @include u-baseline(2, padding-right);
  }

  .heading--xl {
    margin-bottom: 0;
  }
}




/** **************
 * Items
 */
.m-roller__items {
  @include xy-cell;
  width: 100%;

  @include breakpoint(large) {
    @include xy-cell(8);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(9);
  }

  .m-teaser {
    margin-bottom: 0;
  }

  .owl-stage {
    padding-left: 0 !important;
  }
}

// Label
//
// Small red label for discounts.
//
// Markup: label.hbs
//
// Type: component
//
// Style guide: Containers.label

.c-label {
  @include font-tiny;
  @include u-baseline(1, padding-right);
  @include u-baseline(1, padding-left);
  @include u-baseline(.5, padding-top);
  @include u-baseline(.5, padding-bottom);
  background-color: var(--label-background, var(--color-warning));
  color: var(--color-light);
  display: inline-block;
}



/** ***********
 * Modifier: label colors
 */
.c-label--info {
  --label-background: var(--color-info);
}

.c-label--success {
  --label-background: var(--color-success);
}

.c-label--warning {
  --label-background: var(--color-warning);
}

.c-label--neutral {
  --label-background: var(--color-dusty);
}

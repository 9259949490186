// Overlay for mobile filters and categories
//
//

$overlay-background: var(--color-light);

.o-overlay {
  background-color: $overlay-background;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  transition: var(--transition-smooth);
  z-index: 80;
}



/** **************
 * Toggle
 */
.o-overlay__toggle {
  @include element-invisible;
  display: none;

  &:focus {
    outline: none;
  }

  &:checked {
    ~ .o-overlay {
      transform: translate(0);
    }
  }
}

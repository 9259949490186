// Preview images
//
// Product preview images.
// Switching from slider to thumbnail list, depending on viewport.
//
// | Image type    | Size       |
// |:-------------:|:----------:|
// | thumbnail     | 30x37      |
// | small         | 520x635    |
// | large         | 950x1160   |
// | lightbox      | 1128x1500  |
//
// Markup: previews.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Containers.previews

$previews-breakpoint: xlarge;
$previews-ribbon-background: rgba(0, 0, 0, .6);
$previews-ribbon-color: var(--color-light);

/** **************
 * Basic styles
 */
.m-previews {
  position: relative;

  @include breakpoint($previews-breakpoint) {
    @include u-baseline(7, margin-bottom);
  }

  img {
    @include u-img-responsive;
  }

  [data-spinner] {
    position: absolute;
    left: calc(50% - #{rem-calc(20)});
    top: calc(50% - #{rem-calc(25)});
    z-index: 10;

    @include breakpoint($previews-breakpoint) {
      display: none;
    }
  }
}







/** ***********
 * Modifier: Hidden items
 */
.m-previews--hidden {
  @include breakpoint($previews-breakpoint) {
    display: none;
  }
}






/** **************
 * Items
 */
.m-previews__items {
  @include u-baseline(5, margin-bottom);
  background-color: var(--color-pearl);
  overflow: hidden !important;
  padding-top: 122.1053%; // Product image format
  position: relative !important;
  transform: translate3d(0, 0, 0);

  &.owl-loaded {
    @include u-baseline(2, margin-bottom);
    background-color: transparent;
    padding-top: 0;
  }

  @include breakpoint($previews-breakpoint) {
    @include xy-grid-container($padding: 0);
    @include xy-grid;
    @include xy-gutters($negative: true);
    background-color: transparent;
    margin-bottom: 0;
    padding-top: 0;
  }
}




/** **************
 * Item
 */
.m-previews__item {
  display: none;
  transform: translate3d(0, 0, 0);
  position: relative;
  overflow: hidden;

  @include breakpoint($previews-breakpoint) {
    @include u-baseline(3, margin-bottom);
    @include xy-cell(6);

    &:not(.m-previews--hidden) {
      display: block;
    }

    // Full width if first or first two images,
    // but without more than 2 images.
    &:nth-child(1):only-child,
    &:nth-child(1):nth-last-child(2),
    &:nth-child(2) {
      @include xy-cell();
    }

    // If more than 2 images
    &:nth-child(2):not(:last-child) {
      @include xy-cell(6);
    }

    // If more than 6 images
    &:nth-child(n+7) {
      @include xy-cell(4);
    }

    // If more than 12 images
    &:nth-child(n+13) {
      @include xy-cell(3);
    }
  }
}

.owl-loaded {
  .m-previews__item {
    display: block;
  }
}




/** **************
 * Image
 */
.m-previews__image {
  img:first-child {
    padding-top: 0;
  }
}










/** ***********
 * More button
 */
.m-previews__more {
  display: none;

  @include breakpoint($previews-breakpoint) {
    @include u-baseline(2, margin-top);
    display: block;
    text-align: center;
  }
}









/** ***********
 * Ribbon if not image available
 */
.m-previews__ribbon {
  @include flex;
  @include flex-align(center, middle);
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;

  // tbd.
  // ~ .c-image {
  //   filter: blur(5px);
  //   transform: scale(1.1);
  // }
}

.m-previews__ribbon-inner {
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);
  @include u-baseline(4, padding-left);
  @include u-baseline(4, padding-right);
  background-color: $previews-ribbon-background;
  color: $previews-ribbon-color;
  text-align: center;
  width: 100%;

  p {
    margin: 0 auto;
    max-width: rem-calc(450);
  }
}

// Flyer List
//
// List with borders and heading for [flyer](section-promotion.html#kssref-promotion-flyer) modules.
// Don't use as template include!
//
// Markup: flyer-list.hbs
//
// Type: organism
//
// Style guide: Promotion.Flyer.Flyerlist

.o-flyer-list {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;
}




/** **************
 * Inner container
 */
.o-flyer-list__inner {
  @include u-single-cell;

  > [class*='heading--'] {
    @include u-baseline(2, margin-bottom);
  }
}




/** **************
 * List
 */
.o-flyer-list__list {
  @include u-list-reset;

  .m-flyer {
    @include xy-grid-container($padding: 0);
    @include xy-grid;
    @include xy-gutters($negative: true);
  }
}




/** **************
 * List item
 */
.o-flyer-list__item {
  @include u-baseline(3, padding-top);
  @include u-baseline(3, padding-bottom);
  border-top: $global-border-dotted;
}

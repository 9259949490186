// Card
//
// White background wrapper with padding for misc. contents.
//
// To use it in your modules use the `c-card` mixin.
//
// Markup: card.hbs
//
// Type: component
//
// Style guide: Containers.cards.card
// @TODO: Create utility class

@import '_mixins';

.c-card {
  @include c-card;
}

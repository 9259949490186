// Input field
//
// Input field types with optional help text.
//
// Markup: forms-input.hbs
//
// Weight: 4
//
// Type: component
//
// Style guide: Forms.Input

.c-input {
  @include u-input-styles;
  @include font-small; // override
  @include u-baseline(3, margin-bottom);
  height: var(--form-input-height, #{rem-calc($global-controls-height)});
  padding: var(--form-input-padding, #{rem-baseline(1)} #{rem-baseline(2)});

  &:active,
  &:focus {
    @include u-input-styles($active: true);
  }
}




/** **************
 * Large input (Search form etc.)
 */
.c-input--large {
  --form-input-height: #{rem-calc($global-controls-height-large)};
  --form-input-padding: #{rem-calc(12)} #{rem-calc(20)};
}





/** ***********
 * Input postfix
 */
.c-input__postfix {
  @include font-tiny;
  @include u-baseline(1, margin-top);
  height: var(--form-input-height, #{rem-calc($global-controls-height)});
  line-height: 2.2;
  margin-left: -2px;
  padding: var(--form-input-padding, #{rem-baseline(1)} #{rem-baseline(2)});

  &:not(.plain) {
    @include u-input-styles;
    background-color: var(--postfix-background, var(--color-slate-petrol));
    border-radius: 0;
    border-top-right-radius: rem-calc(3);
    border-bottom-right-radius: rem-calc(3);
    color: var(--color-light);
  }
}




/** **************
 * Datepicker modifier:
 * Disable redonly styles for datepicker fields.
 */
.c-input--datepicker {
  &[readonly] {
    background-color: inherit;
  }
}








// Input field with error
//
// This works with every form element like `textarea` etc.
//
// Markup: forms-input-error.hbs
//
// Weight: 4
//
// Style guide: Forms.Input.Input-Error

// Input field with help text
//
// Markup: forms-input-helptext.hbs
//
// Weight: 5
//
// Style guide: Forms.Input.Input-Helptext

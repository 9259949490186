/// Global CSS custom properties
@use 'sass:math';

// Calculate font metrics by baseline
@function font-css-var($size: 16, $line: 24, $family: 'display', $weight: 'normal') {
  $font-size: rem-calc($size);
  $line-height: math.div($line, $size);

  @return '#{$font-size}/#{$line-height}';
}

:root {
  --baseline: #{$global-baseline};

  // Colors
  @each $key, $value in $color-theme-list {
    @include color-properties($key, $value);
  }

  // Fonts
  --font-copy: #{font(base, 'copy')};
  --font-display: #{font(base, 'display')};
  --font-13: #{font-css-var(13, 16, 'copy')} var(--font-copy);
  --font-16-copy: #{font-css-var(16, 24, 'copy')} var(--font-copy);
  --font-16-display: #{font-css-var(16, 24, 'display')} var(--font-display);
  --font-18-copy: #{font-css-var(18, 32, 'copy')} var(--font-copy);
  --font-18-display: #{font-css-var(18, 32, 'display')} var(--font-display);
  --font-18-narrow-copy: #{font-css-var(18, 24, 'copy')} var(--font-copy);
  --font-18-narrow-display: #{font-css-var(18, 24, 'display')} var(--font-display);
  --font-20-copy: #{font-css-var(20, 32, 'copy')} var(--font-copy);
  --font-20-display: #{font-css-var(20, 32, 'display')} var(--font-display);
  --font-20-narrow-display: #{font-css-var(20, 24, 'display')} var(--font-display);
  --font-22-copy: #{font-css-var(22, 32, 'copy')} var(--font-copy);
  --font-22-display: #{font-css-var(22, 32, 'display')} var(--font-display);
  --font-24-copy: #{font-css-var(24, 32, 'copy')} var(--font-copy);
  --font-24-display: #{font-css-var(24, 32, 'display')} var(--font-display);
  --font-28-display: #{font-css-var(28, 40, 'display')} var(--font-display);
  --font-32-display: #{font-css-var(32, 48, 'display')} var(--font-display);
  --font-40-display: #{font-css-var(40, 48, 'display')} var(--font-display);
  --font-64-display: #{font-css-var(64, 72, 'display')} var(--font-display);
  --font-80-display: #{font-css-var(80, 112, 'display')} var(--font-display);

  // Shadows
  --shadow-light: 0 1px 1px rgba(0, 0, 0, .03), 0 2px 2px rgba(0, 0, 0, .03), 0 4px 4px rgba(0, 0, 0, .03), 0 8px 8px rgba(0, 0, 0, .03), 0 16px 16px rgba(0, 0, 0, .03);
  --shadow-medium: 0 1px 1px rgba(0, 0, 0, .06), 0 2px 2px rgba(0, 0, 0, .06), 0 4px 4px rgba(0, 0, 0, .06), 0 8px 8px rgba(0, 0, 0, .06), 0 16px 16px rgba(0, 0, 0, .06);
  --shadow-heavy: 0 1px 1px rgba(0, 0, 0, .12), 0 2px 2px rgba(0, 0, 0, .12), 0 4px 4px rgba(0, 0, 0, .12), 0 8px 8px rgba(0, 0, 0, .12), 0 16px 16px rgba(0, 0, 0, .12);
  --shadow-inset: inset 0 1px 3px rgba(0, 0, 0, .39), 0 -1px 1px var(--color-light), 0 1px 0 var(--color-light);
  --shadow-popup: 0 2px 9px rgba(0, 0, 0, .1), 0 3px 3px rgba(0, 0, 0, .025);

  // Tints
  --tint-dark-light: rgba(0, 0, 0, .2);
  --tint-dark-medium: rgba(0, 0, 0, .4);
  --tint-dark-heavy: rgba(0, 0, 0, .6);
  --tint-light-light: rgba(255, 255, 255, .2);
  --tint-light-medium: rgba(255, 255, 255, .4);
  --tint-light-heavy: rgba(255, 255, 255, .6);

  // Tansitions
  --transition-default: all .05s ease-in-out;
  --transition-smooth: all .25s ease-in-out;
  --transition-smoother: all .45s ease-in-out;
}

// Radio button
//
// Radio button styles
//
// Weight: 1
//
// Markup: forms-radio.hbs
//
// .c-radio--extended - Showing extended `:checked` styles
//
// Type: component
//
// Style guide: Forms.Radio
@use 'sass:math';
$forms-radio-size: rem-calc($global-baseline * 4);
$forms-radio-padding: $forms-radio-size + (rem-calc($global-baseline));

.c-radio {
  @include u-baseline(1, margin-bottom);

  label {
    display: block;
  }

  [type='radio'] {
    @include element-invisible;

    // Styles for checked input
    &:checked {
      ~ .c-radio__value {
        font-weight: bold;
      }

      ~ .c-radio__value:before {
        background-color: var(--color-geyser);
      }

      ~ .c-radio__value:after {
        background-color: var(--color-petrol);
        border-radius: 50%;
        content: '';
        width: .75em;
        height: .75em;
        position: absolute;
        left: rem-calc(6);
        top: rem-calc(9);
      }
    }
  }
}




/** **************
 * Modifier: extended :checked styles
 */
.c-radio--extended {
  [type='radio']:checked {
    ~ .c-radio__value {
      background-color: var(--color-ice);
      box-shadow: var(--shadow-light);

      .c-radio__info {
        --form-radio-info-display: block;
      }
    }

    ~ .c-radio__value:after {
      left: rem-calc(22);
      top: rem-calc(30);
    }
  }

  .c-radio__value {
    --form-radio-value-display: block;
    --form-radio-value-padding-left: #{rem-baseline(8)};
    --form-radio-value-left: #{rem-baseline(2)};
    --form-radio-value-top: #{rem-baseline(3)};
    @include u-baseline(3, padding-top);
    @include u-baseline(2, padding-right);
    @include u-baseline(3, padding-bottom);
    background-color: var(--color-light);

    p {
      margin: 0;
    }
  }
}





/** **************
 * Value
 */
.c-radio__value {
  display: var(--form-radio-value-display, inline-block);
  padding-left: var(--form-radio-value-padding-left, #{$forms-radio-padding});
  position: relative;

  &:before {
    --form-input-border-radius: 50%;
    @include u-input-styles;
    @include u-icon-relative-size;
    @include u-baseline(1, margin-right);
    content: '';
    display: inline-block;
    position: absolute;
    left: var(--form-radio-value-left, 0);
    top: var(--form-radio-value-top, #{rem-calc(3)});
    vertical-align: middle;
  }
}



/** **************
 * Additional info (payments)
 */
.c-radio__info {
  @include u-baseline(3, margin-top);
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-right);
  border-top: $global-border-dotted;
  color: var(--color-petrol);
  display: var(--form-radio-info-display, none);
  font-weight: normal;
  margin-left: -($forms-radio-padding * 1.6);
  margin-right: -(math.div($forms-radio-padding, 2.3));
  padding-left: $forms-radio-padding * 1.6;
}





/** **************
 * Icons (payments)
 */
.c-radio__icons {
  @include u-baseline(2, right);
  @include u-baseline(3, top);
  margin: 0;
  position: absolute;

  @include breakpoint(medium) {
    @include u-baseline(2, top);
  }

  svg {
    width: rem-calc($global-payment-icon-width-small);
    height: rem-calc($global-payment-icon-height-small);

    @include breakpoint(medium) {
      width: rem-calc($global-payment-icon-width);
      height: rem-calc($global-payment-icon-height);
    }
  }
}

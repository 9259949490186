// Payments (list)
//
// Widget with payment icons to display in cart.
//
// Markup: paymentslist.hbs
//
// Type: module
//
// Style guide: Containers.paymentslist

.m-paymentslist {
  @include c-card;
  @include u-baseline(1, margin-top);

  ul {
    @include u-list-reset;
    @include u-baseline(2, margin-top);
    font-size: 0;
  }

  li {
    @include u-baseline(1, margin-right);
    @include u-baseline(1, margin-bottom);
    display: inline-block;
  }

  svg {
    width: rem-calc($global-payment-icon-width);
    height: rem-calc($global-payment-icon-height);
    transform: translate3d(0, 0, 0);
  }
}

// Strip
//
// A line of max. 3 text boxes.
//
// Markup: strip.hbs
//
// Type: organism
//
// Style guide: Containers.strip
@use 'sass:math';
$strip-gap: 7;
$strip-border-pos: math.div($strip-gap, 2);
$strip-line-size: rem-calc(60);

.o-strip {
  --textbox-text-wrap: balance;
  @include u-baseline($strip-gap, gap);
  display: grid;
  justify-items: center;

  @include breakpoint(xlarge) {
    @include u-baseline($strip-gap, gap);
    grid-auto-columns: auto;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  > * {
    position: relative;

    &:not(:last-child)::after {
      @include u-baseline(-$strip-border-pos, bottom);
      border-bottom: 1px dotted var(--color-dusty);
      content: '';
      display: block;
      height: 1px;
      left: calc(50% - #{math.div($strip-line-size, 2)});
      position: absolute;
      width: $strip-line-size;

      @include breakpoint(xlarge) {
        @include u-baseline(-$strip-border-pos, right);
        border-right: 1px dotted var(--color-dusty);
        border-bottom: none;
        bottom: calc(50% - #{math.div($strip-line-size, 2)});
        left: auto;
        width: 1px;
        height: $strip-line-size;
      }
    }
  }
}

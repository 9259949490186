// Label List
//
// List that looks like a table with labels. Used for [autosuggest](section-siteelements.html#kssref-siteelements-autosuggest).
//
// Type: module
//
// Markup: labellist.hbs
//
// Style guide: Content.labellist

$labellist-label-color: var(--color-slate);
$labellist-border-color: var(--color-ice);

.m-labellist {
  list-style: none;
  margin: 0;
  padding: 0;
}







/** ***********
 * Item
 */
.m-labellist__item {
  @include flex;
  @include flex-align(left, middle);
  @include u-baseline(1, padding-top);
  @include u-baseline(1, padding-bottom);
  border-bottom: 1px solid $labellist-border-color;
}






/** ***********
 * Value
 */
.m-labellist__value {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}







/** ***********
 * Label
 */
.m-labellist__label {
  @include u-baseline(3, padding-left);
  color: $labellist-label-color;
  text-align: right;
  white-space: nowrap;
}

// Create group
//
// Container with link and form to create group in wishlist.
//
// Markup: creategroup.hbs
//
// Type: module
//
// JavaScript: true
//
// Style guide: Wishlist.creategroup

.m-creategroup {
  @include u-borderbox;
  @include u-baseline(3, margin-bottom);

  .c-icon {
    @include u-baseline(1.5, margin-right);
    background-color: var(--color-petrol);
    border-radius: 50%;
    color: var(--color-light);
    padding: rem-calc(4);
    width: 1.25em;
    height: 1.25em;

    svg {
      transform: translate3d(0, 0, 0);
    }
  }
}






/** ***********
 * Form
 */
.m-creategroup__form {
  @include u-baseline(2, padding-top);

  @include breakpoint(medium) {
    @include u-baseline(4, padding-left);
    max-width: rem-calc(600);
  }
}

/**
 * Grid Loco
 *
 * A super flexible CSS grid using CSS grid and custom properties.
 * Create your grid in your modules or on the fly in the DOM without
 * stiff classes or mixins. Do what you want, but don't forget the omen!
 *
 * @author Martin Szymanski
 * @link https://github.com/elfacht/grid-loco/
 * @version tbd.
 * @license tbd. but something MIT, I guess.
 *
 * @var {String} --grid-max (Grid max width in px)
 * @var {String} --grid-gap-[<small>|<medium>|<large>|<xlarge>] (Gap between the columns in px)
 * @var {Number} --grid-rows (Amount of grid rows)
 * @var {Number} --grid-cols (Amount of grid cols)
 * @var {String} --grid-align [<stretch>|<center>|<start>|<end>] (Align of all items inside grid)
 * @var {String} --grid-height (Custom grid height)
 * @var {Number} --grid-row-start
 * @var {Number} --grid-row-start-[<small>|<medium>|<large>|<xlarge>]
 * @var {Number} --grid-row-end
 * @var {Number} --grid-row-end-[<small>|<medium>|<large>|<xlarge>]
 * @var {Number} --grid-col-start
 * @var {Number} --grid-col-start-[<small>|<medium>|<large>|<xlarge>]
 * @var {Number} --grid-col-end
 * @var {Number} --grid-col-end-[<small>|<medium>|<large>|<xlarge>]
 * @var {Number} --grid-col-zindex
 */

// ––– Grid defaults –––
// Set default grid max-width
$grid-max-width: $global-max-width;

// Set Grid gap widths
$grid-gap: (
  small: 16px,
  medium: 16px,
  large: 24px,
  xlarge: 24px,
  xxlarge: 24px,
  xxxlarge: 40px
) !default;

// ––– Grid container –––
.u-grid {
  // Grid gap sizes per breakpoint
  --grid-gap-default: #{map-get($grid-gap, 'small')};
  --grid-gap-medium: #{map-get($grid-gap, 'medium')};
  --grid-gap-large: #{map-get($grid-gap, 'large')};
  --grid-gap-xlarge: #{map-get($grid-gap, 'xlarge')};
  --grid-gap-xxlarge: #{map-get($grid-gap, 'xxlarge')};
  --grid-gap-xxxlarge: #{map-get($grid-gap, 'xxxlarge')};

  // Grid gap default variable
  --grid-gap: var(--grid-gap-small, var(--grid-gap-default));

  // Calculate column width by grid max. width,
  // column amount and grid gap
  --grid-col-width: calc(calc(var(--grid-max, #{rem-calc($grid-max-width)}) - calc(var(--grid-cols, 12) * var(--grid-gap, var(--grid-gap-default)))) / var(--grid-cols, 12));

  // Need to be a string, otherwise
  // the linter will hurt you.
  $grid-template-cols: '[start] minmax(0, 1fr) [grid-start] repeat(var(--grid-cols, 12), minmax(0, var(--grid-col-width))) [grid-end] minmax(0, 1fr) [end]';

  // Box model (margin, padding)
  --grid-margin-default: 0;
  --grid-padding-default: 0;
  --grid-margin: var(--grid-margin-small, var(--grid-margin-default));
  --grid-padding: var(--grid-padding-small, var(--grid-padding-default));

  margin-bottom: var(--grid-margin);
  padding-top: var(--grid-padding);
  padding-bottom: var(--grid-padding);

  @include breakpoint(large) {
    --grid-margin: var(--grid-margin-large, var(--grid-margin-small, var(--grid-margin-default)));
    --grid-padding: var(--grid-padding-large, var(--grid-padding-small, var(--grid-padding-default)));
  }

  // Use grid only if not full-bleed
  &:not(.u-grid--full) {
    align-items: var(--grid-align, start);
    display: grid;
    gap: var(--grid-gap);
    grid-template-rows: repeat(var(--grid-rows, 1), var(--grid-height));
    grid-template-columns: var(--grid-columns, #{$grid-template-cols});

    @include breakpoint(medium) {
      --grid-gap: var(--grid-gap-medium, var(--grid-gap-small, var(--grid-gap-default)));
    }

    @include breakpoint(large) {
      --grid-gap: var(--grid-gap-large, var(--grid-gap-medium, var(--grid-gap-small, var(--grid-gap-default))));
    }

    @include breakpoint(xlarge) {
      --grid-gap: var(--grid-gap-xlarge, var(--grid-gap-large, var(--grid-gap-medium, var(--grid-gap-small, var(--grid-gap-default)))));
    }

    @include breakpoint(xxlarge) {
      --grid-gap: var(--grid-gap-xxlarge, var(--grid-gap-xlarge, var(--grid-gap-large, var(--grid-gap-medium, var(--grid-gap-small, var(--grid-gap-efault))))));
    }

    @include breakpoint(xxxlarge) {
      --grid-gap: var(--grid-gap-xxxlarge, var(--grid-gap-xxlarge, var(--grid-gap-xlarge, var(--grid-gap-large, var(--grid-gap-medium, var(--grid-gap-small, var(--grid-gap-efault)))))));
    }
  }
}

// ––– Grid element –––
.u-grid__cell {
  // Element column positions
  --grid-col-start-default: grid-start;
  --grid-col-end-default: grid-end;
  --grid-col-start: var(--grid-col-start-small, var(--grid-col-start-default));
  --grid-col-end: var(--grid-col-end-small, var(--grid-col-end-default));

  // Element row positions
  --grid-row-start-default: auto;
  --grid-row-end-default: auto;
  --grid-row-start: var(--grid-row-start-small, var(--grid-row-start-default));
  --grid-row-end: var(--grid-row-end-small, var(--grid-row-end-default));

  align-self: var(--grid-align-self, stretch);
  grid-column: var(--grid-col-start) / var(--grid-col-end);
  grid-row: var(--grid-row-start) / var(--grid-row-end);
  position: relative;
  z-index: var(--grid-col-zindex);

  @include breakpoint(medium) {
    --grid-col-start: var(--grid-col-start-medium, var(--grid-col-start-small, var(--grid-col-start-default)));
    --grid-col-end: var(--grid-col-end-medium, var(--grid-col-end-small, var(--grid-col-end-default)));
    --grid-row-start: var(--grid-row-start-medium, var(--grid-row-start-small, var(--grid-row-start-default)));
    --grid-row-end: var(--grid-row-end-medium, var(--grid-row-end-small, var(--grid-row-end-default)));
  }

  @include breakpoint(large) {
    --grid-col-start: var(--grid-col-start-large, var(--grid-col-start-medium, var(--grid-col-start-small, var(--grid-col-start-default))));
    --grid-col-end: var(--grid-col-end-large, var(--grid-col-end-medium, var(--grid-col-end-small, var(--grid-col-end-default))));
    --grid-row-start: var(--grid-row-start-large, var(--grid-row-start-medium, var(--grid-row-start-small, var(--grid-row-start-default))));
    --grid-row-end: var(--grid-row-end-large, var(--grid-row-end-medium, var(--grid-row-end-small, var(--grid-row-end-default))));
  }

  @include breakpoint(xlarge) {
    --grid-col-start: var(--grid-col-start-xlarge, var(--grid-col-start-large, var(--grid-col-start-medium, var(--grid-col-start-small, var(--grid-col-start-default)))));
    --grid-col-end: var(--grid-col-end-xlarge, var(--grid-col-end-large, var(--grid-col-end-medium, var(--grid-col-end-small, var(--grid-col-end-default)))));
    --grid-row-start: var(--grid-row-start-xlarge, var(--grid-row-start-large, var(--grid-row-start-medium, var(--grid-row-start-small, var(--grid-row-start-default)))));
    --grid-row-end: var(--grid-row-end-xlarge, var(--grid-row-end-large, var(--grid-row-end-medium, var(--grid-row-end-small, var(--grid-row-end-default)))));
  }

  @include breakpoint(xxlarge) {
    --grid-col-start: var(--grid-col-start-xxlarge, var(--grid-col-start-xlarge, var(--grid-col-start-large, var(--grid-col-start-medium, var(--grid-col-start-small, var(--grid-col-start-default))))));
    --grid-col-end: var(--grid-col-end-xxlarge, var(--grid-col-end-xlarge, var(--grid-col-end-large, var(--grid-col-end-medium, var(--grid-col-end-small, var(--grid-col-end-default))))));
    --grid-row-start: var(--grid-row-start-xxlarge, var(--grid-row-start-xlarge, var(--grid-row-start-large, var(--grid-row-start-medium, var(--grid-row-start-small, var(--grid-row-start-default))))));
    --grid-row-end: var(--grid-row-end-xxlarge, var(--grid-row-end-xlarge, var(--grid-row-end-large, var(--grid-row-end-medium, var(--grid-row-end-small, var(--grid-row-end-default))))));
  }
}

// ––– Grid for the modal (un-grid) –––
.u-grid--modal {
  --grid-columns: 1fr;
}

// ––– Grid box model –––
.u-grid--padding-s {
  --grid-padding-small: #{rem-baseline(5)};
  --grid-padding-large: #{rem-baseline(7)};
}

.u-grid--padding-l {
  --grid-padding-small: #{rem-baseline(7)};
  --grid-padding-large: #{rem-baseline(12)};
}

.u-grid--margin-s {
  --grid-margin-small: #{rem-baseline(5)};
  --grid-margin-large: #{rem-baseline(7)};
}

.u-grid--margin-l {
  --grid-margin-small: #{rem-baseline(7)};
  --grid-margin-large: #{rem-baseline(12)};
}


// ––– Grid layouts –––
.u-grid--wide {
  --grid-col-start-large: 3;
  --grid-col-end-large: 13;
}

.u-grid--narrow {
  --grid-col-start-large: 4;
  --grid-col-end-large: 12;
}

.u-grid--stretch {
  --grid-max: #{$global-max-width * 1.275};
}

.u-grid--content {
  > .u-grid__cell:first-child {
    --grid-col-start-xlarge: grid-start;
    --grid-col-end-xlarge: 10;
    --grid-col-start-xxlarge: grid-start;
    --grid-col-end-xxlarge: 9;
  }

  > .u-grid__cell:last-child:not(:first-child) {
    --grid-col-start-xlarge: 10;
    --grid-col-end-xlarge: grid-end;
  }
}

.u-grid--8-4 {
  > .u-grid__cell:first-child {
    --grid-col-start-xlarge: grid-start;
    --grid-col-end-xlarge: 10;
  }

  > .u-grid__cell:last-child:not(:first-child) {
    --grid-col-start-xlarge: 10;
    --grid-col-end-xlarge: grid-end;
  }
}

.u-grid--double {
  > .u-grid__cell {
    &:nth-child(odd) {
      --grid-col-start-xlarge: grid-start;
      --grid-col-end-xlarge: 8;
    }

    &:nth-child(even) {
      --grid-col-start-xlarge: 8;
      --grid-col-end-xlarge: grid-end;
    }
  }
}

.u-grid--3-col {
  > .u-grid__cell {
    &:nth-child(odd) {
      --grid-col-start-medium: grid-start;
      --grid-col-end-medium: 8;
    }

    &:nth-child(even) {
      --grid-col-start-medium: 8;
      --grid-col-end-medium: grid-end;
    }

    &:nth-child(3n+1) {
      --grid-col-start-xlarge: grid-start;
      --grid-col-end-xlarge: 6;
    }

    &:nth-child(3n+2) {
      --grid-col-start-xlarge: 6;
      --grid-col-end-xlarge: 10;
    }

    &:nth-child(3n+3) {
      --grid-col-start-xlarge: 10;
      --grid-col-end-xlarge: grid-end;
    }
  }
}

.u-grid--4-col {
  > .u-grid__cell {
    &:nth-child(odd) {
      --grid-col-start-medium: grid-start;
      --grid-col-end-medium: 8;
    }

    &:nth-child(even) {
      --grid-col-start-medium: 8;
      --grid-col-end-medium: grid-end;
    }

    &:nth-child(4n+1) {
      --grid-col-start-xlarge: grid-start;
      --grid-col-end-xlarge: 5;
    }

    &:nth-child(4n+2) {
      --grid-col-start-xlarge: 5;
      --grid-col-end-xlarge: 8;
    }

    &:nth-child(4n+3) {
      --grid-col-start-xlarge: 8;
      --grid-col-end-xlarge: 11;
    }

    &:nth-child(4n+4) {
      --grid-col-start-xlarge: 11;
      --grid-col-end-xlarge: grid-end;
    }
  }
}

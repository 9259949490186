// Button
//
// This is the different heading types, re-usable with CSS classes.
//
// Font-sizes and styles are defined with mxins in `utils/_typography.scss`.
//
// Markup: button.hbs
//
// .c-button--highlight - Button for special purposes (Light consultancy)
// .c-button--secondary - Button for secondary purposes (reset, back etc.)
// .c-button--tertiary - Button for teriary purposes (phone etc.)
// .c-button--custom - Custom apps button (e.g. energy costs calculator).
// .c-button--hollow - Button with thin line
// .c-button--hollow-light - Button with thin line on dark background
// .c-button--is-next - Button has arrow icon for next step
// .c-button--large - Large button, works with every type
// .c-button--xlarge - X-large button, works with every type
// .c-button--small - Small button for rare usage like [Cookie Consent.](section-siteelements.html#kssref-siteelements-cookie)
// .c-button--block - Block button
// .c-button--block-mobile - Block button, but only on small devices
//
// Type: component
//
// Style guide: Controls.Button

$button-icon-background: rgba(255, 255, 255, .15);
$button-height: rem-calc($global-controls-height);
$button-height-large: rem-calc($global-controls-height-large);
$button-height-xlarge: rem-baseline(9);
$button-height-small: rem-baseline(5);
$button-spinner-size: 1.5em;
$button-spinner-width: rem-calc(4);
$button-spinner-track-color: rgba(255, 255, 255, .2);

@import '_mixins';

/** **************
 * Basic / primary button
 */
.c-button {
  @include font-button;
  box-shadow: var(--shadow-medium) !important; // Thanks, Magento …
  background-color: var(--button-background, var(--color-orange));
  border: 1px solid var(--button-color, transparent);
  border-radius: var(--button-border-radius, rem-baseline(3));
  color: var(--button-color, var(--color-light));
  cursor: pointer;
  display: var(--button-display, inline-block);
  height: var(--button-height, #{$button-height});
  padding-top: var(--button-padding-top, rem-calc(11));
  padding-left: var(--button-padding-left, rem-baseline(4));
  padding-right: var(--button-padding-right, rem-baseline(4));
  padding-bottom: var(--button-padding-bottom, rem-calc(11));
  text-align: center;
  white-space: nowrap;
  width: var(--button-width);

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    box-shadow: var(--shadow-heavy) !important;
    border-color: var(--button-hover-color, transparent);
    color: var(--button-hover-color, var(--color-light));
    filter: brightness(1.1);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: .7;
  }

  svg {
    vertical-align: middle;
  }

  &:not(.c-button--large) {
    .c-icon {
      color: var(--button-icon-color, currentColor);
      margin-top: var(--button-icon-margin-top, -#{rem-calc(4)});
      margin-left: var(--button-icon-margin-left, 0);
      margin-right: var(--button-icon-margin-right, .75em);
      width: var(--button-icon-size, 1.5em);
      height: var(--button-icon-size, 1.5em);
      transform: var(--button-icon-trans, 0);
    }
  }
}




/** **************
 * Large button
 */
.c-button--large {
  @include c-button-large;
}



/** **************
 * X-large button
 */
.c-button--xlarge {
  --button-border-radius: #{rem-baseline(5)};
  --button-height: #{$button-height-large};
  --button-icon-trans: translateY(-#{rem-calc(2)});
  @include font-button($size: 'xlarge-small');
  overflow: hidden;
  position: relative;

  @include breakpoint(large) {
    --button-border-radius: #{rem-baseline(7)};
    --button-height: #{$button-height-xlarge};
    --button-padding-top: #{rem-baseline(2.75)};
    --button-padding-bottom: #{rem-baseline(2.75)};
    @include font-button($size: 'xlarge');
  }

  &.c-button--has-icon {
    --button-padding-left: #{rem-baseline(3.5)};
    --button-icon-size: #{rem-baseline(4)};
    --button-icon-margin-top: 0;
    --button-icon-margin-left: 0;
    --button-icon-margin-right: #{rem-baseline(3)};
    --button-icon-trans: translateY(0);

    @include breakpoint(large) {
      --button-padding-top: #{rem-baseline(2)};
      --button-padding-left: #{rem-baseline(4)};
      --button-icon-size: #{rem-baseline(5)};
      --button-icon-trans: translateY(-#{rem-calc(1)});
    }

    &:before {
      @include u-baseline(8, width);
      background: $button-icon-background;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      z-index: 0;

      @include breakpoint(large) {
        @include u-baseline(10, width);
      }
    }
  }
}



/** **************
 * Small button
 */
.c-button--small {
  --button-height: #{$button-height-small};
  --button-padding-top: #{rem-calc(7)};
  --button-padding-bottom: #{rem-calc(7)};
}





/** **************
 * Highlight button with petrol background
 */
.c-button--highlight {
  --button-background: var(--color-petrol);
}





/** **************
 * Secondary button with dark background
 */
.c-button--secondary {
  --button-background: var(--color-slate-petrol);
}





/** **************
 * Tertiary button with dark background
 */
.c-button--tertiary {
  --button-background: var(--color-slate);
}



/** **************
 * Custom app button with blue background
 */
.c-button--custom {
  --button-background: var(--color-energy);
}




/** **************
 * Button has icon for next step
 */
.c-button--is-next {
  --button-padding-right: #{rem-baseline(2)};

  span {
    @include c-arrowlink($arrowlink-options-next);
    color: $button-color;
    position: relative;

    &:after {
      @include u-baseline(2, margin-left);
    }
  }

  &:hover span {
    color: var(--color-light);
  }
}



/** **************
 * Hollow buttons (border only)
 */
.c-button--hollow {
  --button-background: transparent;
  --button-color: var(--color-orange);
  --button-hover-background: transparent;
  --button-hover-color: var(--color-petrol);
}

.c-button--hollow-light {
  --button-background: transparent;
  --button-color: var(--color-light);
  --button-hover-background: transparent;
  --button-hover-color: var(--color-orange);

  &:focus {
    @include u-focus-styles($color: var(--color-orange));
  }
}




/** **************
 * Block buttons
 */
.c-button--block {
  --button-display: block;
  --button-width: 100%;
  margin-right: 0;
}

// button is only block for small devices
.c-button--block-mobile {
  @include breakpoint(medium down) {
    --button-display: block;
    --button-width: 100%;
  }
}




/** **************
 * Ghost button (no styles, for icon submit buttons)
 */
.c-button--ghost {
  @include font-small($type: 'copy');
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: 0;
}











// Button for Wishlist
//
// Add items to wishlist with this button. Used in [Actionbox](section-checkout.html#kssref-checkout-actionbox)
//
// Markup: button-wishlist.hbs
//
// .c-button-wishlist--active - Button is active, item is already on wishlist (unregistered user only)
//
// Type: component
//
// JavaScript: true
//
// Style guide: Controls.Button.Wishlist


/** ***********
 * Basic styles
 */
.c-button-wishlist {
  @include font-button($size: 'large');
  --button-background: transparent;
  --button-border-radius: #{rem-baseline(4)};
  --button-color: var(--color-orange);
  --button-display: block;
  --button-height: #{$button-height-large};
  --button-hover-background: transparent;
  --button-hover-color: var(--color-petrol);
  --button-width: 100%;
  overflow: hidden;
  position: relative;

  @include breakpoint(xxlarge) {
    --button-width: #{rem-baseline(7)};
    --button-padding-left: 0;
    --button-padding-right: 0;
    --button-icon-margin-right: 0;
  }

  &:not(.c-button--large) .c-icon {
    --button-icon-margin-top: -#{rem-calc(2)};
    --button-icon-margin-left: 0;

    @include breakpoint(xxlarge) {
      --button-icon-margin-left: #{rem-calc(2)};
      --button-icon-margin-top: 0;
      --button-icon-size: 1.25em;
    }
  }
}







/** ***********
 * Icon wrapper for positioning
 */
.c-button-wishlist__icon {
  display: inline-block;
  position: relative;
}




// Button w/ icon
//
// Button has an icon.
//
// Markup: button-with-icon.hbs
//
// Type: component
//
// JavaScript: false
//
// Style guide: Controls.Button.WithIcon



/** ***********
 * Spinner
 */
.c-button__spinner {
  animation: buttonspinner 1.1s infinite linear;
  border-radius: 50%;
  display: block;
  border-top: $button-spinner-width solid $button-spinner-track-color;
  border-right: $button-spinner-width solid $button-spinner-track-color;
  border-bottom: $button-spinner-width solid $button-spinner-track-color;
  border-left: $button-spinner-width solid var(--color-light);
  float: left;
  margin-right: rem-calc(10);
  transform: translateZ(0);
  width: $button-spinner-size;
  height: $button-spinner-size;
}

@include keyframes(buttonspinner) {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

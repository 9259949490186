// List group
//
// Mostly used for list groups on mobile screens.
//
// Markup: listgroup.hbs
//
// Type: component
//
// Style guide: Lists.Listgroup

/** **************
 * Wrapper
 */
.c-listgroup {
  @include u-listgroup;
  @include u-module-margin;
}

// Connector
//
// Connected accounts used in customer account section.
//
// Markup: connector.hbs
//
// Type: module
//
// Style guide: Containers.connector

$connector-border-color: var(--color-slush-slate);
$connector-status-color: var(--color-slate-petrol);

.m-connector {
  @include u-baseline(3, margin-top);
  @include u-baseline(4, margin-bottom);
  list-style: none;
  padding-left: 0;
}








/** ***********
 * Item
 */
.m-connector__item {
  @include u-baseline(2, margin-bottom);
  @include u-baseline(3, padding);
  border: 1px solid var(--color-slush-ice);
  background-color: var(--color-ice);
  border-radius: rem-calc(3);
  text-align: center;

  @include breakpoint(large) {
    @include flex;
    @include flex-align(left, middle);
    @include u-baseline(2, margin-bottom);
  }
}








/** ***********
 * Button
 */
.m-connector__button {
  @include u-baseline(2, margin-bottom);

  @include breakpoint(large) {
    flex: 0 1 45%;
    margin-bottom: 0;
  }
}









/** ***********
 * Status text
 */
.m-connector__status {
  color: $connector-status-color;

  @include breakpoint(large) {
    @include u-baseline(1, padding-left);
    flex: 0 1 55%;
    text-align: right;
  }
}
